import { Dropdown } from "react-bootstrap";
import useDropdown from "../../hooks/dropdown.hook";
import SelectArrowIcon from "../icons/select-arrow.icon";
import SuccessSignIcon from "../icons/success-sign.icon";

const YesNoDropdown = ({ selected, setSelected, id, isFixed }) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({});
  const popperConfig = {
    ...(isFixed ? { strategy: "fixed" } : {}),
  };
  const items = ['Yes', 'No'];
  return (
    <Dropdown
      show={isOpen} 
      className="transparennt-wrapper-dropdown"
      onToggle={() => setIsOpen(prev => !prev)}
    >
      <Dropdown.Toggle ref={refBtn} id={id ?? 'dropdown-basic'}>
        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
          <span className={`f-500 f-14 ${selected ? 'f-black' : 'f-grey'}`}>
            {selected ?? 'Select'}
          </span>
          <span
            style={{
              ...(isOpen ? { transform: "rotate(180deg)" } : {}),
            }}
          >
            <SelectArrowIcon width="12px" height="8px" />
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount ref={refMenu} popperConfig={popperConfig} className="yes-no-modal">
        {items.map((item, index) => (
          <div
            key={index} 
            className="d-flex align-items-center justify-content-between py-1 px-3 item"
            onClick={() => {
              setSelected(item)
              setIsOpen(false)
            }}
          >
            <span>
              {item}
            </span>
            {selected === item && (
              <SuccessSignIcon width="12px" height="9px" />
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default YesNoDropdown;
