import React, { useState, useEffect } from "react";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import "./cdsa.scss";
import { Form, Button, Spinner } from "react-bootstrap";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import {
  getAPIData,
  getScenarioPacks as DropdownApi,
  postRequest,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { GreyLoader } from "../../Theme/APILoaders";
import CDSAmainpageloader from "../AttackSurfaceLoaders/CDSAmainpageloader";
import ConnectionToolModal from "../../Integrations/ConnectToolModal";
import { AttackSurfaceAccessUI } from "../../supplyChainMonitoring/SupplyCompany/SupplyCompany";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
import integrationUtils from "../../../Utils/integration.utils";
const CDSA = ({ goTo, refreshMenuItems, supplierId, accessLevel }) => {
  const { authTokens } = useAuth();
  const [showResetModal, setShowResetModal] = useState(false);
  const [ToolsList, setToolsList] = useState([]);
  const [ServicesList, setServicesList] = useState([]);
  const [CapabilitiesList, setCapabilitiesList] = useState([]);
  const [newToolName, setNewToolName] = useState("");
  const [newToolSelected, setNewToolSelected] = useState(false);
  const [addingNewTool, setAddingNewTool] = useState(false);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [connectToolDetail, setConnectToolDetail] = useState(false);
  const [connectModalShow, setConnectModalShow] = useState(false);
  const [_, setIntegration] = useState(false);
  const [technologyData, setTechnologyData] = useState(false);
  useEffect(() => {
    setMainPageLoading(true);
    getPageData();
  }, []);
  function getPageData() {
    getAPIData(438, authTokens, {
      optionEnum2: accessLevel,
      optionEnum1: supplierId,
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response?.mr?.Success) {
          let updated_data = [
            ...response?.CDSAMainMenuOption_List?.[0].ToolAttestationRow_List,
          ].map((item) => {
            return {
              ...item,
              selected: item?.SelectedTool
                ? { id: 1, category: "Yes" }
                : item?.ShowNoCapability
                ? { id: 2, category: "No" }
                : null,
              data: [
                { id: 1, category: "Yes" },
                { id: 2, category: "No" },
              ],
              addTool: false,
              loading: false,
              changingTool: false,
              connectingIntegration: false,
            };
          });

          setToolsList(updated_data);
          setServicesList(
            response?.CDSAMainMenuOption_List?.[1].ToolAttestationRow_List
          );
          setCapabilitiesList(
            response?.CDSAMainMenuOption_List?.[2].ToolAttestationRow_List
          );
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function updateRowData(toolIndex, optionIndex = null, parent = null) {
    getAPIData(263, authTokens)
      .then((response) => {
        if (response?.mr?.Success) {
          let old_data = { ...ToolsList[toolIndex] };
          let new_data = {
            ...response?.CDSAMainMenuOption_List?.[0].ToolAttestationRow_List[
              toolIndex
            ],
          };
          let updated_object = { ...old_data, ...new_data };

          let new_list = [...ToolsList];
          new_list[toolIndex] = updated_object;

          if (optionIndex !== null) {
            new_list[toolIndex].optionList[optionIndex].changingTool = false;
          }
          if (parent) {
            var panel = parent.querySelector(".panel");
            if (panel?.style?.maxHeight) {
              panel.style.maxHeight = null;
            }
          }
          setTimeout(() => {
            setToolsList(new_list);
          }, 10);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function ToggleDropdownApi(pageEnum, optionEnum) {
    return new Promise((resolve, reject) => {
      DropdownApi(authTokens, pageEnum, optionEnum)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function ToggleAccordion(e) {
    // var acc = document.getElementById(e.target.id);
    // var parent = e.target.closest(".border-item");
    // // var panel = e.target.nextElementSibling;
    // var panel = parent.querySelector(".panel");
    // if (panel?.style?.maxHeight) {
    //   panel.style.maxHeight = null;
    // } else {
    //   panel.style.maxHeight = panel.scrollHeight + "px";
    // }
  }

  // Connection Tool Modal
  async function getIntegrationStateDetail(id, button_function = 1) {
    if (button_function !== 0) {
      const response = await getAPIData("65", authTokens, {
        OptionEnum1: id,
        OptionEnum2: button_function,
      });
      if (button_function === 1 || button_function === 2) {
        setConnectToolDetail(response);
        setConnectModalShow(true);
      } else if (button_function === 4) {
        if (response.Success === true) {
          return response;
        } else {
        }
      }
      if(button_function===6 && response?.Message){
        updateAccessKeyInLocalStorage(response?.Message)
         
      }
    }
  }

  async function getIntegrationDataById(title, order, id) {
    setTechnologyData({ title: title, order: order, id: id });
    const integration = await getAPIData("64", authTokens, {
      OptionEnum1: order,
      OptionEnum2: id,
    });
    setIntegration(integration.IntegrationList);
  }

  return (
    <>
      {mainPageLoading ? (
        <CDSAmainpageloader />
      ) : (
        <div className="cdsa-wrapper">
          <div className="mt-2">
            <AttackSurfaceAccessUI />
          </div>

          <div className="font-weight-bold mt-3">Tools</div>
          <div
            className="bg-grey radius-8 border-parent"
            style={{
              pointerEvents: "none",
            }}
          >
            {ToolsList.map((tool, toolIndex) => {
              return (
                <div
                  style={{
                    display: "flex",
                  }}
                  className={` flex-column border-item  ${
                    tool?.Visible ? "" : "d-none"
                  }  `}
                >
                  <div
                    className="d-flex flex-column align-items-center  w-100 pointer px-4  py-3 bg-hover-row"
                    id={toolIndex}
                    // onClick={ToggleAccordion}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100  ">
                      <div className=" d-flex align-items-baseline">
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-1"
                          style={{
                            visibility: `${
                              "hidden"
                              // !tool?.ResponseProvided ? "visible" : "hidden"
                            }`,
                          }}
                        />
                        <div className="d-flex flex-column">
                          <p className="f-500 m-0">{tool?.TechnologyTitle}</p>
                          <p className="f-12 f-darkgrey">
                            {tool?.ShowSelectedTool ? (
                              <span className="d-inline-flex align-items-center">
                                <img
                                  alt=""
                                  src={integrationUtils.getImagePath(tool?.SelectedTool?.obj_IntegrationId, tool?.SelectedTool?.ToolIcon)}
                                  className="mr-2"
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "50%",
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "/images/attack-surface/generic2.svg";
                                  }}
                                />
                                <span className="f-black d-inline-flex align-items-center">
                                  {tool?.SelectedTool?.ToolName}
                                </span>
                                <span
                                  className="f-blue d-inline-flex- d-none align-items-center mx-2"
                                  onClick={(e) => {
                                    var parent =
                                      e.target.closest(".border-item");

                                    let panel = parent.querySelector(".panel");
                                    if (panel?.style?.maxHeight) {
                                      return; // panel.style.maxHeight = null;
                                    }

                                    let updated_data = [...ToolsList];
                                    updated_data[toolIndex].changingTool = true;
                                    setToolsList(updated_data);

                                    ToggleDropdownApi(
                                      262,
                                      tool?.TechnologyEnum
                                    ).then((response) => {
                                      let updated_data = [...ToolsList];
                                      updated_data[
                                        toolIndex
                                      ].changingTool = false;
                                      setToolsList(updated_data);
                                      if (response?.mr?.Success) {
                                        let updated_data = [...ToolsList];
                                        updated_data[toolIndex].optionList = [
                                          ...response?.TechnologyTool_List,
                                        ].map((item) => {
                                          return {
                                            ...item,
                                            changingTool: false,
                                          };
                                        });
                                        updated_data[toolIndex].loading = false;
                                        setToolsList(updated_data);

                                        var panel =
                                          parent.querySelector(".panel");

                                        if (panel?.style?.maxHeight) {
                                          // panel.style.maxHeight = null;
                                        } else {
                                          panel.style.maxHeight =
                                            panel.scrollHeight + "px";
                                        }
                                      }
                                    });
                                  }}
                                >
                                  {/* {tool.changingTool
                                    ? "Please wait.."
                                    : "Change"} */}
                                </span>
                              </span>
                            ) : (
                              // tool?.TechnologyQuestion
                              <span className="f-12 f-darkgrey">
                                {tool?.ResponseProvided === false &&
                                  "Not performed"}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div>{tool?.loading && <GreyLoader />}</div>
                        {tool?.ShowSelectedTool ? (
                          <div className="f-black f-500 mr-3">Yes</div>
                        ) : (
                          <>
                            {tool?.ResponseProvided && !tool?.SelectedTool ? (
                              <div className="f-black f-500 mr-3">No</div>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Connect Banner  */}
                    {tool?.ShowConnectButton && false && (
                      <div className="d-flex align-items-center justify-content-between p-2 bg-white m-2 hg-border radius-4 w-100">
                        <div>
                          <p className="font-weight-bold m-0">
                            <img
                              alt=""
                              src="/images/attack-surface/plug.svg"
                              className="mr-2"
                            />
                            Available Integration, connect it to HighGround?
                          </p>
                          <p className="f-12 f-darkgrey">
                            Get your data into HighGround to get verified data
                          </p>
                        </div>
                        <Button
                          className="hg-blue-btn"
                          onClick={() => {
                            getIntegrationStateDetail(
                              tool?.SelectedTool?.obj_IntegrationId
                            );
                          }}
                        >
                          Connect
                        </Button>
                      </div>
                    )}
                  </div>

                  {/* Connect to Highground banner */}
                  <div className="panel">
                    {/* Choose tool  */}
                    <div className="tool-listing-wrapper ml-2">
                      {tool?.optionList && tool?.optionList?.length > 0 && (
                        <div className="f-12 f-500 f-grey ml-3">
                          Select all that apply
                        </div>
                      )}

                      {tool?.optionList?.map((option, optionIndex) => {
                        const IsToolChanging = ToolsList[
                          toolIndex
                        ].optionList.some((item) => item.changingTool === true);
                        return (
                          <div
                            className="d-flex align-items-center mt-2 pointer"
                            onClick={(e) => {
                              if (IsToolChanging) return;
                              if (
                                tool?.SelectedTool?.obj_IntegrationId ===
                                option?.obj_IntegrationId
                              )
                                return;
                              let updated_data = [...ToolsList];
                              updated_data[toolIndex].SelectedTool = option;

                              var parent = e.target.closest(".border-item");

                              updated_data[toolIndex].optionList[
                                optionIndex
                              ].changingTool = true;
                              setToolsList(updated_data);

                              setNewToolSelected(false);
                              postRequest(
                                authTokens,
                                "CreateIntegrationTechnologyTool",
                                {
                                  Selected: true,

                                  ToolName: option?.ToolName,
                                  ObjectId: option?.obj_IntegrationId,
                                  accessKey: JSON.parse(
                                    localStorage.getItem("user_MessageObj")
                                  ),
                                  IntegrationTechnology: tool.TechnologyEnum,
                                }
                              ).then((response) => {
                                if (response?.mr?.Success) {
                                  updateAccessKeyInLocalStorage(response?.mr?.Message)
                              
                                  refreshMenuItems();
                                  updateRowData(toolIndex, optionIndex, parent);
                                } else {
                                  let updated_data = [...ToolsList];
                                  updated_data[toolIndex].optionList[
                                    optionIndex
                                  ].changingTool = false;
                                  setToolsList(updated_data);
                                  CallToast(
                                    "Something went wrong",
                                    false,
                                    false,
                                    "v2style"
                                  );
                                }
                              });
                            }}
                          >
                            <div className="checkbox-margin ml-3">
                              <Form.Group
                                controlId={`card ${toolIndex}${optionIndex}`}
                                className={`round-checkbox blue-checkbox ${
                                  IsToolChanging
                                    ? "cursor-default"
                                    : "radio-shadow"
                                }`}
                              >
                                <Form.Check
                                  type={"radio"}
                                  checked={
                                    tool?.SelectedTool?.obj_IntegrationId ===
                                    option?.obj_IntegrationId
                                  }
                                  name={`check ` + toolIndex}
                                  label=""
                                  disabled={IsToolChanging}
                                  onChange={() => {}}
                                  onClick={(e) => {
                                    return false;
                                  }}
                                />
                              </Form.Group>
                            </div>
                            <img
                              alt=""
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "50%",
                              }}
                              src={`/images/attack-surface/integrations/${option?.ToolIcon?.toLowerCase()}`}
                              className="ml-3 mr-2"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  "/images/attack-surface/generic2.svg";
                              }}
                            />
                            <div className="f-500"> {option?.ToolName} </div>
                            <div>
                              {option?.changingTool ? (
                                <Spinner
                                  animation="border"
                                  variant="secondary"
                                  size="sm"
                                  className="mx-2"
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}

                      {!tool.addTool ? (
                        <div
                          className="d-flex align-items-center f-grey f-12 ml-4 pointer mt-2 mb-4"
                          onClick={() => {
                            setNewToolName("");
                            let updated_data = [...ToolsList];
                            updated_data[toolIndex].addTool = true;
                            setToolsList(updated_data);
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/add-tool-icon.svg"
                            className="mr-2"
                          />
                          Add
                        </div>
                      ) : (
                        <div className="d-flex align-items-center mb-3 mt-2">
                          <div className="checkbox-margin ml-3">
                            <Form.Group
                              controlId={`card ${toolIndex}  `}
                              className={`round-checkbox blue-checkbox radio-shadow`}
                            >
                              <Form.Check
                                type={"radio"}
                                checked={newToolSelected}
                                name={`check `}
                                label=""
                                onChange={() => {
                                  setNewToolSelected(true);
                                }}
                              />
                            </Form.Group>
                          </div>
                          <img
                            alt=""
                            width={24}
                            height={24}
                            src="/images/attack-surface/generic2.svg"
                            className="ml-3 mr-2"
                          />
                          <div className="tool-creator-field d-flex align-items-center ">
                            <input
                              type="text"
                              value={newToolName}
                              onChange={(e) => setNewToolName(e.target.value)}
                              autoFocus
                            />
                            <Button
                              className=""
                              onClick={(e) => {
                                if (newToolName === "") return;
                                var parent = e.target.closest(".border-item");
                                setAddingNewTool(true);
                                postRequest(
                                  authTokens,
                                  "CreateIntegrationTechnologyTool",
                                  {
                                    Selected: newToolSelected,
                                    ObjectId: 0,
                                    ToolName: newToolName,
                                    accessKey: JSON.parse(
                                      localStorage.getItem("user_MessageObj")
                                    ),
                                    IntegrationTechnology: tool.TechnologyEnum,
                                  }
                                ).then((response) => {
                                  setAddingNewTool(false);
                                  if (response?.mr?.Success) {
                                    updateAccessKeyInLocalStorage(response?.mr?.Message)
                              
                                    refreshMenuItems();
                                    let updated_data = [...ToolsList];
                                    updated_data[toolIndex].addTool = false;
                                    setToolsList(updated_data);

                                    if (newToolSelected) {
                                      updateRowData(toolIndex, null, parent);
                                    } else {
                                      ToggleDropdownApi(
                                        262,
                                        tool?.TechnologyEnum
                                      ).then((response) => {
                                        if (response?.mr?.Success) {
                                          refreshMenuItems();
                                          let updated_data = [...ToolsList];
                                          updated_data[toolIndex].optionList = [
                                            ...response?.TechnologyTool_List,
                                          ].map((item) => {
                                            return {
                                              ...item,
                                              changingTool: false,
                                            };
                                          });
                                          updated_data[
                                            toolIndex
                                          ].loading = false;
                                          setToolsList(updated_data);
                                          let panel =
                                            parent.querySelector(".panel");

                                          panel.style.maxHeight =
                                            panel.scrollHeight + "px";
                                        }
                                      });
                                    }
                                  } else {
                                    CallToast(
                                      "Something went wrong",
                                      false,
                                      false,
                                      "v2style"
                                    );
                                  }
                                });
                              }}
                            >
                              {addingNewTool ? (
                                <span className="d-inline-flex align-items-center">
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                    className="mx-1"
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                    }}
                                  />
                                  <span className="d-inline-flex align-items-center">
                                    Add
                                  </span>
                                </span>
                              ) : (
                                "Add"
                              )}
                            </Button>
                          </div>
                          <img
                            alt=""
                            src="/images/attack-surface/small-cross.svg"
                            className="ml-2 pointer"
                            onClick={() => {
                              let updated_data = [...ToolsList];
                              updated_data[toolIndex].addTool = false;
                              setToolsList(updated_data);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Services */}
          <div className="font-weight-bold mt-3">Services</div>
          <div className="bg-grey radius-8 border-parent">
            {ServicesList.map((service, serviceIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    goTo(service?.TechnologyTitle);
                  }}
                  style={{
                    pointerEvents: `${accessLevel === 1 ? "none" : "all"}`,
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                    <div className=" d-flex align-items-baseline">
                      <img
                        alt=""
                        src="/images/attack-surface/yellow-dot.svg"
                        className="mr-1"
                        style={{
                          visibility: `${
                            "hidden"
                            // !service?.ResponseProvided ? "visible" : "hidden"
                          }`,
                        }}
                      />
                      <div className="d-flex flex-column">
                        <p className="f-500 m-0-">{service?.TechnologyTitle}</p>
                        {/* <p className="f-12 f-darkgrey">
                          {service?.TechnologyQuestion}
                        </p> */}
                      </div>
                    </div>
                    <div>
                      {accessLevel === 1 ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                        />
                      ) : (
                        <img
                          alt=""
                          src="/images/attack-surface/chevron-right.svg"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Capabilities */}
          <div className="font-weight-bold mt-3">Capabilities</div>
          <div className="bg-grey radius-8 border-parent">
            {CapabilitiesList.map((capability, capabilityIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    goTo(capability?.TechnologyTitle);
                  }}
                  style={{
                    pointerEvents: `${accessLevel === 1 ? "none" : "all"}`,
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                    <div className=" d-flex align-items-baseline">
                      <img
                        alt=""
                        src="/images/attack-surface/yellow-dot.svg"
                        className="mr-1"
                        style={{
                          visibility: `${
                            "hidden"
                            // !capability?.ResponseProvided ? "visible" : "hidden"
                          }`,
                        }}
                      />
                      <div className="d-flex flex-column">
                        <p className="f-500 m-0">
                          {capability?.TechnologyTitle}
                        </p>
                        {/* <p className="f-12 f-darkgrey">
                          {capability?.TechnologyQuestion}
                        </p> */}
                      </div>
                    </div>
                    <div>
                      {accessLevel === 1 ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                        />
                      ) : (
                        <img
                          alt=""
                          src="/images/attack-surface/chevron-right.svg"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Reset button */}

          <AttackSurfaceResetModal
            refreshData={getPageData}
            refreshMenuItems={refreshMenuItems}
            resetPageEnum={267}
            show={showResetModal}
            hideModal={() => {
              setShowResetModal(false);
            }}
          />
          {connectToolDetail && (
            <ConnectionToolModal
              refreshData={getPageData}
              data={connectToolDetail}
              show={connectModalShow}
              onHide={() => setConnectModalShow(false)}
              handleIntegration={() =>
                getIntegrationDataById(
                  technologyData.title,
                  technologyData.order,
                  technologyData.id
                )
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default CDSA;
