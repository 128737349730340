import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { IndependentGetAPIData, getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import './mspClientPriceCalculator.scss';
import { IncreaseDecreaseNumbersComponent } from "../mspSell/mspPriceCalculator/MspAddEditServiceModal";
import { addZerows } from "../mspSell/mspPriceCalculator";
import { DateRangePicker, createStaticRanges } from "react-date-range";

import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears,
    startOfQuarter,
    endOfQuarter,
    subQuarters,
    subMonths,
    subYears
} from "date-fns";
import { HGHighlightText } from "../../App/App";
import { GreyLoader } from "../../Theme/APILoaders";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import Draggable from "react-draggable";


export const MapCurrentSpendModal = ({
    show,
    hideModal,
    clientId,
    packageId,
    packagePeriod,
    packagePrice,
    packagePriceText,
    isFromAddContribTable,
    afterMap = () => { },
    afterMapReset = () => { }
    // setReset = () => { }
}) => {
    const [searchString, setSearchString] = useState("");
    const [currentSpendTableData, setCurrentSpendTableData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [allSelectedTotals, setAllSelectedTotals] = useState(0);
    const [periods, setPeriods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: null,
        endDate: null,
        key: "selection",
    })
    const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
    const { authTokens } = useAuth();
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [productSubCategoryList, setProductSubCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedSubcategory, setSelectedSubcategory] = useState(0);
    const [resetLoading, setResetLoading] = useState(false);
    const [mapLoading, setMapLoading] = useState(false);
    const [packagePeriodObj, setPackagePeriodObj] = useState({})

    useEffect(() => {
        if (packagePeriod && packagePeriod != '') {
            setPackagePeriodObj(periods.find(item => item?.DDLText == packagePeriod))
        }
    }, [packagePeriod, periods])

    useEffect(() => {
        if (show) {
            getPackageCurrentMapSpend();
            getProductCategories();
        }
    }, [show])
    async function getProductCategories() {
        await /*getAPIData*/IndependentGetAPIData(636, authTokens)
            .then((response) => {
                setProductCategoryList(response?.TechnologySpendDetails_List);
            })
            .catch((err) => { });
    }

    async function getProductSubCategories(_categoryId) {
        await /*getAPIData*/IndependentGetAPIData(627, authTokens, {
            optionEnum1: _categoryId,
        })
            .then((response) => {
                setProductSubCategoryList(response?.TechnologySpendDetails_List);
            })
            .catch((err) => { });
    }

    const [isResetClicked, setIsResetClicked] = useState(false);
    function getPackageCurrentMapSpend(categoryId, subcategoryId, isForReset = false) {
        setLoading(true);
        if (isForReset) {
            setIsResetClicked(true);
            setResetLoading(true);
        }
        //kwargs = {}
        return new Promise((resolve, reject) => {
            postData(authTokens, "GetPAckageCurrentmapSpend", {
                CategoryId: categoryId ?? 0,
                SubCategoryId: subcategoryId ?? 0,
                objId_Customer: clientId,
                ObjId_Package: packageId
            })
                .then((response) => {
                    if (response?.mr?.Success) {
                    }
                    if (isForReset) {
                        const updatedData = response?.PackageMapCurrentSpend_List.map(item => ({
                            ...item,
                            IsSelected: false
                        }));
                        setCurrentSpendTableData(updatedData);
                        setResetLoading(false);
                    } else {
                        setCurrentSpendTableData(response?.PackageMapCurrentSpend_List)
                    }
                    setPeriods(response?.PeriodFrequency_List);
                    setLoading(false);
                })
                .catch((error) => {
                    reject(error);
                    if (isForReset) {
                        setResetLoading(false);
                    }
                    setLoading(false);
                });
            // setLoading(false);
        });
    }
    function updatePackageMappedPrice() {
        return new Promise((resolve, reject) => {
            postData(authTokens, "UpdatePackageMappedPrice", {
                ObjId_Package: packageId,
                ObjId_Customer: clientId,
                NewPackagePrice: packagePrice - allSelectedTotals,
                PackageMapCurrentSpend_List: currentSpendTableData?.filter(item => item?.IsSelected == true)?.length > 0 ?
                    JSON.stringify(currentSpendTableData?.filter(item => item?.IsSelected == true)) :
                    JSON.stringify([{}]),
            })
                .then((response) => {
                    if (response?.Success) {
                        CallToast("Mapped successfully", false, false, "v2style");
                        setMapLoading(false);
                        hideModal();
                        if (!isResetClicked) {
                            if (isFromAddContribTable) {
                                afterMap();
                            }
                        } else {
                            afterMapReset();
                        }
                    } else {
                        CallToast("Something went wrong", false, false, "v2style");
                        setMapLoading(false);
                    }

                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    useEffect(() => {
        // const totalSelectedPrice = currentSpendTableData?.reduce((sum, item) => {
        //     if (item?.IsSelected) {
        //         return sum + (item?.Price * item?.Quantity);
        //     }
        //     return sum;
        // }, 0);
        const totalSelectedPrice = currentSpendTableData?.reduce((sum, item) => {
            if (item?.IsSelected) {
                if (packagePeriodObj?.DDLId == item?.Period) {
                    return sum + (item?.Price * item?.Quantity);
                } else {
                    if (packagePeriodObj?.DDLId == 0) { //Monthly
                        if (item?.Period == 1) { //Quarterly to Monthly
                            return sum + ((item?.Price / 3) * item?.Quantity);
                        }
                        if (item?.Period == 2) { //Bi Annually to Monthly
                            return sum + ((item?.Price / 6) * item?.Quantity);
                        }
                        if (item?.Period == 3) { //Annually to Monthly
                            return sum + ((item?.Price / 12) * item?.Quantity);
                        }
                    }

                    if (packagePeriodObj?.DDLId == 1) { //Quarterly
                        if (item?.Period == 0) { //Monthly to Quarterly
                            return sum + ((item?.Price * 3) * item?.Quantity);
                        }
                        if (item?.Period == 2) { //Bi Annually to Quarterly
                            return sum + ((item?.Price / 2) * item?.Quantity);
                        }
                        if (item?.Period == 3) { //Annually to Quarterly
                            return sum + ((item?.Price / 4) * item?.Quantity);
                        }
                    }

                    if (packagePeriodObj?.DDLId == 2) { //Bi Annually
                        if (item?.Period == 0) { //Monthly to Bi Annually
                            return sum + ((item?.Price * 6) * item?.Quantity);
                        }
                        if (item?.Period == 1) { //Quarterly to Bi Annually
                            return sum + ((item?.Price * 2) * item?.Quantity);
                        }
                        if (item?.Period == 3) { //Annually to Bi Annually
                            return sum + ((item?.Price / 2) * item?.Quantity);
                        }
                    }

                    if (packagePeriodObj?.DDLId == 3) { //Annually
                        if (item?.Period == 0) { //Monthly to Annually
                            return sum + ((item?.Price * 12) * item?.Quantity);
                        }
                        if (item?.Period == 1) { //Quarterly to Annually
                            return sum + ((item?.Price * 4) * item?.Quantity);
                        }
                        if (item?.Period == 2) { //Bi Annually to Annually
                            return sum + ((item?.Price * 2) * item?.Quantity);
                        }
                    }
                }
            }
            return sum;
        }, 0);
        setAllSelectedTotals(totalSelectedPrice);
    }, [currentSpendTableData])


    function formatCurrency(value, currencySymbol) {
        let formattedValue = value.toFixed(2);
        let parts = formattedValue.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return currencySymbol + parts.join(".");
    }


    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
        startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
        startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
        startOflastYear: startOfYear(addYears(new Date(), -1)),
        endOflastYear: endOfYear(addYears(new Date(), -1)),
        startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
        endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
        startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
        startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
        startOfCurrentYear: startOfYear(new Date()),
        endOfPreviousYear: endOfYear(subYears(new Date(), 1)),



    };


    const sideBarOptions = () => {
        const customDateObjects = [
            {
                label: "Current Year",
                range: () => ({
                    startDate: defineds.startOfCurrentYear,
                    endDate: defineds.endOfToday,
                }),
            },
            {
                label: "Previous Year",
                range: () => ({
                    startDate: defineds.startOflastYear,
                    endDate: defineds.endOfPreviousYear,
                }),
            }
        ];

        return customDateObjects;
    };


    const sideBar = sideBarOptions();

    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar),
    ];

    useEffect(() => {
        if (selectedDateRange?.startDate != null && selectedDateRange?.endDate != null) {
            getPackageCurrentMapSpend(selectedDateRange?.startDate, selectedDateRange?.endDate);
        }
    }, [selectedDateRange])

    function changePrice(oldPrice, prevPeriod, newPeriod) {
        if (prevPeriod == 0) { //monthly
            if (newPeriod == 1) {
                return oldPrice * 3;
            }
            if (newPeriod == 2) {
                return oldPrice * 6;
            }
            if (newPeriod == 3) {
                return oldPrice * 12;
            }
        }
        if (prevPeriod == 1) { //quarterly
            if (newPeriod == 0) {
                return oldPrice / 3;
            }
            if (newPeriod == 2) {
                return oldPrice * 2;
            }
            if (newPeriod == 3) {
                return oldPrice * 4;
            }
        }
        if (prevPeriod == 2) { //bi annually
            if (newPeriod == 0) {
                return oldPrice / 6;
            }
            if (newPeriod == 1) {
                return oldPrice / 2;
            }
            if (newPeriod == 3) {
                return oldPrice * 2;
            }
        }
        if (prevPeriod == 3) { //annually
            if (newPeriod == 0) {
                return oldPrice / 12;
            }
            if (newPeriod == 1) {
                return oldPrice / 4;
            }
            if (newPeriod == 2) {
                return oldPrice / 2;
            }
        }

    }

    console.log('currentSpendTableData ==== ', currentSpendTableData)
    function changeValue(value, tableItem) {
        let data_list = [...currentSpendTableData];
        let t = data_list?.map((temp) => {
            if (
                temp?.ObjectId ==
                tableItem?.ObjectId
            ) {
                temp.Price = value;
                temp.Total = temp.Quantity * temp.Price;
            }
            return temp;
        });
        setCurrentSpendTableData(data_list);
        return true;
    }


    return (
        <Draggable defaultPosition={{ x: 0, y: 0 }} handle=".dragger-icon">
            <div
                id="map-current-spend-modal"
                className="map-current-spend-modal"
            >
                <div className="map-current-spend-modal-header d-flex">
                    <div
                        className="dragger-icon"
                        style={{
                            position: "absolute",
                            background: "transparent",
                            width: "95%",
                            height: "30px",
                        }}
                    ></div>
                    <div className="f-500 f-14 f-black line-height-22 letter-space-2 w-100 text-center">Map Current Spend</div>
                    <img
                        alt=""
                        height={11}
                        width={11}
                        className="pointer"
                        src="/images/attack-surface/cross-icon.svg"
                        onClick={() => {
                            hideModal();
                        }}
                    />
                </div>
                <div className="map-current-spend-modal-body">
                    {
                        loading ? <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                            <GreyLoader width={20} />
                            Loading...
                        </span> :
                            <>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div
                                        className={`transparent-input  d-flex align-items-center w-100 p-2 radius-4 mt-2 ${false ? "input-error" : ""
                                            } `}
                                    >
                                        <div className="f-darkgrey flex-shrink-0">
                                            <img
                                                alt=""
                                                src="/images/attack-surface/search-icon.svg"
                                                className="pointer mr-2"
                                                width={14}
                                                height={14}

                                            />
                                        </div>
                                        <input
                                            className=" pl-1 pr-2 w-100"
                                            style={{
                                                border: 'none'
                                            }}
                                            placeholder="Search"
                                            value={searchString}
                                            onChange={(e) => {
                                                setSearchString(e?.target?.value);
                                            }}
                                        />
                                    </div>
                                    <>
                                        <Dropdown
                                            className="category-filter-dropdown m-1 technologySpendModelDatePicker"
                                            id={`date_filterX`}
                                            show={isDateDropdownOpen}
                                            onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
                                        >
                                            <Dropdown.Toggle className="p-0">
                                                <div
                                                    className={`d-flex align-items-center justify-content-between f-500 f-14 line-height-22 letter-space-2 pointer ${selectedSubcategory != 0 ? 'txt-blue' : 'greyText2'}`}
                                                >
                                                    {
                                                        selectedSubcategory != 0 && <span className="blue-dot" />
                                                    }
                                                    Filter
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="p-0">
                                                {
                                                    selectedCategory == 0 ? <>
                                                        {
                                                            productCategoryList?.map((category, index) => {
                                                                return (
                                                                    <div
                                                                        className='d-flex align-items-center justify-content-between hg-border-bottom bg-hover-dark-grey pointer px-3 py-2'
                                                                        onClick={() => {
                                                                            setSelectedCategory(category);
                                                                            getProductSubCategories(category?.ObjectId);
                                                                        }}
                                                                    >
                                                                        <div className={`f-400 f-14 line-height-22 letter-space-2 ${selectedCategory?.ObjectId == category?.ObjectId ? 'txt-blue' : 'greyText2'
                                                                            }`}>
                                                                            {category?.CategoryName}
                                                                        </div>
                                                                        <img
                                                                            alt=""
                                                                            src="/images/attack-surface/chevron-right.svg"
                                                                        />

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </> : <>
                                                        <div
                                                            className="f-400 f-14 greyText2 bg-hover-dark-grey line-height-22 letter-space-2 px-3 py-2 pointer"
                                                            onClick={() => {
                                                                setSelectedCategory(0);
                                                                setSelectedSubcategory(0);
                                                                getPackageCurrentMapSpend();
                                                            }}
                                                        >
                                                            Clear
                                                        </div>
                                                        <div
                                                            className="w-100 px-3 py-1 pointer bg-hover-dark-grey"
                                                            onClick={() => {
                                                                setSelectedCategory(0);
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                src="/images/supply-chain/chevron-left.svg"
                                                            />
                                                        </div>
                                                        <div
                                                            className={`hg-border-bottom bg-hover-dark-grey pointer px-3 py-2 f-400 f-14 line-height-22 letter-space-2 ${selectedSubcategory == 1 ? 'txt-blue' : 'greyText2'
                                                                }`}
                                                            onClick={() => {
                                                                setSelectedSubcategory(1);
                                                                getPackageCurrentMapSpend(selectedCategory?.ObjectId)
                                                            }}
                                                        >
                                                            All subcategories
                                                        </div>
                                                        {
                                                            productSubCategoryList?.map((subcategory, index) => {
                                                                return (
                                                                    <div
                                                                        className="hg-border-bottom bg-hover-dark-grey pointer px-3 py-2"
                                                                        onClick={() => {
                                                                            setSelectedSubcategory(subcategory);
                                                                            getPackageCurrentMapSpend(selectedCategory?.ObjectId, subcategory?.ObjectId)
                                                                        }}
                                                                    >
                                                                        <div className={`f-400 f-14 line-height-22 letter-space-2 ${selectedSubcategory?.ObjectId == subcategory?.ObjectId ? 'txt-blue' : 'greyText2'
                                                                            }`}>
                                                                            {subcategory?.CategoryName}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                </div>
                                {
                                    currentSpendTableData?.length > 0 ? <>
                                        <div className="current-spend-table w-100">
                                            <div className="d-flex align-items-center">
                                                <Form.Group className="square-checkbox blue-checkbox-minus pointer-checkbox mx-2 mb-1">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name="checkbox"
                                                        checked={currentSpendTableData.some(item => item.IsSelected)}
                                                        onChange={() => {
                                                            const updatedData = currentSpendTableData.map(item => ({
                                                                ...item,
                                                                IsSelected: false
                                                            }));
                                                            setCurrentSpendTableData(updatedData);
                                                            setIsResetClicked(true);
                                                        }}
                                                    />
                                                </Form.Group>
                                                <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2">Recurring Items</div>
                                            </div>
                                            <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2 ml-3">Price</div>
                                            <div
                                                className="f-500 f-12 f-darkgrey line-height-18 letter-space-2"
                                                style={{
                                                    marginLeft: '10px'
                                                }}
                                            >
                                                Qty.
                                            </div>
                                            <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2">Total</div>
                                            <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2">Period</div>
                                        </div>
                                        {
                                            currentSpendTableData?.filter((item) => item?.ItemName.toLowerCase()?.includes(searchString?.toLowerCase()))?.length > 0 ?
                                                currentSpendTableData?.filter((item) => item?.ItemName.toLowerCase()?.includes(searchString?.toLowerCase())
                                                )?.map((tableItem) => {
                                                    return (
                                                        <div className={`current-spend-table w-100 mt-1 ${tableItem?.IsSelected ? 'bg-lightblue radius-8' : ''}`}>
                                                            <div>
                                                                <div className="d-flex align-items-center">
                                                                    <Form.Group className="blue-checkbox pointer-checkbox mx-2">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name="checkbox"
                                                                            checked={tableItem.IsSelected}
                                                                            onChange={() => {
                                                                                const updatedData = currentSpendTableData.map(item => {
                                                                                    if (item.ObjectId === tableItem.ObjectId) {
                                                                                        return { ...item, IsSelected: !item.IsSelected };
                                                                                    }
                                                                                    return item;
                                                                                });
                                                                                setCurrentSpendTableData(updatedData);
                                                                                setIsResetClicked(false);
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                    <div>
                                                                        <span
                                                                            className="f-400 f-14 f-black line-height-18 letter-space-2"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: HGHighlightText(
                                                                                    tableItem?.ItemName,
                                                                                    searchString
                                                                                ),
                                                                            }}
                                                                        />
                                                                        <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2">{`${tableItem?.CategoryName} > ${tableItem?.SubCategoryName}`}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <IncreaseDecreaseNumbersComponent
                                                                type={"number"}
                                                                floatValue={true}
                                                                inputClassName={'bg-transparent border-none w-100 py-1'}
                                                                divOnInputClassName={`w-100 d-flex align-items-center radius-4 ${tableItem?.IsSelected ? 'always-highlight-input' : 'highlight-input'} px-2 py-1 m-1`}
                                                                children={
                                                                    <>
                                                                        <div className="f-400 f-14 f-black line-height-18 letter-space-2 d-flex align-items-center mr-2">
                                                                            {tableItem?.CurrencySymbol}
                                                                        </div>
                                                                    </>
                                                                }
                                                                value={addZerows(tableItem?.Price?.toString())}
                                                                onChange={(value) => {
                                                                    // let data_list = [...currentSpendTableData];
                                                                    // let t = data_list?.map((temp) => {
                                                                    //     if (
                                                                    //         temp?.ObjectId ==
                                                                    //         tableItem?.ObjectId
                                                                    //     ) {
                                                                    //         temp.Price = value;
                                                                    //         temp.Total = temp.Quantity * temp.Price;
                                                                    //     }
                                                                    //     return temp;
                                                                    // });
                                                                    // setCurrentSpendTableData(data_list);
                                                                    // return true;
                                                                    changeValue(value, tableItem);
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                            <IncreaseDecreaseNumbersComponent
                                                                type={"number"}
                                                                floatValue={false}
                                                                inputClassName={'bg-transparent border-none w-100 py-1'}
                                                                divOnInputClassName={`w-100 d-flex align-items-center radius-4 ${tableItem?.IsSelected ? 'always-highlight-input' : 'highlight-input'} px-2 py-1 m-1`}
                                                                value={tableItem?.Quantity}
                                                                onChange={(value) => {
                                                                    let data_list = [...currentSpendTableData];
                                                                    let t = data_list?.map((temp) => {
                                                                        if (
                                                                            temp?.ObjectId ==
                                                                            tableItem?.ObjectId
                                                                        ) {
                                                                            temp.Quantity = value;
                                                                            temp.Total = temp.Quantity * temp.Price;
                                                                        }
                                                                        return temp;
                                                                    });
                                                                    setCurrentSpendTableData(data_list);
                                                                    return true;
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                            <div className="f-400 f-14 f-black line-height-18 letter-space-2 d-flex align-items-center">{tableItem?.CurrencySymbol}{" "}{addZerows((tableItem?.Price * tableItem?.Quantity)?.toString())}</div>
                                                            <Dropdown className="transparennt-wrapper-dropdown d-flex align-items-center">
                                                                <Dropdown.Toggle className="d-flex align-items-center">
                                                                    <div className="f-400 f-14 f-black line-height-18 letter-space-2">
                                                                        {tableItem?.Period_Text ?? 'Select'}
                                                                    </div>
                                                                    <img
                                                                        alt=""
                                                                        src="/images/chevron-down.svg"
                                                                        className="ml-2"
                                                                        width={14}
                                                                        height={14}
                                                                    />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="p-0">
                                                                    {periods?.map((periodItem) => (
                                                                        <div
                                                                            key={periodItem?.DDLId}
                                                                            className="f-400 f-14 f-black line-height-18 letter-space-2 pointer px-3 py-2 hg-border-bottom"
                                                                            onClick={(e) => {
                                                                                const updated_data = currentSpendTableData.map(item => {
                                                                                    if (item.ObjectId === tableItem?.ObjectId) {
                                                                                        return {
                                                                                            ...item,
                                                                                            Period: periodItem?.DDLId,
                                                                                            Period_Text: periodItem?.DDLText,
                                                                                        };
                                                                                    }
                                                                                    return item;
                                                                                });
                                                                                console.log('updated_data >>>>', updated_data)
                                                                                setCurrentSpendTableData(updated_data);
                                                                                e.target
                                                                                    .closest(".transparennt-wrapper-dropdown")
                                                                                    .click();
                                                                            }}
                                                                        >
                                                                            {periodItem?.DDLText}
                                                                        </div>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    )
                                                }) : <div
                                                    className="d-flex align-content-center justify-content-center w-100 my-4 f-400 f-14 f-darkgrey"
                                                >
                                                    No results for "{searchString}"
                                                </div>
                                        }
                                    </> : <div className="w-100 d-flex align-items-center justify-content-center my-5">No data yet</div>
                                }
                            </>
                    }
                </div>

                <div className="map-current-spend-modal-footer">
                    <div className="current-spend-table-footer-block hg-border-top"
                        style={{
                            margin: '0px 16px',
                            padding: '4px 0px'
                        }}
                    >
                        <div className="f-500 f-14 f-black line-height-22 letter-space-2">Platinum package</div>
                        <div>
                            <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">Package Price (RRP)</div>
                            <div className="f-400 f-14 f-black line-height-22 letter-space-2">{packagePriceText}</div>
                        </div>
                        <div>
                            <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">Contribution</div>
                            <div className="f-400 f-14 f-green-light line-height-22 letter-space-2">- {packagePriceText?.charAt(0)}{addZerows(allSelectedTotals?.toString())}</div>
                        </div>
                        <div>
                            <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">New Package Price</div>
                            <div className="f-500 f-14 f-black line-height-22 letter-space-2">{formatCurrency((packagePrice - allSelectedTotals), packagePriceText?.charAt(0))}</div>
                        </div>
                    </div>
                    <div className="hg-border-bottom"></div>
                    <div className="d-flex align-items-center justify-content-between"
                        style={{
                            padding: '12px'
                        }}
                    >
                        <div
                            className={` mt-2 ${resetLoading ? 'cursor-default' : 'crsrpoint'}`}
                            onClick={() => {
                                if (!resetLoading) {
                                    setSelectedCategory(0);
                                    setSelectedSubcategory(0);
                                    setSearchString('');
                                    getPackageCurrentMapSpend(0, 0, true);
                                }
                            }}
                        >
                            <img alt="" src="/images/actioncenter/reset.svg" height="15" className={`${resetLoading ? 'spin' : ''}`} />
                            <span style={{ color: "#616778" }}>&nbsp; {resetLoading ? 'Resetting' : 'Reset'}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-end "
                        >
                            <Button
                                className="hg-cancel-btn mr-2"
                                onClick={() => {
                                    hideModal();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="hg-blue-btn"
                                disabled={mapLoading}
                                onClick={() => {
                                    setMapLoading(true);
                                    updatePackageMappedPrice();
                                }}
                            >
                                {mapLoading ? 'Mapping' : 'Map'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default MapCurrentSpendModal;


export function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
