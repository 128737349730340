import { createRef, useEffect } from 'react';
import WarningRoundedIcon from '../icons/warning-rounded.icon';

const AuthTextInput = ({ 
  icon,
  endIcon, 
  error, 
  className,
  disableErrorHighlight,
  autoFocus, 
  ...otherProps 
}) => {
  const ref = createRef(null);

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref.current]);

  return (
    <div>
      <div
        className={`section-input ${(error && !disableErrorHighlight) ? 'error' : ''}`}
      >
        <div className={`position-relative d-flex ${icon ? 'pl-4' : ''}`}>
          <input
            {...otherProps}
            ref={ref}
            className={`w-100 ${className ?? ''}`}
          />
          {endIcon && (
            endIcon
          )}
          <div className="input-icon">
            {icon && (
              icon
            )}
          </div>
        </div>
      </div>
      {error && (
        <div className="d-flex error-section" style={{ gap: '8px' }}>
          <div style={{ marginTop: '-3px', padding: '0px 1px' }}>
            <WarningRoundedIcon fill="#B2494A" />
          </div>
          <span className="error-message">
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

export default AuthTextInput;
