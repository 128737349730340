import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, ToggleButton } from "react-bootstrap";
import { HGHighlightText } from "../../../../App/App";
import userUtils from "../../../../../Utils/user.utils";
import { BASE_API_ROUTES } from "../../../../../constants/apis.constants";
import { useAuth } from "../../../../context/Auth";
import { CallToast } from "../../../../PriceCalculator/modals/CustomToast";
import integrationUtils from "../../../../../Utils/integration.utils";


const SelectToolDropdown = ({
  children,
  contributionName,
  productId,
  importDetails,
  setImportDetails = () => { }
}) => {
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const data = [];
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();
  const [allTools, setAllTools] = useState([]);
  const [securityStackTools, setSecurityStackTools] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [selectedTool, setSelectedTool] = useState({});

  useEffect(() => {
    if (IsDropdownOpen) {
      // setLoading(true);
      setDropdownLoading(true);
      getAllIntegrationsByTechType(contributionName, false, searchStr);
      getAllIntegrationsByTechType(contributionName, true, searchStr);
    }
  }, [IsDropdownOpen])



  const getAllIntegrationsByTechType = async (
    contributionName,
    showAll = false,
    searchText = ''
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const message = userUtils.getUserMessage();
        const formData = new FormData();
        formData.append('TechnologyType', contributionName);
        formData.append('ShowAll', showAll);
        formData.append('Search_text', searchText);
        formData.append('accessKey', message);

        fetch(
          BASE_API_ROUTES.getAllIntegrationsByTechType,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${authTokens}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data?.mr?.Success) {
              if (showAll) {
                setAllTools(data?.Integration_List)
              } else {
                setSecurityStackTools(data?.Integration_List)
              }
              setDropdownLoading(false);
            } else {
              reject('Failed to fetch integrations');
              setDropdownLoading(false);
            }
          })
          .catch((error) => {
            CallToast("Something went wrong", false, false, "v2style");
            setDropdownLoading(false);
            setIsDropdownOpen(false);
            setSelectedTool({});
            reject(error);
          });
      } catch (error) {
        console.error('Error fetching integrations', error);
        setDropdownLoading(false);
        reject(error);
      }
    });
  };


  function checkToolMatch(passedId) {
    return importDetails?.some(({ ScoreContributionSelected_List }) =>
      ScoreContributionSelected_List?.some(({ ContributionName, List_ScoreProducts }) =>
        ContributionName == contributionName &&
        List_ScoreProducts?.some(({ objId_Product, List_ProductTool }) =>
          objId_Product == productId &&
          List_ProductTool?.some(({ ObjId_Tool }) => ObjId_Tool == passedId)
        )
      )
    );
  }

  return (
    <Dropdown className="select-tool-dropdown mr-4"
      show={IsDropdownOpen}
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <div className="drop-header">
          <div className="no-highlight-input w-100 d-flex align-items-center p-2">
            <div>
              <img
                width={14}
                height={14}
                src="/images/attack-surface/search-icon.svg"
                className="m-1"
                alt=""
              />
            </div>

            <input
              className="pl-2 flex-grow-1"
              placeholder="Search"
              type="text"
              value={searchStr}
              onChange={(e) => {
                setSearchStr(e.target.value);
                getAllIntegrationsByTechType(contributionName, false, e.target.value);
                getAllIntegrationsByTechType(contributionName, true, e.target.value);
              }}
            />
          </div>
        </div>

        <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2 py-2">
          Security Stack tools
        </div>
        {
          dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
            <div className="msp-service-update-loader ml-2 mr-1"></div>
            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
          </div> : <div>
            {
              securityStackTools/*?.filter((item) =>
                                            item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))*/?.length > 0 ? <div>
                {
                  securityStackTools/*?.filter((item) =>
                                                    item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))*/?.map((integration) => {
                    return (
                      <div
                        className={`d-flex align-items-center p-2 ${checkToolMatch(integration?.IntegrationId) ? 'bg-hover-grey pointer' : ''}`}
                        onClick={(e) => {
                          if (!checkToolMatch(integration?.IntegrationId)) {
                            if (productId) {
                              const updatedData = importDetails.map(service => {
                                return {
                                  ...service,
                                  ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                    if (contribution.ContributionName == contributionName) {
                                      return {
                                        ...contribution,
                                        List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                          if (item.objId_Product === productId) {
                                            return {
                                              ...item,
                                              List_ProductTool: [
                                                ...item.List_ProductTool,
                                                {
                                                  ObjId_Tool: integration?.IntegrationId,
                                                  ToolIcon: null,
                                                  ToolName: integration?.IntegrationName
                                                }
                                              ]
                                            };
                                          }
                                          return item;
                                        })
                                      };
                                    }
                                    return contribution;
                                  })
                                };
                              });

                              setImportDetails(updatedData);
                            } else {
                              CallToast("Please select product first", false, false, "v2style");
                            }

                            e.target
                              .closest(".select-tool-dropdown")
                              .click();
                          }
                        }}
                      >
                        <img
                          alt=""
                          width={16}
                          height={16}
                          className="mr-2 rounded-full"
                          src={integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
                        />
                        <div className={`f-500 f-12 line-height-18 letter-space-2 ${checkToolMatch(integration?.IntegrationId) ? 'txt-blue' : 'f-grey'}
                                                                    `}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: HGHighlightText(
                                integration?.IntegrationName,
                                searchStr
                              ),
                            }}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
              </div>
            }
          </div>
        }

        <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2 py-2">
          All tools
        </div>
        {
          dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
            <div className="msp-service-update-loader ml-2 mr-1"></div>
            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
          </div> : <div>
            {
              allTools/*?.filter((item) =>
                                            item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))*/?.length > 0 ? <div>
                {
                  allTools/*?.filter((item) =>
                                                    item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))*/?.map((integration) => {
                    return (
                      <div
                        className={`d-flex align-items-center  p-2  ${checkToolMatch(integration?.IntegrationId) ? '' : 'bg-hover-grey pointer'}`}
                        onClick={(e) => {
                          if (!checkToolMatch(integration?.IntegrationId)) {
                            if (productId) {
                              const updatedData = importDetails.map(service => {
                                return {
                                  ...service,
                                  ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                    if (contribution.ContributionName == contributionName) {
                                      return {
                                        ...contribution,
                                        List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                          if (item.objId_Product === productId) {
                                            return {
                                              ...item,
                                              List_ProductTool: [
                                                ...item.List_ProductTool,
                                                {
                                                  ObjId_Tool: integration?.IntegrationId,
                                                  ToolIcon: null,
                                                  ToolName: integration?.IntegrationName
                                                }
                                              ]
                                            };
                                          }
                                          return item;
                                        })
                                      };
                                    }
                                    return contribution;
                                  })
                                };
                              });

                              setImportDetails(updatedData);
                            } else {
                              CallToast("Please select product first", false, false, "v2style");
                            }

                            e.target
                              .closest(".select-tool-dropdown")
                              .click();
                          }
                        }}
                      >
                        <img
                          alt=""
                          width={16}
                          height={16}
                          className="mr-2 rounded-full"
                          src={integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
                        />
                        <div className={`f-500 f-12 line-height-18 letter-space-2 ${checkToolMatch(integration?.IntegrationId) ? 'txt-blue' : 'f-grey'}`}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: HGHighlightText(
                                integration?.IntegrationName,
                                searchStr
                              ),
                            }}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
              </div>
            }
          </div>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectToolDropdown;