import { useMemo } from "react";
import { Radar } from "react-chartjs-2";

const mapPropsToOptions = (options) => ({
  ...options,
  ...(options.animation !== undefined && {
    animation: {
      ...options.animation,
      duration: options.animation?.duration ?? 1
    },
  }),
  ...(options.responsive !== undefined && ({
    responsive: !!options.responsive,
  })),
  ...(options.maintainAspectRatio !== undefined && ({
    maintainAspectRatio: !!options.maintainAspectRatio
  })),
  ...(options.legend !== undefined && ({
    legend: {
      ...options.legend,
      display: options.legend?.display ?? false
    }
  })),
  ...(options.tooltips !== undefined && ({
    tooltips: {
      ...options.tooltips,
      enabled: !!options.tooltips
    }
  })),
  ...(options.elements !== undefined && ({
    elements: {
      ...options.elements,
      point: {
        ...options.elements?.point,
        radius: options.elements?.point?.radius ?? 2,
        hoverRadius: options.elements?.point?.hoverRadius ?? 4
      }
    }
  })),
  ...(options.devicePixelRatio !== undefined && ({
    devicePixelRatio: options.devicePixelRatio
  })),
  ...(options.scales !== undefined && ({
    scales: {
      ...options.scales,
      r: {
        ...options.scales?.r,
        beginAtZero: options.scales?.r?.beginAtZero ?? false,
        max: options.scales?.r?.max ?? 35,
        min: options.scales?.r?.min ?? 0,
        ticks: {
          ...options.scales?.r?.ticks,
          display: !!options.scales?.r?.ticks?.display,
          stepSize: options.scales?.r?.ticks?.stepSize ?? 8.75,
          backdropColor: options.scales?.r?.ticks?.backdropColor ?? "rgba(0, 0, 0, 0)",
        },
        pointLabels: {
          ...options.scales?.r?.pointLabels,
          display: !!options.scales?.r?.pointLabels?.display,
          fontSize: options.scales?.r?.pointLabels?.fontSize ?? 16,
          fontFamily: options.scales?.r?.pointLabels?.fontFamily ?? "Archivo, sans-serif",
          fontColor: options.scales?.r?.pointLabels?.fontColor ?? "#101010"
        }
      }
    }
  })),
  ...(options.layout !== undefined && ({
    layout: {
      ...options.layout,
      padding: {
        ...options.layout?.padding,
        top: options.layout?.padding?.top ?? 0,
        right: options.layout?.padding?.right ?? 0,
        bottom: options.layout?.padding?.bottom ?? 0,
        left: options.layout?.padding?.left ?? 0
      }
    }
  })),
})

const mapPropsToData = (data) => ({
  ...data,
  labels: data.labels ?? [],
  datasets: data.datasets
});

/**
 * 
 * @param {Object} props
 * @param {Object} props.options
 * @param {Object} props.data
 */
const MyRadarChart = (props) => {
  const updatedOptions = useMemo(() => {
    return mapPropsToOptions(props.options ?? {});
  }, [props.options]);
  const updatedData = useMemo(() => {
    return mapPropsToData(props.data ?? {});
  }, [props.data]);
  return (
    <Radar
      {...props}
      data={updatedData}
      options={updatedOptions}
    />
  );
}

export default MyRadarChart;
