const getImagePath = (integrationId, toolIcon) => {
  if (toolIcon && toolIcon.startsWith('https')) {
    return toolIcon;
  }
  return `/images/attack-surface/integrations/${integrationId}.webp`;
}

const integrationUtils = {
  getImagePath
}

export default integrationUtils;
