const AuthChangeInput = ({ icon, value, className, valueStyles = {}, onChange = () => {} }) => (
  <div>
    <div
      className={`change-input ${className ?? ''}`}
    >
      <div 
        className={`mr-sm-1 position-relative d-flex ${icon ? 'pl-4' : ''}`}
      >
        <span className='f-12 f-grey w-100 f-500' style={valueStyles}>
          {value}
        </span>
        <div className='f-14 f-darkgrey cursor-pointer' onClick={onChange}>
          Change
        </div>
        <div className="input-icon">
          {icon && (
            icon
          )}
        </div>
      </div>
    </div>
  </div>
);

export default AuthChangeInput;
