import { useEffect, useRef, useState } from "react";

const useToggleRow = ({ 
  rowRef, 
  multiple = false, 
  defaultOpen = false, 
  parentId = null,
  panelVisibleOut = false
}) => {
  const panelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [visibleTimeout, setVisibleTimeout] = useState(null);

  const toggleRow = (extraHeight = 0) => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (!multiple) {
      let allPoints = [];
      if (parentId) {
        const parentPanel = document?.getElementById(parentId);
        if (parentPanel) {
          allPoints = parentPanel?.querySelectorAll(".point");
        }
      } else {
        allPoints = document?.querySelectorAll(".point");
      }
      allPoints?.forEach(function (point) {
        const otherPanel = point.querySelector(".panel");
        if (otherPanel !== panel && otherPanel.style.maxHeight) {
          otherPanel.style.maxHeight = null;
          if (panelVisibleOut) {
            otherPanel.style.overflow = "hidden";
            otherPanel.classList.remove("open");
          }
        }
      });
    }
    if (visibleTimeout) {
      clearTimeout(visibleTimeout);
    }
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      panel.classList.remove("open");
      if (panelVisibleOut) {
        panel.style.overflow = "hidden";
      }
      setIsOpen(false);
    } else {
      console.log('panel', extraHeight)
      panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
      panel.classList.add("open");
      if (panelVisibleOut) {
        setVisibleTimeout(setTimeout(() => {
          if (panel.classList.contains("open")) {
            panel.style.overflow = "visible";
          }
          setVisibleTimeout(null);
        }, 400));
      }
      setIsOpen(true);
    }
  }

  const refreshState = (extraHeight = 0) => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
    }
  }

  const refreshPanelStates = (extraHeight = 0) => {
    if (parentId) {
      const parentPanel = document?.getElementById(parentId);
      if (parentPanel) {
        const allPanels = parentPanel?.querySelectorAll(".panel");
        allPanels?.forEach(function (panel) {
          if (panel.style.maxHeight) {
            panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
          }
        });
      }
      return;
    }
    const allPanels = document?.querySelectorAll(".panel");
    allPanels?.forEach(function (panel) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + extraHeight + "px";
      }
    });
  }

  const setOpen = (state) => {
    setIsOpen(state);
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (state) {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.classList.add("open");
      if (panelVisibleOut) {
        setVisibleTimeout(setTimeout(() => {
          if (panel.classList.contains("open")) {
            panel.style.overflow = "visible";
          }
          setVisibleTimeout(null);
        }, 400));
      }
    } else {
      panel.style.maxHeight = null;
      panel.classList.remove("open");
      if (panelVisibleOut) {
        panel.style.overflow = "hidden";
      }
    }
  }

  const closeAllPanels = () => {
    if (visibleTimeout) {
      clearTimeout(visibleTimeout);
      setVisibleTimeout(null);
    }
    if (parentId) {
      const parentPanel = document?.getElementById(parentId);
      if (parentPanel) {
        const allPanels = parentPanel?.querySelectorAll(".panel");
        allPanels?.forEach(function (panel) {
          panel.style.maxHeight = null;
          panel.classList.remove("open");
          if (panelVisibleOut) {
            panel.style.overflow = "hidden";
          }
        });
      }
      return;
    }
    const allPanels = document?.querySelectorAll(".panel");
    allPanels?.forEach(function (panel) {
      panel.style.maxHeight = null;
      panel.classList.remove("open");
      if (panelVisibleOut) {
        panel.style.overflow = "hidden";
      }
    });
  }

  useEffect(() => {
    if (!defaultOpen) return;
    const parent = rowRef.current;
    const panel = parent?.querySelector(".panel");
    if (!panel) return;
    const originalTransition = panel.style.transition;
    panel.style.transition = 'none';
    toggleRow(20);
    setTimeout(() => {
      panel.style.transition = originalTransition;
    }, 0);
  }, [defaultOpen]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const maxHeight = panelRef?.current?.style?.maxHeight;
          setIsOpen(maxHeight && parseInt(maxHeight, 10) > 0);
        }
      }
    });

    if (panelRef.current) {
      observer.observe(panelRef.current, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      if (panelRef.current) {
        observer.disconnect();
      }
    };
  }, [panelRef, panelRef?.current]);

  return { 
    toggleRow, 
    setOpen, 
    refreshState, 
    refreshPanelStates, 
    closeAllPanels, 
    isOpen,
    panelRef
  };
}

export default useToggleRow;
