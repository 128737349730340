import React, { useEffect, useRef } from "react";

const CustomInputField = ({
  value,
  onChange,
  placeholder,
  greyBorder,
  greyBackground,
  isError,
  labelIcon,
  errorMsg,
  type,
  prefix,
  showHighlight,
  required,
  onBlur=()=>{},
  disabled,
  style,
  className,
  setRef
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (setRef) {
      setRef(inputRef);
    }
  }, [inputRef]);

  return (
    <div className=""
    style={{
      pointerEvents:disabled?  "none" :"all",
      maxWidth: "100%",
    }}
    
    >
      <div
        className={`${
          greyBorder ? "highlight-input-with-grey-border" : "highlight-input"
        }   ${
          isError ? "input-error" : ""
        } d-flex align-items-center radius-4 ${
          greyBackground ? "bg-grey" : ""
        } w-100
        
        ${showHighlight?  "highlight-contain" :""}
        ${required ? "required" : ""}
        `}
        style={{
          maxWidth: "100%",
      overflow: "hidden",
        }}
      >
        {labelIcon && (
          <img
            src={labelIcon}
            alt=""
            className="ml-2"
            style={{
              filter: isError
                ? "invert(68%) sepia(34%) saturate(6584%) hue-rotate(320deg) brightness(95%) contrast(101%)"
                : "",
            }}
          />
        )}
        {prefix&&
        <div className="ml-1">{prefix} </div>}
        <input
          ref={inputRef}
        onBlur={onBlur}
          type={type? type : "text"}
          className={`border-none bg-transparent p-2 flex-1 ${className ?? ""}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={style}
        />
      </div>
      {isError && <span className="f-danger f-12">{errorMsg}</span>}
    </div>
  );
};

export default CustomInputField;
