import { Button } from "react-bootstrap";
import "./sections.styles.scss";
import ImageLoader from "../../Common/ImageLoader";

const TeamInviteSignupSection = ({ 
  inviteByName,
  inviteSentToName,
  defaultEmail,
  logo,
  setFormState
}) => {
  return (
    <div className="team-invite-section d-flex flex-column" style={{ gap: '16px' }}>
      <div className="d-flex justify-content-center">
        <ImageLoader
          src={
            logo
          }
          width={40}
          height={40}
          alt=""
          className="logo"
          noImageComponent={(
            <div 
              className="logo"
              style={{ width: '40px', height: '40px', backgroundColor: '#F4F4F4' }} 
            />
          )}
        />
      </div>
      <div>
        <div className="title f-black">
          {inviteByName} from {inviteSentToName} 
        </div>
        <div className="title f-black">
          has invited you to HighGround
        </div>
      </div>
      <div className="line" />
      <div>
        <div className="invite-to f-grey">
          This invitation was sent to
        </div>
        <div className="invite-email f-grey">
          {defaultEmail}
        </div>
      </div>
      <div >
        <form
          onSubmit={e => {
            e.preventDefault();
            setFormState(1);
          }}
        >
          <div style={{ marginTop: '8px' }}>
            <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type="submit"
              style={{ padding: "5px" }}
            >
              Sign up
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TeamInviteSignupSection;
