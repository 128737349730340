import { useState } from "react";

const useUserDetails = () => {
  const userData = localStorage.getItem('object_Data');
  const [userDetails, setUserDetails] = useState(userData ? JSON.parse(userData) : {});

  return {
    userDetails,
    setUserDetails,
  };
}

export default useUserDetails;
