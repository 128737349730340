import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { IndependentGetAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import userUtils from "../../../../Utils/user.utils";
import { BASE_API_PAGE_ENUMS, BASE_API_ROUTES } from "../../../../constants/apis.constants";
import ImageLoader from "../../../Common/ImageLoader";
import integrationUtils from "../../../../Utils/integration.utils";
import { ReplaceIntegrationTypes } from "../../../../constants/integrations.constants";
import IntegrationListItem from "../../../AttackSurface/KPICyberScoreSection/integration-list-item.component";
import useApiActions from "../../../../hooks/api-actions.hook";

const ReplaceTypeData = {
    [ReplaceIntegrationTypes.REMOVE]: {
        title: ' Caution, replace this tool',
        description: 'To remove this tool from your security stack, you must remove it from all clients, products, services and packages.',
        description2: 'Alternatively, you can replace this tool with an alternative tool below.',
        confirmButton: 'Replace and Remove'
    },
    [ReplaceIntegrationTypes.DELETE]: {
        title: 'Caution, replace this tool before deleting it',
        description: 'To delete this tool from your security stack, you must remove it from all clients, products, services and packages.',
        description2: 'Alternatively, you can replace this tool with an alternative tool below.',
        confirmButton: 'Replace and Delete'
    }
}

export const ReplaceToolModal = ({
    show,
    selectedToolId,
    setReplaceToolData = () => {},
    technologyType,
    technologyEnum,
    hideModal,
    onReplaceTool,
    refreshData,
    inUseDataResponse,
    replaceType = ReplaceIntegrationTypes.REMOVE // remove or delete
}) => {
    const { authTokens } = useAuth();
    const [inUseData, setInUseData] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [dropdownLoading, setDropdownLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allTools, setAllTools] = useState([]);
    const [securityStackTools, setSecurityStackTools] = useState([]);
    const [selectedTool, setSelectedTool] = useState({});
    const replaceTypeData = ReplaceTypeData[replaceType];
    const [renamingIntegrationId, setRenamingIntegrationId] = useState(null);
    const { renameIntegration, deleteIntegration, checkToolInUse } = useApiActions();
    const [isReplacing, setIsReplacing] = useState(false);

    const filteredAllTools = useMemo(() => {
        return allTools?.filter((item) => item?.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()));
    }, [allTools, searchStr]);

    const filteredSecurityStackTools = useMemo(() => {
        return securityStackTools?.filter((item) => item?.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()));
    }, [securityStackTools, searchStr]);

    useEffect(() => {
        if (show) {
            getAllIntegrationsByTechType(technologyType);
            const resInUseData = [
                inUseDataResponse?.TotalClientCount > 0 && {
                    icon: '/images/client-icon-light.svg',
                    label: `${inUseDataResponse?.TotalClientCount} ${inUseDataResponse?.TotalClientCount == 1 ? 'Client' : 'Clients'}`,
                },
                inUseDataResponse?.TotalProductCount > 0 && {
                    icon: '/images/other-apps-icon-light.svg',
                    label: `${inUseDataResponse?.TotalProductCount} ${inUseDataResponse?.TotalProductCount == 1 ? 'Product' : 'Products'}`,
                },
                inUseDataResponse?.TotalServicesCount > 0 && {
                    icon: '/images/service-icon-light.svg',
                    label: `${inUseDataResponse?.TotalServicesCount} ${inUseDataResponse?.TotalServicesCount == 1 ? 'Service' : 'Services'}`,
                },
                inUseDataResponse?.TotalPackagesCount > 0 && {
                    icon: '/images/msp/msp-package-icon-light.svg',
                    label: `${inUseDataResponse?.TotalPackagesCount} ${inUseDataResponse?.TotalPackagesCount == 1 ? 'Package' : 'Packages'}`,
                }
            ]?.filter(Boolean)
            setInUseData(resInUseData);
        } else {
            setInUseData([]);
            setSearchStr("");
            setDropdownLoading(false);
            setAllTools([]);
            setSecurityStackTools([]);
            setSelectedTool({});
        }
    }, [show, selectedToolId])

    const onRename = (integrationId, newName) => {
        setRenamingIntegrationId(integrationId)
        renameIntegration(integrationId, newName).finally(() => {
            setRenamingIntegrationId(null);
            getAllIntegrationsByTechType(technologyType);
            const selectDropdown = document.querySelector('.replace-dropdown');
            selectDropdown.click();
        });
    }

    const onDeleteOptionClick = (integrationId) => {
        return new Promise((resolve) => {
            checkToolInUse({ integrationId }).then((response) => {
                const resInUseData = [
                  response?.TotalClientCount > 0,
                  response?.TotalProductCount > 0,
                  response?.TotalServicesCount > 0,
                  response?.TotalPackagesCount > 0
                ]?.filter(Boolean);
                if (resInUseData.length > 0) {
                  setReplaceToolData({
                    integrationId: integrationId,
                    replaceType: ReplaceIntegrationTypes.DELETE,
                    inUseData: response
                  });
                  resolve();
                } else {
                  deleteIntegration({ integrationId }).finally(() => {
                    resolve();
                    getAllIntegrationsByTechType(technologyType);
                    const replaceDrp = document.querySelector('.replace-dropdown');
                    replaceDrp?.click();
                    refreshData();
                    if (selectedTool?.IntegrationId === integrationId) {
                      setSelectedTool({});
                    }
                  });
                }
              }).catch(() => {
                resolve();
              });
        })
    }

    const getAllIntegrationsByTechType = async (
        technologyType,
    ) => {
        return new Promise((resolve, reject) => {
            try {
                const message = userUtils.getUserMessage();
                const formData = new FormData();
                formData.append('TechnologyType', technologyType);
                formData.append('TechnologyTypeEnum', technologyEnum);
                formData.append('ShowAll', true);
                formData.append('accessKey', message);

                fetch(
                    BASE_API_ROUTES.getAllIntegrationsByTechType,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${authTokens}`,
                        },
                    }
                )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data?.mr?.Success) {
                            setAllTools(data?.Integration_List)
                            setSecurityStackTools(data?.SecurityStack_List)
                        } else {
                            reject('Failed to fetch integrations');
                            setDropdownLoading(false);
                        }
                    })
                    .catch((error) => {
                        CallToast("Something went wrong", false, false, "v2style");
                        setDropdownLoading(false);
                        hideModal();
                        setSelectedTool({});
                        reject(error);
                    });
            } catch (error) {
                console.error('Error fetching integrations', error);
                setDropdownLoading(false);
                reject(error);
            }
        });
    };

    function replaceTool(newToolId) {
        setIsReplacing(true);
        return new Promise((resolve, reject) => {
            IndependentGetAPIData(991, authTokens, {
                optionEnum1: selectedToolId,
                optionEnum2: newToolId,
            })
                .then((response) => {
                    if (replaceType === ReplaceIntegrationTypes.DELETE) {
                        IndependentGetAPIData(BASE_API_PAGE_ENUMS.DeleteIntegration, authTokens, {
                            optionEnum1: selectedToolId,
                            optionEnum2: 0
                        }).then((response) => {
                            CallToast("Replaced successfully", false, false, "v2style");
                            hideModal();
                            setSelectedTool({});
                            onReplaceTool();
                        }).catch((error) => {
                            CallToast("Something went wrong", false, false, "v2style");
                            hideModal();
                            setSelectedTool({});
                        }).finally(() => {
                            setIsReplacing(false);
                        });
                    } else {
                        CallToast("Replaced successfully", false, false, "v2style");
                        hideModal();
                        setSelectedTool({});
                        onReplaceTool();
                        setIsReplacing(false);
                    }
                })
                .catch((error) => {
                    CallToast("Something went wrong", false, false, "v2style");
                    hideModal();
                    setSelectedTool({});
                    setIsReplacing(false);
                });
        });
    }

    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName="replace-tool-modal"
            className="replace-tool-modal"
            aria-labelledby="replace-tool-modal"
            centered
            scrollable
            onHide={() => {
                if (isReplacing) return;
                hideModal();
                setSelectedTool({});
            }}
        >
            <Modal.Header>
                <div className="d-flex align-items-center">
                    <img
                        alt=""
                        id=""
                        width={20}
                        className="mr-2"
                        src="/images/warningTriangleFullOrange.svg"
                    />
                    <div className="f-500 f-16 f-black line-height-28 letter-space-2">
                        {replaceTypeData.title}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                    {replaceTypeData.description}
                </div>
                <div className="f-400 f-14 f-black line-height-22 letter-space-2 mt-4">
                    {replaceTypeData.description2}
                </div>

                {
                    <div>
                    {
                        inUseData?.length > 0 ?
                            <div className="bg-grey p-2 radius-4 mt-2">
                                <div className="f-400 f-14 f-grey line-height-22 letter-space-2">In use by</div>

                                {
                                    inUseData?.map((item) => {
                                        return (
                                            <div className="d-flex align-items-center mt-1">
                                                <img
                                                    alt=""
                                                    src={item?.icon}
                                                    className="mr-2"
                                                    width={12}
                                                    height={12}
                                                />
                                                <div className="f-400 f-14 f-black line-height-22 letter-space-2">{item?.label}</div>
                                            </div>
                                        )
                                    })
                                }

                            </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-4">
                                Not in use yet
                            </div>
                    }
                    </div>
                }

                <div className="bg-grey p-2 radius-4 mt-2">
                    <div className="f-400 f-14 f-grey line-height-22 letter-space-2">Replace with</div>
                    <Dropdown drop="right" className="mr-4 my-dropdown transparennt-wrapper-dropdown replace-dropdown"
                        onToggle={(e) => {
                            setIsDropdownOpen(e);
                            setTimeout(() => {
                                const searchInput = document.getElementById('replace-tool-search');
                                searchInput?.focus();
                            }, 100)
                        }}
                    >
                        <Dropdown.Toggle>
                            {
                                selectedTool?.IntegrationName ? <div
                                    className="d-flex align-items-center bg-hover-grey p-2 pointer"
                                >
                                    <ImageLoader
                                        alt=""
                                        width={16}
                                        height={16}
                                        className="mr-2 rounded-full"
                                        src={integrationUtils.getImagePath(selectedTool?.IntegrationId, selectedTool?.ToolIcon)}
                                        noImageComponent={(
                                            <img 
                                                width={16}
                                                height={16}
                                                src={"/images/attack-surface/generic2.svg"}
                                                className="mr-2 rounded-full"
                                                alt=""
                                            />
                                        )}
                                    />
                                    <div className='f-500 f-12 line-height-18 letter-space-2 f-grey'>
                                        {selectedTool?.IntegrationName}
                                    </div>
                                </div> : <div className="d-flex align-items-center pointer mt-3">
                                    <img
                                        alt=""
                                        id=""
                                        width={16}
                                        className="mr-2"
                                        src="/images/warningTriangle.svg"
                                    />
                                    <div className="f-400 f-14 f-darkgrey">
                                        Select tool
                                    </div>
                                </div>
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                            className="p-1 menu disable-transform top inner-dropdown"
                            popperConfig={{ 
                                strategy: 'fixed',
                            }}
                            renderOnMount
                            style={{  minHeight: '200px' }}
                        >
                            <div>
                                <div className="no-highlight-input w-100 d-flex align-items-center p-1 pb-2">
                                    <div>
                                        <img
                                            width={14}
                                            height={14}
                                            src="/images/attack-surface/search-icon.svg"
                                            className="m-1"
                                            alt=""
                                        />
                                    </div>
                                    <input
                                        id="replace-tool-search"
                                        className="pl-2 flex-grow-1"
                                        placeholder="Search"
                                        type="text"
                                        value={searchStr}
                                        onChange={(e) => {
                                            setSearchStr(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            {searchStr ? (
                                 <div className="hide-scrollbar" style={{ overflowY: 'auto', maxHeight: '220px' }}>
                                 <div className="f-400 f-12 f-darkgrey line-height-18 letter-spice-2" style={{ padding: '4px 12px' }}>
                                     Results
                                 </div>
                                 {
                                     dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
                                         <div className="msp-service-update-loader ml-2 mr-1"></div>
                                         <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                                     </div> : <div>
                                         {
                                            [
                                                ...filteredSecurityStackTools,
                                                ...filteredAllTools
                                            ]?.map((integration) => {
                                                return (
                                                    <div
                                                        className="d-flex align-items-center bg-hover-grey pointer"
                                                        style={{ padding: '0px 12px' }}
                                                        onClick={(e) => {
                                                            setSelectedTool(integration);
                                                            e.target
                                                                .closest(".replace-dropdown")
                                                                .click();
                                                        }}
                                                    >
                                                        <IntegrationListItem 
                                                            integrationName={integration?.IntegrationName}
                                                            integrationId={integration?.IntegrationId}
                                                            isLoading={renamingIntegrationId === integration?.IntegrationId}
                                                            toolIcon={integration?.ToolIcon}
                                                            onRenameOption={onRename}
                                                            showAddSub={false}
                                                            showRemove={false}
                                                            showRename={integration?.IsCustom}
                                                            setShowResetToolModal={() => {}}
                                                            onDeleteOption={onDeleteOptionClick}
                                                            isUserDefined={integration?.IsCustom}
                                                            style={{ width: '100%' }}
                                                            renameStyle={{ width: '100px' }}
                                                            searchComponent={searchStr}
                                                        />
                                                    </div>
                                                )
                                            })
                                         }
                                     </div>
                                 }
                             </div>
                            ) : (
                                 <div className="hide-scrollbar" style={{ overflowY: 'auto', maxHeight: '220px' }}>
                                 <div className="f-400 f-12 f-darkgrey line-height-18 letter-spice-2" style={{ padding: '4px 12px' }}>
                                     Security Stack tools
                                 </div>
                                 {
                                     dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
                                         <div className="msp-service-update-loader ml-2 mr-1"></div>
                                         <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                                     </div> : <div>
                                         {
                                             filteredSecurityStackTools?.length > 0 ? <div>
                                                 {
                                                     filteredSecurityStackTools?.map((integration) => {
                                                         return (
                                                             <div
                                                                 className="d-flex align-items-center bg-hover-grey pointer"
                                                                 style={{ padding: '0px 12px' }}
                                                                 onClick={(e) => {
                                                                     setSelectedTool(integration);
                                                                     e.target
                                                                         .closest(".replace-dropdown")
                                                                         .click();
                                                                 }}
                                                             >
                                                                 <IntegrationListItem 
                                                                    integrationName={integration?.IntegrationName}
                                                                    integrationId={integration?.IntegrationId}
                                                                    isLoading={renamingIntegrationId === integration?.IntegrationId}
                                                                    toolIcon={integration?.ToolIcon}
                                                                    onRenameOption={onRename}
                                                                    showAddSub={false}
                                                                    showRemove={false}
                                                                    showRename={integration?.IsCustom}
                                                                    setShowResetToolModal={() => {}}
                                                                    onDeleteOption={onDeleteOptionClick}
                                                                    isUserDefined={integration?.IsCustom}
                                                                    style={{ width: '100%' }}
                                                                    renameStyle={{ width: '100px' }}
                                                                    searchComponent={searchStr}
                                                                 />
                                                             </div>
                                                         )
                                                     })
                                                 }
                                             </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                                                 {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
                                             </div>
                                         }
                                     </div>
                                 }
                                 <div className="f-400 f-12 f-darkgrey line-height-18 letter-spice-2" style={{ padding: '4px 12px' }}>
                                     All tools
                                 </div>
                                 {
                                     dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
                                         <div className="msp-service-update-loader ml-2 mr-1"></div>
                                         <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                                     </div> : <div>
                                         {
                                             filteredAllTools?.length > 0 ? <div>
                                                 {
                                                     filteredAllTools?.map((integration) => {
                                                         return (
                                                             <div
                                                                 className="d-flex align-items-center bg-hover-grey pointer"
                                                                 style={{ padding: '0px 12px' }}
                                                                 onClick={(e) => {
                                                                     setSelectedTool(integration);
                                                                     e.target
                                                                         .closest(".replace-dropdown")
                                                                         .click();
                                                                 }}
                                                             >
                                                                 <IntegrationListItem 
                                                                     integrationName={integration?.IntegrationName}
                                                                     integrationId={integration?.IntegrationId}
                                                                     isLoading={renamingIntegrationId === integration?.IntegrationId}
                                                                     toolIcon={integration?.ToolIcon}
                                                                     onRenameOption={onRename}
                                                                     showAddSub={false}
                                                                     showRemove={false}
                                                                     showRename={integration?.IsCustom}
                                                                     setShowResetToolModal={() => {}}
                                                                     onDeleteOption={onDeleteOptionClick}
                                                                     isUserDefined={integration?.IsCustom}
                                                                     style={{ width: '100%' }}
                                                                     renameStyle={{ width: '100px' }}
                                                                     searchComponent={searchStr}
                                                                     drop="up"
                                                                 />
                                                             </div>
                                                         )
                                                     })
                                                 }
                                             </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                                                 {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
                                             </div>
                                         }
                                     </div>
                                 }
                             </div>
                            )}
                        </Dropdown.Menu>
                    </Dropdown >
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex align-items-center justify-content-end w-100">
                    <div
                        className="f-500 f-14 greyText2 line-height-22 letter-space-2 pointer mr-3"
                        onClick={() => {
                            if (isReplacing) return;
                            hideModal();
                            setSelectedTool({});
                        }}
                    >
                        Cancel
                    </div>
                    <Button
                        className={`${selectedTool?.IntegrationName ? 'hg-blue-btn' : 'disabled-btn'} d-flex align-items-center`}
                        style={{ gap: '8px' }}
                        onClick={() => {
                            if (isReplacing) return;
                            replaceTool(selectedTool?.IntegrationId);
                        }}
                    >
                        <span>{replaceTypeData.confirmButton}</span>
                        {isReplacing && (
                            <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                                className="mx-1"
                                style={{
                                width: "12px",
                                height: "12px",
                                }}
                            />
                        )}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ReplaceToolModal;
