import { Spinner } from "react-bootstrap";
import integrationUtils from "../../../../Utils/integration.utils";
import { HGHighlightText } from "../../../App/App";
import IntegrationItemDropdown from "../integration-item.dropdown";
import useApiActions from "../../../../hooks/api-actions.hook";
import { useEffect, useState } from "react";
import { ReplaceIntegrationTypes } from "../../../../constants/integrations.constants";
import { IndependentGetAPIData } from "../../../../Utils/commonApi";
import { BASE_API_PAGE_ENUMS } from "../../../../constants/apis.constants";
import { useAuth } from "../../../context/Auth";
import CustomInputField from "../../../Theme/CustomInputField";

const IntegrationItem = ({ 
  integrationName, 
  integrationId, 
  toolIcon, 
  isLoading,
  onAdd, 
  showIcon = true,
  searchValue = "",
  isCustom,
  refreshData = () => {},
  isShared = false,
  setReplaceToolData = () => {}
}) => {
  const { authTokens } = useAuth();
  const imagePath = integrationUtils.getImagePath(integrationId, toolIcon);
  const { checkToolInUse, deleteIntegration } = useApiActions();
  const [newIntegrationName, setNewIntegrationName] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);
  const [inputRef, setInputRef] = useState(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isRenameLoading, setIsRenameLoading] = useState(false);

  const onDeleteOption = async () => {
    if (isShared) {
      const response = await checkToolInUse({ integrationId })
      const resInUseData = [
        response?.TotalClientCount > 0,
        response?.TotalProductCount > 0,
        response?.TotalServicesCount > 0,
        response?.TotalPackagesCount > 0
      ]?.filter(Boolean);
      if (resInUseData.length > 0) {
        setReplaceToolData({
          integrationId,
          selectedReplaceType: ReplaceIntegrationTypes.DELETE,
          inUseData: response
        });
      } else {
        deleteIntegration({ integrationId }).finally(() => {
          refreshData();
        });
      }
    } else {
      deleteIntegration({ integrationId }).finally(() => {
        refreshData();
      });
    }
  }

  async function renameIntegration(_integrationId, newName) {
    setIsRenameLoading(true);
    return new Promise((resolve, reject) => {
      IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      })
        .finally(() => {
          resolve();
          refreshData();
          setIsRenameLoading(false);
        })
    });
  }

  const onRenameOptionClick = () => {
    setIsRenaming(true);
    setNewIntegrationName(integrationName);
  }

  const onAcceptRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    renameIntegration(integrationId, newIntegrationName);
  }

  const onCancelRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    setNewIntegrationName('');
  }

  useEffect(() => {
    if (!isRenaming) return;
    inputRef?.current?.focus();
  }, [isRenaming, inputRef?.current]);

  return (
    <div 
      className="d-flex align-items-center justify-content-between w-100 pointer integration-item-wrapper"
      onClick={() => {
        if (isLoading || isRenaming) return;
        onAdd();
      }}
    >
      <div className="d-flex align-items-center">
        {showIcon && (
          <img
            alt=""
            style={{
              width: "16px",
              height: "16px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
            src={imagePath}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src =
                "/images/attack-surface/generic2.svg";
            }}
          />
        )}
        {isRenaming ? (
          <div className="d-flex align-items-center">
            <CustomInputField
              placeholder={"Integration name"}
              type={"text"}
              value={newIntegrationName}
              onChange={(e) => {
                setNewIntegrationName(e.target.value);
              }}
              className='rename-input'
              setRef={setInputRef}
            />
          </div>
        ) : (
          <div className="f-grey">
            <span 
              dangerouslySetInnerHTML={{
                __html: HGHighlightText(integrationName, searchValue),
              }}
            />
          </div>
        )}
      </div>
      {isLoading || isRenameLoading ? (
        <Spinner
          animation="border"
          variant="secondary"
          size="sm"
          className="mx-2"
          style={{
            width: "12px",
            height: "12px",
          }}
        />
        ) : (
          isRenaming ? (
            <div className="d-flex align-items-center ml-2" style={{ gap: '8px' }}>
              <button className="apply" onClick={onAcceptRename}>Apply</button>
              <button className="cancel" onClick={onCancelRename}>Cancel</button>
            </div>
          ) : (
            isCustom ? (
              <div 
                className="pr-2 integration-options"
                style={{ 
                  height: '20px',
                  ...(isOptionsOpen ? { display: 'block' } : {})
                }}
              >
                <IntegrationItemDropdown 
                  integrationId={integrationId} 
                  onRenameOption={onRenameOptionClick}
                  onDeleteOption={onDeleteOption}
                  showRename={true}
                  showRemove={false}
                  showDelete={true}
                  setOpen={setIsOptionsOpen}
                />
              </div>
            ) : (
              <div className="pr-2">
                <button className="integration-add select-tool-btn">
                  <img
                    alt=""
                    src="/images/attack-surface/add-tool-icon.svg"
                    className="mr-2"
                    width={11}
                    height={11}
                    style={{ top: '-1px', position: 'relative' }}
                  />
                  <span className="f-grey">Add</span>
                </button>
              </div>
            )
          )
      )}
    </div>
  )
}

export default IntegrationItem;
