import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../personal-profile/personal-profile-modals/usermodal.scss";
import { postRequest } from "../../../Utils/commonApi";
import LabelledInputPassword from "../../Theme/LabelledInputPassword";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
function UserDetailModal({ type, userDetails, memberId, show, hideModal, handleSave, refreshData, passedOptionEnum99 }) {
  const { authTokens } = useAuth();
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");
  const [lastNameErrorText, setlastNameErrorText] = useState("");
  const [page, setPage] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // for email updation States
  const [password, setPassword] = useState("");
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [code, setCode] = useState("");
  const [codeErrorText, setCodeErrorText] = useState("");

  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState(false);
  const [positionErrorText, setPositionErrorText] = useState("");

  const [saving, setSaving] = useState(false);
  useEffect(() => {
    if (show) {
      const [firstNameSplited, lastNameSplited] = userDetails.FullName.split(/ (.*)/s);
      setFirstName(firstNameSplited);
      setLastName(lastNameSplited);
      if (type === 'position') {
        setPosition(userDetails.Position)
      }
      setPage(0);
    }
  }, [show]);

  const ClearState = () => {
    setPassword("");
    setPasswordErrorText("");
    setNewEmail("");
    setEmailErrorText("");
    setCode("");
    setCodeErrorText("");
    setPage(0);
    hideModal();
  };

  const SubmitName = () => {
    if (!firstName) {
      setfirstNameError(true);
      setfirstNameErrorText("This field can't be empty");
      return false;
    }
    if (!lastName) {
      setlastNameError(true);
      setlastNameErrorText("This field can't be empty");
      return false;
    }
    if (!firstName.match(/^[A-Za-z]+$/)) {
      setfirstNameError(true);
      setfirstNameErrorText("Don't use numbers or symbols");
      return false;
    }
    if (!lastName.match(/^[A-Za-z\s]+$/)) {
      setlastNameError(true);
      setlastNameErrorText("Don't use numbers or symbols");
      return false;
    }
    setSaving(true);

    postRequest(authTokens, "UpdateUserFullName", {
      FirstName: firstName,
      LastName: lastName,
      optionStrEnum1: memberId,
      optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      setSaving(false);
      if (response.Message || response.Success === true) {
        // window.localStorage.setItem(
        //   "user_MessageObj",
        //   JSON.stringify(response?.Message)
        // );

        setSaving(false);
        CallToast("Updated successfully", false, false, "v2style");
        refreshData();
        setLastName("");
        setFirstName("");
        hideModal();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };

  const EmailUpdation = () => {
    let emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (password == "") {
      setPasswordErrorText("Please fill password");
      return false;
    } else {
      if (page == 0) {
        setEmailErrorText("");
        setCodeErrorText("");
        setPage(page + 1);
        return false;
      }
    }
    if (!newEmail.match(emailPattern) || newEmail == "") {
      setEmailErrorText("Not a valid email");
      return false;
    } else {
      if (page == 1) {
        setCodeErrorText("");
        setPage(page + 1);
        return false;
      }
    }
    if (code == "") {
      setCodeErrorText("Please fill a valid code");
      return false;
    } else {
      setPassword("");
      setNewEmail("");
      setCode("");
      CallToast("Your Email successfully changed", false, false);
      hideModal();
    }
  };

  const SubmitPositions = () => {
    if (position.length == 0) {
      setPositionError(true);
      setPositionErrorText("This field can't be empty");
      return false;
    }
    if (!position.match(/^[A-Za-z\s]+$/)) {
      setPositionError(true);
      setPositionErrorText("Don't use numbers or symbols");
      return false;
    }
    setSaving(true);

    postRequest(authTokens, "UpdatePosition", {
      position: position,
      optionStrEnum1: memberId,
      optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    }).then((response) => {
      setSaving(false);
      if (response.Success) {

        setSaving(false);
        CallToast("Updated successfully", false, false, "v2style");
        refreshData();
        setLastName("");
        setPosition("");
        hideModal();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="user-details-modal"
      className=""
      aria-labelledby="user-details-modal-title-vcenter"
      centered
      scrollable
      onHide={ClearState}
    >
      <Modal.Header className="header text-center ">
        {page !== 0 && (
          <img
            src="/images/leftarrow.png"
            alt=""
            onClick={() => setPage(page - 1)}
          />
        )}
        <h1 className="w-100 mb-0 mt-0" style={{ fontSize: "16px" }}>
          {type == "name" && "Change name"}
          {type == "email" && "Change email"}
          {type == "position" && "Position"}
        </h1>
        <span className="cursor-pointer" onClick={ClearState}>
          <img src="/images/user-detail-close.svg" alt="" />
        </span>
      </Modal.Header>

      <Modal.Body>
        {/* For first name and Last name */}
        {type == "name" && (
          <>
            <div className="mt-2">
              <p className="label-input">First Name</p>
              <InputGroup>
                <Form.Control
                  className={`user-name ${firstNameError ? "input-error" : ""}`}
                  id=""
                  type="text"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setfirstNameError(false);
                    setfirstNameErrorText("");
                  }}
                />
              </InputGroup>
              {firstNameError && (
                <span className="error-text">{firstNameErrorText}</span>
              )}
            </div>
            <div className="mt-3">
              <p className="label-input">Last Name</p>
              <InputGroup>
                <Form.Control
                  className={`user-name ${lastNameError ? "input-error" : ""}`}
                  id=""
                  type="text"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setlastNameError(false);
                    setlastNameErrorText("");
                  }}
                />
              </InputGroup>
              {lastNameError && (
                <span className="error-text">{lastNameErrorText}</span>
              )}
            </div>
            <div className="empty-div"></div>
          </>
        )}

        {/* For Email Updation */}

        {type == "email" && page < 2 && (
          <div>
            <p className="mt-2">
              Your current email is &nbsp;
              <span className="current-email">{userDetails?.UserName}</span>
            </p>
            <p className="label-input">Please enter your password</p>
            <LabelledInputPassword
              // PrefixComponent={<PrefixPasswordInput />}
              // SuffixComponent={<SuffixComponent />}
              placeholder="Password"
              error={passwordErrorText ? true : false}
              // disabled={true}
              value={password}
              onChange={(e) => {
                setPasswordErrorText("");
                setPassword(e.target.value);
              }}
            />
            {passwordErrorText && (
              <span className="error-text">{passwordErrorText}</span>
            )}
            {page == 1 && (
              <>
                <p className="mt-3">
                  Please enter a new email and we will send you a verification
                  code
                </p>
                <InputGroup>
                  <Form.Control
                    className={`user-name new-email ${
                      emailErrorText ? "input-error" : ""
                    }`}
                    placeholder="New email"
                    id=""
                    type="email"
                    value={newEmail}
                    onChange={(e) => {
                      setEmailErrorText("");
                      setNewEmail(e.target.value);
                    }}
                  />
                </InputGroup>
                {emailErrorText && (
                  <span className="error-text">{emailErrorText}</span>
                )}
              </>
            )}
          </div>
        )}

        {type == "email" && page == 2 && (
          <div>
            <p className="mt-2">
              We just sent you a verification code to &nbsp;
              <span className="current-email">m.mail@highground.io</span>
            </p>
            <InputGroup>
              <Form.Control
                className={`user-name new-email  ${
                  codeErrorText ? "input-error" : ""
                }  `}
                placeholder="Code"
                id=""
                type="text"
                value={code}
                onChange={(e) => {
                  setCodeErrorText("");
                  setCode(e.target.value);
                }}
              />
            </InputGroup>
            {codeErrorText && (
              <span className="error-text">{codeErrorText}</span>
            )}
          </div>
        )}

        {type == "position" && (
          <div className="mt-2">
            <p className="label-input">Position</p>
            <InputGroup>
              <Form.Control
                className={`user-name ${positionError ? "input-error" : ""}`}
                id=""
                type="text"
                value={position}
                onChange={(e) => {
                  setPosition(e.target.value);
                  setPositionError(false);
                  setPositionErrorText("");
                }}
              />
            </InputGroup>
            {positionError && (
              <span className="error-text">{positionErrorText}</span>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn" onClick={ClearState}>
            Cancel
          </Button>
          <Button
            className="submit-btn"
            onClick={() => {
              if (type == "name") {
                SubmitName();
              }

              if (type == "email") {
                EmailUpdation();
                // if (page < 2) {
                //   setPage(page + 1);
                // } else {
                //   CallToast("Your Email successfully changed", false, false);
                //   hideModal();
                // }
              }

              if (type == "position") {
                SubmitPositions();
              }
            }}
          >
            {/* {type == "name" && "Save"}

            {type == "email" && page == 0 && "Continue"}
            {type == "email" && page == 1 && "Send verification code"}
            {type == "email" && page == 2 && "Change email"} */}
            {saving ? (
              <>
                <Spinner
                  size="sm"
                  className=" ml-2 mr-2"
                  animation="border"
                  variant="light"
                />
                Saving...
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default UserDetailModal;
