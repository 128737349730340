import { useEffect, useRef } from "react";

const useScrollLoader = ({ onLoad, pager = {}, ref }) => {
  const scrollRef =  useRef(null);
  const observerTarget = ref ? ref : scrollRef;
  const pagers = Array.isArray(pager) ? pager : [pager];

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        pagers.forEach(pager => {
          if (!pager.loading && entries[0].isIntersecting && (pager?.currentPage || -1) < (pager?.totalPages || 0)) {
            onLoad();
          }
        })
      },
      { threshold: 1, }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, pager]);

  return observerTarget;
}

export default useScrollLoader;
