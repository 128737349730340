import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import IntegrationItem from "../../../AttackSurface/KPICyberScoreSection/select-tool/integration-item.component";

const SelectIntegrationDropdown = ({
  children,
  linkedTools = [],
  setLinkedTools = () => { }
}) => {
  const dropdownRef = useRef();
  const { authTokens } = useAuth();
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [allIntegrationList, setAllIntegrationList] = useState([]);

  useEffect(() => {
    if (IsDropdownOpen && allIntegrationList.length === 0) {
      setSearchStr("");
      setDropdownLoading(true);
      getAllIntegrationList();
    }
  }, [IsDropdownOpen, allIntegrationList])

  const integrations = useMemo(() => (
    allIntegrationList.filter((item) =>
      !linkedTools.some((linkedTool) => linkedTool.IntegrationId === item.IntegrationId)
    )
  ), [allIntegrationList, linkedTools]);

  async function getAllIntegrationList() {
    await getAPIData(990, authTokens, {
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setAllIntegrationList(response?.SecurityStack_List);
          setDropdownLoading(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setDropdownLoading(false);
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setDropdownLoading(false);
      });
  }


  return (
    <Dropdown ref={dropdownRef} className="select-integration-dropdown mr-4 my-dropdown linked-products-dropdown"
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu 
        popperConfig={{ 
          strategy: 'fixed'
        }}
        style={{
          minHeight: "200px",
        }} 
        className="p-1 menu"
      >
        <div className="drop-header">
          <div className="no-highlight-input w-100 d-flex align-items-center p-2">
            <div>
              <img
                width={14}
                height={14}
                src="/images/attack-surface/search-icon.svg"
                className="m-1"
                alt=""
              />
            </div>

            <input
              className="pl-2 flex-grow-1"
              placeholder="Search"
              type="text"
              value={searchStr}
              autoFocus={true}
              onChange={(e) => {
                setSearchStr(e.target.value);
              }}
            />
          </div>
          <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2 py-1">
            Security Stack
          </div>
        </div>
        {
          dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
            <div className="msp-service-update-loader ml-2 mr-1"></div>
            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
          </div> : <div>
            {
              integrations?.filter((item) =>
                item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))?.length > 0 ? <div>
                {
                  integrations?.filter((item) =>
                    item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))?.map((integration, integrationIndex) => {
                      return (
                        <div
                          className="px-0 pl-3 py-2 integration-item"
                          key={`sellio-${integration?.ToolName}-${integrationIndex}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }} 
                        >
                          <IntegrationItem 
                            integrationId={integration?.IntegrationId}
                            integrationName={integration?.IntegrationName}
                            isLoading={false}
                            toolIcon={integration?.ToolIcon}
                            onAdd={() => {
                              let updated_data = [...linkedTools];
                              let searchIndex = updated_data.findIndex(
                                (item) => item.IntegrationId === integration?.IntegrationId
                              );
                              if (searchIndex !== -1) {
                                updated_data.splice(searchIndex, 1);
                              } else {
                                updated_data.push(integration);
                              }
                              setLinkedTools(updated_data);
                              dropdownRef.current
                                .closest(".select-integration-dropdown")
                                .click();
                            }}
                            searchValue={searchStr}
                            isCustom={integration?.IsCustom}
                            refreshData={() => {
                              getAllIntegrationList();
                            }}
                          />
                        </div>
                      )
                    })
                }
              </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
              </div>
            }
          </div>
        }
      </Dropdown.Menu>
    </Dropdown >
  );
};

export default SelectIntegrationDropdown;