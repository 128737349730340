import React, { useEffect, useMemo, useRef, useState } from "react";
import "./drilldownModal.scss";
import { GreyLoader } from "../Theme/APILoaders";
import { AttestationPopUp } from "./KPIpopup/KPIpopup";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import IntegrationListItem from "./KPICyberScoreSection/integration-list-item.component";
import SelectToolDropdown from "./KPICyberScoreSection/select-tool/select-tool.dropdown";
import NoteSection from './KPICyberScoreSection/note-section/note-section.component';
import SearchTitle from '../Common/search-title.component';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_ACTIONS, ROUTE_PATHS } from "../../constants/app.constants";
import { IntegrationCategories } from "../../constants/integrations.constants";
import useToggleRow from "../../hooks/toggle-row.hook";
import httpUtils from "../../Utils/http.utils";
import { viewOnlyAccess } from "../App/App";
import AddToolToSecStack from "../Common/add-tool-to-sec-stack/add-tool-to-sec-stack.modal";
import useApiActions from "../../hooks/api-actions.hook";
import YesNoDropdown from "../Common/yes-no.dropdown";
import useNoteSection from "./KPICyberScoreSection/note-section/note-section.data";
import ConnectedToolItem from "../MSPv2/securityStack/connected-tool-item.component";

const AttestationRow = ({ 
  tool, 
  toolIndex,
  toolData,
  setToolData,
  clientId,
  showAttestationIcons,
  searchComponent,
  getTools,
  removeAllIntegrations,
  addNewIntegration,
  renameIntegration,
  createIntegrationsMonitoredSOC,
  isSaas,
  setLoading,
  getCyberScoreData,
  AccessStateLabel,
  setSelectedIntegrationId,
  disconnectIntegration,
  rowParentId,
  onChildToggle = () => {},
  displayTopBorder = false,
  hideChilds = true,
  hasParentToolTitle = false,
  refreshPanelState = () => {},
  deleteIntegration = () => {},
  nestedOffset = false,
  isViewOnly=false
}) => {
  const pointRef = useRef(null);
  const panelRef = useRef(null);
  const childContainerRef = useRef(null);
  const history = useHistory();
  const toolTitle = SearchTitle({ title: tool?.TechnologyTitle, searchValue: searchComponent });
  const { toggleRow, refreshState, setOpen, closeAllPanels } = useToggleRow({ rowRef: pointRef, parentId: rowParentId, panelVisibleOut: true });
  const hasChilds = tool.Grouping && tool.IsChild === false;
  const isChildOf = tool.Grouping && tool.IsChild === true;
  const childs = useMemo(() => (
    hasChilds ? toolData.filter((itemToolData) => itemToolData.IsChild && itemToolData.Grouping === tool.Grouping) : []
  ), [toolData, tool, hasChilds]);
  const hasChildToolTitle = useMemo(() => (
    childs.some((child) => SearchTitle({ title: child?.TechnologyTitle, searchValue: searchComponent }))
  ), [childs, searchComponent]);
  const hasToolsToolTitle = useMemo(() => (
    tool?.SelectedTool_List?.some((option) => SearchTitle({ title: option?.ToolName, searchValue: searchComponent }))
  ), [tool, searchComponent]);
  const hasChildsToolsToolTitle = useMemo(() => (
    childs.some((child) => child?.SelectedTool_List?.some((option) => SearchTitle({ title: option?.ToolName, searchValue: searchComponent }))
  )), [childs, searchComponent]);
  const childWrapperId = useMemo(() => hasChilds ? `cyber-score-child-rows-${tool?.TechnologyTitle}` : null, [tool, hasChilds]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const { getAllTechTypeByIntegration } = useApiActions();
  const { isLoading, submitNote } = useNoteSection();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  
  useEffect(() => {
    refreshState();
    if (!searchComponent) {
      closeAllPanels();
      return;
    }
    if (!hasChildToolTitle && !hasToolsToolTitle && !hasChildsToolsToolTitle) return;
    setOpen(true);
  }, [searchComponent]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const maxHeight = panelRef?.current?.style?.maxHeight;
          setIsPanelOpen(maxHeight && parseInt(maxHeight, 10) > 0);
        }
      }
    });

    if (panelRef.current) {
      observer.observe(panelRef.current, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      if (panelRef.current) {
        observer.disconnect();
      }
    };
  }, [panelRef, panelRef?.current]);

  if (!toolTitle && !hasChildToolTitle && !hasToolsToolTitle && !hasChildsToolsToolTitle && !hasParentToolTitle) return null;
  if (hideChilds && isChildOf) return null;

  const onToggleRow = () => {
    toggleRow();
    onChildToggle(childContainerRef?.current?.scrollHeight);
  }

  const yesNoId = `yes-no-dropdown-${tool?.TechnologyEnum}`;

  function openTheDropDown() {
    const dropdown = document.querySelector(`#${yesNoId}`);
    if (dropdown) {
      dropdown.click();
    }
  }

  return (
    <div
      className={`point ${displayTopBorder ? 'border-item-top' : ''}`}
      style={{
        pointerEvents: isViewOnly || viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") && 'none'
      }}
      ref={pointRef} 
      onClick={(e) => {
        if (hasChilds && tool?.selected?.category) {
          onToggleRow();
        } else if (tool?.selected?.category === "Yes") {
          onToggleRow();
        } else if (tool?.selected?.category === "No") {
          onToggleRow();
        } else if (tool?.IntegrationList?.length > 0) {
          onToggleRow();
        } else {
          openTheDropDown();
        }
        refreshPanelState();
      }}
    >
      <div 
        className={`d-flex flex-column align-items-center justify-content-between bg-hover-row w-100 row-section ${
          isViewOnly ? 'cursor-default' : viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ? 'cursor-default' : 'pointer'
        }`}
        style={{
          ...(nestedOffset ? { paddingLeft: `${nestedOffset}px` } : {}),
        }}
      >
        <div className="d-flex align-items-center justify-content-between w-100 " style={{ gap: '24px' }}>
          <div className="d-flex align-items-center  w-85">
            {showAttestationIcons && (
              <div className="mr-2">
                <AttestationPopUp>
                  <img
                    alt=""
                    src={
                      Attestation_Icon_Mappings[
                        tool?.ScoreAttestationRow?.ScoreAttestation
                      ]
                    }
                    className=""
                  />
                </AttestationPopUp>
              </div>
            )}
            <div className="d-flex flex-column">
              <div className="d-flex align-items-baseline ">
                <p className="f-500 m-0 component-title">{toolTitle ? toolTitle : tool?.TechnologyTitle}</p>
              </div>
              {tool?.selected?.category === "Yes" && !isPanelOpen && tool?.SelectedTool_List?.length > 0 ? (
                <div className="d-flex align-items-center" style={{ gap: '8px', flexWrap: 'wrap' }}>
                  {
                    tool?.SelectedTool_List?.map((option, optionIndex) => (
                      <ConnectedToolItem
                        key={`connected-tool-${option?.ToolName}-${optionIndex}`} 
                        tool={option}
                        searchValue={searchComponent}
                      />
                    ))
                  }
                </div>
              ) : (
                <div className="f-12 f-darkgrey component-question" style={{ lineHeight: '20px' }}>
                  {tool?.TechnologyQuestion}
                </div>
              )}
            </div>
          </div>
          <div
            className="option-dropdown d-flex align-items-center"
            onClick={(e) => e.stopPropagation()}
          >
            <div>{tool?.loading && false &&<GreyLoader />}</div>
            {
              isViewOnly ? <div className="f-darkgrey">
              {tool?.selected?.category ?? 'No Selected'}
              </div> : viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ?  
              <div className="f-darkgrey">
                {tool?.selected?.category ?? 'No Selected'}
                </div>
              : 
              (
                <YesNoDropdown
                  id={yesNoId}
                  selected={tool?.selected?.category}
                  setSelected={(val) => {
                    if (tool?.selected?.category === val) return;
                    let updated_data = [...toolData];
                    updated_data[toolIndex].selected = {
                      id: val === "Yes" ? 1 : 0,
                      category: val,
                    };
                    updated_data[toolIndex].error = false;
                    updated_data[toolIndex].loading = true;
                    if (val === "Yes") {
                      setLoading(true);
                      getTools({
                        technologyEnum: tool?.TechnologyEnum,
                        rowIndex: toolIndex,
                      }).then(() => {
                        onToggleRow();
                        setLoading(false)
                      });
                    }
                    if (val === "No") {
                      updated_data[toolIndex].loading = true;
                      setLoading(true)
                      removeAllIntegrations({
                        rowIndex: toolIndex,
                        technologyEnum:
                          updated_data[toolIndex]?.TechnologyEnum,
                      }).then(() => {
                        onToggleRow();
                      });
                    }
                    setToolData(updated_data);
                  } }
                  isFixed={isChildOf}
                />
              )
            }
          </div>
        </div>
      </div>
      <div ref={panelRef} className="panel" onClick={(e) => e.stopPropagation()}>
        {tool?.selected?.category === "No" ? (
          <NoteSection 
            onResize={(target) => {
              if (!target) return;
              const parent = target.closest(".point");
              let panel = parent.querySelector(".panel");
              if (!panel.style.maxHeight) return;
              panel.style.maxHeight = panel.scrollHeight + 20 + "px";
              onChildToggle(panel.scrollHeight + 20);
            }}
            clientId={clientId}
            technologyEnum={tool?.TechnologyEnum} 
            tool={tool}
            refetchTools={() => {
              getCyberScoreData({
                keepToolsAtRow: toolIndex,
              });
            }}
            submitNote={submitNote}
            isLoading={isLoading}
            value={tool?.ScoreAttestationRow?.NoteForNoAnswer}
            userDetails={tool?.ScoreAttestationRow?.UserDetailsVM}
            LastUpdatedBy={tool?.ScoreAttestationRow?.LastUpdatedBy}
            LastUpdated={tool?.ScoreAttestationRow?.LastUpdated}
            isChild={tool?.IsChild}
          />
        ) : tool?.selected?.category === "Yes" ? (
          hasChilds ? (
            <div id={childWrapperId}>
              {childs.map((child, childIndex) => (
                <AttestationRow 
                  key={`child-${child?.TechnologyEnum}-${childIndex}`} 
                  tool={child} 
                  toolIndex={toolData?.findIndex(item => item?.TechnologyEnum === child?.TechnologyEnum)} 
                  toolData={toolData} 
                  setToolData={setToolData} 
                  clientId={clientId} 
                  showAttestationIcons={showAttestationIcons} 
                  searchComponent={searchComponent} 
                  getTools={getTools} 
                  removeAllIntegrations={removeAllIntegrations} 
                  addNewIntegration={addNewIntegration} 
                  renameIntegration={renameIntegration} 
                  isSaas={isSaas} 
                  setLoading={setLoading} 
                  getCyberScoreData={getCyberScoreData} 
                  AccessStateLabel={AccessStateLabel} 
                  setSelectedIntegrationId={setSelectedIntegrationId} 
                  disconnectIntegration={disconnectIntegration}
                  deleteIntegration={deleteIntegration}
                  rowParentId={childWrapperId}
                  hideChilds={false}
                  displayTopBorder={true}
                  hasParentToolTitle={!!toolTitle || !!hasToolsToolTitle}
                  createIntegrationsMonitoredSOC={createIntegrationsMonitoredSOC}
                  onChildToggle={(extraHeight) => {
                    refreshState(extraHeight);
                    setTimeout(() => {
                      refreshState(extraHeight);
                    }, 100);
                  }}
                  nestedOffset={48}
                  isViewOnly={isViewOnly}
                />
              ))}
            </div>
          ) : (
            <div 
              className="tool-listing-wrapper ml-4 d-flex flex-column" 
              style={{ gap: '4px', padding: '0 16px' }}
              ref={childContainerRef} 
            >
              {tool?.SelectedTool_List
                ?.filter(option => option?.ToolSelected)
                ?.map((option, optionIndex) => {
                  return (
                    <IntegrationListItem 
                      key={`io-${option?.ToolName}-${optionIndex}`} 
                      option={option} 
                      integrationName={option?.ToolName}
                      integrationId={option?.obj_IntegrationId}
                      toolIcon={option?.ToolIcon}
                      isLoading={option?.loading}
                      optionIndex={optionIndex}
                      showRename={option?.IsUserDefined}
                      isUserDefined={option?.IsUserDefined}
                      isManagedBySOC={option?.IsManagedBySOC}
                      managedBySOCState={option?.ManagedBySOCState}
                      style={{ marginLeft: '16px' }}
                      showSOC
                      searchComponent={searchComponent}
                      onRenameOption={(integrationId, newName) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        renameIntegration(integrationId, newName).then(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).finally(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}
                      onDeleteOption={(integrationId) => {
                        const updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        deleteIntegration(integrationId).then(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).finally(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}
                      onRemoveOption={(integrationId) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        disconnectIntegration(
                          integrationId
                        ).finally(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).finally(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}
                      onAddSubscription={(integrationId) => {
                        const integration = tool?.SelectedTool_List?.find(item => item?.obj_IntegrationId === integrationId);
                        if (!integration) return;
                        const query = httpUtils.generateQueryString({
                          action: ROUTE_ACTIONS.addSubscription,
                          integrationName: integration.ToolName,
                          integrationCategory: IntegrationCategories.CyberSecurity
                        })
                        const path = isSaas ? ROUTE_PATHS.saas.technologySpend : ROUTE_PATHS.mspv2.clients.technologySpend(clientId);
                        const url = `${path}${query}`;
                        history.push(url);
                      }}
                      onSOCStateChange={(integrationId, isManagedBySOC) => {
                        let updated_data = [...toolData];
                        const optionIndex = tool?.SelectedTool_List
                          ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                        updated_data[toolIndex].SelectedTool_List[
                          optionIndex
                        ].loading = true;
                        setLoading(true);
                        createIntegrationsMonitoredSOC(integrationId, isManagedBySOC).then(() => {
                          getTools({
                            technologyEnum: tool?.TechnologyEnum,
                            rowIndex: toolIndex,
                          }).then(() => {
                            getCyberScoreData({
                              keepToolsAtRow: toolIndex,
                            });
                          });
                        });
                        setToolData(updated_data);
                      }}  
                    />
                  )
                })}

                <SelectToolDropdown 
                  integrationList={tool?.IntegrationList?.filter(option => !option?.ToolSelected)}
                  fullIntegrationList={tool?.IntegrationList}
                  isPractice={tool?.ContriType == 1 ? true : false}
                  addingNewTool={toolData[toolIndex].addingNewTool}
                  onOpenModal={() => {
                    return new Promise((resolve, reject) => {
                      if (tool?.IntegrationList?.length === 0) {
                        setLoading(true);
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).finally(() => {
                          setLoading(false);
                          resolve();
                        });
                      } else {
                        resolve();
                      }
                    });
                  }}
                  onOptionAdd={(option) => {
                    return new Promise((resolve, reject) => {
                      let updated_data = [...toolData];
                      const optionIndex = tool?.IntegrationList
                        ?.findIndex(item => item?.obj_IntegrationId === option?.obj_IntegrationId);
                      updated_data[toolIndex].IntegrationList[
                        optionIndex
                      ].loading = true;
                      getAllTechTypeByIntegration({
                        integrationId: option?.obj_IntegrationId,
                      }).then((response) => {
                        if (response?.IntegrationTechnologyType_List?.length > 0 && !isSaas) {
                          setSelectedIntegration({
                            ...option,
                            TechnologyType_List: response?.IntegrationTechnologyType_List,
                          });
                        }
                      })
                      addNewIntegration({
                        technologyEnum:
                          updated_data[toolIndex].TechnologyEnum,
                        IntegrationId: option?.obj_IntegrationId,
                        toolName: option?.IntegrationName,
                        selected: true,
                        rowIndex: toolIndex,
                        isManagedBySOC: option?.IsManagedBySOC,
                      }).then(() => {
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).then(() => {
                          getCyberScoreData({
                            keepToolsAtRow: toolIndex,
                          });
                          refreshPanelState();
                        });
                      })
                      .finally(() => resolve());
                      setToolData(updated_data);
                    });
                  }}
                  onNewOptionAdd={(optionName) => {
                    return new Promise((resolve, reject) => {
                      if (optionName === "") {
                        resolve();
                        return;
                      };
                      let updated_data = [...toolData];
                      updated_data[toolIndex].addingNewTool = true;
                      setToolData(updated_data);
                      addNewIntegration({
                        technologyEnum:
                          updated_data[toolIndex].TechnologyEnum,
                        IntegrationId: 0,
                        toolName: optionName,
                        selected: true,
                        rowIndex: toolIndex,
                      }).then((response) => {
                        let updated_data = [...toolData];
                        updated_data[toolIndex].addNewTool = false;
                        updated_data[toolIndex].newToolName = "";
                        if (response?.mr?.EntityId) {
                          const integrationId = response?.mr?.EntityId;
                          getAllTechTypeByIntegration({
                            integrationId,
                          }).then((response) => {
                            if (response?.IntegrationTechnologyType_List?.length > 0 && !isSaas) {
                              setSelectedIntegration({
                                obj_IntegrationId: integrationId,
                                ToolName: optionName,
                                TechnologyType_List: response?.IntegrationTechnologyType_List,
                              });
                            }
                          })
                        }
                        setToolData(updated_data);
                        getTools({
                          technologyEnum: tool?.TechnologyEnum,
                          rowIndex: toolIndex,
                        }).then(() => {
                          getCyberScoreData({
                            keepToolsAtRow: toolIndex,
                          });
                        });
                      }).finally(() => resolve());
                    })
                  }}
                  refreshData={() => {
                    getTools({
                      technologyEnum: tool?.TechnologyEnum,
                      rowIndex: toolIndex,
                    }).then(() => {
                      getCyberScoreData({
                        keepToolsAtRow: toolIndex,
                      });
                    });
                  }}
                />
            </div>
          )
        ) : null}
      </div>
      <AddToolToSecStack 
        show={!!selectedIntegration} 
        toolName={selectedIntegration?.ToolName}
        toolIcon={selectedIntegration?.ToolIcon}
        integrationId={selectedIntegration?.obj_IntegrationId}
        technologyTypeList={selectedIntegration?.TechnologyType_List}
        onHide={() => {
          setSelectedIntegration(null);
        }}
        onAddStack={(e) => {
          e.stopPropagation();
          setSelectedIntegration(null);
        }}
      />
    </div>
  );
}

export default AttestationRow;