import React from "react";
import { SearchCriteriaEnumASEIssues } from "../../../../constants/ase.constants";
import DeviceUserIssuesFilter from "../device-user-issues-filter/device-user-issues-filter.component";

const sections = [
  {
    Title: 'Issues',
    Items: [
      { Name: 'MFA', key: 'showMFA' },
      { Name: 'Risky User', key: 'showRiskyUser' },
    ]
  },
  {
    Title: 'Filters',
    Items: [
      { Name: 'Name', enum: SearchCriteriaEnumASEIssues.Name },
      { Name: 'Email', enum: SearchCriteriaEnumASEIssues.Email },
      { Name: 'Job Title', enum: SearchCriteriaEnumASEIssues.Job_Title },
      { Name: 'Operating System', enum: SearchCriteriaEnumASEIssues.Operating_System },
    ]
  },
];

const UserIssuesFilter = ({ aseFilters, setASEFilters }) => (
  <DeviceUserIssuesFilter
    aseFilters={aseFilters}
    setASEFilters={setASEFilters}
    sections={sections}
  />
)

export default UserIssuesFilter;
