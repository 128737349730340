import { useMemo } from "react";
import { Line } from "react-chartjs-2";

const mapPropsToOptions = (options) => ({
  ...options,
  ...(options.tension !== undefined && {
    tension: options.tension,
  }),
  ...(options.bezierCurve !== undefined && {
    bezierCurve: !!options.bezierCurve,
    }),
  ...(options.datalabels !== undefined && ({
    display: options.datalabels?.display ?? false,
  })),
  ...(options.style !== undefined && ({
    style: {
      ...options.style,
      strokewidth: options.style?.strokewidth ?? 1,
    },
  })),
  ...(options.plugins !== undefined && ({
    plugins: {
      ...options.plugins,
      legend: {
        ...options.plugins?.legend,
        display: options.plugins?.legend?.display ?? false,
      },
    },
  })),
  ...(options.scales !== undefined && ({
    scales: {
      ...options.scales,
      x: {
        ...options.scales?.x,
        grid: {
          ...options.scales?.x?.grid,
          display: options.scales?.x?.grid?.display ?? false,
        },
        ticks: {
          ...options.scales?.x?.ticks,
          color: options.scales?.x?.ticks?.color ?? "#B6B6BF",
          autoSkip: options.scales?.x?.ticks?.autoSkip ?? true,
          maxTicksLimit: options.scales?.x?.ticks?.maxTicksLimit ?? 10,
        },
        border: {
          ...options.scales?.x?.border,
          display: options.scales?.x?.border?.display ?? false,
        },
      },
      y: {
        ...options.scales?.y,
        grid: {
          ...options.scales?.y?.grid,
          display: options.scales?.y?.grid?.display ?? false,
        },
        position: options.scales?.y?.position ?? "right",
        ticks: {
          ...options.scales?.y?.ticks,
          color: options.scales?.y?.ticks?.color ?? "#B6B6BF",
          autoSkip: options.scales?.y?.ticks?.autoSkip ?? true,
          maxTicksLimit: options.scales?.y?.ticks?.maxTicksLimit ?? 6,
          max: options.scales?.y?.ticks?.max ?? 50,
        },
        border: {
          ...options.scales?.y?.border,
          display: options.scales?.y?.border?.display ?? false,
        },
      },
    },
  })),
  ...(options.animation !== undefined && ({
    animation: {
      ...options.animation,
      easing: options.animation?.easing ?? "linear",
    },
  })),
  ...(options.responsive !== undefined && ({
    responsive: !!options.responsive,
  })),
  ...(options.maintainAspectRatio !== undefined && ({
    maintainAspectRatio: !!options.maintainAspectRatio,
  })),
  ...(options.title !== undefined && ({
    title: {
      ...options.title,
      display: options.title?.display ?? false,
    },
  })),
  ...(options.plugins !== undefined && ({
    plugins: {
      ...options.plugins,
      autocolors: options.plugins?.autocolors ?? true,
      datalabels: {
        ...options.plugins?.datalabels,
        backgroundColor: options.plugins?.datalabels?.backgroundColor ?? "transparent",
        color: options.plugins?.datalabels?.color ?? "transparent",
      },
    },
  })),
})

const mapPropsToData = (data) => ({
  ...data,
  labels: data.labels ?? [],
  datasets: data.datasets
});

/**
 * 
 * @param {Object} props
 * @param {Object} props.options
 * @param {Object} props.data
 */
const MyLineChart = (props) => {
  const updatedOptions = useMemo(() => {
    return mapPropsToOptions(props.options ?? {});
  }, [props.options]);
  const updatedData = useMemo(() => {
    return mapPropsToData(props.data ?? {});
  }, [props.data]);
  return (
    <Line
      {...props}
      data={updatedData}
      options={updatedOptions}
    />
  );
}

export default MyLineChart;