import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { HGHighlightText } from "../../App/App";
import ImageLoader from "../../Common/ImageLoader";
import ArrowDownIcon from "../../icons/arrow-down.icon";
import { GreyLoader } from "../../Theme/APILoaders";
import RiskyUserTooltip from "../Overview/risky-user-tooltip/risky-user.tooltip";
import Loader from "../../Common/loader";

const headers = [
  { title: "Name", key: "Name" },
  { title: "Email", key: "Email" },
  { title: "Job title", key: "Job_title" },
  { title: "Risky User", key: "Risk_Status" },
  { title: "MFA", key: "MFA_Status" },
]

const UserIssuesTable = ({ 
  userIssues, 
  updateMfaStatus, 
  aseData, 
  setASEData,
  isLoading = false,
  sortEnabled = false,
  searchString = "",
  userSortColumn = "",
  userSortOrder = 0, 
  onSetUserSortColumn = () => {}
}) => (
  <div className="bg-grey radius-8 border-parent">
    <div className="border-item mfa-table-row header">
      {headers.map((header, headerIndex) => (
        <div 
          className={`f-12 f-500 f-grey cell ${sortEnabled ? 'cursor-pointer' : ''} ${headerIndex === 0 ? 'pl-2' : ''}`} 
          key={headerIndex}
          onClick={() => onSetUserSortColumn(header.key)}
        >
          <span>{header.title}</span>
          {userSortColumn === header.key && (
            <ArrowDownIcon
              className="ml-2"
              width={12}
              height={12}
              fill="#616778"
              style={{
                transform: userSortOrder === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          )}
        </div>
      ))}
    </div>

    {isLoading ? (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '180px' }}>
        <Loader />
      </div>
    ) : (
      userIssues?.map((item, itemIndex) => {
        return (
          <div className="border-item mfa-table-row row-item">
            <div className="">
              <div className="d-flex align-items-center">
                <ImageLoader 
                  src={item?.UserIcon}
                  alt=""
                  className="mr-2 ml-2"
                  width={30}
                  height={30}
                  noImageComponent={(
                    <ImageWithInitials
                      initials={item.User ?? item?.Name?.charAt(0)}
                      width={30}
                      height={30}
                      background={"purple"}
                      className="mr-2 ml-2"
                    />
                  )}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: HGHighlightText(item?.Name, searchString),
                  }}
                />
              </div>
            </div>
            <div className="email">{item?.Email}</div>
            <div className="">{item?.Job_title}</div>
            <div className="">
              <RiskyUserTooltip item={item}>
                <img
                  src={item.RiskStatusLogo}
                  alt=""
                  className="mr-2"
                  width={13}
                  height={13}
                />
                <span>
                  {item.RiskStatusText}
                </span>
              </RiskyUserTooltip>
            </div>
            <div className="">
              {item?.MFA_Status === true && (
                <div className="f-black">
                  <img
                    src={item?.MFAStatusLogo}
                    alt=""
                    className="mr-2"
                  />
                  {item?.MFAStatusText}
                </div>
              )}
              {item?.MFA_Status === false && (
                <div
                  className="bg-blue f-white pointer w-fit-content p-1 px-2 radius-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    const newUserIssues = [...aseData.UserIssues];
                    const updateItemIndex = newUserIssues.findIndex(userIssue => userIssue.UserRecordId === item.UserRecordId);
                    newUserIssues[updateItemIndex].MFA_Status = null;
                    setASEData({
                      ...aseData,
                      UserIssues: newUserIssues
                    });
                    updateMfaStatus({
                      MFAState:2,
                      UserId:item?.UserId,
                      UserRecordId:item?.UserRecordId
                    })
                  }}
                >
                  {item?.MFAStatusText}
                </div>
              )}
              {item?.MFA_Status === null && (
                <div
                  className="d-flex align-items-center p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <GreyLoader className={"mr-2"} width={12} />
                  Enabling...
                </div>
              )}
            </div>
          </div>
        );
      })
    )}
  </div>
);

export default UserIssuesTable;
