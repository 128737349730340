
import React, { useEffect, useState } from 'react'
import { validateEmail } from '../../Utils/CommonFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLayout from './auth-layout/auth.layout';
import AuthBody from './auth-layout/auth.body';
import AuthDefaultSections from './auth-layout/auth-default-sections.component';
import ForgotPasswordSendEmailSection from './sections/forgot-password-send-email.section';
import useQueryParams from '../../hooks/query-params.hook';

const ForgotPasswordStatesTitles = [
  { title: "Reset password", description: "We will send an email with instructions on how to reset your password." },
]

const ForgotPassword = () => {
    const history = useHistory();
  const { getParam } = useQueryParams();
  const defaultEmail = getParam("email");
  const [email, setEmail] = useState("");
  const [pageData, setPageData] = useState({});
  const [formState, setFormState] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
   
  const [timeState, setTimeState] = useState({
    seconds:0,
    startTimer:false
  })
  const [sendingEmail, setSendingEmail] = useState(false)
  const [errors, setErrors] = useState({
    emailError:""
  })

  const PAGE_STATE={
    FORGOT_PASSWORD:"FORGOT_PASSWORD",
    MAIL_SENT:"MAIL_SENT",
    RESET_PASSWORD:"RESET_PASSWORD"
  }

  useEffect(() => {
    if (timeState.startTimer) {
      countdownTimer();
      setTimeState({ ...timeState, startTimer: false });
    }
  }, [timeState]);
  

useEffect(() => {
  if (formState === 0) {
    setPagState(PAGE_STATE.FORGOT_PASSWORD);
  } else if (formState === 1) {
    setPagState(PAGE_STATE.MAIL_SENT, email);
  }
}, [formState]);

  useEffect(() => {
    if (!defaultEmail) return;
    setEmail(defaultEmail);
  }, [defaultEmail]);


  function setPagState(pageType, email) {
    switch (pageType) {
      case PAGE_STATE.FORGOT_PASSWORD:
        setPageData({
          headerText: "Forgot your password?",
          subText:
            "Please enter an email from your account. We will send you password recovery instructions",
            state:"FORGOT_PASSWORD"
        });

        break;
      case PAGE_STATE.MAIL_SENT:
        setPageData({
          headerText: "Check your email",
          subText: `A reset password link has been sent to your email address ${email}. Kindly follow instructions to regain account access`,
          state:"MAIL_SENT"
        });

        break;
     

      default:
        break;
    }
  }

  async function sendPasswordResetEmail() {
    setSendingEmail(true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("emailAddress", email);
    urlencoded.append("baseCallingUrl", "http://localhost:3000");

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/ResetPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setTimeState({ ...timeState, seconds: 60, startTimer: true });
        setSendingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        setSendingEmail(false);
      });
  }


  async function checkEmailvalidation(){
    if(email.length===0){
      setErrors({...errors,emailError:"Email is required"})
      return false
    }else if(!validateEmail(email)){
      setErrors({...errors,emailError:"Enter valid email address"})
      return false
    }else{
      return true
    }
  }

  
  function countdownTimer() {
    const timer = setInterval(() => {
      if (timeState.seconds > 0) {
        setTimeState((prev) => {
          if (prev.seconds >= 1) {
            return { ...prev, seconds: prev.seconds - 1 };
          } else {
            clearInterval(timer);
            return prev;
          }
        });
      }
    }, 1000);
  }

  const sections = [ForgotPasswordSendEmailSection];
  const titles = ForgotPasswordStatesTitles[formState];
  const CurSection = sections[formState] ?? <div />;
 
  return (
    <AuthLayout>
      <AuthBody>
        <AuthDefaultSections
          title={titles.title}
          description={titles.description}
        >
          <CurSection 
            defaultEmail={email}
            sendPasswordResetEmail={sendPasswordResetEmail}
            loading={sendingEmail}
            timeState={timeState}
            emailSent={emailSent}
          />
        </AuthDefaultSections>
      </AuthBody>
    </AuthLayout>
  );
}

export default ForgotPassword