import React from "react";
import { Dropdown } from "react-bootstrap";
import "./device-user-issues-filter.styles.scss";
import SuccessSignIcon from "../../../icons/success-sign.icon";

const DeviceUserIssuesFilter = ({ aseFilters, setASEFilters, sections }) => {
  const onSelectIssue = (item) => {
    setASEFilters({
      ...aseFilters,
      [item.key]: !aseFilters[item.key]
    });
  }

  const onSelectFilter = (item) => {
    const isItemSelected = aseFilters.SearchCriterias.some(criteria => criteria.criteriaEnum === item.enum);
    const updatedSearchCriterias = aseFilters.SearchCriterias
      .filter(criteria => !sections[1].Items.find(filter => filter.enum === criteria.criteriaEnum));
    setASEFilters({
      ...aseFilters,
      SearchCriterias: [
        ...updatedSearchCriterias,
        ...(isItemSelected ? [] : [
          { criteriaEnum: item.enum, SearchParams: [''] }
        ]) 
      ]
    });
  }

  const isIssueSelected = (item) => aseFilters[item.key];
  const isFilterSelected = (item) => aseFilters.SearchCriterias.some(criteria => criteria.criteriaEnum === item.enum);

  return (
    <Dropdown alignRight className="my-dropdown device-user-issues-filter transparennt-wrapper-dropdown">
      <Dropdown.Toggle variant="light" className="toggle px-2 f-black">
        Filter
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu">
        <Section 
          items={sections[0].Items}
          title={sections[0].Title}
          isItemSelected={isIssueSelected}
          onSelectItem={onSelectIssue}
        />
        <Section
          items={sections[1].Items}
          title={sections[1].Title}
          isItemSelected={isFilterSelected}
          onSelectItem={onSelectFilter}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DeviceUserIssuesFilter;

const Section = ({ title, items, isItemSelected, onSelectItem }) => {
  const hasSelectedItems = items.some(item => isItemSelected(item));
  return (
    <div className="section">
      <h3 className="title m-0 f-darkgrey">{title}</h3>
      <div>
        {items.map((item, index) => {
          const isSelected = isItemSelected(item);
          return (
            <div 
              className="item" 
              key={index}
              onClick={() => onSelectItem(item)}
            >
              {(hasSelectedItems || isSelected) && (
                <SuccessSignIcon width={12} height={9} fill={isSelected ? "#616778" : 'transparent'} />
              )}
              <span className="f-grey">{item.Name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
