export const ShowNeverExplanationList = [
  'Last reviewed',
  'Last audited',
  'Last performed',
  'Last tested',
];

export const AttestedValue = 'Information missing, please complete';
export const PolicyDurationTitle = 'Policy Duration';
export const StartDateTitle = 'Start Date';
export const ExpiryDateTitle = 'Expiry Date';
export const CurrencyTitle = 'Currency';
