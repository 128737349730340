import { useEffect, useRef, useState } from 'react';
import UploadIcon from '../../icons/upload.icon';
import AuthTextInput from '../auth-text-input.component';
import { Button } from 'react-bootstrap';
import ImageCropperModal from '../../settingsV2/personal-profile/personal-profile-modals/ImageCropperModal';
import WarningRoundedIcon from '../../icons/warning-rounded.icon';
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import CreateYourCompanyIcon from '../../icons/create-your-company.icon';
import ImageLoader from "../../Common/ImageLoader";

const AccountTypeSections = [
  { 
    id: 1,
    title: "Manage my company’s security",
    description: "Strengthen my company's cybersecurity posture.",
    img: "/images/auth/signup/Regular User.webp",
    roleValue: "SAAS"
  },
  {
    id: 2,
    title: "Increase my security sales (MSP)",
    description: "Increase my MSPs cybersecurity sales and manage my customers security posture.",
    img: "/images/auth/signup/MSP.webp",
    roleValue: "MSP"
  }
]

const SignUpCompanySection = ({ 
  error, 
  companyError,
  setCompanyNameError: setCompanyError,
  setFormState,
  onCompanyInfoSubmit,
  countryList,
  defaultCompanyImage,
  defaultCompanyName,
  defaultSelectedCountry,
  defaultRole,
  curStep,
  amountsStep,
}) => {
  const fileUpload = useRef();
  const [companyName, setCompanyName] = useState(defaultCompanyName);

  const [selectedImage, setSelectedImage] = useState("");
  const [cropperModal, setCropperModal] = useState(false);
  const [companyImage, setCompanyImage] = useState(defaultCompanyImage);
  const [imgError, setImgError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(defaultSelectedCountry ?? null);
  const [selectedAccountType, setSelectedAccountType] = useState(AccountTypeSections[0]);
  const [hoveredAccountType, setHoveredAccountType] = useState(null);

  const setImage = (image) => {
    setCompanyImage(image);
  };

  const showCropperModal = () => {
    setCropperModal(true);
  };

  const hideCropperModal = () => {
    setCropperModal(false);
  };

  useEffect(() => {
    if (selectedCountry) return;
    const uk = countryList.find((country) => country.code === "GB");
    setSelectedCountry(uk);
  }, [selectedCountry]);

  useEffect(() => {
    if (!defaultRole) return;
    const role = AccountTypeSections.find((role) => role.roleValue === defaultRole);
    setSelectedAccountType(role);
  }, [defaultRole]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onCompanyInfoSubmit(companyName, selectedAccountType.roleValue, selectedCountry, companyImage);
        }}
      >
        <div className="left-section">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="m-0 section-title f-black">
              Create your company
            </h2>
            {(curStep && amountsStep) && (
              <span className="section-step f-darkgrey">
                {curStep}/{amountsStep}
              </span>
            )}
          </div>
          <div className="block">
            <div className="d-flex align-items-center w-100" style={{ gap: '8px' }}>
              <ImageLoader 
                src={companyImage}
                width="40px"
                height="40px"
                style={{ borderRadius: '8px' }}
                noImageComponent={(
                  <div style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '8px',
                    backgroundColor: '#3D42DE'
                  }} />
                )}
              />
              <div>
                <h4 className="m-0 f-grey f-12 f-500" style={{ lineHeight: '18px' }}>
                  Company logo
                </h4>
                {imgError ? (
                  <div className="d-flex error-section m-0" style={{ gap: '8px' }}>
                    <div style={{ marginTop: '-3px', padding: '0px 1px' }}>
                      <WarningRoundedIcon fill="#B2494A" />
                    </div>
                    <span className="error-message">
                      {imgError}
                    </span>
                  </div>
                ) : (
                  <p className="m-0 f-darkgrey f-12" style={{ lineHeight: '18px' }}>
                    Image files up to 10MB at least 400px
                  </p>
                )}
               
              </div>
              <input
                type="file"
                accept=".png, .jpeg, .webp, .avif"
                ref={fileUpload}
                className="d-none"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    const MAX_SIZE = 10 * 1024 * 1024;
                    if (e.target.files[0] && e.target.files[0].size > MAX_SIZE) {
                      setImgError("Up to 10MB files allowed");
                      return;
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);

                    reader.onload = function () {
                      setSelectedImage(reader.result);
                      showCropperModal();
                      e.target.value = null;
                      setImgError("");
                    };
                  }
                }}
              />
              <div className='flex-1 d-flex justify-content-end'>
                <button 
                  type='button' className='upload-btn'
                  onClick={() => {
                    fileUpload.current.click();
                  }}
                >
                  <UploadIcon height="12px" />
                  <span className='f-grey'>
                    Upload
                  </span>
                </button>
              </div>
            </div>
            <div>
              <label className="input-label f-grey" htmlFor="companyname">
                Company name
              </label>
              <AuthTextInput
                type="text"
                id="companyname"
                name="companyname"
                className="input"
                placeholder="Enter your company name"
                value={companyName}
                onChange={(event) => {
                  setCompanyName(
                    event.target.value
                  );
                  setCompanyError("");
                }}
                error={error || companyError}
                autoFocus
              />
            </div>
            <div>
              <label className="input-label f-grey" htmlFor="companyname">
                Country
              </label>
              <CompanySettingsDropdown
                fieldId={"objId"}
                fieldValue={"name"}
                selected={selectedCountry}
                placeholder="Choose your country"
                onClick={(id, val, e, value) => {
                  setSelectedCountry(value);
                }}
                data={countryList}
                DropDownMenuClassName="w-100"
              />
            </div>
            <div>
              <label className="input-label f-grey">
                What would you like to do in HighGround?
              </label>
              <div className="d-flex flex-column w-100" style={{ gap: '4px' }}>
                {AccountTypeSections.map((section, index) => (
                  <label
                    className={`account-type-item m-0 ${selectedAccountType.id === section.id ? 'selected' : ''}`}
                    htmlFor={`accountType${index}`} 
                    key={index}
                    onClick={() => {
                      setSelectedAccountType(section);
                    }}
                    onMouseEnter={() => {
                      setHoveredAccountType(section);
                    }}
                    onMouseLeave={() => {
                      setHoveredAccountType(null);
                    }}
                  >
                    <div className="d-flex align-items-center w-100" style={{ gap: '8px' }}>
                      <input type="radio" name="accountType" id={`accountType${index}`} style={{ display: 'none' }} />
                      <label className="account-type-title m-0">
                        {section.title}
                      </label>
                    </div>
                    <p className="account-type-description">
                      {section.description}
                    </p>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div>
            <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type="submit"
              style={{ padding: "5px" }}
            >
              Continue
            </Button>
            <Button
              size="lg"
              variant="none"
              className="w-100 f-14 f-grey"
              style={{ padding: "5px" }}
              onClick={() => {
                setFormState(1);
              }}
            >
              Back
            </Button>
          </div>
        </div>
      </form>
      <div className="right-section w-100">
        <CreateYourCompanyIcon 
          className='img-container' 
          companyName={companyName}
          country={selectedCountry?.name}
          imgURL={companyImage}
        />
        {AccountTypeSections.map((section, index) => (
          <div className={`hovered-container ${section.id === hoveredAccountType?.id ? 'hovered' : ''}`} >
            <img
              src={section.img}
              className='img-container'
            />
          </div>
        ))}
      </div>
      <ImageCropperModal
        show={cropperModal}
        hideModal={hideCropperModal}
        profileImage={selectedImage}
        setProfileImage={setImage}
        uploadType={99}
        cropShape="rect"
      />
    </>
  );
}

export default SignUpCompanySection;
