const generateQueryString = (queryObj) => {
  const entries = Object.entries(queryObj).filter((value) => {
    return value[1] !== null && value[1] !== undefined;
  });
  if (entries.length === 0) return '';
  let query = '?';
  entries.forEach((value, index) => {
    if (!value[1]) return;
    query += `${value[0]}=${value[1]}${index !== entries.length - 1 ? '&' : ''}`;
  });
  return query;
}

const toFormData = (body) => {
  const formData = new FormData();
  Object.entries(body).map(value => {
    if (value[1] instanceof FileList) {
      for (let i = 0; i < value[1].length; ++i) {
        formData.append(`${value[0]}`, value[1].item(i) || '');
      }
    } else if (value[1] instanceof Array) {
      for (let i = 0; i < value[1].length; ++i) {
        formData.append(`${value[0]}[${i}]`, value[1][i]);
      }
    } else if (value[1] instanceof Object && !(value[1] instanceof File)) {
      Object.keys(value[1]).map(key => {
        formData.append(`${value[0]}[${key}]`, value[1][key]);
      });
    } else {
      formData.append(value[0], value[1]);
    }
  });
  return formData;
}

const httpUtils = {
  generateQueryString,
  toFormData
};

export default httpUtils;
