import { useEffect, useMemo, useState } from "react";
import { CustomHGDropdown } from "../../../clients/createClientScreens/CyberComplianceForGroupModal";
import { isString } from "lodash";
import { CustomSubDateSelection } from "../../../../../constants/subscription.constants";

const getPeriodTitle = (months) => {
  const years = Math.floor(months / 12);
  const restMonths = Math.floor(months % 12);
  const days = months >= 1 ? Math.floor((months % 1) * 30) : Math.floor(months * 30);
  const periodTextYears = `${years} year${years !== 1 ? 's' : ''}`;
  const periodTextMonths = `${restMonths} month${restMonths !== 1 ? 's' : ''}`;
  const periodTextDays = `${days} day${days !== 1 ? 's' : ''}`;
  return `${years > 0 ? periodTextYears : ''} ${restMonths > 0 ? periodTextMonths : ''} ${days > 0 ? periodTextDays : ''}`;
};

const SubscriptionTermsPeriod = ({
  selected,
  errors,
  onChange = () => {}, 
  maxMonths,
  maxValue
}) => {
  const [count, setCount] = useState("");
  const [customDateSelection, setCustomDateSelection] = useState(CustomSubDateSelection[1]);

  const months = count * customDateSelection.value;
  const periodTitle = useMemo(() => getPeriodTitle(months), [months]);

  useEffect(() => {
    if (!selected) return;
    const customDate = CustomSubDateSelection.find((item) => item.OptionText === selected?.DurationType);
    if (customDate) {
      setCustomDateSelection(customDate);
      setCount(selected.Duration);
    }
  }, [selected]);

  useEffect(() => {
    if (!count) {
      onChange(undefined);
      return;
    }
    const months = count * customDateSelection.value;
    if (maxMonths && months > maxMonths) {
      setCount(maxMonths / customDateSelection.value);
      return;
    }
    if (maxValue) {
      const maxValueDateSelection = CustomSubDateSelection.find((item) => item.OptionText === maxValue?.DurationType);
      const maxValueMonths = maxValue?.Duration * maxValueDateSelection.value;
      if (months > maxValueMonths) {
        onChange({ Duration: maxValue.Duration, DurationType: maxValue.DurationType });
        return;
      }
    }
    onChange({ Duration: count, DurationType: customDateSelection.OptionText });
  }, [count, customDateSelection, maxValue]);


  return (
    <CustomInput
      count={count}
      setCount={setCount}
      datePeriod={customDateSelection}
      setDatePeriod={setCustomDateSelection}
      periodTitle={periodTitle}
      errors={errors}
      maxMonths={maxMonths}
    />
  );
}

const CustomInput = ({
  count,
  setCount,
  datePeriod,
  setDatePeriod,
  periodTitle,
  errors,
  maxMonths
}) => (
  <div className="d-flex align-items-center" style={{ gap: '16px' }}>
    <div
      className={`${
        errors
          ? "input-error"
          : ""
      } d-flex align-items-center radius-4 highlight-input border-none  p-1 `}
    >
      <input
        type="number"
        className={`bg-transparent  border-none`}
        placeholder="Type"
        value={count}
        onChange={(e) => {
          let value = e.target.value;
          if (e.target.value && +e.target.value && +e.target.value < 0) {
            value = Math.abs(+e.target.value);
          }
          if (isString(value)) {
            value = value.replace(/^0+/, "");
          }
          setCount(value);
        }}
        style={{
          width: "100px",
        }}
      />
    </div>
    <CustomHGDropdown
      fieldId={"Id"}
      contentwidth
      fieldValue={"OptionText"}
      selected={datePeriod}
      placeholder="Select"
      pluralCheck={count == 1}
      onClick={(id, val, item) => {
        setDatePeriod(item);
      }}
      data={CustomSubDateSelection}
      dropDownMenuClass="menu disable-transform"
    />
    {count && (
      <span className="f-grey f-12 f-400">
        {periodTitle}
      </span>
    )}
  </div>
)

export default SubscriptionTermsPeriod;