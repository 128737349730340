import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import * as createdData from "../created.json";
import * as creatingData from "../creating.json";
import LoaderIcon from "../../icons/loader.icon";

const SaasAnimationSection = ({
  signUpCompleted,
  callBack,
  callBackData,
  userType = "SAAS",
}) => {
  const [index, setIndex] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCompletedCount((prev) => prev + 1);
      setIndex((prevIndex) => (prevIndex + 1) % SaasUserMessages.length);
    }, 5000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (signUpCompleted) {
    }
  }, [signUpCompleted]);

  useEffect(() => {
    if (signUpCompleted) {
      setTimeout(() => {
        callBack(callBackData);
      }, 3000);
    }
  }, [signUpCompleted]);

  const SaasUserMessages = [
    "Creating your cyber KPIs",
    "Customising your Technology Spend dashboard",
    "Setting up your Governance & Resilience centre",
    "Creating your Attack Surface Management module",
    "Setting up your Integrations",
  ];

  const welcomeAnimationData = {
    MSP: {
      messages: [
        "Creating Security Stack",
        "Building Security Operations centre",
        "Configuring Managed Security",
        "Setting up Integrations",
        "Creating Action Centre",
      ],
      bannerIcon: [
        "msp-anim-1.webp",
        "msp-anim-2.webp",
        "msp-anim-3.webp",
        "msp-anim-4.webp",
        "msp-anim-5.webp",
      ],
      explanationsheader: [
        "Build your security stack",
        "Improve your security operations",
        "Increase your security sales",
        "Integrations",
        "Action Centre",
      ],
      explanationsHeaderIcon: [],
      explanations: [
        "Security stack is a crucial part of your security offering to clients - make sure you have all the bases covered.",
        "Having a good security stack is only half the battle - how you operate is just an important. Manage engineers certifications, your organisations accreditations, alignment to security frameworks and your security processes & procedures from HighGround.",
        "Selling cyber to your clients is a harder sell than it ought to be. Build security packages your clients can visualise and understand, empowering them to make informed decisions on the security risks they are willing to take.",
        "Automate data ingestion from your PSA, RMM & Security tools and gain security insights like never before. Because lets face it, you ain’t got time to enter it manually!",
        "So many ideas, so little time to implement them! Taking action is always the hardest part, with a lack of follow-through killing your progress. Create your actions in HighGround and assign them to your team - and yes, you can sync them with your existing task management tools too!",
      ],
      pageImages: [
        "/images/auth/signup/msp-creation/Security Stack.webp",
        "/images/auth/signup/msp-creation/Security Operations.webp",
        "/images/auth/signup/msp-creation/Managed Security.webp",
        "/images/auth/signup/msp-creation/Integrations.webp",
        "/images/auth/signup/msp-creation/Action Centre.webp",
      ]
    },
    SAAS: {
      messages: [
        "Creating cyber KPIs",
        "Creating Technology Spend dashboard",
        "Setting up Governance & Resilience centre",
        "Creating Attack Surface Management module",
        "Setting up Integrations",
      ],
      bannerIcon: [
        "saas-anim-1.webp",
        "saas-anim-2.webp",
        "saas-anim-3.webp",
        "saas-anim-4.webp",
        "saas-anim-5.webp",
      ],
      explanationsheader: [
        "Cyber KPIs",
        "Technology Spend",
        "Governance & Resilience",
        "Attack surface",
        "Integrations",
      ],
      explanationsHeaderIcon: [
        "/images/navIcons/dashboardIcon.svg",
        "/images/navIcons/technology-spend-icon.svg",
        "/images/navIcons/complianceDashboardIcon.svg",
        "/images/attack-surface/attack_surface_dashboard_icon.svg",
        "/images/navIcons/intergrationsDashboard.svg",
      ],
      explanations: [
        "Simple KPIs help senior management understand and track your organisations cyber security posture.",
        "Mapping your technology spend helps you see just how little really goes to protecting your organisation.",
        "Ensure your can bounce back from a cyber-attack by being cyber resilient, and your senior management more cyber-aware.",
        "Mapping your attack surface is crucial to understanding, reducing and securing it.",
        "Automate data ingestion from across your security stack and gain security insights like never before.",
      ],
      pageImages: [
        "/images/auth/signup/saas-creation/Dashboard.webp",
        "/images/auth/signup/saas-creation/Technology Spend.webp",
        "/images/auth/signup/saas-creation/Governance & Resilience.webp",
        "/images/auth/signup/saas-creation/Attack Surface.webp",
        "/images/auth/signup/saas-creation/Integrations.webp",
      ]
    },
  };

  const isCompleted = signUpCompleted;

  return (
    <div className="account-creation-section">
      <div className="left-section-creation">
        <div
          className="radius-8 zoom-independent"
          style={
            {
              // boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
            }
          }
        >
          {isCompleted ? (
            <div className="d-flex align-items-center justify-content-center" style={{ height: '160px' }}>
              <Lottie
                loop={false}
                play={true}
                speed={0.7}
                animationData={createdData.default}
                style={{ width: 80, height: 80, margin: "0 auto" }}
                onComplete={() => {
                  // if (callBack) {
                  //   callBack(callBackData);
                  // }
                }}
              />
            </div>
          ) : (
            <Lottie
              loop={true}
              play={true}
              speed={0.7}
              animationData={creatingData.default}
              style={{ width: 160, height: 160, margin: "0 auto" }}
            />
          )}
          
        </div>
        <div>
          <div
            className="d-flex align-items-center justify-content-center w-100 text-center position-relative"
            style={{
              marginTop: "16px",
            }}
          >
            {isCompleted ? (
              <span className="f-14 f-500">
                Account created
              </span>
            ) : (
              welcomeAnimationData[userType].messages.map((message, i) => (
                <div
                  key={i}
                  className={`${
                    i === index ? "visible" : ""
                  } position-absolute f-14 f-500 signup-message`}
                  style={{ animationDuration: index === -1 ? "0s" : "2s", whiteSpace: 'nowrap' }}
                >
                  {message}
                </div>
              ))
            )}
          </div>

          <div className="f-darkgrey mt-3 d-flex align-items-center" style={{ gap: '9px' }}>
            <LoaderIcon className="loader" />
            <span>
              {isCompleted ? "Redirecting to your dashboard..." : "Please wait..."}
            </span>
          </div>
        </div>
        {!isCompleted && (
          <div style={{ width: '320px' }}>
            <div className="f-16 f-500 position-relative">
              {welcomeAnimationData[userType].explanations.map((message, i) => (
              <div
                key={i}
                className={`${
                  i === index ? "visible" : ""
                } position-absolute signup-message`}
                style={{ animationDuration: index === -1 ? "0s" : "2s", textAlign: 'center'}}
              >
                <span className="f-14 f-grey" style={{ fontWeight: 400 }}>
                  {message}
                </span>
              </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="right-section">
        {welcomeAnimationData[userType].pageImages.map((img, i) => (
          <div className={`hovered-container${i === index ? 'hovered' : ''}`} key={i}>
            <img
              src={img}
              className='img-container'
            />
          </div>
        ))}
      </div>
    </div>
    // <div
    //   className="d-flex w-100 h-100 p-4 bg-white signup-animation"
    //   style={{
    //     position: "fixed",
    //     top: 0,
    //     right: 0,
    //     bottom: 0,
    //     left: 0,
    //     zIndex: 20000000,
    //   }}
    // >
    //   <div className="radius-24 border1 bg-grey w-25 p-4 d-flex flex-column overflow-y-auto hide-scrollbar signup-screen-side-banner">
    //     <img
    //       alt=""
    //       src={"/images/" + welcomeAnimationData[userType].bannerIcon[index]}
    //       // src={"/saas-anim-1.svg"}
    //       className="w-100 mx-auto mb- my-5"
    //       style={{
    //         borderRadius: "8px",
    //         border: "1px solid var(--Grayscale-Gray-05, #EBEDF1)",
    //         boxShadow: "0px 12px 24px 0px rgba(16, 16, 16, 0.08)",
    //       }}
    //     />
    //     <div className="d-flex align-items-center justify-content-between mb-2">
    //       <div className="d-flex align-items-center f-grey f-16">
    //         {" "}
    //         {welcomeAnimationData[userType].explanationsHeaderIcon[index] && (
    //           <img
    //             src={
    //               welcomeAnimationData[userType].explanationsHeaderIcon[index]
    //             }
    //             width={18}
    //             height={18}
    //             alt=""
    //             className="mr-2"
    //           />
    //         )}{" "}
    //         {welcomeAnimationData[userType].explanationsheader[index]}
    //       </div>

    //       <div></div>
    //     </div>
    //     <div className="f-16 f-500 position-relative">
    //       {/* { welcomeAnimationData[userType].explanations[index]} */}

    //       {welcomeAnimationData[userType].explanations.map((message, i) => (
    //         <div
    //           key={i}
    //           className={`${
    //             i === index ? "visible" : ""
    //           } position-absolute f-16 f-500 signup-message`}
    //           style={{ animationDuration: index === -1 ? "0s" : "2s" }}
    //         >
    //           {message}
    //         </div>
    //       ))}
    //     </div>
    //     <br />
    //     <div>
    //       {/* <div className="f-black f-16 f-700 highground-royal-font">
    //         Mark Lamb
    //       </div>
    //       <div className="f-darkgrey">Founder</div> */}
    //     </div>
    //   </div>
    //   {true && (
    //     <div className="d-flex align-items-center  w-75 flex-column signup-screen-content">
    //       <div
    //         className="m-auto d-flex align-items-center justify-content-center flex-column w-80 "
    //         style={{
    //           // height: "55%",
    //           overflow: "hidden",
    //         }}
    //       >
    //         {completedCount > 5 && signUpCompleted ? (
    //           <div
    //             className="radius-8 zoom-independent"
    //             style={
    //               {
    //                 // boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
    //               }
    //             }
    //           >
    //             <Lottie
    //               loop={false}
    //               play={true}
    //               speed={0.7}
    //               animationData={createdData.default}
    //               style={{ width: 340, height: 300, margin: "0 auto" }}
    //               onComplete={() => {
    //                 if (callBack) {
    //                   callBack(callBackData);
    //                 }
    //               }}
    //             />
    //           </div>
    //         ) : (
    //           <div
    //             className="radius-8 zoom-independent"
    //             style={
    //               {
    //                 // boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.1)",
    //               }
    //             }
    //           >
    //             <Lottie
    //               loop={true}
    //               play={true}
    //               speed={0.7}
    //               animationData={creatingData.default}
    //               style={{ width: 340, height: 300, margin: "0 auto" }}
    //             />
    //           </div>
    //         )}
    //         {completedCount > 5 && signUpCompleted ? (
    //           <>
    //             <div
    //               className="d-flex align-items-center justify-content-center w-100 text-center f-500 f-16 signup-message visible"
    //               style={{
    //                 marginTop: "41px",
    //               }}
    //             >
    //               Account created!
    //             </div>

    //             <div className="f-darkgrey mt-3">
    //               Redirecting to your dashboard....
    //             </div>
    //           </>
    //         ) : (
    //           <>
    //             <div
    //               className="d-flex align-items-center justify-content-center w-100 text-center position-relative"
    //               style={{
    //                 marginTop: "41px",
    //               }}
    //             >
    //               {welcomeAnimationData[userType].messages.map((message, i) => (
    //                 <div
    //                   key={i}
    //                   className={`${
    //                     i === index ? "visible" : ""
    //                   } position-absolute f-16 f-500 signup-message`}
    //                   style={{ animationDuration: index === -1 ? "0s" : "2s" }}
    //                 >
    //                   {message}
    //                 </div>
    //               ))}
    //             </div>

    //             <div className="f-darkgrey mt-3">Please wait ...</div>
    //           </>
    //         )}
    //       </div>

    //       <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
    //         © 2024 HighGround. &nbsp; &nbsp;
    //         <u
    //           className="pointer"
    //           onClick={() => {
    //             window.open(
    //               "https://www.highground.io/privacy_policy/",
    //               "_blank"
    //             );
    //           }}
    //         >
    //           Privacy Policy
    //         </u>
    //         &nbsp; &nbsp;
    //         <u
    //           className="pointer"
    //           onClick={() => {
    //             window.open(
    //               "https://www.highground.io/highground-terms-and-conditions/",
    //               "_blank"
    //             );
    //           }}
    //         >
    //           Terms & Conditions
    //         </u>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default SaasAnimationSection;
