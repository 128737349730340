import { HGHighlightText } from "../../App/App";
import ImageLoader from "../../Common/ImageLoader";
import ArrowDownIcon from "../../icons/arrow-down.icon";
import Loader from "../../Common/loader";

const headers = [
  { title: "Device", key: "Name" },
  { title: "Operating System", key: "Operating_System" },
  { title: "Installed Date", key: "Installed_Date" },
  { title: "Warranty Expiry", key: "Warranty_Expiry" },
  { title: "End of Life Date", key: "End_Of_Life_Date" },
];

const DeviceIssuesTable = ({ 
  deviceIssues, 
  noDataComponent, 
  className, 
  isLoading = false,
  sortEnabled = false,
  searchString = "",
  deviceSortColumn = "",
  deviceSortOrder = 0,
  onSetDeviceSortColumn = () => {}
}) => {
  return (
    <div className={className}>
      {deviceIssues?.length > 0 ? (
        <>
          <div className="wrapper-header">
            {headers.map((header, headerIndex) => (
              <div 
                className={`f-500 cell d-flex align-items-center ${sortEnabled ? 'cursor-pointer' : ''}`}
                key={headerIndex}
                onClick={() => onSetDeviceSortColumn(header.key)}
              >
                <span>{header.title}</span>
                {deviceSortColumn === header.key && (
                  <ArrowDownIcon
                    className="ml-2"
                    width={12}
                    height={12}
                    fill="#616778"
                    style={{
                      transform: deviceSortOrder === 1 ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}
                  />
                )}
              </div>
            ))}
          </div>

          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center" style={{ height: '180px' }}>
              <Loader />
            </div>
          ) : (
            <div className="wrapper-row">
              {deviceIssues?.map((item) => {
                return (
                  <div className="line-row">
                    <div className="cell d-flex" style={{ gap: '8px' }}>
                      <div>
                        {item?.DeviceLogo && (
                          <ImageLoader
                            src={
                              item?.DeviceLogo
                            }
                            width={20}
                            height={20}
                            alt=""
                            className="mr-2"
                            noImageComponent={(
                              <div />
                            )}
                          />
                        )}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: HGHighlightText(item?.Name, searchString),
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column" style={{ gap: '4px' }}>
                        {item?.Info_Tiles?.map((tileTime, i) => (
                          <div key={`pl-${i}`} className="period-label">
                            <img
                              src={tileTime.InfoIcon}
                              width={14}
                              height={12}
                              alt=""
                              className="icon"
                            />
                            <span className="text">
                              {tileTime.info}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="cell f-grey">
                      {item?.OperatingSystemLogo && (
                        <img
                          src={
                            item?.OperatingSystemLogo
                          }
                          width={13}
                          height={13}
                          alt=""
                          className="mr-2"
                        />
                      )}
                      {item?.Operating_System}
                    </div>
                    <div className="f-grey cell">
                      {item?.Installed_Date}
                    </div>
                    <div className="f-grey cell">
                      {item?.Warranty_Expiry}
                    </div>
                    <div className="f-grey cell">
                      {item?.End_Of_Life_Date}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        noDataComponent
      )}
    </div>
  );
}

export default DeviceIssuesTable;
