import { useState } from "react";
import useApiActions from "../../../../../hooks/api-actions.hook";

const useAttackSurfaceData = ({ defaultSearchCriterias = [] }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [filterList, setFilterList] = useState({
    showUserIssues: true,
    showMFA: true,
    showRiskyUser: true,
    showDeviceIssues: true,
    showWarrantlyExpired: true,
    showPerformance: true,
    showEndOfLife: true,
    showOldDevice: true,
    SearchCriterias: defaultSearchCriterias
  });
  const userSortColumn = filterList.UserSortColumn;
  const userSortOrder = filterList.UserSortOrder;
  const deviceSortColumn = filterList.DeviceSortColumn;
  const deviceSortOrder = filterList.DeviceSortOrder;
  const { getUserDeviceIssues } = useApiActions();

  const fetchAttackSurfaceData = async (clientId) => {
    try {
      setIsLoading(true);
      const response = await getUserDeviceIssues({ clientId, filterList });
      setData(response?.detail);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching attack surface data', error);
      setIsLoading(false);
    }
  }

  const onSetUserSortColumn = (columnName) => {
    const newFilterList = { ...filterList };
    if (newFilterList.UserSortColumn === columnName) {
      const newOrder = newFilterList.UserSortOrder === 0 ? 1 
        : newFilterList.UserSortOrder === 1 ? -1 
        : 0;
      if (newOrder === -1) {
        delete newFilterList.UserSortColumn;
        delete newFilterList.UserSortOrder;
      } else {
        newFilterList.UserSortOrder = newOrder;
      }
    } else {
      newFilterList.UserSortColumn = columnName;
      newFilterList.UserSortOrder = 0;
    }
    if (newFilterList.DeviceSortColumn) {
      delete newFilterList.DeviceSortColumn;
      delete newFilterList.DeviceSortOrder;
    }
    setFilterList(newFilterList);
  }

  const onSetDeviceSortColumn = (columnName) => {
    const newFilterList = { ...filterList };
    if (newFilterList.DeviceSortColumn === columnName) {
      const newOrder = newFilterList.DeviceSortOrder === 0 ? 1 
        : newFilterList.DeviceSortOrder === 1 ? -1 
        : 0;
      if (newOrder === -1) {
        delete newFilterList.DeviceSortColumn;
        delete newFilterList.DeviceSortOrder;
      } else {
        newFilterList.DeviceSortOrder = newOrder;
      }
    } else {
      newFilterList.DeviceSortColumn = columnName;
      newFilterList.DeviceSortOrder = 0;
    }
    if (newFilterList.UserSortColumn) {
      delete newFilterList.UserSortColumn;
      delete newFilterList.UserSortOrder;
    }
    setFilterList(newFilterList);
  }

  return { 
    isLoading, 
    data,
    filterList,
    userSortColumn,
    userSortOrder,
    deviceSortColumn,
    deviceSortOrder,
    onSetUserSortColumn,
    onSetDeviceSortColumn,
    fetchAttackSurfaceData,
    setFilterList,
    setData
  };
}

export default useAttackSurfaceData;
