import { Fragment, useState, useEffect, useRef } from "react";
import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import "./companysettings.scss";
import {
  CustomGetApi,
  getAPIData,
  postRequest,
} from "../../../Utils/commonApi";
import CompanySettingsDropdown from "./companysettingsdrodown/CompanySettingsDropdown";
import ChangeAddressModal from "./companySettingsModals/ChangeAddressModal";
import { UpdateNameModal } from "./companySettingsModals/UpdateNameModal";
import CompanyTimeZoneDropDown from "./companysettingsdrodown/CompanyTimeZoneDropDown";
import { useAuth } from "../../context/Auth";
import { useDispatch, useSelector } from "react-redux";
import { setcompany } from "../../../redux/companyState/actions";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ImageCropperModal from "../personal-profile/personal-profile-modals/ImageCropperModal";
import CompanyPageLoader from "./CompanyPageLoader";
import {
  NO_ACCESS_TEXT,
  getAccessStateValue,
  viewOnlyAccess,
} from "../../App/App";
import ManageClientNotificationsModal from "./companySettingsModals/ManageClientNotificationsModal";
import SendCopyMSPEmailsModal from "./companySettingsModals/SendCopyMSPEmailsModal";
import SendCopyClientEmailsModal from "./companySettingsModals/SendCopyClientEmailsModal";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import TemporarySupportAccessModal from "./TemporarySupportAccessModal";
const CompanySettings = ({ accesStateName }) => {
  const { userType, authTokens } = useAuth();
  const fileUpload = useRef();
  const [pageData, setPageData] = useState({});
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);

  const companyDetails = useSelector((state) => state?.company?.companyDetail);
  const [industry, setIndustry] = useState();
  const [employee, setEmployee] = useState();
  const [timezone, setTimezone] = useState();
  const [country, setCountry] = useState();
  const [localisation, setLocalisation] = useState();
  const [updateNameModal, setUpdateNameModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [cropperModal, setCropperModal] = useState(false);
  const [showTemporarySupportAccessModal, setShowTemporarySupportAccessModal] = useState(false);
  const [manageEmalNotificationsModal, setManageEmalNotificationsModal] =
    useState(false);
  const [sendCopyMSPEmailsModal, setSendCopyMSPEmailsModal] = useState(false);
  const [sendCopyClientEmailsModal, setSendCopyClientEmailsModal] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const hideCropperModal = () => {
    setCropperModal(false);
  };
  const showCropperModal = () => {
    setCropperModal(true);
  };
  const showUpdateNameModal = () => {
    setUpdateNameModal(true);
  };
  const hideUpdateNameModal = () => {
    setUpdateNameModal(false);
  };

  const showAddressModal = () => {
    setAddressModal(true);
  };
  const hideAddressModal = () => {
    setAddressModal(false);
  };

  async function getGeneralInfo() {
    const result = await getAPIData("99", authTokens);
    if (result?.mr?.Success) {
      setPageData(result);
      setMainPageLoading(false);
      dispatch(setcompany(result));
      setIndustry(
        result?.DDLMembers &&
          result?.DDLMembers[0]?.DDLMemberList?.filter(
            (val) => val.DDLText == result.Industry
          )[0]
      );
      setEmployee(
        result?.DDLMembers &&
          result?.DDLMembers[1]?.DDLMemberList?.filter(
            (val) => val.DDLText == result.UserCount
          )[0]
      );
      setTimezone(
        result?.DDLMembers &&
          result?.DDLMembers[2]?.DDLMemberList?.filter(
            (val) => val.DDLText == result.Timezone
          )[0]
      );
      setLocalisation(
        result?.DDLMembers &&
          result?.DDLMembers[3]?.DDLMemberList?.filter(
            (val) => val.DDLId == result.Localisation
          )[0]
      );
      setCountry({
        objId: parseInt(result?.Country),
        name: result?.Country_Text,
      });
      setSelectedImage(result?.LogoURL);
    } else {
      setMainPageLoading(false);

      CallToast("Something went wrong", false, false, "v2style");
    }
  }
  useEffect(() => {
    getCountryList();
    setMainPageLoading(true);
    getGeneralInfo();
  }, []);

  async function getCountryList() {
    await CustomGetApi(`CC_API/GetCountries?IsSimpleSort=0`)
      .then((response) => {
        if (response?.mr?.Success) {
          setCountryList(response?.detail);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleOnChange(value, fieldName) {
    return new Promise((resolve, reject) => {
      const inputs = {
        NotificationName: fieldName,
        NotificationValue: value,
        SettingOwner: companyDetails.objId_SettingOwner,
        OwnerType: 0,
      };

      postRequest(authTokens, "SubmitNotificationSettings", inputs)
        .then((response) => {
          resolve(response);
          getGeneralInfo();
          if (response.Success) {
            CallToast("Updated successfully", false, false, "v2style");
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  return (
    <Fragment>
      {mainPageLoading && <CompanyPageLoader />}
      <div
        className={`company-settings-v2    ${
          mainPageLoading ? "d-none" : ""
        }  `}
        style={{
          pointerEvents: viewOnlyAccess("AccessState_SystemSettings")
            ? "none"
            : "all",
        }}
      >
        <h3>Company settings</h3>
        <input
          type="file"
          accept="image/*"
          ref={fileUpload}
          className="d-none"
          onChange={(e) => {
            if (e.target.files[0]) {
              const reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);

              reader.onload = function () {
                setSelectedImage(reader.result);
                showCropperModal();
              };
            }
            // setSelectedImage(e.target.files[0]);
            // showCropperModal();
          }}
        />

        <div className="d-flex justify-content-between align-items-center">
          <img
            className="rounded-circle border-grey camera-icon cursor-pointer"
            src={
              selectedImage
                ? selectedImage
                : "https://st4.depositphotos.com/1012074/20946/v/450/depositphotos_209469984-stock-illustration-flat-isolated-vector-illustration-icon.jpg"
            }
            alt=""
            // onClick={() => setShowUploadModal(true)}
            width="79"
          />

          <AttackSurfaceTooltip
            disabled={
              getAccessStateValue("AccessState_System_Settings") >= 3
            }
            bottomLeft={true}
            topLeft={false}
            content={<span>{NO_ACCESS_TEXT}</span>}
          >
            <div
              className="d-flex align-items-center"
              style={{
                pointerEvents:
                  getAccessStateValue("AccessState_SystemSettings") < 3
                    ? "none"
                    : "all",
              }}
            >
              {getAccessStateValue("AccessState_SystemSettings") < 3 && (
                <img
                width={14}
                height={14}
                alt=""
                src="/images/attack-surface/small-lock.svg"
                className="mr-2"
                
              />
              )}

              {selectedImage ? (
                <div>
                  <Button
                    className="change-photo-btn"
                    onClick={() => {
                      fileUpload.current.click();
                    }}
                  >
                    Change logo
                  </Button>
                  <Button
                    className="remove-photo-btn"
                    onClick={() => {
                      setSelectedImage("");

                      CallToast("Logo removed", false, false, "v2style");
                    }}
                  >
                    Remove logo
                  </Button>
                </div>
              ) : (
                <Button
                  className="profile-upload-btn"
                  onClick={() => {
                    fileUpload.current.click();
                  }}
                >
                  Upload logo
                </Button>
              )}
            </div>
          </AttackSurfaceTooltip>
        </div>
        <hr />

        <div className="personal-info mt-2">
          <h4>Company Info</h4>
          <div className="user-details">
            <Row>
              <Col
                xs={12}
                className="pointer"
                onClick={() => {
                  if (
                    getAccessStateValue("AccessState_SystemSettings") >= 3
                  ) {
                    showUpdateNameModal();
                  }
                }}
              >
                <div className="name-detail">
                  <div className="name-label">Company Name</div>

                  <div>
                    <span className="name">{companyDetails?.CompanyName}</span>
                    {getAccessStateValue("AccessState_SystemSettings") >=
                    3 ? (
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    ) : (
                      <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mx-2"
               
                />
                    )}
                  </div>
                </div>
                <hr className="m-0" />
              </Col>

              <Col xs={12}>
                <div className="name-detail">
                  <div className="name-label">Industry</div>
                  <div className="industry-drop">
                   
                    <CompanySettingsDropdown
                    disabled={getAccessStateValue("AccessState_SystemSettings") <
                    3}
                    disabledIcon={getAccessStateValue("AccessState_SystemSettings") <
                    3}
                     
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      selected={industry}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        setIndustry({ DDLId: id, DDLText: val });
                        handleOnChange(id, "SOindustry")
                          .then((res) => {})
                          .catch((err) => console.log(err));
                      }}
                      data={
                        companyDetails?.DDLMembers &&
                        companyDetails?.DDLMembers[0].DDLMemberList
                      }
                    />
                  </div>
                </div>
                <hr className="m-0" />
              </Col>
              <Col xs={12}>
                <div className="name-detail">
                  <div className="name-label">Employees</div>
                  <div className="employee-drop">
                    <CompanySettingsDropdown
                      disabled={getAccessStateValue("AccessState_SystemSettings") <
                      3}
                      disabledIcon={getAccessStateValue("AccessState_SystemSettings") <
                      3}
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      selected={employee}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        handleOnChange(id, "SOusers");
                        setEmployee({ DDLId: id, DDLText: val });
                      }}
                      data={
                        companyDetails?.DDLMembers &&
                        companyDetails?.DDLMembers[1].DDLMemberList
                      }
                    />
                  </div>
                </div>
                <hr className="m-0" />
              </Col>
              <Col xs={12} className="pointer" onClick={() => {
                if (getAccessStateValue("AccessState_SystemSettings") == 99) {
                  showAddressModal();
                }
                }}>
                <div className="name-detail">
                  <div className="name-label">Address</div>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{
                      maxWidth: "90%",
                    }}
                  >
                    <div className="text-ellipsis " style={{ width: "90%" }}>
                      <span className="name">
                        {companyDetails?.Address1
                          ? `${companyDetails?.Address1}, `
                          : ""}
                        {companyDetails?.Address2
                          ? `${companyDetails?.Address2}, `
                          : ""}
                        {companyDetails?.Address3
                          ? `${companyDetails?.Address3}, `
                          : ""}

                        {companyDetails?.County
                          ? `${companyDetails?.County}, `
                          : ""}
                        {/* {companyDetails?.Country
                          ? `${companyDetails?.Country},`
                          : ""} */}
                        {companyDetails?.Postcode
                          ? `${companyDetails?.Postcode}`
                          : ""}
                      </span>
                    </div>
                    {getAccessStateValue("AccessState_SystemSettings") >=
                    3 ? (
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    ) : (
                      <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mx-2"
               
                />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* Email Notifications */}

        {companyDetails?.EnableEmailNotificationSettings && (
          <div className="personal-info" style={{ marginTop: "2.5rem" }}>
            <h4>Email Notifications</h4>
            <div className="user-details">
              <Row>
                {userType == "555555" && (
                  <>
                    <Col
                      xs={12}
                      onClick={() =>{
                        if(getAccessStateValue("AccessState_SystemSettings") >=
                        3){

                          setSendCopyMSPEmailsModal(true)
                        }
                      }}
                    >
                      <div className="name-detail language-text pointer">
                        <div className="name-label">
                          Send copy of MSP system emails to
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-end"
                          style={{
                            maxWidth: "90%",
                          }}
                        >
                          <div
                            className="text-ellipsis "
                            style={{ width: "90%" }}
                          >
                            <span className="name">
                              {companyDetails?.MSPClientsEmails?.length > 0 ? (
                                <>
                                  {companyDetails?.MSPClientsEmails?.length >
                                  2 ? (
                                    <>
                                      {companyDetails?.MSPClientsEmails[0]},
                                      {companyDetails?.MSPClientsEmails[1]}, +
                                      {companyDetails?.MSPClientsEmails
                                        ?.length - 2}
                                    </>
                                  ) : (
                                    <>
                                      {companyDetails?.MSPClientsEmails?.join(
                                        ", "
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                "No redirects"
                              )}
                            </span>
                          </div>
                          {getAccessStateValue("AccessState_SystemSettings") >=
                    3 ? (
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    ) : (
                      <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mx-2"
               
                />
                    )}
                        </div>
                      </div>
                      <hr className="m-0" />
                    </Col>
                  </>
                )}
                <Col xs={12} onClick={() => {
                  if(getAccessStateValue("AccessState_SystemSettings") >=
                  3){
                    setSendCopyClientEmailsModal(true)

                  }
                  
                  
                  }}>
                  <div className="name-detail language-text pointer">
                    <div className="name-label">
                      Send copy of system email notifications to
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-end"
                      style={{
                        maxWidth: "90%",
                      }}
                    >
                      <div className="text-ellipsis " style={{ width: "90%" }}>
                        <span className="name">
                          {companyDetails?.SystemEmails?.length > 0 ? (
                            <>
                              {companyDetails?.SystemEmails?.length > 2 ? (
                                <>
                                  {companyDetails?.SystemEmails[0]},
                                  {companyDetails?.SystemEmails[1]}, +
                                  {companyDetails?.SystemEmails?.length - 2}
                                </>
                              ) : (
                                <>{companyDetails?.SystemEmails?.join(", ")}</>
                              )}
                            </>
                          ) : (
                            "No redirects"
                          )}
                        </span>
                      </div>
                      {getAccessStateValue("AccessState_SystemSettings") >=
                    3 ? (
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    ) : (
                      <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mx-2"
               
                />
                    )}
                    </div>
                  </div>
                  <hr className="m-0" />
                </Col>

                <Col
                  xs={12}
                  className="pointer"
                  onClick={() => {
                    if(getAccessStateValue("AccessState_SystemSettings") >=
                    3){
                      setManageEmalNotificationsModal(true);

                    }
                  }}
                >
                  <div className="name-detail">
                    <div className="name-label">
                      Manage user email notifications
                    </div>
                    <div>
                      {/* <span className="name">{companyDetails?.CompanyName}</span> */}
                      {getAccessStateValue("AccessState_SystemSettings") >=
                    3 ? (
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    ) : (
                      <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="mx-2"
               
                />
                    )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ padding: "5px" }}></div>
          </div>
        )}

        {/* Regional Settings */}

        <div className="personal-info" style={{ marginTop: "2.5rem" }}>
          <h4>Regional Settings</h4>
          <div className="user-details">
            <Row>
              <Col xs={12}>
                <div className="name-detail">
                  <div className="name-label">Country</div>
                  <div className="time-zone-drop-down timezone-drop ">
                    {pageData?.isCountryEnabled ? (
                      <CompanyTimeZoneDropDown
                        disabled={getAccessStateValue("AccessState_SystemSettings") <
                        3}
                        disabledIcon={getAccessStateValue("AccessState_SystemSettings") <
                        3}
                          fieldId={"objId"}
                          fieldValue={"name"}
                          header={"Choose option"}
                          selected={country}
                          placeholder="Choose..."
                          //  showid={true}
                          onClick={(id, val) => {
                            handleOnChange(id, "SOcountry");
                            setCountry({ objId: id, name: val });
                          }}
                          data={countryList}
                        />
                    ) : (
                      <div>
                        <span className="name mr-3">
                          {country?.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="m-0 " />
              </Col>
              <Col xs={12}>
                <div className="name-detail language-text">
                  <div className="name-label">Currency</div>
                  <div>
                    <span className="name mr-3">
                      {pageData?.CurrencyCodeSymbol}{" "}
                    </span>
                  </div>
                </div>
                <hr className="m-0" />
              </Col>

              <Col xs={12}>
                <div className="name-detail">
                  <div className="name-label">Timezone</div>
                  <div className="time-zone-drop-down timezone-drop ">
                    <CompanyTimeZoneDropDown
                    disabled={getAccessStateValue("AccessState_SystemSettings") <
                    3}
                    disabledIcon={getAccessStateValue("AccessState_SystemSettings") <
                    3}
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      selected={timezone}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        handleOnChange(id, "SOtimezone");
                        setTimezone({ DDLId: id, DDLText: val });
                      }}
                      data={
                        companyDetails?.DDLMembers &&
                        companyDetails?.DDLMembers[2].DDLMemberList
                      }
                    />
                  </div>
                </div>
                <hr className="m-0 " />
              </Col>
              <Col xs={12}>
                <div className="name-detail language-text">
                  <div className="name-label">Language</div>
                  <div>
                    <span className="name">English</span>

                    <div className="tooltipx">
                      <img
                        alt=""
                        src="/images/settings-v2/info-icon.svg"
                        className="details-arrow"
                      />
                      <span className="tooltiptext">
                        More language will be available soon...
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="m-0" />
              </Col>
              <Col xs={12} className="">
                <div className="name-detail">
                  <div className="name-label">Localisation</div>
                  <div>
                    <span className="name mr-3">
                      {pageData?.Localisation_Text}{" "}
                    </span>
                  </div>
                  {/* <div className="time-zone-drop-down timezone-drop ">
                    <CompanyTimeZoneDropDown
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      selected={localisation}
                      placeholder="Choose..."
                      header={"Choose option"}
                      onClick={(id, val) => {
                        handleOnChange(id, "SO_Localisation");
                        setLocalisation({ DDLId: id, DDLText: val });
                      }}
                      data={
                        companyDetails?.DDLMembers &&
                        companyDetails?.DDLMembers[3].DDLMemberList
                      }
                    />
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ padding: "5px" }}></div>
        </div>
        <div className="personal-info" style={{ marginTop: "2.5rem" }}>
          <h4>Temporary Support Access</h4>
          <div className="user-details">
            <Row>
            <Col
                  xs={12}
                  className="pointer"
                  onClick={() => {
                    setShowTemporarySupportAccessModal(true);
                  }}
                >
                  <div className="name-detail">
                    <div className="name-label">
                    Temporary Support Access
                    </div>
                    <div>
                      <img
                        alt=""
                        src="/images/chevron-right.svg"
                        className="details-arrow"
                      />
                    </div>
                  </div>
                </Col>
            </Row>
          </div>
          <div style={{ padding: "5px" }}></div>
        </div>
      </div>
      <TemporarySupportAccessModal
        show={showTemporarySupportAccessModal}
        hideModal={() => {
          setShowTemporarySupportAccessModal(false);
        }}
      />
      <ManageClientNotificationsModal
        show={manageEmalNotificationsModal}
        hideModal={() => {
          setManageEmalNotificationsModal(false);
        }}
        refresh={() => {
          getGeneralInfo();
        }}
      />
      <SendCopyMSPEmailsModal
        show={sendCopyMSPEmailsModal}
        hideModal={() => {
          setSendCopyMSPEmailsModal(false);
        }}
        emailsList={companyDetails?.MSPClientsEmails}
        refresh={() => {
          getGeneralInfo();
        }}
      />
      <SendCopyClientEmailsModal
        show={sendCopyClientEmailsModal}
        hideModal={() => {
          setSendCopyClientEmailsModal(false);
        }}
        emailsList={companyDetails?.SystemEmails}
        refresh={() => {
          getGeneralInfo();
        }}
      />
      <ChangeAddressModal
        show={addressModal}
        hideModal={hideAddressModal}
        handleOnChange={handleOnChange}
      />
      <UpdateNameModal
        show={updateNameModal}
        hideModal={hideUpdateNameModal}
        handleOnChange={handleOnChange}
      />
      <ImageCropperModal
        show={cropperModal}
        hideModal={hideCropperModal}
        profileImage={selectedImage}
        setProfileImage={setSelectedImage}
        uploadType={1}
      />
    </Fragment>
  );
};

export default CompanySettings;
