const ChipsInformativeIcon = (props) => (
  <svg width="44" height="44" {...props} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#F6F8FB"/>
    <g clip-path="url(#clip0_62779_216329)">
    <path d="M23.6666 23.8768V25.6185C22.9124 25.3518 22.1051 25.27 21.3128 25.38C20.5204 25.4899 19.7659 25.7884 19.1128 26.2503C18.4596 26.7123 17.9269 27.3242 17.5592 28.0347C17.1916 28.7452 16.9998 29.5335 16.9999 30.3335L15.3333 30.3327C15.333 29.3151 15.5657 28.3109 16.0135 27.3972C16.4613 26.4834 17.1124 25.6843 17.9168 25.061C18.7212 24.4378 19.6576 24.0069 20.6542 23.8015C21.6509 23.5961 22.6813 23.6216 23.6666 23.876V23.8768ZM21.9999 22.8335C19.2374 22.8335 16.9999 20.596 16.9999 17.8335C16.9999 15.071 19.2374 12.8335 21.9999 12.8335C24.7624 12.8335 26.9999 15.071 26.9999 17.8335C26.9999 20.596 24.7624 22.8335 21.9999 22.8335ZM21.9999 21.1668C23.8416 21.1668 25.3333 19.6752 25.3333 17.8335C25.3333 15.9918 23.8416 14.5002 21.9999 14.5002C20.1583 14.5002 18.6666 15.9918 18.6666 17.8335C18.6666 19.6752 20.1583 21.1668 21.9999 21.1668ZM26.9999 26.1668V23.6668H28.6666V26.1668H31.1666V27.8335H28.6666V30.3335H26.9999V27.8335H24.4999V26.1668H26.9999Z" fill="#9499AA"/>
    </g>
    <rect x="24.5" y="7.5" width="13" height="13" rx="6.5" fill="#F6F8FB"/>
    <rect x="24.5" y="7.5" width="13" height="13" rx="6.5" stroke="#F6F8FB"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0001 19.8332C27.7783 19.8332 25.1667 17.2216 25.1667 13.9998C25.1667 10.7781 27.7783 8.1665 31.0001 8.1665C34.2218 8.1665 36.8334 10.7781 36.8334 13.9998C36.8334 17.2216 34.2218 19.8332 31.0001 19.8332ZM31.5618 16.9177V15.751H30.3952V16.9177H31.5618ZM29.5006 11.7161C29.2337 11.996 29.0517 12.346 28.9759 12.7253L30.1204 12.9545C30.1529 12.792 30.2309 12.6421 30.3452 12.5221C30.4596 12.4022 30.6057 12.3172 30.7664 12.2771C30.9272 12.2369 31.0961 12.2433 31.2534 12.2953C31.4107 12.3474 31.55 12.4431 31.6551 12.5712C31.7602 12.6993 31.8267 12.8547 31.8469 13.0192C31.8671 13.1836 31.8403 13.3505 31.7694 13.5003C31.6985 13.65 31.5865 13.7766 31.4465 13.8653C31.3065 13.9539 31.1442 14.001 30.9785 14.001H30.3952V15.1677H30.9785C31.3653 15.1677 31.7441 15.0579 32.0709 14.851C32.3978 14.6441 32.6591 14.3487 32.8245 13.999C32.99 13.6494 33.0528 13.26 33.0056 12.8761C32.9584 12.4922 32.8031 12.1296 32.5579 11.8305C32.3126 11.5314 31.9875 11.3081 31.6203 11.1866C31.2531 11.0651 30.8589 11.0503 30.4837 11.144C30.1084 11.2378 29.7675 11.4361 29.5006 11.7161Z" fill="#9499AA"/>
    <defs>
    <clipPath id="clip0_62779_216329">
    <rect width="20" height="20" fill="white" transform="translate(12 12)"/>
    </clipPath>
    </defs>
  </svg>
);

export default ChipsInformativeIcon;
