const AvatarIcon = (props) => (
  <svg width="20" height="20" {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#D4D6DD"/>
    <g clip-path="url(#clip0_62010_4786)">
    <path d="M14 15H6V14C6 13.337 6.26339 12.7011 6.73223 12.2322C7.20107 11.7634 7.83696 11.5 8.5 11.5H11.5C12.163 11.5 12.7989 11.7634 13.2678 12.2322C13.7366 12.7011 14 13.337 14 14V15ZM10 10.5C9.60603 10.5 9.21593 10.4224 8.85195 10.2716C8.48797 10.1209 8.15726 9.8999 7.87868 9.62132C7.6001 9.34274 7.37913 9.01203 7.22836 8.64805C7.0776 8.28407 7 7.89397 7 7.5C7 7.10603 7.0776 6.71593 7.22836 6.35195C7.37913 5.98797 7.6001 5.65726 7.87868 5.37868C8.15726 5.1001 8.48797 4.87913 8.85195 4.72836C9.21593 4.5776 9.60603 4.5 10 4.5C10.7956 4.5 11.5587 4.81607 12.1213 5.37868C12.6839 5.94129 13 6.70435 13 7.5C13 8.29565 12.6839 9.05871 12.1213 9.62132C11.5587 10.1839 10.7956 10.5 10 10.5Z" fill="#9499AA"/>
    </g>
    <defs>
    <clipPath id="clip0_62010_4786">
    <rect width="12" height="12" fill="white" transform="translate(4 4)"/>
    </clipPath>
    </defs>
  </svg>
);

export default AvatarIcon;
