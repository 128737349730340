import React, { useEffect, useState } from "react";

const CyberScoreGauge = ({value, showTitle = true}) => {
    const [gaugeValue, setGaugeValue] = useState(value)

    useEffect(() => {
        let _value = Math.max(0, Math.min(100, value));
        // calculate the percentage of the value in the range of 0-100
    var percentage = (_value - 0) / (100 - 0);

  // map the percentage to the range of 100-20
  var newValue = (1 - percentage) * (100 - 20) + 20;
  setGaugeValue(Math.floor(newValue))
    }, [value])
    // 100- zero
    // 20- full
  return (
    <div className="w-auto position-relative">
      <div
        className="position-absolute d-flex align-items-center justify-content-center gauge-graph-text"
        style={{
          boxShadow: "0 11px 42px rgba(255, 156, 50, 0.3)",
        }}
      >
        {value}
      </div>
      {
        showTitle &&
        <div
          className="f-12 f-600 f-black position-absolute "
          style={{
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-4px",
          }}
        >
          CyberScore
        </div>
      }
      <svg width="140" height="140" viewBox="0 0 140 ">
        {/* box-shadow: 0 0 10px rgba(0, 143, 212, 0.16); */}
        <defs>
          <linearGradient id="gauge-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stop-color="#FF853A" />
            <stop offset="50%" stop-color="#FF853A" />
            <stop offset="100%" stop-color="#FF5C5B" />
          </linearGradient>
        </defs>
        <circle
          stroke-dasharray="100"
          stroke-dashoffset="20"
          className="circle_animation_unfilled"
          r="50"
          cy="70"
          cx="70"
          strokeWidth="14"
          stroke="#ebecf1"
          fill="none"
          pathLength="100"
          transform="rotate(125, 70, 70)"
        />
        <circle
          stroke-dasharray="100"
          strokeDashoffset={gaugeValue}
          className=""
          r="50"
          cy="70"
          cx="70"
          strokeWidth="14"
          stroke="url(#gauge-gradient)"
          fill="none"
          pathLength="100"
          transform="rotate(125, 70, 70)"
        />
      </svg>
    </div>
  );
};

export default CyberScoreGauge;


export const CyberScoreGaugeSmaller = ({ value, showTitle = true }) => {
  const [gaugeValue, setGaugeValue] = useState(value);

  useEffect(() => {
    let _value = Math.max(0, Math.min(100, value));
    // Calculate the percentage of the value in the range of 0-100
    var percentage = (_value - 0) / (100 - 0);

    // Map the percentage to the range of 100-20
    var newValue = (1 - percentage) * (100 - 20) + 20;
    setGaugeValue(Math.floor(newValue));
  }, [value]);

  // 100- zero
  // 20- full
  return (
    <div className="w-auto position-relative" style={{ width: "120px", height: "120px" }}>
      <div
        className="position-absolute d-flex align-items-center justify-content-center gauge-graph-text-smaller"
        style={{
          boxShadow: "0 11px 42px rgba(255, 156, 50, 0.3)",
        }}
      >
        {value}
      </div>
      {showTitle && (
        <div
          className="f-12 f-600 f-black position-absolute"
          style={{
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "-4px",
          }}
        >
          CyberScore
        </div>
      )}
      <svg width="120" height="120" viewBox="0 0 120 120">
        <defs>
          <linearGradient id="gauge-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FF853A" />
            <stop offset="50%" stopColor="#FF853A" />
            <stop offset="100%" stopColor="#FF5C5B" />
          </linearGradient>
        </defs>
        <circle
          strokeDasharray="100"
          strokeDashoffset="20"
          className="circle_animation_unfilled"
          r="42"
          cy="60"
          cx="60"
          strokeWidth="12"
          stroke="#ebecf1"
          fill="none"
          pathLength="100"
          transform="rotate(125, 60, 60)"
        />
        <circle
          strokeDasharray="100"
          strokeDashoffset={gaugeValue}
          r="42"
          cy="60"
          cx="60"
          strokeWidth="12"
          stroke="url(#gauge-gradient)"
          fill="none"
          pathLength="100"
          transform="rotate(125, 60, 60)"
        />
      </svg>
    </div>
  );
};
