import { Dropdown, Spinner } from "react-bootstrap";
import useDropdown from "../../../hooks/dropdown.hook";
import HorizontalDotsIcon from "../../icons/horizontal-dots.icon";
import { useEffect, useState } from "react";

const LoaderState = {
  Remove: "remove",
  Delete: "delete",
}

const IntegrationItemDropdown = ({ 
  integrationId, 
  onAddSubscription = () => { }, 
  onRemoveOption = () => { }, 
  onRenameOption = () => { },
  onDeleteOption = () => { },
  showAddSub, 
  showRename,
  showRemove = true,
  showDelete,
  drop = "down",
  setOpen = () => { }
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false});
  const [loaderState, setLoaderState] = useState(null);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Dropdown drop={drop} onSelect={() => setIsOpen(false)} show={isOpen} className="w-100 transparennt-wrapper-dropdown my-dropdown">
      <Dropdown.Toggle ref={refBtn} className="integration-options-btn">
        <div 
          className="pointer px-2" onClick={(e) => {
            e.stopPropagation();
            setIsOpen(prev => !prev);
          }}
        >
          <img
            alt=""
            className="pointer"
            src="/images/msp/horizontal-dots.svg"
          />
          <HorizontalDotsIcon fill="#616778" width="16" height="4" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu 
        ref={refMenu} 
        className="integration-actions-dropdown menu"
      >
        {showAddSub && (
          <div
            className="integration-item"
            onClick={(e) => {
              e.stopPropagation();
              onAddSubscription(integrationId);
              setIsOpen(false);
            }}
          >
            <span className="item py-1">Add Subscription</span>
          </div>
        )}
        {showRename && (
          <div
            className="integration-item"
            onClick={(e) => {
              e.stopPropagation();
              onRenameOption(integrationId);
              setIsOpen(false);
            }}
          >
            <span className="item py-1">Rename</span>
          </div>
        )}
        {showRemove && (
          <div
            className="integration-item d-flex align-items-center justify-content-between"
            style={{ gap: "16px" }} 
            onClick={async (e) => {
              e.stopPropagation();
              setLoaderState(LoaderState.Remove);
              try {
                await onRemoveOption(integrationId);
              } finally {
                setLoaderState(null);
                setIsOpen(false);
              }
            }}
          >
            <span className="delete py-1">Remove</span>
            {loaderState === LoaderState.Remove ? (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            ) : (
              <div style={{ width: '12px' }} />
            )}
          </div>
        )}
        {showDelete && (
          <div
            className="integration-item d-flex align-items-center justify-content-between"
            style={{ gap: "16px" }} 
            onClick={async (e) => {
              e.stopPropagation();
              setLoaderState(LoaderState.Delete);
              try {
                await onDeleteOption(integrationId);
              } finally {
                setLoaderState(null);
                setIsOpen(false);
              }
            }}
          >
            <span className="delete py-1">Delete</span>
            {loaderState === LoaderState.Delete ? (
              <Spinner
                animation="border"
                variant="secondary"
                size="sm"
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
            ) : (
              <div style={{ width: '12px' }} />
            )}
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default IntegrationItemDropdown;
