import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import "./companysettings.scss";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import moment from "moment"

const TemporarySupportAccessModal = ({ show, hideModal = () => {} }) => {
  const periods = [
    {
      PeriodId: 21998,
      PeriodDayCount: 1,
      PeriodText: "24 hours",
    },
    {
      PeriodId: 21999,
      PeriodDayCount: 2,
      PeriodText: "2 days",
    },
    {
      PeriodId: 22000,
      PeriodDayCount: 5,
      PeriodText: "5 days",
    },
    {
      PeriodId: 22001,
      PeriodDayCount: 7,
      PeriodText: "7 days",
    },
  ];
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [periodsList, setPeriodsList] = useState(periods);
  const [sessionList, setSessionList] = useState([])
  const { authTokens } = useAuth();
  const [givingAccess, setGivingAccess] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)

  const activeSession = useMemo(() => {
    return sessionList?.find((session) => session?.Status === 0);
  }, [sessionList]);

  useEffect(() => {
    if (show) {
      getModalData();
    }
  }, [show]);

  function getModalData() {
    return new Promise((resolve, reject) => {
      getAPIData(`${1100}`, authTokens, {})
        .then((response) => {
          resolve(response);
          setPeriodsList(response?.Period_DDL)
          setSessionList(response?.Session_List)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function revokeAccess(_id) {
    setDataLoading(true)
    return new Promise((resolve, reject) => {
      getAPIData(`${1101}`, authTokens, {
        optionEnum1:_id
      })
        .then((response) => {
          resolve(response);
    setDataLoading(false)
    getModalData()
         
        })
        .catch((err) => {
          reject(err);
    setDataLoading(false)

        });
    });
  }

  function giveAccessApi() {
    setGivingAccess(true)
    postData(authTokens, "CreateTempSupport", {
     periodId:selectedPeriod?.PeriodId,

     
    }).then(()=>{
      setGivingAccess(false)
      setSelectedPeriod()
      getModalData()
    }).catch((err)=>{
      setGivingAccess(false)

    })
  }

   

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName={`temporarySupportAccessModal`}
      className={`temporarySupportAccessModal`}
      aria-labelledby="temporarySupportAccessModal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Temporary Support Access
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="transparent-scroller">

      {dataLoading && (
                    <div
                      className="position-absolute w-100 h-100 bg-white"
                      style={{
                        opacity: "0.6",
                        zIndex: "2",
                      }}
                    ></div>
                  )}
        <div className="m-4">
          <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
            HighGround needs explicit permission to access your account in order
            to assist with support Issues and account health checks. Admins can
            grant access to HighGround for a specific amount of time.
          </div>
          <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
            Allowing HighGround to access your account as an authorized admin
            does not affect your available user count. Any changes to this
            setting will trigger an email notification to all admins in your
            account.
          </div>

          <div className="f-500 f-14 f-black line-height-22 letter-space-2 mt-3">
            Give access
          </div>
          <div className="d-flex align-items-center">
            <Dropdown
              className="transparennt-wrapper-dropdown-periods mr-2"
              style={{
                width: "240px",
                height: "40px",
              }}
            >
              <Dropdown.Toggle>
                <div
                  className="d-flex align-items-center border-gray justify-content-between px-3"
                  style={{
                    width: "240px",
                    height: "40px",
                  }}
                >
                  <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">
                    {selectedPeriod?.PeriodText ?? "Select period"}
                  </div>
                  <img
                    alt=""
                    src="/images/chevron-right.svg"
                    className="details-arrow rotate90"
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {periodsList?.map((periodItem) => {
                  return (
                    <div
                      className="f-400 f-14 greyText2 line-height-22 letter-space-2 py-1 px-3 pointer"
                      onClick={(e) => {
                        e.target
                          .closest(".transparennt-wrapper-dropdown-periods")
                          .click();
                        setSelectedPeriod(periodItem);
                      }}
                    >
                      {periodItem?.PeriodText}
                    </div>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {activeSession ? (
              <Button
                className="hg-red-btn"
                style={{
                  width: "137px",
                  height: "40px",
                }}
                onClick={() => {
                  revokeAccess(activeSession?.objId)
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <div className="f-500 f-14 line-height-22 letter-space-2">
                    Revoke Access
                  </div>
                </div>
              </Button>
            ) : (
              <Button
                disabled={givingAccess}
                className="hg-blue-btn"
                style={{
                  width: "137px",
                  height: "40px",
                }}
                onClick={() => {
                  if(selectedPeriod){
                    giveAccessApi()

                  }
                }}
              >
                <div className="d-flex align-items-center">
                  <img alt="" src="/images/play-icon.svg" className="mx-2" />
                  <div className="f-500 f-14 line-height-22 letter-space-2">
                    {"Give access" ?? "Update access duration"}
                  </div>
                </div>
              </Button>
            )}
            
          </div>

          <div className="d-flex mt-3">
            <img
              alt=""
              width={14}
              height={14}
              src="/images/info-icon.svg"
              className="mr-2 mt-1"
            />
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2">
              HighGround reserves the right to access your account without prior
              notice in certain situations. These include emergency situations
              to prevent harm to you, other users, or situations where we
              suspect that your use or access to our services is violating the
              <span className="f-400 f-14 line-height-22 letter-space-2">
                {" "}
                Acceptable Use Policy
              </span>{" "}
              or
              <span className="f-400 f-14 line-height-22 letter-space-2">
                {" "}
                Terms of Service
              </span>
              .
            </div>
          </div>

          <div className="f-400 f-14 f-black line-height-22 letter-space-2 mt-3">
            History
          </div>
          <div className="bg-grey-imp my-3">
            {sessionList?.map((historyItem, index) => {
              console.log('historyItem?.SupportPeriodText', historyItem?.SupportPeriodText)
              return (
                <div
                  className={`d-flex w-100 align-items-center justify-content-between p-3 ${
                    index < sessionList?.length - 1 ? "border-bottom" : ""
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                      {historyItem?.Status === 0
                        ? moment(historyItem?.StartDate).format("ll")
                        : moment(historyItem?.StartDate).format("lll")}
                    </div>
                    <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                      &nbsp;-&nbsp;
                    </div>
                    {historyItem?.Status === 0 ? (
                      <div className="f-400 f-14   line-height-22 letter-space-2">
                        <span
                          style={{
                            color: historyItem?.StatusColour,
                          }}
                        >
                          {historyItem?.Status_Text}{" "}
                        </span>
                      </div>
                    ) : (
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                        {moment(historyItem?.EndDate).format("lll")}&nbsp;{" "}
                        <span
                          style={{
                            color: historyItem?.StatusColour,
                          }}
                        >
                          {historyItem?.Status_Text}{" "}
                        </span>
                      </div>
                    )}
                   
                  </div>
                  <div className="d-flex align-items-center w-40 justify-content-between">
                  <div
                      className={`f-400 f-14 pointer ${
                        historyItem?.Status === 0
                          ? "f-danger"
                          : ""
                      } mx-2 line-height-22 letter-space-2`}

                      onClick={()=>{
                        revokeAccess(historyItem?.objId)
                      }}
                    >
                      {historyItem?.Status===0 ?  "Revoke Access"   :""}
                    </div>
                     
                    <div className="f-400 f-14 f-black line-height-22 letter-space-2 w-25 d-flex justify-content-end">
                       
                      {historyItem?.SupportPeriodText}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div
            className="d-flex align-items-center w-100 text-center"
            style={{
              width: '752px',
              height: '216px'
            }}
          >
            <div className="w-100">
            <img
              alt=""
              width={44}
              height={44}
              src="/images/no-access-records-icon.svg"
              className="mr-2 mt-1"
            />
            <div className="f-600 f-14 greyText2 line-height-22 letter-space-2 mt-3">No given access</div>
            <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2">There no access records yet</div>
            </div>

          </div> */}
          <div   className="p-2" />
        </div>
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
};

export default TemporarySupportAccessModal;
