import React from "react";
import ContentLoader from "react-content-loader";

const CSuitePageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="16.8%" y="50" rx="3" ry="3" width="15%" height="26" />
      <rect x="16.8%" y="105" rx="3" ry="3" width="40%" height="20" />

      <rect x="16.8%" y="140" rx="4" ry="4" width="15%" height="15" />
      <rect x="16.8%" y="165" rx="4" ry="4" width="20%" height="15" />
      <rect x="16.8%" y="190" rx="4" ry="4" width="9%" height="15" />
      <rect x="16.8%" y="215" rx="4" ry="4" width="18%" height="15" />
      <rect x="16.8%" y="240" rx="4" ry="4" width="24%" height="15" />
      <rect x="16.8%" y="265" rx="4" ry="4" width="19%" height="15" />

      {/* <rect x="18%" y="700" rx="4" ry="4" width="60%" height="60" />
      <rect x="18%" y="765" rx="4" ry="4" width="60%" height="60" />
      <rect x="18%" y="830" rx="4" ry="4" width="60%" height="60" /> */}
    </ContentLoader>
  );
};

export default CSuitePageLoader;
