import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../Login/Login.css";
import AuthLayout from "../Loginv2/auth-layout/auth.layout";
import AuthBody from "../Loginv2/auth-layout/auth.body";
import AuthDefaultSections from "../Loginv2/auth-layout/auth-default-sections.component";
import ResetPasswordSection from "../Loginv2/sections/reset-password.section";
import { ROUTE_ACTIONS } from "../../constants/app.constants";
import useApiActions from "../../hooks/api-actions.hook";

const ResetPasswordFormState = {
  NEW_PASSWORD: 0,
}

const ResetPasswordTitles = [
  { title: "Create new password", description: "Create new strong password.\nUse at least 12 characters. Do not enter a password that you use on another site or one that can be easily guessed." },
];

function ResetPasswordForm() {
  let params = new URLSearchParams(document.location.search.substring(1));
  let ids = params.get("id");
  let tokens = params.get("token"); // is the string "Jonathan"

  const [uid, setUid] = useState(ids || "");
  const [token, setToken] = useState(tokens || "");
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [formState, setFormState] = useState(ResetPasswordFormState.NEW_PASSWORD);
  const [loading, setLoading] = useState(false);
  const [disableCredFieldsHL, setDisableCredFieldsHL] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState("");
  const history = useHistory();
  const { getUserEmailById } = useApiActions();

  async function sendPasswordReset(password) {
    var urlencoded = new URLSearchParams();
    urlencoded.append("password", password);
    urlencoded.append("uid", uid);
    urlencoded.append("token", token);

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/SubmitNewPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Success === true) {
          history.push(`/login?action=${ROUTE_ACTIONS.passwordChanged}&email=${defaultEmail}`);
        } else {
          setError("Unable to Reset Password");
        }
        if (data.Success === false) {
          setError("Unable to Reset Password");
        }
      })
      .catch((error) => {
        setError("Unable to Reset Password");
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleResetPassword = (password, confirmPassword, passwordStrength) => {
    let hasError = false;
    setLoading(true);
    if (password == "") {
      setPasswordError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (confirmPassword == "") {
      setConfirmPasswordError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (passwordStrength < 5) {
      setPasswordError(
        "Password must contain 12 characters or more and must include lowercase, uppercase, numeric and special characters"
      );
      setLoading(false);
      hasError = true;
    }
    if (password != confirmPassword) {
      setConfirmPasswordError("Passwords don't match");
      setLoading(false);
      hasError = true;
    }
    if (hasError) return;
    sendPasswordReset(password);
  }

  useEffect(() => {
    if(!uid) return;
    getUserEmailById({ id: uid }).then((data) => {
      if (data.detail) {
        setDefaultEmail(data.detail);
      }
    });
  }, [uid]);

  const sections = [ResetPasswordSection];
  const titles = ResetPasswordTitles[formState];
  const CurSection = sections[formState] ?? <div />;

  return (
    <AuthLayout>
      <AuthBody>
        <AuthDefaultSections
          title={titles.title}
          description={titles.description}
          hideInfo
        >
          <CurSection 
            defaultEmail={defaultEmail}
            error={error}
            loading={loading}
            setError={setError}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            confirmPasswordError={confirmPasswordError}
            setConfirmPasswordError={setConfirmPasswordError}
            handleResetPassword={handleResetPassword}
            setDisableCredFieldsHL={setDisableCredFieldsHL}
            disableCredFieldsHL={disableCredFieldsHL}
          />
        </AuthDefaultSections>
      </AuthBody>
    </AuthLayout>
  )
}
export default ResetPasswordForm;
