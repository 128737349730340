import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const [params, setParams] = useState(new URLSearchParams(location.search));

  useEffect(() => {
    setParams(new URLSearchParams(location.search));
  }, [location.search]);

  const getParam = (key) => {
    return params.get(key);
  };

  const setParam = (key, value) => {
    params.set(key, value);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const clearParams = () => {
    setParams(new URLSearchParams());
    history.push(`${location.pathname}`);
  };

  return { getParam, setParam, clearParams };
};

export default useQueryParams;
