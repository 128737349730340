import React, { useState, useEffect, lazy, Suspense } from "react";
// import "./networks.scss";
import "./suppliers.scss";
import "../common.scss";
import { useAuth } from "../../context/Auth";

import { AttackSurfaceGetApi, getAPIData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AssetsListMainPageLoader from "../AttackSurfaceLoaders/AssetsListMainPageLoader";

import { useHistory } from "react-router-dom";
import { cleanText } from "../Index";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import AddSupplierModal from "./AddSupplierModal";
import EditPotentialSupplierModal from "./EditPotentialSupplierModal";
// import EditSupplierModal from "./EditSupplierModal";
import RequestsModal from "../../supplyChainMonitoring/RequestsModal/Index";
import { viewOnlyAccess } from "../../App/App";
import integrationUtils from "../../../Utils/integration.utils";

// const RequestsModal = lazy(() =>
//   import("../../supplyChainMonitoring/RequestsModal/Index")
// );
// const EditSupplierModal = lazy(() => import("./EditSupplierModal"));

const Suppliers = (props) => {
  const { goTo, setAssetId, refreshMenuItems } = props;
  const history = useHistory();

  // homepage
  // potentialSuppliers
  const [page, setPage] = useState("homepage");

  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [showIntegrationPopup, setShowIntegrationPopup] = useState(true);

  const [showRequestsModal, setShowRequestsModal] = useState(false);

  const { authTokens } = useAuth();

  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
  const [potentialSupplierList, setPotentialSupplierList] = useState([]);
  const [potentialSupplierData, setPotentialSupplierData] = useState({});
  const [supplierData, setSupplierData] = useState({});
  const [unAllocatedSuppliers, setUnAllocatedSuppliers] = useState([]);

  useEffect(() => {
    setMainPageLoading(true);
    getPotentialSuppliers();
    setPage("homepage");
  }, []);

  async function getPotentialSuppliers(filter = 0) {
    await getAPIData(`${452}`, authTokens, {
      // optionEnum1: filter,
    })
      .then((response) => {
        setMainPageLoading(false);
        if (response?.mr?.Success) {
          setPotentialSupplierList(
            response?.PotentialSupplierDetail_List?.filter(
              (item) => item?.Type !== "Potential"
            )
          );
          setUnAllocatedSuppliers(
            response?.PotentialSupplierDetail_List?.filter(
              (item) => item?.Type === "Potential"
            )
          );
          setPotentialSupplierData(response);
          // setPotentialSupplierFilters(response?.PotentialSupplierFilterEnum_List);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setMainPageLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to delete Potential supplier
  async function deletePotentialSupplier(PotentialSupplierId) {
    await getAPIData(`${453}`, authTokens, {
      optionEnum1: PotentialSupplierId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          getPotentialSuppliers();
          CallToast("Supplier deleted successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        console.log(err);
      });
  }
  return (
    <div className="">
      {mainPageLoading ? (
        <AssetsListMainPageLoader />
      ) : (
        <>
          <div className="w-100 d-flex align-items-center justify-content-center bg-lightblue p-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                alt=""
                src="/images/attack-surface/plug-icon.svg"
                className="mr-3"
              />
              <div className="d-flex align-items-center justify-content-center">
                Automate your data acquisition by integrating your existing
                security tools
              </div>
              <div
                className="d-flex align-items-center justify-content-center f-blue mx-3 pointer"
                onClick={() => {
                  history.push("/integrations");
                }}
              >
                Connect tools
              </div>
            </div>
          </div>

          {/* Main Components */}

          <div className="attack-networks-wrapper">
            {page === "homepage" && (
              <>
                <div className="d-flex align-items-center justify-content-between pt-3 pb-2">
                  <div className="font-weight-bold mt-3 mb-2">Suppliers</div>

                  <Button
                    // className="hg-blue-btn py-1 px-2"
                    className={`hg-blue-btn py-1 px-2 ${viewOnlyAccess("AccessState_AttackSurface") ? 'btn-disable' : 'hg-blue-btn'
                      } `}
                    // disabled={viewOnlyAccess("AccessState_ComplianceManager") ? true : false}
                    disabled={false}
                    onClick={() => {
                      setShowSupplierModal(true);
                    }}
                  >
                    {
                      viewOnlyAccess("AccessState_AttackSurface") &&
                      <img
                        alt=''
                        className='lockerDarkGreyImage mr-2 mb-1'
                        src='/images/settings-v2/locker-grey.svg'
                      />
                    }
                    Add
                  </Button>
                </div>
                <div className="bg-grey radius-8 border-parent">
                  <div
                    className={`  ${showIntegrationPopup ? "d-flex" : "d-none"
                      }     bg-hover-row border-item justify-content-between align-items-center  pointer request-item hover-on-show-parent p-3`}
                    onClick={() => { }}
                  >
                    {unAllocatedSuppliers?.length > 0 ? (
                      <>
                        {unAllocatedSuppliers?.length > 0 && (
                          <div
                            className="access-data-wrapper d-flex align-items-center py-2 pointer w-100 justify-content-between pr-2"
                            onClick={() => {
                              setShowRequestsModal(true);
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div className={` ${"image-group"}`}>
                                {unAllocatedSuppliers
                                  ?.slice(0, 3)
                                  ?.map((supplier) => {
                                    return (
                                      <>
                                        {supplier?.PotentialSupplierIcon?.toLowerCase() ===
                                          "noicon" ? (
                                          <span style={{ marginLeft: "-10px" }}>
                                            <ImageWithInitials
                                              width={32}
                                              background="purple"
                                              initials={
                                                supplier?.PotentialSupplierName?.toUpperCase()?.[0] ??
                                                "X"
                                              }
                                            />
                                          </span>
                                        ) : (
                                          <img
                                            alt=""
                                            src={
                                              supplier?.PotentialSupplierIcon
                                            }
                                            width={32}
                                            style={{
                                              border: "1px solid white",
                                            }}
                                            height={32}
                                            className="rounded-full"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null; // prevents looping
                                              currentTarget.src =
                                                "/images/user-logo.svg";
                                            }}
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                              <div className={`${"ml-2"}`}>
                                View potential suppliers
                              </div>
                            </div>

                            <img
                              alt=""
                              src="/images/chevron-right.svg"
                              className="ml-2"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className=" d-flex align-items-center">
                          <img
                            alt=""
                            src="/images/supply-chain/blue-plug.svg"
                            className="mr-2"
                          />
                          <div className="f-black">
                            Integrate your tools to identify your suppliers
                            automatically
                          </div>
                        </div>

                        <div className="f-blue d-flex align-items-center">
                          <div
                            className="pointer"
                            onClick={() => {
                              history.push("/integrations");
                            }}
                          >
                            Integrations
                          </div>
                          <img
                            src="/images/supply-chain/blue-redirect.svg"
                            alt=""
                            className="mx-2 pointer"
                            onClick={() => {
                              history.push("/integrations");
                            }}
                          />
                          <img
                            src="/images/small-cross-icon.svg"
                            alt=""
                            className="mx-2 pointer"
                            onClick={() => {
                              setShowIntegrationPopup(false);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {potentialSupplierList?.map(
                    (potentialSupplier, potentialSupplierIndex) => {
                      return (
                        <SupplierRow
                          setSupplierData={setSupplierData}
                          potentialSupplier={potentialSupplier}
                          setShowEditSupplierModal={setShowEditSupplierModal}
                          deletePotentialSupplier={deletePotentialSupplier}
                        />
                      );
                    }
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
      <AddSupplierModal
        show={showSupplierModal}
        hideModal={() => setShowSupplierModal(false)}
        refreshData={() => {
          refreshMenuItems();
          getPotentialSuppliers();
        }}
      />
      <EditPotentialSupplierModal
        show={showEditSupplierModal}
        hideModal={() => setShowEditSupplierModal(false)}
        refreshData={() => getPotentialSuppliers()}
        editSupplierData={supplierData}
      />
      <RequestsModal
        show={showRequestsModal}
        showPotentialSupplierPage={true}
        hideModal={() => setShowRequestsModal(false)}
        refreshData={() => {
          getPotentialSuppliers();
        }}
      />
    </div>
  );
};

export default Suppliers;

const SupplierRow = ({
  setSupplierData,
  potentialSupplier,
  setShowEditSupplierModal,
  deletePotentialSupplier,
}) => {
  return (
    <div
      className={`d-flex bg-hover-row border-item justify-content-between align-items-center request-item hover-on-show-parent p-3 ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'
        }`}
      onClick={() => {
        if (!viewOnlyAccess("AccessState_AttackSurface")) {
          setSupplierData(potentialSupplier);
          setShowEditSupplierModal(true);
        }
      }}
    >
      <div className="d-flex align-items-center">
        {potentialSupplier?.PotentialSupplierIcon?.toLowerCase() ===
          "noicon" ? (
          <ImageWithInitials
            width={32}
            background="purple"
            initials={
              potentialSupplier?.PotentialSupplierName?.[0]?.toUpperCase() ??
              "X"
            }
          />
        ) : (
          <img
            alt=""
            src={potentialSupplier?.PotentialSupplierIcon}
            width={32}
            height={32}
            className=" rounded-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/user-logo.svg";
            }}
          />
        )}

        <div className="d-flex flex-column ml-2">
          <div className="d-flex align-items-center">
            <div className="f-500 f-black">
              {potentialSupplier?.PotentialSupplierName}
            </div>
            {potentialSupplier?.IsEmailSent && (
              <div className="d-flex align-items-center f-darkgrey ml-2">
                <img
                  alt=""
                  className="mr-1"
                  src="/images/supply-chain/grey-tick-icon.svg"
                />

                {handleUnmanagedText(
                  potentialSupplier?.PotentialSupplierStatusText
                )}
              </div>
            )}
          </div>
          <div className="f-grey mr-2 f-500 d-flex">
            {/* If the source is Attcak Surface */}
            {potentialSupplier?.Type === "ASE" && (
              <div className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                <img
                  width={13}
                  src="/images/attack-surface/attack_surface_dashboard_icon.svg"
                  alt=""
                  className="mr-2"
                />
                Attack Surface
              </div>
            )}
            {/* If the source is SCM */}
            {potentialSupplier?.Type === "SCM" && (
              <div className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                <img
                  width={13}
                  src="/images/settings-v2/supply-chain.svg"
                  alt=""
                  className="mr-2"
                />
                Supply Chain Monitoring
              </div>
            )}
            {potentialSupplier?.IntegrationInfo?.IntegrationName && (
              <div className="d-flex align-items-center radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish">
                <img
                  width={16}
                  height={16}
                  src={integrationUtils.getImagePath(potentialSupplier?.IntegrationInfo?.IntegrationId, potentialSupplier?.IntegrationInfo?.ToolIcon)}
                  alt=""
                  className="mr-2"
                />
                {potentialSupplier?.IntegrationInfo?.IntegrationName}
              </div>
            )}
            {potentialSupplier?.PotentialSupplierService_List?.slice(0, 2).map(
              (item) => {
                return (
                  <div
                    className="d-flex radius-2 f-grey f-500 f-12 p-1 mx-1 bg-greyish"
                  // style={{ background: "#ebedf1" }}
                  >
                    # {item?.ServiceName}
                  </div>
                );
              }
            )}
            {potentialSupplier?.PotentialSupplierService_List?.length > 2 && (
              <div className="d-flex radius-2 f-grey f-500 f-12 p-1 bg-greyish">
                +{potentialSupplier?.PotentialSupplierService_List?.length - 2}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div
          className="mr-2 requestlist-dropdown"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown className="device-dropdown">
            <Dropdown.Toggle>
              <img
                alt=""
                src="/images/supply-chain/horizontal-dots.svg"
                className="hover-on-show-child"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                <Row>
                  <Col xs={12} className={` ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'}`}>
                    <div
                      className="drop-item pl-3"
                      onClick={(e) => {
                        if (!viewOnlyAccess("AccessState_AttackSurface")) {
                          e.target.closest(".device-dropdown").click();
                        }
                      }}
                    >
                      {
                        viewOnlyAccess("AccessState_AttackSurface") &&
                        <img
                          alt=''
                          className='lockerDarkGreyImage mr-2 mb-1'
                          src='/images/settings-v2/locker-grey.svg'
                        />
                      }
                      Send reminder
                    </div>
                  </Col>
                  <hr
                    className=" line-divider-light "
                    style={{
                      width: "70%",
                      margin: "auto",
                      /* margin-left: auto; */
                      marginTop: "12px",
                      marginBottom: "8px",
                    }}
                  />
                  <Col xs={12} className={` ${viewOnlyAccess("AccessState_AttackSurface") ? 'cursor-default' : 'pointer'}`}>
                    <div
                      className="drop-item pl-3"
                      onClick={(e) => {
                        if (!viewOnlyAccess("AccessState_AttackSurface")) {
                          deletePotentialSupplier(
                            potentialSupplier?.PotentialSupplierId
                          );
                          e.target.closest(".device-dropdown").click();
                        }
                      }}
                    >
                      {
                        viewOnlyAccess("AccessState_AttackSurface") &&
                        <img
                          alt=''
                          className='lockerDarkGreyImage mr-2 mb-1'
                          src='/images/settings-v2/locker-grey.svg'
                        />
                      }
                      Delete
                    </div>
                  </Col>
                </Row>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export function handleUnmanagedText(text) {
  if (text === null || text === undefined || text === "") return "";
  if (text?.toLowerCase() === "un managed") {
    return "Unmanaged";
  } else {
    return text;
  }
}
