import React from "react";
import { SearchCriteriaEnumASEIssues } from "../../../../constants/ase.constants";
import DeviceUserIssuesFilter from "../device-user-issues-filter/device-user-issues-filter.component";

const sections = [
  {
    Title: 'Issues',
    Items: [
      { Name: 'Old Devices (5yrs+)', key: 'showOldDevice' },
      { Name: 'Warranty Expired', key: 'showWarrantlyExpired' },
      { Name: 'End of Life', key: 'showEndOfLife' },
    ]
  },
  {
    Title: 'Filters',
    Items: [
      { Name: 'Operating System', enum: SearchCriteriaEnumASEIssues.Operating_System },
      { Name: 'Installed Date', enum: SearchCriteriaEnumASEIssues.Installed_Date },
      { Name: 'Warranty Expiry', enum: SearchCriteriaEnumASEIssues.Warrantly_Expiry },
      { Name: 'End of Life Date', enum: SearchCriteriaEnumASEIssues.End_Of_Life_Date },
    ]
  },
];

const DeviceIssuesFilter = ({ aseFilters, setASEFilters }) => (
  <DeviceUserIssuesFilter
    aseFilters={aseFilters}
    setASEFilters={setASEFilters}
    sections={sections}
  />
);

export default DeviceIssuesFilter;
