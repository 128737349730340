import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap"
import HorizontalDotsIcon from "../../../icons/horizontal-dots.icon";
import './sources-settings.styls.scss';
import TableIcon from "../../../icons/table.icon";
import SelectArrowIcon from "../../../icons/select-arrow.icon";
import SwitchButtonMulti from "../../../Theme/switchButtonMulti";
import ShownHiddenCols from "../../../Common/shown-hidden-cols/shown-hidden-cols.component";
import useDropdown from "../../../../hooks/dropdown.hook";

const SourcesSettingsDropdown = ({ 
  showSourceColumn, 
  setShowSourceColumn, 
  columns,
  setColumns, 
  modalRef,
  whiteListColumns
}) => {
  const [sourcesPosition, setSourcesPosition] = useState({ x: 0, y: 0 });
  const [columnSettings, setColumnSettings] = useState(false);
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });

  useEffect(() => {
    setColumnSettings(false);
  }, [isOpen]);

  useEffect(() => {
    if (!modalRef?.current) return;
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === "style") {
                const style = mutation.target.style;
                if (style.transform) {
                    const [x, y] = style.transform.match(/-?\d+/g);
                    setSourcesPosition({ x, y });
                }
            }
        });
    });
    observer.observe(modalRef.current, {
        attributes: true,
    });
    return () => {
        observer.disconnect();
    }
  }, [modalRef, modalRef.current]);

  return (
    <Dropdown show={isOpen} onToggle={() => setIsOpen(prev => !prev)} alignRight className="transparennt-wrapper-dropdown sources-settings">
      <Dropdown.Toggle ref={refBtn} className="cursor-pointer">
        <HorizontalDotsIcon width={12} fill="#616778" />
      </Dropdown.Toggle>
      <Dropdown.Menu ref={refMenu} className="menu hide-scrollbar">
          {columnSettings ? (
            <ShownHiddenCols
              columns={columns}
              setColumns={setColumns}
              draggableParentOffset={sourcesPosition}
              whiteListColumns={whiteListColumns}
            />
          ) : (
            <div>
              <h3 className="title f-darkgrey m-0">Settings</h3>
              <div className="d-flex flex-column">
                <div 
                  className="item d-flex align-items-center justify-content-between" 
                  onClick={() => setColumnSettings(true)}
                >
                  <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                    <TableIcon width={13} fill="#616778" />
                    <h3 className="mt-0 f-grey text">Columns settings</h3>
                  </div>
                  <div className="arrow-icon">
                    <SelectArrowIcon width={9} fill="#616778" />
                  </div>
                </div>
                <div 
                  className="item d-flex align-items-center justify-content-between"
                  onClick={() => setShowSourceColumn(!showSourceColumn)}
                >
                  <h3 className="m-0 f-grey text">Show source column</h3>
                  <div className="switcher" onClick={e => e.stopPropagation()}>
                    <SwitchButtonMulti
                      value={showSourceColumn}
                      id={'show-source-column'}
                      onChange={() => {
                        setShowSourceColumn(!showSourceColumn)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropdown.Menu>
    </Dropdown>
  );
}

export default SourcesSettingsDropdown;
