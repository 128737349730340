import { useEffect, useRef } from "react";

const VerticallyExpandInput = ({
    topLabel,
    maxLength,
    value = '',
    onChange = (value) => { }
}) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    return (
        <div className="my-2">
            {topLabel && (
                <div className="f-400 f-14 f-black">{topLabel}</div>
            )}
            <div
                className={`highlight-input-with-grey-border w-100 d-flex align-items-end p-2 radius-4 mt-1 ${maxLength && (value?.length >= maxLength) ? "input-error" : ""}`}
            >
                <textarea
                    ref={textareaRef}
                    tabIndex={0}
                    placeholder="Service description"
                    className="flex-grow-1 pl-1 pr-2 highlight-textarea-without-border"
                    value={value}
                    onChange={(e) => {
                        onChange(e?.target?.value);
                    }}
                    rows={1}
                    style={{
                        resize: "none",
                        overflow: "hidden",
                        border: 'none'
                    }}
                    onInput={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                />
                {maxLength && (
                    <div className="f-darkgrey">{value?.length}/{maxLength}</div>
                )}
            </div>
            {maxLength && (value.length >= maxLength) && (
                <div className="f-danger f-12 p-1">
                    Maximum description length of {maxLength} characters has been reached
                </div>
            )}
        </div>
    );
};

export default VerticallyExpandInput;