import { useState } from "react";
import { getAPIData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { BASE_API_PAGE_ENUMS } from "../../constants/apis.constants";

const useCyberGroupModalData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const { authTokens } = useAuth();

  const fetchData = () => {
    setIsLoading(true);
    getAPIData(BASE_API_PAGE_ENUMS.CurrentUserDetails, authTokens, {})
      .then((response) => {
        setData(response.AccessKeys);
      })
      .finally(() => setIsLoading(false));
  }

  return {
    isLoading,
    data,
    fetchData
  }
}

export default useCyberGroupModalData;
