import "./auth.layout.styles.scss";

const AuthLayout = ({ children }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="auth-layout ">
      {children}
      <div className="footer">
        <div className="f-12 f-500 f-darkgrey">
          © {currentYear} HighGround. &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/privacy_policy/",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </u>
          &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/highground-terms-and-conditions/",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </u>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
