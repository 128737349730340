import React, { useEffect } from "react";
import "./subscriptionOverview.scss";
import { Bar, Line } from "react-chartjs-2";
import { Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createStaticRanges } from "react-date-range"; 
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
import { Chart } from "chart.js";
import MyLineChart from "../../chartjs/my-line.chart";
const SubscriptionOverview = () => {

    const data = {
        labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
        datasets: [
          {
            data: [15, 40, 5, 45, 70, 50, 20],
            label: "Africa",
            borderColor: "#008fd4",
            backgroundColor: "#008fd409",
            fill: true,
            pointRadius: 0,
            borderWidth: 2,
          },
        ],
      };
      function average(ctx) {
        const values = ctx.chart.data.datasets[0].data;
        return values.reduce((a, b) => a + b, 0) / values.length;
      }
      const _annotation = {
        type: "line",
        borderColor: "black",
        borderDash: [6, 6],
        borderDashOffset: 0,
        borderWidth: 3,
        label: {
          enabled: true,
          content: (ctx) => "Average: " + average(ctx).toFixed(2),
          position: "end",
        },
        scaleID: "y",
        value: (ctx) => {
          console.log(ctx);
          return average(ctx);
        },
      };
      const options = {
        tension: 0.4,
        legend: {
          display: false,
        },
        annotation: {
          annotations: [
            {
              type: "line",
              borderColor: "red",
              borderDash: [1, 1],
              borderDashOffset: 0,
              borderWidth: 1,
              label: {
                enabled: true,
                content: (ctx) => "Average: " + average(ctx).toFixed(2),
                // position: "end",
              },
              scaleID: "x",
              value: (ctx) => {
                console.log(ctx);
                return average(ctx);
              },
            },
          ],
        },
        datalabels: {
          display: false,
        },
        style: {
          strokewidth: 1,
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        title: {
          display: false,
          //   text: "World population per region (in millions)",
        },
    
        // tooltips: {
        //   callbacks: {
        //     label: function (tooltipItem) {
        //       return "xcc";
        //     },
        //   },
        // },
        plugins: {
          legend: {
            display: false,
          },
    
          autocolors: true,
          annotation: {
            annotations: {
              _annotation,
              // line1: {
              //   type: "line",
              //   yMin: 60,
              //   yMax: 60,
              //   borderColor: "rgb(255, 99, 132)",
              //   borderWidth: 2,
              // },
            },
          },
          //   zoom: {
          //     zoom: {
          //       wheel: {
          //         enabled: true,
          //       },
          //       pinch: {
          //         enabled: true,
          //       },
          //       mode: "y",
          //     },
          //   },
          datalabels: {
            backgroundColor: "transparent",
            // backgroundColor: function (context) {
            //   return context.dataset.backgroundColor;
            // },
            // borderRadius: 4,
            color: "transparent",
            // font: {
            //   weight: "bold",
            // },
            // formatter: Math.round,
            // padding: 6,
          },
        },
      };





      const bar_data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July',"August","Sept."],
        datasets: [
          {
            label: 'Sales',
            backgroundColor: '#008FD4',
            borderColor: '#008FD4',
            borderWidth: 0,
            hoverBackgroundColor: '#008FD4',
            hoverBorderColor: '#008FD4',
            data: [65, 59, 80, 81, 56, 55, 40,50,20],

          },
        ],
      };
    
 
      const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
        startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
        startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
        startOflastYear: startOfYear(addYears(new Date(), -1)),
        endOflastYear: endOfYear(addYears(new Date(), -1)),
        startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
        endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
        startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
        startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
        startOfCurrentYear: startOfYear(new Date()),
        endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
      };
    
      const sideBarOptions = () => {
        const customDateObjects = [
          {
            label: "Current Year",
            range: () => ({
              startDate: defineds.startOfCurrentYear,
              endDate: defineds.endOfToday,
            }),
          },
          {
            label: "Previous Year",
            range: () => ({
              startDate: defineds.startOflastYear,
              endDate: defineds.endOfPreviousYear,
            }),
          },
          {
            label: "Last 12 Months",
            range: () => ({
              startDate: defineds.startOfLastTwelveMonth,
              endDate: defineds.endOfToday,
            }),
          },
          {
            label: "Last 6 Months",
            range: () => ({
              startDate: defineds.startOfLastSixMonth,
              endDate: defineds.endOfToday,
            }),
          },
          {
            label: "Last 90 Days",
            range: () => ({
              startDate: defineds.startOfLastNintyDay,
              endDate: defineds.endOfToday,
            }),
          },
          {
            label: "Last 30 Days",
            range: () => ({
              startDate: defineds.startOfLastThirtyDay,
              endDate: defineds.endOfToday,
            }),
          },
        ];
    
        return customDateObjects;
      };
    
      const sideBar = sideBarOptions();
    
      const staticRanges = [...createStaticRanges(sideBar)];
  
 


  return (
    <div className="px-4 subscription-overview">
      <div className="p-4" />
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div
          className="d-flex align-items-center"
        >
          <div className="account-chip  mr-2 selected">
            <div className="title mb-2">All Accounts</div>
            <div className="subtitle">44</div>
          </div>
          <div className="account-chip  mr-2">
            <div className="title mb-2">MSP’s Accounts</div>
            <div className="subtitle">44</div>
          </div>
          <div className="account-chip  mr-2">
            <div className="title mb-2">SAAS’s Accounts</div>
            <div className="subtitle">44</div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="f-500 f-grey mr-2">For period</div>
          <div className="d-flex align-items-center bg-grey radius-4">
          <Dropdown
                  className="date-filter-dropdown m-1 dashboardDateFilter"
                  alignRight
                  onToggle={(e) => {
                    
                  }}
                  // id={`${activeFilter?.filterType}_filterX`}
                >
                  <Dropdown.Toggle className="p-0">
                    <div className="d-flex align-items-center p-2 bg-grey f-grey f-500 radius-4">
                      Current Year
                      <img
                        src="/images/big-chevron-down.svg"
                        alt=""
                        className={`ml-2 ${
                          false ? "rotate180" : ""
                        }`}
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="p-0"
                    style={{
                      zIndex: 4,
                    }}
                  >
                    <DateRangePicker 
                      direction="vertical"
                      ranges={[ {}]}
                      staticRanges={staticRanges}
                      onChange={(ranges) => {
                     
                        if (
                          ranges.selection.startDate ===
                          ranges.selection.endDate
                        )
                          return;

                         
                        document.querySelector(".dashboardDateFilter").click();
                      }}
                    />
                    <div
                      className="pl-4 f-12 pointer border-bottom pt-0"
                      onClick={() => {
                         
                     
                      }}
                      style={{
                        padding: "0.7rem",
                      }}
                    >
                      Clear
                    </div>
                   
                  </Dropdown.Menu>
                </Dropdown>

                <div className="px-2">|</div>
          <div className="d-flex align-items-center pr-2">
            <img src="/images/attack-surface/date-icon.svg" alt="" className="mr-2"/>
            1 Jan, 2024 - 14 Mar, 2024

          </div>
          </div>
          
        </div>
      </div>

      <div className="d-flex bg-grey radius-8 mb-3">
        <div className="flex-1 border-right p-3">
            <div className="f-500 f-grey mb-2">Active</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>
        <div className="flex-1 border-right p-3">
            <div className="f-500 f-grey mb-2">Suspended</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>
        <div className="flex-1 border-right p-3">
            <div className="f-500 f-grey mb-2">Active trials</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>
        <div className="flex-1 border-right p-3">
            <div className="f-500 f-grey mb-2">Expired Trial</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>
        <div className="flex-1 border-right p-3">
            <div className="f-500 f-grey mb-2">Paid accounts</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>
        <div className="flex-1   p-3">
            <div className="f-500 f-grey mb-2">Overall Accounts</div>
            <div className="f-18 f-500 f-black">34</div>

        </div>

      </div>

      <div className="d-flex bg-grey radius-8 mb-3">
        <div className="w-30 border-right">
        <div className=" ">
          <div className="p-3">
            <div className="d-flex al;ign-items-center justify-content-between">
              <div className="f-500 f-grey">Logins</div>
              <div>
               
              </div>
            </div>
            <div className="f-18 f-500 f-black mt-1"> 156</div>
          </div>
          <div>
            {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
            <MyLineChart redraw={false} data={data} options={options} height={70} />
          </div>
        </div>
        </div>
        <div className="w-70">
        <div className=" ">
          <div className="p-3">
            <div className="d-flex al;ign-items-center justify-content-between">
              <div className="f-500 f-grey">Logins</div>
              <div>
               
              </div>
            </div>
            <div className="f-18 f-500 f-black mt-1"> 156</div>
          </div>
          <div>
            {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
            <Bar redraw={false} data={bar_data} options={options} height={30} />
          </div>
        </div>
        </div>

      </div>




      <div className="d-flex bg-grey radius-8 mb-3">
        <div className="w-30 border-right p-3">
          <div className="f-grey f-500 mb-3">Geography of accounts</div>
         {[
            { name: "United Kingdom", progress: 20 },
            { name: "India", progress: 80 },
            { name: "Germany", progress: 10 },
            { name: "Spain", progress: 5 },
            { name: "United Kingdom", progress: 20 },
            { name: "India", progress: 80 },
            { name: "Germany", progress: 10 },
            { name: "Spain", progress: 5 },
          ].map((item) => {
            return (
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center f-grey">
                    <img
                      src="/images/msp/dollar-icon.svg"
                      alt=""
                      className="mr-2"
                      width={13}
                      height={13}
                    />
                    {item.name}
                  </div>
                  <div className="f-darkgrey">10</div>
                </div>
                <div className="w-100">
                  <progress value={item.progress} max={100} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-70">
        <canvas id="canvas-geo"></canvas>
        </div>

      </div>

      <div className="d-flex bg-grey radius-8">
        <div className="border-right flex-1 p-3">
            <div className="f-500 f-grey mb-3">Top 10  active MSP's</div>

            {
                [1,1,1,1,1,1,1,1,1,1].map((_,i)=>{
                    return(
                        <div className="d-flex align-items-center mb-2">   
                        <div className="f-darkgrey mr-2"
                        style={{
                            width:"19px"
                        }}
                        >{i+1} </div> 
                        <div className="d-flex align-items-center f-darkgrey">
                        <img
              alt=""
              src={"/images/user-logo.svg"}
              className="  mr-2 rounded-full"
              width={16}
              height={16}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/user-logo.svg";
              }}
            /> M3Networks
                            
                            </div> 
                        
                          </div>
                    )
                })
            }
        </div>
        <div className="flex-1 p-3">
        <div className="f-500 f-grey mb-3">Top 10  active SAAS's</div>
        {
                [1,1,1,1,1,1,1,1,1,1].map((_,i)=>{
                    return(
                        <div className="d-flex align-items-center mb-2">   
                        <div className="f-darkgrey mr-2"
                        style={{
                            width:"19px"
                        }}
                        >{i+1} </div> 
                        <div className="d-flex align-items-center f-darkgrey">
                        <img
              alt=""
              src={"/images/user-logo.svg"}
              className="  mr-2 rounded-full"
              width={16}
              height={16}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/user-logo.svg";
              }}
            /> M3Networks
                            
                            </div> 
                        
                          </div>
                    )
                })
            }
        </div>
    
      </div>
    </div>
  );
};

export default SubscriptionOverview;
