import { useRef, useState } from 'react';
import EmailIcon from '../../icons/email.icon';
import UploadIcon from '../../icons/upload.icon';
import AuthChangeInput from '../auth-change-input.component';
import AuthTextInput from '../auth-text-input.component';
import { Button, Spinner } from 'react-bootstrap';
import ImageCropperModal from '../../settingsV2/personal-profile/personal-profile-modals/ImageCropperModal';
import WarningRoundedIcon from '../../icons/warning-rounded.icon';
import CreateYourAccountIcon from '../../icons/create-your-account.icon';
import './sections.styles.scss';
import PasswordStrengthInput from '../password-strength.input';

const SignUpPasswordSection = ({ 
  defaultEmail, 
  setError, 
  error, 
  setPasswordError, 
  passwordError,
  fullNameError,
  setFullNameError,
  setFormState,
  onProfileInfoSubmit,
  defaultPassword,
  defaultFullName,
  defaultProfileImg,
  defaultJobTitle,
  loading,
  setDisableCredFieldsHL,
  disableCredFieldsHL,
  showJobTitle,
  jobTitleError,
  setJobTitleError,
  curStep,
  amountsStep
}) => {
  const fileUpload = useRef();
  const [fullName, setFullName] = useState(defaultFullName);
  const [jobTitle, setJobTitle] = useState(defaultJobTitle);
  const [password, setPassword] = useState(defaultPassword);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [cropperModal, setCropperModal] = useState(false);
  const [userImage, setUserImage] = useState(defaultProfileImg);
  const [imgError, setImgError] = useState("");

  const setImage = (image) => {
    setUserImage(image);
  };

  const showCropperModal = () => {
    setCropperModal(true);
  };

  const hideCropperModal = () => {
    setCropperModal(false);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onProfileInfoSubmit(fullName, password, userImage, passwordStrength, jobTitle);
        }}
      >
        <div className="left-section">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="m-0 section-title f-black">
              Create your account
            </h2>
            {(curStep && amountsStep) && (
              <span className="section-step f-darkgrey">
                {curStep}/{amountsStep}
              </span>
            )}
          </div>
          <div className="block">
            <h3 className="m-0 block-title f-black">
              Profile
            </h3>
            <div className="d-flex align-items-center w-100" style={{ gap: '8px' }}>
              <img
                src={userImage || "/images/user-logo.svg"}
                width="40px"
                height="40px"
                style={{ borderRadius: '9999px' }}
              />
              <div>
                <h4 className="m-0 f-grey f-12 f-500" style={{ lineHeight: '18px' }}>
                  Profile picture
                </h4>
                {imgError ? (
                  <div className="d-flex error-section m-0" style={{ gap: '8px' }}>
                    <div style={{ marginTop: '-3px', padding: '0px 1px' }}>
                      <WarningRoundedIcon fill="#B2494A" />
                    </div>
                    <span className="error-message">
                      {imgError}
                    </span>
                  </div>
                ) : (
                  <p className="m-0 f-darkgrey f-12" style={{ lineHeight: '18px' }}>
                    Image files up to 10MB at least 400px
                  </p>
                )}
               
              </div>
              <input
                type="file"
                accept=".png, .jpeg, .webp, .avif"
                ref={fileUpload}
                className="d-none"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    const MAX_SIZE = 10 * 1024 * 1024;
                    if (e.target.files[0] && e.target.files[0].size > MAX_SIZE) {
                      setImgError("Up to 10MB files allowed");
                      return;
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);

                    reader.onload = function () {
                      setSelectedImage(reader.result);
                      showCropperModal();
                      e.target.value = null;
                      setImgError("");
                    };
                  }
                }}
              />
              <div className='flex-1 d-flex justify-content-end'>
                <button 
                  type='button' className='upload-btn'
                  onClick={() => {
                    fileUpload.current.click();
                  }}
                >
                  <UploadIcon height="12px" />
                  <span className='f-grey'>
                    Upload
                  </span>
                </button>
              </div>
            </div>
            <div>
              <label className="input-label f-grey" htmlFor="fullName">
                Full name
              </label>
              <AuthTextInput
                type="text"
                id="fullName"
                name="username"
                className="input"
                placeholder="Enter your full name"
                value={fullName}
                onChange={(event) => {
                  setFullName(
                    event.target.value
                  );
                  setFullNameError("");
                }}
                error={error || fullNameError}
                autoFocus
              />
            </div>
            {showJobTitle && (
              <div>
                <label className="input-label f-grey" htmlFor="jobtitle">
                  Job Title
                </label>
                <AuthTextInput
                  type="text"
                  id="jobtitle"
                  name="jobtitle"
                  className="input"
                  placeholder="Enter your job title"
                  value={jobTitle}
                  onChange={(event) => {
                    setJobTitle(
                      event.target.value
                    );
                    setJobTitleError("");
                  }}
                  error={error || jobTitleError}
                />
              </div>
            )}
          </div>
          <div className='block'>
            <h3 className="m-0 block-title f-black">
              Protect account
            </h3>
            <div>
              <label className="input-label f-grey" htmlFor="email">
                Email
              </label>
              <AuthChangeInput
                type="text"
                id="email"
                name="username"
                className="input email-section"
                value={defaultEmail}
                onChange={() => {
                  setFormState(0);
                }}
                icon={(
                  <div style={{ marginLeft: '2px' }}>
                    <EmailIcon fill="#BBBEC9" />
                  </div>
                )}
              />
            </div>
            <div>
              <label className="input-label f-grey" htmlFor="password">
                Create password
              </label>
              <PasswordStrengthInput 
                error={error}
                passwordError={passwordError}
                setPasswordError={setPasswordError}
                password={password}
                setPassword={setPassword}
                setError={setError}
                updatePasswordStrength={setPasswordStrength}
                disableCredFieldsHL={disableCredFieldsHL}
                setDisableCredFieldsHL={setDisableCredFieldsHL}
              />
            </div>
          </div>
          <div>
          <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type={loading ? "button" : "submit"}
              style={{ padding: "5px" }}
            >
              {loading ? (
                <span>
                  <Spinner
                    size="sm"
                    className="mr8px"
                    animation="border"
                  />{" "}
                  Authenticating
                </span>
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </div>
      </form>
      <div className="right-section w-100">
        <CreateYourAccountIcon 
          className='img-container' 
          fullName={fullName}
          email={defaultEmail}
          imgURL={userImage}
        />
      </div>
      <ImageCropperModal
        show={cropperModal}
        hideModal={hideCropperModal}
        profileImage={selectedImage}
        setProfileImage={setImage}
        uploadType={99}
      />
    </>
  );
}

export default SignUpPasswordSection;
