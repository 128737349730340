import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import './mspClientPriceCalculator.scss';
import Draggable from "react-draggable";
import SourcesSettingsDropdown from "./sources-settings/sources-settings.dropdown";
import useSourcesModalSortList from "./sources-modal-sort-list.data";
import Loader from "../../Common/loader";
import { FixedSizeList as List } from 'react-window';

const tabsHeaderTitles = {
    'Users': ['Sources', 'First Name', 'Sur name', 'Email', 'Job title'],
    'Devices': ['Sources', 'Host Name', 'Make', 'Model', 'Operating System', 'Serial', 'IP Address', 'Mac Address'],
    'Mobile Devices': ['Sources', 'Host Name', 'Make', 'Model', 'Operating System', 'Serial', 'IP Address', 'Mac Address'],
    'Servers': ['Sources', 'Host Name', 'Make', 'Model', 'Operating System', 'Serial', 'IP Address', 'Mac Address'],
    'Router/Firewalls': ['Sources', 'Make', 'Model', 'Model', 'Serial', 'Mac Address'],
    'Network Devices': ['Sources', 'Host Name', 'IP Address', 'Mac Address'],
    'Domains': ['Sources', 'Domain Name']
}

function colWidthByFieldKey(fieldKey) {
    if (fieldKey === 'Sources') {
        return '70px';
    }
    return '160px';
}

export const SourcesModal = ({
    show,
    hideModal,
    startData,
    tempUserDetailsData,
    _setAssetsFromIntegrations = () => { },
    setTempUserDetailsData = () => { },
    getPageData = () => { },
    saveAssets = () => { },
    clientId
}) => {
    const modalRef = useRef(null);
    const scrollRef = useRef(null);
    const [assetsListSelected, setAssetsListSelected] = useState(null)
    const [assetsList, setAssetsList] = useState([]);
    const [applyLoading, setApplyLoading] = useState(false);
    const [tabsHeaderVisibility, setTabsHeaderVisibility] = useState([]);
    const [tabsData, setTabsData] = useState([]);
    const tableHeaderRef = useRef(null);
    const { isLoading: isSortListLoading, data: sortList, getSourcesModalSortList } = useSourcesModalSortList();
    const [canScrollRight, setCanScrollRight] = useState(false);
    const [canScrollBottom, setCanScrollBottom] = useState(false);

    const showSourceColumn = useMemo(() => (
        tabsHeaderVisibility?.find((item) => item.FieldKey === 'Sources')?.Visibility ?? true
    ), [tabsHeaderVisibility]);

    const currentTabsData = useMemo(() => (
        tabsData?.find((item) => item?.ObjectDefId === assetsListSelected?.ObjectDefId)
    ), [tabsData, assetsListSelected]);

    const currentHeaders = useMemo(() => (
        tabsHeaderVisibility
            ?.filter((item) => tabsHeaderTitles[assetsListSelected?.Name]?.includes(item.FieldName))
            ?.filter((item) => item.Visibility)
            ?.sort((a, b) => a.SortOrder - b.SortOrder)
    ), [tabsHeaderVisibility, assetsListSelected]);

    const currentHeadersSettings = useMemo(() => (
        tabsHeaderVisibility
            ?.filter((item) => tabsHeaderTitles[assetsListSelected?.Name]?.includes(item.FieldName))
            ?.sort((a, b) => a.SortOrder - b.SortOrder)
            ?.map((item) => item.FieldKey)
    ), [tabsHeaderVisibility, assetsListSelected]);

    const currentHeaderWidth = useMemo(() => (
        currentHeaders?.reduce((acc, item) => {
            return acc + parseInt(colWidthByFieldKey(item.FieldKey));
        }, 0)
    ), [currentHeaders]);

    useEffect(() => {
        if (show) {
            getSourcesModalSortList({ clientId, sortList: '' });
        }
    }, [show]);

    useEffect(() => {
        if (isSortListLoading || !sortList) return;
        if (sortList.AssetSourceVisibility_List) {
            setTabsHeaderVisibility(sortList?.AssetSourceVisibility_List);
            setTabsData(sortList?.AssetSource_List);
            if (!assetsListSelected) {
                setAssetsListSelected(sortList?.AssetSource_List[0]);
            }
            let arr = sortList?.AssetSource_List
            arr = arr.map(obj => ({
                ...obj,
                isApplied: false
            }));
            setAssetsList(arr);
            _setAssetsFromIntegrations(sortList?.AssetSource_List);
        }
    }, [sortList, isSortListLoading]);

    const smListTable = document.getElementsByClassName('smListTable');

    useEffect(() => {
        if (!smListTable || !smListTable[0]) return;
        smListTable[0].addEventListener('scroll', function(event) {
            const horizontalScrollValue = event.target.scrollLeft;
            tableHeaderRef.current.scrollLeft = horizontalScrollValue;
            setCanScrollRight(event.target.scrollWidth - event.target.clientWidth - event.target.scrollLeft > 5);
            setCanScrollBottom(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop > 5);
        });
    }, [smListTable, smListTable?.[0]]);

    useEffect(() => {
        if (!smListTable || !smListTable[0]) return;
        setCanScrollRight(smListTable[0].scrollWidth - smListTable[0].clientWidth - smListTable[0].scrollLeft > 5);
        setCanScrollBottom(smListTable[0].scrollHeight - smListTable[0].clientHeight - smListTable[0].scrollTop > 5);
    }, [assetsListSelected, smListTable]);

    return (
        <Draggable 
            defaultPosition={{ x: 0, y: 0 }} 
            handle=".dragger-icon"
        >
            <div
                id="sources-modal"
                className="sources-modal"
                ref={modalRef}
            >
                {isSortListLoading && (
                    <div className="sources-loader">
                        <Loader />
                    </div>
                )}
                <div className="sources-modal-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div
                            className="dragger-icon"
                            style={{
                                position: "absolute",
                                background: "transparent",
                                width: "95%",
                                height: "30px",
                            }}
                        ></div>
                        <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">Sources</div>
                        <img
                            alt=""
                            height={9}
                            width={9}
                            src="/images/attack-surface/cross-icon.svg"
                            className="cursor-pointer"
                            onClick={() => {
                                hideModal();
                            }}
                        />
                    </div>
                </div>

                <div className="sources-modal-body">
                    <div className="modal-block d-flex align-items-center justify-content-between">
                        <div className="left-block">
                            <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2 px-3 py-2">Assets</div>
                            {
                                tabsData?.map((assetsItem) => {
                                    return (
                                        <div className={`d-flex align-items-center justify-content-between px-3 py-1 ${assetsListSelected?.ObjectDefId == assetsItem?.ObjectDefId && 'bg-grey'}`}>
                                            <div
                                                className="d-flex align-items-center justify-content-between pointer"
                                                style={{ width: '150px' }}
                                                onClick={() => {
                                                    setAssetsListSelected(assetsItem);
                                                }}
                                            >
                                                <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
                                                    {assetsItem?.Name}
                                                </div>
                                                <div
                                                    className="f-400 f-14 f-black line-height-22 letter-space-2"
                                                    style={{
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {assetsItem?.Count}
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    assetsListSelected?.ObjectDefId == assetsItem?.ObjectDefId ?
                                                        <div
                                                            className="f-500 f-14 txt-blue line-height-22 letter-space-2 pointer"
                                                            onClick={() => {
                                                                const updated_data = assetsList.map(
                                                                    (dataItem) => {
                                                                        if (dataItem?.ObjectDefId == assetsItem?.ObjectDefId) {
                                                                            return {
                                                                                ...dataItem,
                                                                                isApplied: true,
                                                                            };
                                                                        }
                                                                        return dataItem;
                                                                    }
                                                                );
                                                                setAssetsList(updated_data);

                                                                setApplyLoading(true);
                                                                const updatedData = tempUserDetailsData.map(
                                                                    (dataItem) => {
                                                                        if (dataItem?.Name?.replace("Per ", "") === assetsListSelected?.Name?.slice(0, -1)) {
                                                                            return {
                                                                                ...dataItem,
                                                                                Qty: assetsListSelected?.Count,
                                                                                IsUpdatedByAsset: true
                                                                            };
                                                                        }
                                                                        return dataItem;
                                                                    }
                                                                );
                                                                setTempUserDetailsData(updatedData);
                                                                setApplyLoading(false);
                                                                CallToast("Applied successfully", false, false, "v2style");
                                                            }}
                                                        >
                                                            {
                                                                assetsItem?.isApplied ? 'Applied' : 'Apply'
                                                            }
                                                        </div> : <div style={{ width: '38px', height: '22px' }}></div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="right-blocka">
                            <div className="radius-4" id="assetTable">
                                {canScrollRight && <div className="right-fade" />}
                                {canScrollBottom && <div className="bottom-fade" />}
                                <div className="top-block">
                                    <div className="hg-border d-flex align-items-center justify-content-between bg-grey px-3 py-1">
                                        <div className="d-flex align-items-center">
                                            <img
                                                alt=""
                                                height={12}
                                                width={12}
                                                src="/images/supply-chain/view-icon.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">Devices preview</div>
                                        </div>
                                        <SourcesSettingsDropdown
                                            columns={tabsHeaderVisibility} 
                                            showSourceColumn={showSourceColumn}
                                            modalRef={modalRef}
                                            whiteListColumns={currentHeadersSettings}
                                            setShowSourceColumn={(value) => {
                                                const updatedColumns = tabsHeaderVisibility.map((item) => {
                                                    if (item.FieldKey === 'Sources') {
                                                        return {
                                                            ...item,
                                                            Visibility: value,
                                                        };
                                                    }
                                                    return item;
                                                });
                                                setTabsHeaderVisibility(updatedColumns);
                                                getSourcesModalSortList({ clientId, sortList: updatedColumns });
                                            }}
                                            setColumns={(updatedColumns) => {
                                                setTabsHeaderVisibility(updatedColumns);
                                                getSourcesModalSortList({ clientId, sortList: updatedColumns });
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="bg-white d-flex align-items-stretch"
                                        style={{ overflow: 'hidden' }}
                                        ref={tableHeaderRef}
                                    >
                                        {currentTabsData?.AssetSourceRowData_List?.length != 0 && (
                                            currentHeaders?.map((item) => {
                                                const isSourcesCell = item.FieldName === 'Sources';
                                                const reduceWidth = showSourceColumn ? 70 : 0;
                                                const reduceLength = showSourceColumn ? 1 : 0;
                                                const colWidth = isSourcesCell || tableHeaderRef?.current?.clientWidth < currentHeaderWidth 
                                                    ? colWidthByFieldKey(item.FieldKey)
                                                    : `${((tableHeaderRef?.current?.clientWidth - reduceWidth) / (currentHeaders?.length - reduceLength))}px`;
                                                return (
                                                    <div
                                                        className="hg-border flex-1 f-500 f-12 f-darkgrey line-height-18 letter-space-2"
                                                        style={{
                                                            maxWidth: colWidth,
                                                            minWidth: colWidth,
                                                            width: colWidth,
                                                            minHeight: '100%',
                                                            wordWrap: 'break-word',
                                                            overflowWrap: 'break-word',
                                                            padding: '4px 8px',
                                                        }}
                                                    >
                                                        {item.FieldName}
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                                <div className="main-data-block hide-scrollbar" style={{ overflow: 'auto' }}>
                                    {
                                        <List
                                            height={300}
                                            itemCount={currentTabsData?.AssetSourceRowData_List?.length === 0 ? 1 : currentTabsData?.AssetSourceRowData_List?.length}
                                            itemSize={34.3}
                                            width='100%'
                                            itemData={currentTabsData?.AssetSourceRowData_List}
                                            className="hide-scrollbar smListTable"
                                            ref={scrollRef}
                                        >
                                            {(props) => {
                                                if (currentTabsData?.AssetSourceRowData_List.length === 0) {
                                                    return (
                                                        <div 
                                                            className="d-flex align-items-center justify-content-center f-400 f-14 greyText2 line-height-22 letter-space-2 p-4"
                                                            style={{ height: '300px' }}
                                                        >
                                                            <span>
                                                                No data here yet
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                                return (
                                                    rowRenderer({ ...props, currentHeaders, tableHeaderRef, currentHeaderWidth, showSourceColumn })
                                                )
                                            }}
                                        </List>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sources-modal-footer d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center justify-content-end "
                    >
                        <Button
                            className="hg-cancel-btn mr-2"
                            onClick={() => {
                                hideModal();
                                setTempUserDetailsData(startData);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="hg-blue-btn"
                            onClick={() => {
                                const updatedArray = tempUserDetailsData.map(item1 => {
                                    const item2 = assetsList.find(item2 => item2?.Name?.slice(0, -1) === item1?.Name?.replace("Per ", ""));
                                    return item2 ? { ...item1, Qty: item2.Count, IsUpdatedByAsset: true } : item1;
                                });

                                setTempUserDetailsData(updatedArray);
                                CallToast("Applied successfully", false, false, "v2style");
                                hideModal();
                            }}
                        >
                            Apply all
                        </Button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

const rowRenderer = ({ index, style, data, currentHeaders, tableHeaderRef, currentHeaderWidth, showSourceColumn }) => {
    const itemBlock = data[index];
    
    return (
        <div className="d-flex align-items-stretch" style={{ ...style }}>
            {currentHeaders?.map((headerItem, headerIndex) => {
                const isSourcesCell = headerItem.FieldName === 'Sources';
                const reduceWidth = showSourceColumn ? 70 : 0;
                const reduceLength = showSourceColumn ? 1 : 0;
                const colWidth = isSourcesCell || tableHeaderRef?.current?.clientWidth < currentHeaderWidth 
                    ? colWidthByFieldKey(headerItem.FieldKey)
                    : `${((tableHeaderRef?.current?.clientWidth - reduceWidth) / (currentHeaders?.length - reduceLength))}px`;
                const itemData = itemBlock.AssetSourceColumnData_List.find((item) => item.FieldName === headerItem.FieldName);
                const sources = isSourcesCell ? [
                    itemData
                ] : [];
                return (
                    <div
                        style={{
                            maxWidth: colWidth,
                            minWidth: colWidth,
                            width: colWidth,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100%',
                        }}
                    >
                        {itemData != undefined ? (
                            <div
                                className="hg-border f-400 f-14 greyText2 line-height-22 letter-space-2 px-3 py-1"
                                style={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    wordWrap: 'break-word',
                                    overflowWrap: 'break-word',
                                    height: '100%',
                                }}
                            >
                                <div
                                    className="d-flex"
                                    style={{
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        height: '100%',
                                    }}
                                >
                                    {isSourcesCell ? (
                                        <OverlayTrigger
                                            placement="bottom"
                                            trigger={["hover", "focus"]}
                                            delay={{ show: 200, hide: 250 }}
                                            className="fix-tooltip"
                                            container={() => document.getElementById("sources-modal")}
                                            overlay={
                                                <Tooltip
                                                    id={`sm-tooltip-assets`} 
                                                    style={{ position: 'relative', zIndex: 10000,  }}
                                                >
                                                    <div className="d-flex flex-column" style={{ gap: '4px' }}>
                                                        {
                                                            sources?.map((hoverItem, index) => {
                                                                return (
                                                                    <div className="d-flex">
                                                                        <img
                                                                            alt=""
                                                                            height={16}
                                                                            width={16}
                                                                            src={`/images/attack-surface/integrations/${hoverItem?.Icon}.webp`}
                                                                            className="mr-1"
                                                                            style={{ borderRadius: '2px' }}
                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null;
                                                                                currentTarget.src =
                                                                                "/images/attack-surface/integrations/generic.svg";
                                                                            }}
                                                                        />
                                                                        <div
                                                                            className="f-400 f-12 f-white line-height-18 letter-space-2"
                                                                        >
                                                                            {hoverItem?.FieldValue}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <div className="d-flex align-items-center sources-icons-pair">
                                                {sources.map((item, index) => {
                                                    const showMore = index === 2 && sources?.length > 3;
                                                    const moreCount = sources?.length - 3;
                                                    return (
                                                        <div className="my-1" style={{
                                                            padding: '1px',
                                                            backgroundColor: '#fff',
                                                            borderRadius: '100%',
                                                            marginLeft: index !== 0 ? '-8px' : 0,
                                                            display: 'flex',
                                                            alignSelf: 'center'
                                                        }}>
                                                            {showMore ? (
                                                                <div 
                                                                    className="d-flex align-items-center justify-content-center"
                                                                    style={{
                                                                        backgroundColor: '#D4D6DD',
                                                                        borderRadius: '100%',
                                                                        width: '16px',
                                                                        height: '16px',
                                                                        fontSize: '8px'
                                                                    }}
                                                                >
                                                                    <span className="f-grey f-500">
                                                                        {`+${moreCount}`}
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <img 
                                                                    alt=""
                                                                    height={16}
                                                                    width={16}
                                                                    src={`/images/attack-surface/integrations/${item?.Icon}.webp`}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src =
                                                                        "/images/attack-surface/integrations/generic.svg";
                                                                    }}
                                                                    style={{ 
                                                                        borderRadius: '100%',
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </OverlayTrigger>
                                    ) : itemData.FieldValue ? (
                                        <OverlayTrigger
                                            placement="bottom"
                                            trigger={["hover", "focus"]}
                                            delay={{ show: 200, hide: 250 }}
                                            container={() => document.getElementById("sources-modal")}
                                            overlay={
                                                <Tooltip
                                                    id={`tooltip-assets`} 
                                                    style={{ position: 'relative', zIndex: 10000,  }}
                                                >
                                                    <span>
                                                    {itemData.FieldValue}
                                                    </span>
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                className=""
                                                style={{
                                                flex: "1 1 100%",
                                                minWidth: 0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                    >
                                                {itemData.FieldValue}
                                                </div>
                                            </div>
                                        </OverlayTrigger>
                                    ) : (
                                        <div />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div
                                className="hg-border f-400 f-14 greyText2 line-height-22 letter-space-2 px-3 py-1"
                                style={{
                                    flexGrow: 1,
                                    display: 'block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    height: '100%',
                                }}
                            >
                                {" "}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SourcesModal;
