import { useState } from "react";
import useApiActions from "../../../../hooks/api-actions.hook";

const useNoteSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { submitQuestionNote } = useApiActions();
  
  const submitNote = ({ noteValue, technologyEnum, clientId }) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      submitQuestionNote({ noteValue, technologyEnum, clientId })
          .finally(() => {
            setIsLoading(false);
            resolve();
          });
    });
  }

  return {
    isLoading,
    submitNote
  }
}

export default useNoteSection;
