import React from "react";
import SwitchButtonMulti from "../../Theme/switchButtonMulti";

const SettingsOption = ({ title, switchId, onChange, value, children, titleComponent, disabled }) => (
  <div className="border-bottom section">
    <div className="d-flex align-items-center justify-content-between px-1">
      {titleComponent ? titleComponent : (
        <div className="f-14 f-400 f-black">{title}</div>
      )}
      <div className="switch">
        {" "}
        <SwitchButtonMulti
          value={value}
          id={switchId}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
    {children ? children : null}
  </div>
);

export default SettingsOption;
