import React, { useEffect, useState } from "react";
import HighgroundDatePicker from "../../../test/TestComponent";
import { CustomCyberComplianceGauge } from "../../mspSell/mspPriceCalculator/mspPriceCalculatorComponents/CyberComplianceGauge";
import { SecOpsCircularProgress } from "../SecOps";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import {
  CallToast,
  CustomRightSideToastForReport,
} from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { BlueComingSoonTag } from "../../../supplyChainMonitoring/Index";
import AuditModal from "../audit/AuditModal";
import { NO_ACCESS_TEXT, getAccessStateValue } from "../../../App/App";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";

const SecOpsHomeScreen = () => {
  const history = useHistory();
  let moment = require('moment');
  const { authTokens } = useAuth();
  const [showSecurityStackBanner, setShowSecurityStackBanner] = useState(true);
  const [showAuditModal, setShowAuditModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [pageData, setPageData] = useState({});

  const [auditId, setAuditId] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [nextAuditDataLoader, setNextAuditDataLoader] = useState(false)


  useEffect(() => {
    setLoading(true);
    getPageData();
  }, []);

  // useEffect(() => {
  //   console.log('-----------------------------here----------------------------------')
  //   if (nextAuditDate != formatDate(pageData?.NextAudit)) {
  //     console.log('nextAuditDate - ', nextAuditDate)
  //   }
  // }, [nextAuditDate])

  async function downloadGapAnalytics(event) {
    // CallToast("Report will be downloaded shortly.", false, false, "v2style");
    CustomRightSideToastForReport(
      "Report is being created and will be sent to you by email shortly."
    );
    await getAPIData(`${655}`, authTokens, {})
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Report has been sent successfully",
            false,
            false,
            "v2style"
          );
          // downloadPDF(response?.FileURL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [orgUrl, setOrgUrl] = useState("");
  async function getPageData() {
    await getAPIData("654", authTokens, { optionEnum1: 0 })
      .then((response) => {
        setShowSecurityStackBanner(response?.ShowBanner);
        if (response?.mr?.Success) {
          setOrgUrl(response?.OrgUrl);
          setPageData(response);
          setAuditId(response?.ObjId_Audit)
          // setNextAuditDate(formatDate(response?.NextAudit))
          localStorage.setItem("auditId", response?.ObjId_Audit);
          if (response?.ObjId_Audit == 0) {
            setIsNew(true);
          }
          setNextAuditDataLoader(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  function processPercentage(percentageString) {
    const parsedValue = parseFloat(percentageString);

    if (!isNaN(parsedValue)) {
      const integerValue = Math.floor(parsedValue);

      return integerValue.toString();
    }

    return percentageString; // Return original string if parsing fails
  }

  function toggleGetStartedBlock(_ConfigValue) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateGettingStarted", {
        ConfigValue: _ConfigValue,
        ConfigType: 1,
      })
        .then((response) => {
          if (response?.Success) {
            resolve(response);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          console.log(err, "error in PSAGlobalConfig");
          reject(false);
        });
    });
  }

  async function createSecOpsAudit() {
    await postData(authTokens, "CreateSecOpsAudit", {
      ObjId_PotentialSupplier: 0,
    })
      .then((response) => {
        if (response?.Success) {
          setAuditId(response?.EntityId)
          localStorage.setItem("auditId", response?.EntityId);
          CallToast("Audit created successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function updateSecOpsAuditDate(date) {
    await postData(authTokens, "UpdateSecOpsAuditDate", {
      objId_Secops: Number(localStorage.getItem("auditId")) ?? 0,
      SecopsNextAuditDate: date
    })
      .then((response) => {
        if (response?.Success) {
          getPageData().then(() => {
            CallToast("Date changed successfully", false, false, "v2style");
          })
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }


  function formatDate(date) {
    let inputDate = moment(date, "D MMM YYYY");
    let outputDateString = inputDate.format("DD/MM/YYYY");

    console.log(outputDateString);
    return outputDateString;
  }

  return (
    <>
      {loading ? (
        <div className="w-70 mx-auto">
          <SecOpsHomeScreenLoader />
        </div>
      ) : (
        <div className="d-flex align-items-start w-70 mx-auto">
          <div className="w-70 mx-3">
            {showSecurityStackBanner && (
              <div className="radius-8 bg-grey d-flex align-items-center justify-content-between p-3 mt-4">
                <div className="d-flex align-items-center">
                  <img
                    alt=""
                    src="/images/msp/score-info-icon.svg"
                    className="mr-2"
                  />
                  Don't forget to define your MSPs security stack
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="f-blue f-500 w-max-content pointer"
                    onClick={() => {
                      history.push("/mspv2/securitystack");
                    }}
                  >
                    Go to Security Stack
                  </div>
                  <img
                    alt=""
                    className="ml-3 pointer"
                    src="/images/attack-surface/cross-icon.svg"
                    onClick={() => {
                      setShowSecurityStackBanner(false);
                      toggleGetStartedBlock(false);
                    }}
                  />
                </div>
              </div>
            )}

            {/* Accredetions Block */}
            <div className="f-14 f-600 f-black mt-4 mb-2">
              {pageData?.SecOpsDashboardData_List?.[0]?.SectionName}
            </div>
            <div className="border-parent radius-8">
              {pageData?.SecOpsDashboardData_List?.[0]?.SecOpsData?.map(
                (item, index) => {
                  return (
                    <div
                      className="d-flex flex-column border-item"
                      onClick={() => {
                        if (getAccessStateValue("AccessState_SecOps") > 1) {
                          if (item?.Url) {
                            history.push(item?.Url);
                          }
                        }
                      }}
                    >
                      <div className={`d-flex align-items-center justify-content-between w-100 px-4  py-3 bg-hover-row ${getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer' : 'cursor-default'
                        }`}>
                        <div
                          className="d-flex flex-column "
                          style={{ width: "75%" }}
                        >
                          <div className="d-flex align-items-baseline ">
                            <p className="f-500 m-0 f-black">{item?.Title}</p>
                          </div>
                          <p
                            className="f-12 f-darkgrey "
                          // style={{ marginLeft: "0.83rem" }}
                          >
                            {item?.Description}
                          </p>
                        </div>
                        <div>
                          {
                            getAccessStateValue("AccessState_SecOps") > 1 ?
                              <img
                                alt=""
                                src="/images/attack-surface/chevron-right.svg"
                              /> : <AttackSurfaceTooltip
                                disabled={
                                  getAccessStateValue("AccessState_SecOps") === 99
                                }
                                bottomLeft={true}
                                topLeft={false}
                                content={<span>{NO_ACCESS_TEXT}</span>}
                              >
                                <img
                                  alt=""
                                  width={14}
                                  src="/images/settings-v2/locker-dark-grey.svg"
                                />
                              </AttackSurfaceTooltip>
                          }
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {/* MSP OPerational Security Block */}
            <div className="f-14 f-600 f-black mt-4 mb-2">
              {pageData?.SecOpsDashboardData_List?.[1]?.SectionName}
            </div>
            <div className="border-parent radius-8">
              {pageData?.SecOpsDashboardData_List?.[1]?.SecOpsData?.map(
                (item, index) => {
                  return (
                    <div
                      className="d-flex flex-column border-item pointer"
                      onClick={() => {
                        if (getAccessStateValue("AccessState_SecOps") > 1) {
                          history.push(item?.Url);
                        }
                      }}
                    >
                      <div className={`d-flex align-items-center justify-content-between w-100 px-4  py-3 bg-hover-row ${getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer' : 'cursor-default'
                        }`}>
                        <div
                          className="d-flex flex-column "
                          style={{ width: "75%" }}
                        >
                          <div className="d-flex align-items-baseline ">
                            <p className="f-500 m-0 f-black">{item?.Title}</p>
                          </div>
                          <p className="f-12 f-darkgrey ">
                            {item?.Description}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="mr-5">
                            <SecOpsCircularProgress
                              progress={processPercentage(item?.Percentage)}
                            />
                          </div>
                          {
                            getAccessStateValue("AccessState_SecOps") > 1 ?
                              <img
                                alt=""
                                src="/images/attack-surface/chevron-right.svg"
                              /> : <AttackSurfaceTooltip
                                disabled={
                                  getAccessStateValue("AccessState_SecOps") === 99
                                }
                                bottomLeft={true}
                                topLeft={false}
                                content={<span>{NO_ACCESS_TEXT}</span>}
                              >
                                <img
                                  alt=""
                                  width={14}
                                  src="/images/settings-v2/locker-dark-grey.svg"
                                />
                              </AttackSurfaceTooltip>
                          }
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {/* MSP Security Baseline Block */}
            <div className="f-14 f-600 f-black mt-4 mb-2">
              {pageData?.SecOpsDashboardData_List?.[2]?.SectionName}
              {/* MSP Security Baseline */}
            </div>
            <div className="border-parent radius-8">
              {pageData?.SecOpsDashboardData_List?.[2]?.SecOpsData?.map(
                (item, index) => {
                  return (
                    <div
                      className="d-flex flex-column border-item pointer"
                      onClick={() => {
                        if (getAccessStateValue("AccessState_SecOps") > 1) {
                          history.push('/mspv2/secops/security-baseline');
                        }
                      }}
                    >
                      <div className={`d-flex align-items-center justify-content-between w-100 px-4  py-3 bg-hover-row ${getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer' : 'cursor-default'
                        }`}>
                        <div
                          className="d-flex flex-column "
                          style={{ width: "75%" }}
                        >
                          <div className="d-flex align-items-baseline ">
                            <p className="f-500 m-0 f-black">
                              {item?.Title}
                            </p>
                          </div>
                          <p className="f-12 f-darkgrey ">
                            {item?.Description}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="mr-5 f-darkgrey f-10 f-500 d-flex justify-content-center align-items-center" style={{
                            height: '40px',
                            width: '40px'
                          }}>
                            {item?.Percentage}
                          </div>
                          {
                            getAccessStateValue("AccessState_SecOps") > 1 ?
                              <img
                                alt=""
                                src="/images/attack-surface/chevron-right.svg"
                              /> : <AttackSurfaceTooltip
                                disabled={
                                  getAccessStateValue("AccessState_SecOps") === 99
                                }
                                bottomLeft={true}
                                topLeft={false}
                                content={<span>{NO_ACCESS_TEXT}</span>}
                              >
                                <img
                                  alt=""
                                  width={14}
                                  src="/images/settings-v2/locker-dark-grey.svg"
                                />
                              </AttackSurfaceTooltip>
                          }
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {/* MSP Security Processes  Block*/}
            <div className="f-14 f-600 f-black mt-4 mb-2">
              {pageData?.SecOpsDashboardData_List?.[3]?.SectionName}
            </div>
            <div className="border-parent radius-8">
              {pageData?.SecOpsDashboardData_List?.[3]?.SecOpsData?.map(
                (item, index) => {
                  return (
                    <div
                      className="d-flex flex-column border-item"
                      onClick={() => {
                        if (getAccessStateValue("AccessState_SecOps") > 1) {
                          history.push(
                            `/mspv2/secops/security-processes?selectedSideBarTab=${item?.ObjectId}`
                          );
                        }
                      }}
                    >
                      <div className={`d-flex align-items-center justify-content-between w-100 px-4  py-3 bg-hover-row ${getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer' : 'cursor-default'
                        }`}>
                        <div
                          className="d-flex flex-column "
                          style={{ width: "75%" }}
                        >
                          <div className="d-flex align-items-baseline ">
                            <img
                              alt=""
                              src="/images/attack-surface/yellow-dot.svg"
                              className="mr-1"
                              style={{
                                visibility: `${!item?.ShowOrangeDot ? "hidden" : "visible"
                                  }`,
                              }}
                            />
                            <p className="f-500 m-0 f-black">{item?.Title}</p>
                          </div>
                          <p
                            className="f-12 f-darkgrey text-ellipsis"
                            style={{ marginLeft: "0.83rem" }}
                          >
                            {item?.Description}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="mr-5">
                            <SecOpsCircularProgress
                              progress={processPercentage(item?.Percentage)}
                            />
                          </div>
                          {
                            getAccessStateValue("AccessState_SecOps") > 1 ?
                              <img
                                alt=""
                                src="/images/attack-surface/chevron-right.svg"
                              /> : <AttackSurfaceTooltip
                                disabled={
                                  getAccessStateValue("AccessState_SecOps") === 99
                                }
                                bottomLeft={true}
                                topLeft={false}
                                content={<span>{NO_ACCESS_TEXT}</span>}
                              >
                                <img
                                  alt=""
                                  width={14}
                                  src="/images/settings-v2/locker-dark-grey.svg"
                                />
                              </AttackSurfaceTooltip>
                          }
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            {/* Manage Your Own Company Security  Block*/}
            <div className="f-14 f-600 f-black mt-4 mb-2">
              Manage your MSPs own security
            </div>
            <div className="d-flex justify-content-between radius-8 bg-grey">
              <div className="p-3">
                <div className="f-14 f-600 f-black mb-2">
                  Free NFR account for your own MSP
                </div>
                <div className="f-12 f-darkgrey">
                  All MSPs get a free NFR for internal use - so don't forget to
                  use this to manage and improve your own cyber security!
                  <br />
                  <br />
                  {/* Don't forget to create your own HighGround.io account and
                  manage your own security through highground too - this is
                  still essential! */}
                  <br />
                  <br />
                </div>
                <div
                  className={`${getAccessStateValue("AccessState_Clients") < 3 ? 'f-darkgrey cursor-default' : 'f-blue pointer'}`}
                  onClick={() => {
                    if (getAccessStateValue("AccessState_Clients") < 3) {
                      return
                    }
                    history.push(orgUrl, {
                      backUrl: "/mspv2/secops",
                    });
                  }}
                >
                  Manage our own security
                  {
                    getAccessStateValue("AccessState_Clients") < 3 ?
                      <img
                        height={16}
                        width={16}
                        alt=""
                        src="/images/attack-surface/small-lock.svg"
                        className="ml-1 mb-1"
                      />
                      :
                      <img
                        src="/images/supply-chain/blue-redirect.svg"
                        alt=""
                        className="mx-2 pointer"
                        onClick={() => { }}
                      />
                  }
                </div>
              </div>
              <div className="d-flex align-items-end">
                <img
                  width={200}
                  src="/images/msp/dashboard-ui.webp"
                  alt=""
                  className=""
                />
              </div>
            </div>

            <div className="p-4" />
          </div>
          <div
            className={`w-30 bg-grey radius-8 mx-3 border-parent position-relative `}
            style={{
              marginTop: showSecurityStackBanner ? "21px" : "48px",
            }}
          >
            {/* <div
              className="position-absolute"
              style={{
                top: "17px",
                right: "17px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-center rounded-full w-fit-content f-15 f-500"
                style={{
                  background: "#D6EDF8",
                  color: "#008FD4",
                  padding: "0px 6px",
                }}
              >
                Coming Soon
              </div>
            </div> */}
            <div
              className="border-item p-"
              style={
                {
                  // opacity: 0.45,
                  // pointerEvents: "none",
                }
              }
            >
              <div
                style={
                  {
                    // opacity: 0.45,
                  }
                }
              >
                <div className="f-grey f-500 m-3">Your score</div>

                <div className="w-fit-content mx-auto">
                  <CustomCyberComplianceGauge
                    value={Number(pageData?.Score ?? 0)}
                    hideComplianceText
                    hidePercentIcon
                    width={180}
                  />
                </div>
                <hr />
                <div className="f-darkgrey ml-3 mb-1">Audit Progress</div>
                <div className="d-flex align-items-center mx-3">
                  <div className="hg-progress-wrapper w-100">
                    <progress
                      min="0"
                      value={Number(pageData?.ProgressPercentage)}
                      max="100"
                    />
                  </div>

                  <div className="f-darkgrey ml-2">
                    {pageData?.ProgressPercentage}%
                  </div>
                </div>
                <hr />
                <div className="f-darkgrey ml-3 mb-2">Internal Audit</div>
                <div className="p-3">
                  <div className="mb-2">
                    <span className="f-darkgrey">Last:</span> &nbsp;
                    <span>{pageData?.LastAudit} </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div>
                        <span className="f-darkgrey">Next:</span> &nbsp;
                        <span>{pageData?.NextAudit} </span>
                      </div>
                      {
                        nextAuditDataLoader ? <div className="msp-service-update-loader ml-2 mr-1"></div> : <></>
                      }
                    </div>
                    {
                      getAccessStateValue("AccessState_SecOps") > 1 &&
                      <div className="change-due-date-picker">
                        <HighgroundDatePicker
                          disablePreviousDates={true}
                          DropdownMenuStyle={{ zIndex: 5 }}
                          selected={
                            formatDate(pageData?.NextAudit) ??
                            ""
                          }
                          placeholder={"Choose due date"}
                          // icon={"/images/attack-surface/date-icon.svg"}
                          onChange={(e) => {
                            setNextAuditDataLoader(true);
                            updateSecOpsAuditDate(e);
                            // setNewDueDate(e);
                            // updateDueDate(e);
                          }}
                          id={"fhgjfgvjhfhj"}
                          isError={false}
                        >
                          <div className="f-blue f-500">
                            Change
                            </div>
                        </HighgroundDatePicker>
                      </div>
                    }
                    {/* <div className="f-blue f-500 pointer">Change</div> */}
                  </div>
                </div>

                <div
                  className={`border1 p-2 mx-3 radius-4 f-500 f-grey text-center ${getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer hg-blue-btn' : 'cursor-default btn-disable'
                    }`}
                  // style={{
                  //   backgroundColor: '#008FD4',
                  //   color: '#FFFFFF'
                  // }}
                  onClick={() => {
                    if (getAccessStateValue("AccessState_SecOps") > 1) {
                      // saveTemplate();
                      // setShowAuditModal(true);
                      if (auditId == 0) {
                        createSecOpsAudit();
                      }
                      history.push("/mspv2/audit?type=AccreditationsTrainingFrameworks", {
                        backUrl: `/mspv2/secops`,
                        auditId: auditId,
                        isNew: isNew
                      });
                    }
                  }}
                >
                  {
                    getAccessStateValue("AccessState_SecOps") < 2 &&
                    <img
                      alt=""
                      className="mr-2 mb-1"
                      width={14}
                      src="/images/settings-v2/locker-dark-grey.svg"
                    />
                  }
                  {
                    Number(pageData?.ProgressPercentage) == 0 || Number(pageData?.ProgressPercentage) == 100 ? 'Run ' : 'Continue '
                  }audit
                </div>

                {/* <Button
                  className="hg-blue-btn mx-3 d-flex"
                  onClick={() => {
                    // saveTemplate();
                    // setShowAuditModal(true);
                  }}
                >
                  Run audit
                </Button> */}
                <div
                  className={`pt-3 mx-3 radius-4 f-500 f-grey text-center ${
                    getAccessStateValue("AccessState_SecOps") > 1 ? 'pointer' : 'cursor-default'
                  }`}
                  onClick={() => {
                    if (getAccessStateValue("AccessState_SecOps") > 1) {
                      setShowAuditModal(true);
                    }
                  }}
                >
                  {
                    getAccessStateValue("AccessState_SecOps") < 2 &&
                    <img
                      alt=""
                      className="mr-2 mb-1"
                      width={14}
                      src="/images/settings-v2/locker-dark-grey.svg"
                    />
                  }
                  Audit history
                </div>
                <hr />
                <div className="mx-3">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="f-darkgrey">Following Best Practice</div>
                    <div>{pageData?.FollowingBestPractice} </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="f-darkgrey">Areas for Improvement</div>
                    <div>{pageData?.AreasForImprovement} </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="f-darkgrey">Areas of Concern</div>
                    <div>{pageData?.AreasOfConcern} </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex f-500 f-black justify-content-center p-2 mt-3 pointer"
                onClick={() => {
                  downloadGapAnalytics();
                }}
                style={{
                  opacity: 1,
                }}
              >
                <div className="w-fit-content mb-2 pointer">
                  <img
                    className="mr-2"
                    src="/images/msp/gap-analysis-icon.svg"
                    alt=""
                  />
                  Print Security Recommendations
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <AuditModal
        show={showAuditModal}
        hideModal={() => setShowAuditModal(false)}
      />
    </>
  );
};

export default SecOpsHomeScreen;

const SecOpsHomeScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="4" ry="4" width="67%" height="90" />
      <rect x="70%" y="70" rx="8" ry="8" width="29%" height="600" />

      <rect x="0%" y="212" rx="4" ry="4" width="67%" height="90" />
      <rect x="0%" y="304" rx="4" ry="4" width="67%" height="90" />
      <rect x="0%" y="396" rx="4" ry="4" width="67%" height="90" />

      <rect x="0%" y="526" rx="4" ry="4" width="67%" height="90" />

      <rect x="0%" y="656" rx="4" ry="4" width="67%" height="90" />
      <rect x="0%" y="748" rx="4" ry="4" width="67%" height="90" />
      <rect x="0%" y="840" rx="4" ry="4" width="67%" height="90" />
      <rect x="0%" y="748" rx="4" ry="4" width="67%" height="90" />
    </ContentLoader>
  );
};
