import { useEffect, useState } from "react";
import { useAuth } from "../context/Auth";
import ContentLoader from "react-content-loader";
import { getAPIData, postData } from "../../Utils/commonApi";
import "./marketplace.scss"
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ComingSoonLoader from "../supplyChainMonitoring/ComingSoonLoader";
import Header from "../Header";

const MarketplaceComingSoon = ({ isMspClientView }) => {
    const [loading, setLoading] = useState(false);
    const isMSP = window.location.pathname.split("/")[1] === 'mspv2';
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }, []);

    const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
    const userDetails = useSelector((state) => state.user?.userDetail);
    console.log('isMSP', isMSP)
    return (
        <div
            className=""
            style={{
                height: "100%",
                width: isMSP && "100%"
            }}
        >
            <Row className="fullHeight">
                <Col xs={12} className="px-0">
                    <div className="dashboard fullHeight">
                        {!isMSP && <header
                            className="d-flex justify-content-between navbar sticky-top "
                            style={{
                                paddingTop: ` ${"0.8em"
                                    } `,
                                background: "#FFFFFF",
                                position: "fixed",
                                width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                                zIndex: isMSP ? 4 : 6,
                                marginLeft: "-0.9rem",
                                paddingLeft: "2rem",
                                borderBottom: `1px solid #EBEDF1`,
                                paddingBottom: "8px",
                            }}
                        >
                            <h1>Storefront</h1>
                            <Header />
                        </header>}
                        {loading ? (
                            <ComingSoonLoader />
                        ) : (
                            <div
                                className="w-100 d-flex flex-column"
                                style={{ paddingTop: isMSP ? "2%" : "5%" }}
                            >
                                {/* Top row */}
                                <div className="d-flex justify-content-between mx-auto mb-3"
                                    style={{
                                        width: "46.5%",
                                    }}
                                >
                                    <div>
                                        <div className={`d-flex align-items-center px-2 w-fit-content bg-warning rounded-full  
                                        ${isMSP ? 'mb-3' : 'mb-2'}
                                        `}
                                            style={{
                                                height: '24px'
                                            }}
                                        >
                                            <img
                                                src="/images/coming-soon-icon.svg"
                                                alt=""
                                                width={12}
                                                className="mr-2"
                                            />
                                            <div className="f-500 f12 f-white">
                                                Coming Q2 2025
                                            </div>
                                        </div>
                                        <div
                                            className="f-600 f-24 f-black mb-4"
                                            style={{
                                                lineHeight: '32px'
                                            }}>
                                            {isMSP ? 'Build your own Storefront' : 'Your MSPs Storefront'}
                                        </div>
                                        <div
                                            className="f-400 f-14 f-grey"
                                            style={{
                                                width: '276px',
                                                height: '88px',
                                                lineHeight: '22px'
                                            }}>
                                            {
                                                isMSP ?
                                                    'Supercharge your sales with a storefront your customers can browse at a time that suits them.' :
                                                    'Browse products and services from our Managed Service Provider at a time that suits you.'
                                            }
                                        </div>
                                        {
                                            isMSP && (
                                                <div>
                                                    {
                                                        isSubmit ? <div className="d-flex align-items-center bg-grey-imp radius-8 px-3"
                                                            style={{
                                                                width: '290px',
                                                                height: '40px'
                                                            }}
                                                        >
                                                            <div className="f-400 f14 f-black">
                                                                <img
                                                                    alt=""
                                                                    className="mr-3"
                                                                    height={8}
                                                                    src="/images/icons/check-green.svg"
                                                                />
                                                                Submitted. We’ll be in touch soon!
                                                            </div>
                                                        </div> : <Button
                                                            className="hg-blue-btn"
                                                            onClick={() => {
                                                                if (!isSubmit) {
                                                                    setIsSubmit(true);
                                                                }
                                                            }}
                                                        >
                                                            Request Early Access
                                                        </Button>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img
                                            alt=""
                                            className=""
                                            width={324}
                                            height={118}
                                            src="/images/authorsComingSoon.png"
                                        />

                                    </div>
                                </div>
                                {/* Proactve decision row
                   */}

                                <div
                                    className="d-flex justify-content-between mx-auto radius-8 mt-4"
                                    style={{
                                        width: "46.5%",
                                        background: "#F6F8FB",
                                        padding: "2rem 0rem 0rem 2rem",
                                    }}
                                >
                                    <div style={{ marginRight: "2rem", width: "38%" }}>
                                        <p
                                            className="mb-2"
                                            style={{ fontSize: "18px", fontWeight: 600 }}
                                        >
                                            {
                                                isMSP ?
                                                    'Make it easier for customers to buy!' :
                                                    'Browse products & services available from your Managed Service Provider'
                                            }
                                        </p>
                                        <p
                                            style={{
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                color: "#616778",
                                                lineHeight: "22px",
                                                textAlign: "left",
                                            }}
                                        >
                                            {
                                                isMSP ?
                                                    'Your customers need you to help them buy.' :
                                                    'From laptops, servers and software to support, telecoms and security services, if your provider sells it, you can find it here.'
                                            }
                                        </p>
                                    </div>
                                    <div
                                        className="flex align-items-start w-100"
                                        style={{
                                            marginRight: "3px",
                                        }}
                                    >
                                        <img
                                            style={{ objectFit: "contain", width: "inherit" }}
                                            className=""
                                            alt=""
                                            src="/images/storefront-main.webp"
                                        />
                                    </div>
                                </div>

                                {/* Thirst row */}
                                <div
                                    className="d-flex justify-content-between mx-auto  mt-4"
                                    style={{
                                        width: "46.5%",
                                        height: "fit-content",
                                    }}
                                >
                                    <div
                                        className="d-flex  flex-column radius-8"
                                        style={{
                                            width: "49%",
                                            height: "revert",
                                            background: "#F6F8FB",
                                        }}
                                    >
                                        <div className="d-flex justify-content-center pt-3 position-relative">
                                            <img
                                                alt=""
                                                height={240}
                                                src={isMSP ? "/images/products-and-services-to-life.webp" : "/images/discover-products.webp"}
                                            />
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    width: "100%",
                                                    height: "24px",
                                                    background: "#F6F8FB",
                                                    bottom: "-7px",
                                                    filter: "blur(8px)",
                                                }}
                                            ></div>
                                        </div>
                                        <div className="" style={{ padding: "1rem 2rem" }}>
                                            <p
                                                className="mb-2"
                                                style={{ fontSize: "18px", fontWeight: 600 }}
                                            >
                                                {
                                                    isMSP ?
                                                        'Bring your products and services to life' :
                                                        'Discover products'
                                                }
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    color: "#616778",
                                                    lineHeight: "22px",
                                                    textAlign: "justify",
                                                }}
                                                className="mb-2"
                                            >
                                                {
                                                    isMSP ?
                                                        'Import products from your PSA and bring them to life with powerful product data and AI functionality.' :
                                                        'Save time emailing and phoning and discover what products and services your provider can offer.'
                                                }
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex  flex-column radius-8"
                                        style={{
                                            width: "49%",
                                            height: "revert",
                                            background: "#F6F8FB",
                                        }}
                                    >
                                        <div className="d-flex justify-content-center pt-3 position-relative">
                                            <img
                                                alt=""
                                                height={240}
                                                src={isMSP ? "/images/create-store.webp" : "/images/request-quote.webp"}
                                            />
                                            <div
                                                className="position-absolute"
                                                style={{
                                                    width: "100%",
                                                    height: "24px",
                                                    background: "#F6F8FB",
                                                    bottom: "-7px",
                                                    filter: "blur(8px)",
                                                }}
                                            ></div>
                                        </div>
                                        <div className="" style={{ padding: "1rem 2rem" }}>
                                            <p
                                                className="mb-2"
                                                style={{ fontSize: "18px", fontWeight: 600 }}
                                            >
                                                {
                                                    isMSP ?
                                                        'Create a store that works the way you do' :
                                                        'Request a quote'
                                                }
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    color: "#616778",
                                                    lineHeight: "22px",
                                                    textAlign: "justify",
                                                }}
                                                className="mb-2"
                                            >
                                                {
                                                    isMSP ?
                                                        'Giving you freedom to create a store that matches how you work, with custom product categories, subcategories and pricing models.' :
                                                        'See something you like? Great, send a quote request and your provider will do the rest!'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {
                                    isMSP && (
                                        <div
                                            className="d-flex justify-content-between mx-auto  mt-4"
                                            style={{
                                                width: "46.5%",
                                                height: "fit-content",
                                            }}
                                        >
                                            <div
                                                className="d-flex  flex-column radius-8"
                                                style={{
                                                    width: "49%",
                                                    height: "revert",
                                                    background: "#F6F8FB",
                                                }}
                                            >
                                                <div className="d-flex justify-content-center pt-3 position-relative">
                                                    <img
                                                        alt=""
                                                        height={240}
                                                        src="/images/run-promotions.webp"
                                                    />
                                                    <div
                                                        className="position-absolute"
                                                        style={{
                                                            width: "100%",
                                                            height: "24px",
                                                            background: "#F6F8FB",
                                                            bottom: "-7px",
                                                            filter: "blur(8px)",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="" style={{ padding: "1rem 2rem" }}>
                                                    <p
                                                        className="mb-2"
                                                        style={{ fontSize: "18px", fontWeight: 600 }}
                                                    >
                                                        Run Promotions
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            color: "#616778",
                                                            lineHeight: "22px",
                                                            textAlign: "justify",
                                                        }}
                                                        className="mb-2"
                                                    >
                                                        Run promotions for new and existing products and services, increasing awareness with clients and ultimately sales.
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                className="d-flex  flex-column radius-8"
                                                style={{
                                                    width: "49%",
                                                    height: "revert",
                                                    background: "#F6F8FB",
                                                }}
                                            >
                                                <div className="d-flex justify-content-center pt-3 position-relative">
                                                    <img
                                                        alt=""
                                                        height={240}
                                                        src="/images/no-transaction-charges.webp"
                                                    />
                                                    <div
                                                        className="position-absolute"
                                                        style={{
                                                            width: "100%",
                                                            height: "24px",
                                                            background: "#F6F8FB",
                                                            bottom: "-7px",
                                                            filter: "blur(8px)",
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="" style={{ padding: "1rem 2rem" }}>
                                                    <p
                                                        className="mb-2"
                                                        style={{ fontSize: "18px", fontWeight: 600 }}
                                                    >
                                                        No transaction charges
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            color: "#616778",
                                                            lineHeight: "22px",
                                                            textAlign: "justify",
                                                        }}
                                                        className="mb-2"
                                                    >
                                                        Empower customers to submit requests to buy your products, then review and quote for these offline.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default MarketplaceComingSoon;


export const MspClientPriceCalculatorLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            viewBox="0 0 1500 900"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
        >
            <rect x="1%" y="8%" rx="5" ry="5" width="45%" height="80" />
            <rect x="1%" y="20%" rx="5" ry="5" width="45%" height="40" />
            <rect x="1%" y="28%" rx="5" ry="5" width="20%" height="20" />

            <rect x="1%" y="32%" rx="5" ry="5" width="45%" height="70" />
            <rect x="1%" y="362" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="465" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="567" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="669" rx="5" ry="5" width="45%" height="100" />

            <rect x="55%" y="8%" rx="10" ry="10" width="40%" height="450" />

            <rect x="55%" y="60%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="67%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="74%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="81%" rx="10" ry="10" width="40%" height="50" />
        </ContentLoader>
    );
};
