import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import ImageLoader from "../../../Common/ImageLoader";

const ExportPackagePDFHeader = ({coverNoteData}) => (
  <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center f-16 f-500">
        Managed Security Package 
      </div>

    <div className="d-flex align-items-center">
      <ImageLoader 
         src={coverNoteData?.ClientSecurityHeader?.UserLogo}
         alt=""
         className="mr-2 radius-4"
         width={20}
         height={20}
          noImageComponent={(
            <div className="mr-2 radius-4">
              <ImageWithInitials
                initials={coverNoteData?.ClientSecurityHeader?.CompanyIntials || coverNoteData?.ClientSecurityHeader?.CompanyName?.[0]}
                width={20}
                height={20}
                background={"purple"}
              />
            </div>
          )}
      />
      <span className="f-500">
        {coverNoteData?.ClientSecurityHeader?.CompanyName}
      </span>
    </div>
  </div>
)

export default ExportPackagePDFHeader;