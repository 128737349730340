import stringUtils from "../Utils/string.utils";

const useDownloadFile = () => {
  const downloadFile = (url) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
          let blobURL = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = blobURL;
          const fileName = stringUtils.getFilenameFromUrl(url);
          a.download = fileName;
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      })
      .catch(error => console.error(error));
  }

  return downloadFile;
}

export default useDownloadFile;
