import { Spinner } from 'react-bootstrap';
import ConnectedToolItem from '../../../MSPv2/securityStack/connected-tool-item.component';
import './technologies-practices.styles.scss';
import { useState } from 'react';

const ListItem = ({ spendItem }) => {
  const [showHiddenBlock, setShowHiddenBlock] = useState(true);
  return (

    <li className={'list-item'}>
      <div className={`d-flex justify-content-between ${spendItem?.detailsList?.length > 0 && 'pointer'}`} style={{ gap: '16px' }}
        onClick={() => {
          if (spendItem?.detailsList?.length > 0) {
            setShowHiddenBlock(!showHiddenBlock)
          }
        }}
      >
        <div className="d-flex  f-500 f-grey">
          {
            spendItem?.icon &&
            <img
              width="13px"
              height="13px"
              src={spendItem?.icon}
              className='mr-2'
              style={{ marginTop: '4px' }}
            />
          }
          <span className={`${'text'} f-grey`} style={{ whiteSpace: 'nowrap' }}>
            {spendItem?.name}
          </span>
        </div>
        <div className={`${'text'} f-black`}>
          {spendItem?.IntegrationList?.length > 0 ? (
            <div className='' style={{ flexWrap: 'wrap', gap: '4px', justifyContent: 'flex-end' }}>
              {spendItem?.IntegrationList.map((integration, index) => (
                <ConnectedToolItem
                  key={`connected-tool-item-${integration?.name}-${index}`}
                  tool={{
                    obj_IntegrationId: integration?.logo,
                    ToolName: integration?.name,
                    ToolIcon: integration?.ToolIcon,
                  }}
                  passedStyle={'w-100 my-1'}
                />
              ))}
            </div>
          ) : (spendItem?.StatusIcon || spendItem?.StatusName) ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey d-flex align-items-center`}>
              {spendItem?.StatusIcon ? (
                <img
                  width="13px"
                  height="13px"
                  src={spendItem?.StatusIcon}
                  className='mr-1'
                  style={{ minWidth: '13px' }}
                />
              ) : null}
              <span className='status-value f-black'>
                {spendItem?.StatusValue}
              </span>
            </span>
          ) : spendItem?.detailsList?.length > 0 ? (
            <div className='d-flex align-content-center justify-content-between'>
              {
                spendItem?.numberOfIssues && <div className='incomplete-missing-txt orangeText px-2 py-1 radius-8 mr-2' style={{ background: '#FFF2DD' }}>
                  {spendItem?.numberOfIssues} Issues
                </div>
              }
              <img
                alt=""
                className=""
                src="/images/big-chevron-down.svg"
              />
            </div>
          ) : spendItem?.noCapability ? (
            <span className={`f-400 f-12 line-height-18 f-black`}>No capability</span>
          ) : spendItem?.answerText ? (
            <span className={`f-400 f-12 line-height-18 f-black`}>{spendItem?.answerText}</span>
          ) : spendItem?.isAnswerError ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey`}>Not answered</span>
          ) : spendItem?.isMissing ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey`}>Missing</span>
          ) : spendItem?.isIssue ? (
            <span className={`${'incomplete-missing-txt'}`} style={{ color: '#BB7F24' }}>Issues</span>
          ) : spendItem?.isinComplete ? (
            <span className={`${'incomplete-missing-txt'} f-darkgrey`}>Incomplete</span>
          ) : null}
        </div>
      </div>
      {
        showHiddenBlock && <div className='ml-3'>
          {spendItem?.detailsList?.map(
            (dataItem, dataItemIndex) => {
              return (
                <ListItem
                  key={dataItemIndex}
                  spendItem={{
                    ...dataItem,
                    name: dataItem?.TechnologyName,
                  }}
                />
              );
            }
          )}
        </div>
      }
    </li>
  )
}

const TechnologiesPractices = ({ technologiesandPractices, isLoading }) => {

  const fakeData = {
    Technologies: [
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Patch Management",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "678831",
            name: "Vipre"
          }
        ]
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Endpoint Protection",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          }
        ]
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Email Filtering",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          },
          {
            logo: "678831",
            name: "Vipre"
          }
        ]
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Web Filtering",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "3278516",
            name: "Censornet (Web Security)"
          },
          {
            logo: "606974",
            name: "WebTitan"
          }
        ]
      },
      {
        icon: "/images/msp/empty-circle.svg",
        TechnologyName: "SIEM / IDS",
        isMissing: true,
        isinComplete: false,
        isAnswerError: true,
        IntegrationList: []
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Offsite Backup",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "3278516",
            name: "Censornet (Web Security)"
          }
        ]
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Cloud Backup",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [
          {
            logo: "3278516",
            name: "Censornet (Web Security)"
          }
        ]
      },
      {
        icon: "/images/msp/cross-empty-circle.svg",
        TechnologyName: "Phishing Training",
        isMissing: true,
        isinComplete: false,
        noCapability: true,
        IntegrationList: []
      },
      {
        icon: "/images/msp/cross-empty-circle.svg",
        TechnologyName: "Dark Web Monitoring",
        isMissing: true,
        isinComplete: false,
        noCapability: true,
        IntegrationList: []
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Secure Configuration",
        isMissing: true,
        isinComplete: false,
        isAnswered: true,
        IntegrationList: [],
        detailsList: [
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "Secure Cloud Configuration",
            isMissing: true,
            isinComplete: false,
            isAnswered: true,
            noCapability: true,
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Secure Device & Network Configuration",
            isMissing: true,
            isinComplete: false,
            isAnswered: true,
            IntegrationList: [
              {
                logo: "3278516",
                name: "SkyKick"
              }
            ]
          }
        ]
      },
      {
        icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
        TechnologyName: "Secure Configuration",
        isMissing: true,
        isinComplete: false,
        IntegrationList: [],
        detailsList: [
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "Secure Cloud Configuration",
            isMissing: true,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Secure Device & Network Configuration",
            isMissing: true,
            isinComplete: false,
            IntegrationList: [
              {
                logo: "3278516",
                name: "SkyKick"
              }
            ]
          }
        ]
      },
      {
        icon: "/images/msp/cross-empty-circle.svg",
        TechnologyName: "Vulnerability Management",
        isMissing: true,
        isinComplete: false,
        noCapability: true,
        IntegrationList: []
      }
    ],
    Practices: [
      {
        isMissing: false,
        PracticeName: "Data Loss Prevention",
        StatusIcon: "",
        StatusValue: "",
        detailsList: [
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Access Control",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Data Encryption",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "Data Destruction",
            isMissing: false,
            isinComplete: false,
            isAnswerError: true,
            IntegrationList: []
          }
        ]
      },
      {
        isMissing: false,
        numberOfIssues: 2,
        PracticeName: "Domain & Email Security",
        StatusIcon: "",
        StatusValue: "",
        detailsList: [
          {
            icon: "/images/msp/orange-error-triangle.svg",
            TechnologyName: "SPF",
            isMissing: false,
            isinComplete: false,
            isIssue: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/orange-error-triangle.svg",
            TechnologyName: "DKIM",
            isMissing: false,
            isinComplete: false,
            isIssue: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/empty-circle.svg",
            TechnologyName: "DMARC",
            isMissing: false,
            isinComplete: false,
            isAnswerError: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "DNSSEC",
            isMissing: false,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "Email Encryption",
            isMissing: false,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          }
        ]
      },
      {
        isMissing: false,
        PracticeName: "Penetration Testing",
        StatusIcon: "",
        StatusValue: "",
        detailsList: [
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "External Network Infrastructure",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Internal Network Infrastructure",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Line of Business Applications",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "Web Applications",
            isMissing: false,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "IaaS Services",
            isMissing: false,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/cross-empty-circle.svg",
            TechnologyName: "PaaS Services",
            isMissing: false,
            isinComplete: false,
            noCapability: true,
            IntegrationList: []
          }
        ]
      },
      {
        isMissing: false,
        numberOfIssues: 4,
        PracticeName: "Governance & Resilience",
        StatusIcon: "",
        StatusValue: "",
        detailsList: [
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Incident Response Plan",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "/images/msp/orange-error-triangle.svg",
            TechnologyName: "Incident Response Audit",
            isMissing: false,
            isinComplete: false,
            isIssue: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/empty-circle.svg",
            TechnologyName: "IT BCDR Plan",
            isMissing: false,
            isinComplete: false,
            isAnswerError: true,
            IntegrationList: []
          },
          {
            icon: "/images/msp/orange-error-triangle.svg",
            TechnologyName: "IT BCDR Test Recovery Drills",
            isMissing: false,
            isinComplete: false,
            isIssue: true,
            IntegrationList: []
          },
          {
            icon: "/Cyber Board Review",
            TechnologyName: "Incident Response Audit",
            isMissing: false,
            isinComplete: false,
            isIssue: true,
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Cyber Risk Assessments",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Information Security Policies",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Security Frameworks",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Data Privacy Frameworks",
            isMissing: false,
            isinComplete: false,
            answerText: 'Yes',
            IntegrationList: []
          },
          {
            icon: "https://hguksouthinboundreports.blob.core.windows.net/3-icons-ui/Tick_GreenCircle.svg",
            TechnologyName: "Cyber Insurance",
            isMissing: false,
            isinComplete: false,
            answerText: 'Insured',
            IntegrationList: []
          },
         
        ]
      },
    ]
  }
  
  return (
    <div className={'technologies-ptractices'}>
      <div className="highground-royal-font f-700 f-16 d-flex align-items-center justify-content-between">
        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
          <span>
            Technologies and Practices
          </span>
          {isLoading && (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          )}
        </div>
      </div>
      <div className={'data-container'}>
        <div className={'section'}>
          <h3 className={`${'section-header'} m-0 f-grey ml-1 hg-border-bottom`}>
            Technologies
          </h3>
          <ul className={'list'}>
            {/*technologiesandPractices*/fakeData?.Technologies?.map(
              (spendItem, spendItemIndex) => {
                return (
                  <ListItem
                    key={spendItemIndex}
                    spendItem={{
                      ...spendItem,
                      name: spendItem?.TechnologyName,
                    }}
                  />
                );
              }
            )}
          </ul>
        </div>
        <div className={'section'}>
          <h3 className={`${'section-header'} m-0 f-grey ml-1`}>
            Practices
          </h3>
          <ul className={'list'}>
            {/*technologiesandPractices*/fakeData?.Practices?.map(
              (spendItem, spendItemIndex) => {
                return (
                  <ListItem
                    key={spendItemIndex}
                    spendItem={{
                      ...spendItem,
                      name: spendItem?.PracticeName,
                      icon: spendItem?.PracticeIcon
                    }}
                  />
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TechnologiesPractices;
