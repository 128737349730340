import { BASE_API_ROUTES } from "../constants/apis.constants";

const getUserMessage = () => JSON.parse(localStorage.getItem("user_MessageObj"));
const getUserToken = () => JSON.parse(localStorage.getItem("tokens"));
const getUserInfo = () => JSON.parse(localStorage.getItem("object_Data"));
const getRefreshToken = () => localStorage.getItem("refresh_token");

const setUserMessage = (message) =>
  localStorage.setItem("user_MessageObj", JSON.stringify(message));
const setUserInfo = (userInfo) =>
  localStorage.setItem("object_Data", JSON.stringify(userInfo));
const setTokens = (tokens) =>
  localStorage.setItem("tokens", JSON.stringify(tokens));

const validateUser = () => {
  const token = getUserToken();
  const message = getUserMessage();
  if (!message || !token) {
    return false;
  }
  return fetch(
    `${BASE_API_ROUTES.validateUserToken}?AccessKey=${message}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",

        Connection: "keep-alive",

        Authorization: `Bearer ${token}`,
      },
    }
  );
}

const userUtils = {
  validateUser,
  getUserMessage,
  getUserToken,
  getUserInfo,
  getRefreshToken,
  setUserMessage,
  setUserInfo,
  setTokens,
};

export default userUtils;
