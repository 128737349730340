export const IntegrationCategories = {
  CyberSecurity: 'Cyber Security',
}

export const ManagedBySOCState = {
  Always: 0,
  Optional: 1,
  Never: 2
};

export const ContributionTypeTitles = ["products", "practices", "tools"];

export const ReplaceIntegrationTypes = {
  REMOVE: 0,
  DELETE: 1
}