import "./sections/sections.styles.scss";
import { Fragment, useEffect, useState } from "react";
import AuthTextInput from "./auth-text-input.component";
import KeyIcon from "../icons/key.icon";
import { USER_PASSWORD_STRENGTH } from "../../constants/user.constants";

const PasswordStrengthInput = ({
  password,
  setPassword,
  passwordError,
  setPasswordError,
  error,
  setError,
  updatePasswordStrength,
  setDisableCredFieldsHL = () => {},
  disableCredFieldsHL
}) => {
  const [shownPassword, setShownPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [curVariations, setCurVariations] = useState([]);

  useEffect(() => {
    var variations = {
      upper: {
        name: 'A-Z uppercase',
        test: /[A-Z]/.test(password)
      },
      lower: {
        name: 'a-z lowercase',
        test: /[a-z]/.test(password)
      },
      digits: {
        name: '0-9 number',
        test: /\d/.test(password)
      },
      nonWords: {
        name: 'symbols',
        test: /\W/.test(password)
      },
      lengthMax: {
        name: '12 characters',
        test: /^.{12,}$/.test(password),
      },
    };

    var strengthCount = 0;
    const unmetVariations = [];
    for (var check in variations) {
      strengthCount += variations[check].test == true ? 1 : 0;
      if (!variations[check].test) {
        unmetVariations.push(variations[check].name);
      }
    }
    setPasswordStrength(strengthCount);
    setCurVariations(unmetVariations);
    updatePasswordStrength(strengthCount);
  }, [password]);

  return (
    <div>
      <AuthTextInput
        id="password" 
        error={error || passwordError}
        type={shownPassword ? "text" : "password"}
        placeholder="New password"
        onChange={(event) => {
          setPassword(
            event.target.value
          );
          setPasswordError("");
          setDisableCredFieldsHL(false);
          setError("");
        }}
        value={password}
        icon={(
          <KeyIcon />
        )}
        endIcon={(
          <div 
            className="ml-2 cursor-pointer"
            onClick={() => {
              setShownPassword(prev => !prev);
            }}
          >
            <img
              src={shownPassword ? "/images/attack-surface/shown-icon.svg" : "/images/attack-surface/hidden-icon.svg"}
              className="ml-2"
            />
          </div>
        )}
        disableErrorHighlight={disableCredFieldsHL}
      />
      <Fragment>
        <div className="d-flex justify-content-between" style={{ margin: '4px 0' }}>
          {[1, 2, 3, 4, 5].map((bar) => (
            <div
              key={bar}
              className={
                bar <= passwordStrength
                  ? USER_PASSWORD_STRENGTH.find(
                      (elmt) =>
                        elmt.maxValue >= passwordStrength
                    ).barColor
                  : "bar-not-active"
              }
            ></div>
          ))}
        </div>
        <div
          className={`${USER_PASSWORD_STRENGTH.find(
            (elmt) => elmt.maxValue >= passwordStrength
          ).color} f-12`}
          style={{ 
            lineHeight: "18px",
            letterSpacing: "0.02em"
           }}
        >
          {curVariations.length > 0
            ? `Use ${curVariations.join(', ')}`
            : "Your password is strong"}
        </div>
      </Fragment>
    </div>
  )
}

export default PasswordStrengthInput;
