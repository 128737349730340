import { Button, Spinner } from "react-bootstrap";
import "./sections.styles.scss";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import AuthTextInput from "../auth-text-input.component";
import EmailIcon from "../../icons/email.icon";
import useQueryParams from "../../../hooks/query-params.hook";

const ssoMethods = [
  {
    title: "Sign in with Microsoft",
    icon: "/images/msp/microsoft-icon.svg",
    url: "https://login.microsoftonline.com/3d001e03-d302-482a-93cf-bf19c150c298/oauth2/v2.0/authorize?client_id=dd2acc63-2cd9-4401-8a68-a2e2872eb298&response_type=code&redirect_uri=http://localhost:3000/login&response_mode=query&scope=User.Read api://dd2acc63-2cd9-4401-8a68-a2e2872eb298/Access.Highground"
  },
  {
    title: "Sign in Google",
    icon: "/images/actioncenter/google.jpg",
    url: "https://accounts.google.com/o/oauth2/v2/auth?client_id=1062360284784-1bq8q1lq4k7rj4f5f8q0c3q2p9q4q7q7.apps.googleusercontent.com&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email&redirect_uri=http://localhost:3000/login&state=google"
  }
];

const UserEmailSection = ({ 
  error,
  setError,
  loading,
  onLoginEmailSubmit,
  emailError,
  setEmailError,
  showSSOMethod,
  setDisableCredFieldsHL,
  disableCredFieldsHL,
  flowType = "login",
  defaultEmail = "",
}) => {
  const [email, setEmail] = useState(defaultEmail);
  const { getParam } = useQueryParams();
  const emailParams = getParam("email");
  const history = useHistory();
  const location = useLocation();

  const footerTitle = flowType === "login" ? "Don't have an account yet?" : "Already have an account?";
  const footerBtnText = flowType === "login" ? "Create an account" : "Sign in";
  const footerPath = flowType === "login" ? "/signup" : "/login";

  useEffect(() => {
    if (location?.state?.newEmail) {
      setEmail(location?.state?.newEmail);
      // setPassword(location?.state?.newPassword);
    }
  }, []);

  useEffect(() => {
    if (!emailParams) return;
    setEmail(emailParams);
  }, [emailParams]);

  return (
    <div className="user-email-section d-flex flex-column" style={{ gap: '16px' }}>
      <div className="d-flex flex-column" style={{ gap: '8px' }}>
        {ssoMethods.map((method, index) => (
          <div 
            key={index}
            className={`sso-method border1 radius-4 d-flex align-items-center justify-content-center f-black f-500 w-100 ${showSSOMethod ? "pointer" : "disabled-sso"}`}
            style={{padding:"5px"}}
            onClick={()=>{
              if (!showSSOMethod) return;
              window.location.href=method.url
            }}
          >
            <img
              src={method.icon}
              alt=""
              width="16px"
              height="16px"
            />
            <span style={{ fontWeight: 400 }}>
              {method.title}
            </span>
            {!showSSOMethod && (
              <div className="coming-soon f-grey">
                Coming soon
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="or-separator">
        <div className="line" />
        <span className="text">Or</span>
        <div className="line" />
        </div>
      <div>
        <form
          onSubmit={e => {
            e.preventDefault();
            onLoginEmailSubmit(email)
          }}
          noValidate
        >
          <AuthTextInput
            id="user-email" 
            error={error || emailError}
            type="email"
            placeholder="Enter your work email address"
            onChange={(event) => {
              setEmail(
                window.sanitizeHGtext(event.target.value)
              );
              setEmailError("");
              setError("");
              setDisableCredFieldsHL(false);
            }}
            value={email}
            icon={(
              <EmailIcon />
            )}
            disableErrorHighlight={disableCredFieldsHL}
            autoFocus
          />

          <div style={{ marginTop: '8px' }}>
            <Button
              size="lg"
              variant="blue"
              className="w-100 f-14"
              type={loading ? "button" : "submit"}
              style={{ padding: "5px" }}
            >
              {loading ? (
                <span>
                  <Spinner
                    size="sm"
                    className="mr8px"
                    animation="border"
                  />{" "}
                  Authenticating
                </span>
              ) : (
                "Continue"
              )}
            </Button>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-center">
        <div className="f-black d-flex">
          {footerTitle} &nbsp;
          <div
            className="f-blue f-500 pointer"
            onClick={() => {
              history.push(footerPath);
            }}
          >
            {footerBtnText}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserEmailSection;
