import ImageLoader from "../../Common/ImageLoader";
import ImageWithInitials from "../../../Utils/ImageWithInitials";

const ExportClientPDFHeader = ({coverNoteData}) => (
  <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center f-16 f-500">
        <ImageLoader
          src={coverNoteData?.ClientSecurityHeader?.UserLogo}
          alt=""
          className="mr-2 rounded-full"
          width={20}
          height={20}
          noImageComponent={(
            <div className="mr-2 rounded-full">
              <ImageWithInitials
                initials={
                  coverNoteData?.ClientSecurityHeader?.CompanyIntials
                  || coverNoteData?.ClientSecurityHeader?.CompanyName[0]
                }
                width={20}
                height={20}
                background={"purple"}
              />
            </div>
          )}
        />
        {coverNoteData?.ClientSecurityHeader?.CompanyName}{" "}
        Security Report
      </div>

    <div className="d-flex align-items-center">
      By &nbsp;
      <ImageLoader 
         src={coverNoteData?.MSPLogo}
         alt=""
         className="mr-2 rounded-full"
         width={20}
         height={20}
          noImageComponent={(
            <div className="mr-2 rounded-full">
              <ImageWithInitials
                initials={coverNoteData?.MSPNameInitials || coverNoteData?.MspName?.[0]}
                width={20}
                height={20}
                background={"purple"}
              />
            </div>
          )}
      />
      <span className="f-500">
        {coverNoteData?.MspName}
      </span>
    </div>
  </div>
)

export default ExportClientPDFHeader;
