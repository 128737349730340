import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./OldLoginStyling.scss";
import {   Redirect } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from "../context/Auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
 
import { useDb } from "../context/db";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { getAPIData } from "../../Utils/commonApi";
import { TrackUser } from "../../Utils/SegmentFunctions";
import CryptoJS from 'crypto-js';
import { setuser } from "../../redux/userState/actions";
import { useDispatch } from "react-redux";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";  
import {FullScreenLoader} from "../Common/loader";
import { IP_API_URL } from "../../constants/apis.constants";
import AuthLayout from "./auth-layout/auth.layout";
import AuthBody from "./auth-layout/auth.body";
import UserEmailSection from "./sections/user-email.section";
import UserPasswordSection from "./sections/user-password.section";
import ScanQRSection from "./sections/scan-qr.section";
import AuthBottomInfoSections from "./auth-layout/auth-bottom-info-sections.component";
import { emailRegExp } from "../../constants/regex.constants";
import useQueryParams from "../../hooks/query-params.hook";
import { ROUTE_ACTIONS } from "../../constants/app.constants";

const LoginFormState = {
  EMAIL: 0,
  PASSWORD: 1,
  SCAN_QR: 2,
}

const Loginv2 = (props) => {
  const [loading, setLoading] = useState(false);
  const [showUserPassfields, setShowUserPassfields] = useState(false)
  const [showSSOMethod, setShowSSOMethod] = useState(false);
  const { setdbObject } = useDb();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState(""); 
  const [email, setEmail] = useState("");
  const [disableCredFieldsHL, setDisableCredFieldsHL] = useState(false);
  
const [hmac, setHmac] = useState('')
  const [loggedIn, setLoggedIn] = useState(false);
  
  const [totp, setTotp] = useState("123456");
 const [pageLoading, setPageLoading] = useState( false)
  const [accessToken, setAccessToken] = useState(false);

  const [error, setError] = useState("");
  const [totpError, setTotpError] = useState(false);
 
  const [count, setCount] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [showQR, setShowQR] = useState(false);
  const CURRENT_URL = useLocation().pathname;
 
  const location = useLocation();
  const { state: loginInformation } = location;

  // const [isRequestSend, setIsRequestSend] = useState(false);
  const {
    authTokens,
    qrCode,
    auth,
    userType,
    setUserType,

    setAuthTokens,
    setQrCode,
    setAuth,
    setMessageEncrypt,
  } = useAuth();

  const { getParam } = useQueryParams();
  const action = getParam("action");
  const paramEmail = getParam("email");

  const LoginStatesTitles = [
    { title: "Sign in", description: "Login to HighGround or create an account to get started." },
    ...(action === ROUTE_ACTIONS.passwordChanged ? [
      { title: "Password changed", description: "Sign in using your new password." },
    ] : [
      { title: "Welcome", description: "Login to HighGround or create an account to get started." },
    ]),
    { title: "Enter code from your authenticator app", description: "To continue, please enter the 6-digit verification code generated by your authenticator app." },
  ]
 
  const [formState, setFormState] = useState(LoginFormState.EMAIL);
  const [mobileView, setMobileView] = useState(false);
  
  const message = error ? error.description : "";
  const history = useHistory();
 
  const urlParams = (user) => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("route");
    const tab = query.get("tab");
    let role = "ceo";

    if (user == "cto") {
      role = "cto";
    }

    if (user == "cfo") {
      role = "cfo";
    }

    let urParams = "";
    if (page == "system_settings") {
      if (tab == "billing") {
        localStorage.setItem("redirectTab", "billing");
        urParams +=
          "/" + role + "/dashboard/redirect?route=system_settings&tab=billing";
      } else {
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=system_settings&tab=subscriptions";
      }
    } else if (page == "pro_services") {
      urParams += "/" + role + "/dashboard/redirect?route=pro_services";
    } else if (page == "integrations") {
      if (tab == "Patch_Management") {
        localStorage.setItem("redirectTab", "Patch Management");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Patch_Management";
      } else if (tab == "Endpoint_Protection") {
        localStorage.setItem("redirectTab", "Endpoint Protection");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Endpoint_Protection";
      } else if (tab == "Email_Filtering") {
        localStorage.setItem("redirectTab", "Email Filtering");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Email_Filtering";
      } else if (tab == "SIEM_IDS") {
        localStorage.setItem("redirectTab", "SIEM IDS");
        urParams +=
          "/" + role + "/dashboard/redirect?route=integrations&tab=SIEM_IDS";
      } else if (tab == "Web_Filtering") {
        localStorage.setItem("redirectTab", "Web Filtering");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Web_Filtering";
      } else if (tab == "Dark_Web_Monitoring") {
        localStorage.setItem("redirectTab", "Dark Web Monitoring");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Dark_Web_Monitoring";
      } else if (tab == "Phishing_Training") {
        localStorage.setItem("redirectTab", "Phishing Training");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Phishing_Training";
      } else if (tab == "Website_Scan") {
        localStorage.setItem("redirectTab", "Website Scan");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Website_Scan";
      } else if (tab == "External_Network_Scan") {
        localStorage.setItem("redirectTab", "External Network Scan");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=External_Network_Scan";
      } else if (tab == "Incident_Response") {
        localStorage.setItem("redirectTab", "Incident Response");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Incident_Response";
      } else {
        urParams += "/" + role + "/dashboard/redirect?route=integrations";
      }
    } else if (page == "Cyber_Essential_Status") {
      urParams +=
        "/" + role + "/dashboard/redirect?route=Cyber_Essential_Status";
    } else if (page == "IR_Plan_Status") {
      urParams += "/" + role + "/dashboard/redirect?route=IR_Plan_Status";
    } else if (page == "Infosec_Policies") {
      urParams += "/" + role + "/dashboard/redirect?route=Infosec_Policies";
    } else if (page == "Risk_Assessment") {
      urParams += "/" + role + "/dashboard/redirect?route=Risk_Assessment";
    } else if (page == "Annual_Cyber_Review") {
      urParams += "/" + role + "/dashboard/redirect?route=Annual_Cyber_Review";
    } else if (page == "Test_Recovery") {
      urParams += "/" + role + "/dashboard/redirect?route=Test_Recovery";
    } else {
      urParams += "/" + role + "/dashboard";
    }
    return urParams;
  };

  async function onLoginEmailSubmit(email) {
    if (email == "") {
      setEmailError("Email can't be empty");
      setLoading(false);
      return false;
    }
    if (!emailRegExp.test(email)) {
      setEmailError("Invalid email address format. Please check and try again.");
      setLoading(false);
      return false;
    }
    setEmail(email);
    setEmailError("");
    setFormState(1);
  }

  async function loginUser(event, email, password) {
    if (loading) return;
    event.preventDefault();
    setdbObject();
    setLoading(true);
    if (email == "") {
      setEmailError("Email can't be empty");
      setLoading(false);

      return false;
    }
    if (password == "") {
      setPasswordError("Password is required.");
      setLoading(false);

      return false;
    }
    setTotpError(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const response = await fetch(IP_API_URL, {
      method: "GET",
    })
      .then((response) => response)
      .catch((err) => {});

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    if (response) {
      const data = await response.json();
      const ipV4Address = data.ip;
      urlencoded.append("ipaddress", ipV4Address);
    } else {
      urlencoded.append("ipaddress", "127.0.0.1");
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error) {
          setLoading(false);
          setError("There is a problem with your username or password. Please check and try again");
          setDisableCredFieldsHL(true);
        } else {
          setAccessToken(data.access_token); //Set Token to Local Storage
          setAuthTokens(data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          setLoggedIn(true);
          if (loggedIn) {
            setLoggedIn(false);
            setTimeout(() => {
              setLoggedIn(true);
            }, 10);
          }
          setError(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("login error");
        setLoading(false);

        setError("There is a problem with your username or password. Please check and try again");
      });
  }
  useEffect(() => {
    //  Remove opend modal and backdrop
    let _sliding_modal = document.querySelector(".hg-sliding-modal.open");
    let _backdrop = document.getElementById("sliding-modal-backdrop");
    if (_backdrop) {
      _backdrop.remove();
    }
    if (_sliding_modal) {
      _sliding_modal.remove();
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getQRData();
      
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!action) return;
    if (action === ROUTE_ACTIONS.passwordChanged) {
      setFormState(LoginFormState.PASSWORD);
    }
  }, [action]);

  useEffect(() => {
    if (!paramEmail) return;
    setEmail(paramEmail);
  }, [paramEmail]);

  useEffect(() => {
    // Check If Its Microsoft LoGin
    const urlParams = new URLSearchParams(window.location.search);
    // loginByMicrosoft("stanley.tobias@highground.io",`ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-0.AQwAAx4APQLTKkiTz78ZwVDCmGPMKt3ZLAFEimii4ocuspiWADU.AgABBAIAAAApTwJmzXqdR4BN2miheQMYAgDs_wUA9P-72RmjPmRQ9BJuWnEmssmbO_7IFtimj0fyVPcIBnYjQv23a4pGJ1tZ2D6tN2IRxj0iJ8N738L9eeJ3U7gzPiEQ8ZrB6As1SCUv0NfVamAeyHlkAmJWp1abaw8kfHI4D1mDkKc8hYmc5VncIi-DLrI_rSBFkExYnY0D-IihG0YGsdS0WhfTg7z_Y2A-Zqk_1YJ-HqyBwE3lPvRFnDdeoU-g1cKMYhJtbHcc-zUtM62n_jtpZ-HsjoPubf9Uwv7f2OflfsCITeNXSsuk_z1Hr-nEjGeOBB2U6W3HCds-ZbEiewbM1XF4BeKwmGKAf3pjSh8-OpVmfgAXdSH8Z4MEvAffXZZ3ybjx5Bml6o-BZZ9cT7PRNlMw8vhSDnBN3qRkO1xqQqhA_6syLEOCKTVRLZwS7UPDW_6yw440qCn98QF9h-cTGzNWYmMtAQrPiwkjayyC-g3xekxkJSOk74MxyxlJE4t7S4CsXKU7NCWSETfO9RzBuB86Jjc9TyPpQ8Ef_qkvoxg3G6UVxjcV1dLh5lREDFBJQ2EkA9BCmhElrFWnk_y-BUYBgxfL3iuLdBe08-Y-EmiZek4stDMt_hRctuIfIcyi0rk6O-kpGGiqhkGAEyxqvUqDhpmoOAD0nN7FOJ3k0a8U7MBbbbSIm1UJaP64HDc0OWAh8QaYZ8DY61BDgAO0qfksekRWUOoSfQ`)

    const oldVersion = urlParams.get("method");
    if(oldVersion  == 'sso'){
      // setShowUserPassfields(true)
      setShowSSOMethod(true);
    }





    const microsoftCode = urlParams.get("code");
    const session_state = urlParams.get("session_state");
    const microsoftLogonEmail = urlParams.get("microsoftLogonEmail");
    if (microsoftCode) {
      setPageLoading(true);
      //1.Coming from Sign up Screen
      if ( microsoftLogonEmail) {
        loginByMicrosoft(
     microsoftLogonEmail,
          `ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-${microsoftCode}`
        );
        return;
      }

      getUserDataFromAuthCode(microsoftCode)
        .then((response) => {
          if (!response) {
            setPageLoading(false);
            return
          }

          if (response?.existingHgUser === true) {
            loginByMicrosoft(
              response?.userLogon ?? microsoftLogonEmail,
              `ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-${microsoftCode}`
            );
          } else {
            history.push(
              `/signup?code=${microsoftCode}&microsoftLogonEmail=${response?.userLogon}&userGivenName=${response?.userGivenName}`
            );
          }
        })
        .catch(() => {
          setPageLoading(false);
        });
      return;
    }







    let user_token = localStorage.getItem("tokens");
    let user_data = localStorage.getItem("object_Data");
    let existingAuth = localStorage.getItem("userAuthenticated")
      ? Boolean(JSON.parse(localStorage.getItem("userAuthenticated")))
      : null;
    let userAccesKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : null;

    if (user_token && user_data && existingAuth && userAccesKey) {
      let _data = JSON.parse(user_data);
      if (!CURRENT_URL.includes("redirect")) {
        // Chcek if the redirect word is not included in URL

        if (_data?.UserDetail?.Role?.toLowerCase() === "msp") {
          // If Role is ""MSP"""
          if(window.screen.width < 700){
            history.push("/mspv2/integrations?type=allIntegrations")
          }else{
            history.push("/mspv2/clients");

          }
          return;
        }
        history.push(
          `/${_data?.UserDetail?.Role?.toLowerCase() ?? "ceo"}/dashboard`
        );
     
      }
    } else {
      // setdbObject();
      localStorage.setItem("dasboardLoader", true);
      // window.localStorage.clear()
      // console.log("storage cleared-")
    }
  }, []);

  async function getQRData() {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setHmac(data?.HMAC)
        window.localStorage.setItem(
          "token_time",
          JSON.stringify(Math.floor(Date.now() / 60000))
        );
        if (data?.ByPass2FA === true) {
          submit2FACode();
          return false;
        }
        if (data.mr.Success === false) {
          setQrCode(data.QRImage);
          setShowQR(true);
          setLoading(false);
          setFormState(LoginFormState.SCAN_QR);
          // return;
        } else {
          setShowQR(false);
          setLoading(false);
        }
        if (data.error) {
          setLoading(false);

          setError({
            type: "danger",
            heading: "Google Auth Check Failed",
            body: "Google Authentication check has failed. Please contact administrator",
          });
        } else {
          if (data.mr.Message === "Google Auth PIN code is required.") {
            setFormState(LoginFormState.SCAN_QR);
            setLoading(false);

            return "totp";
          } else {
            setFormState(LoginFormState.SCAN_QR);

            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("qr error");

        setError("Something went wrong");
      });
  }

  async function submit2FACode(mfaCode) {
    if (String(mfaCode).length < 6) {
      setTotpError("Incorrect verification code.");
      return;
    }
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=${mfaCode}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.ExThrown) {
          setLoading(false);

          setTotpError("Incorrect verification code.");
        } else {
          setCount(count + 1);
          if (data?.Success) {
            let responseData = { ...data };

            getAPIData(0, authTokens, { accessKey: data?.Message })
              .then((res) => {
                TrackUser("User Signed In", {
                  email: res?.UserDetail?.UserName,
                  UserFullName: res?.UserDetail?.FullName,
                });
                if (res?.isTempAccessSession === true) {
                  window.localStorage.setItem("showTempAccessBanner", "true");
                } else {
                  window.localStorage.removeItem("showTempAccessBanner");
                }
                localStorage.setItem("InActivityTimer",0)
                dispatch(
                  setuser({
                    ...res?.UserDetail,
                    CommonComponents: res?.CommonComponents,
                    ScoreAttestation_Icon:
                      res?.CommonComponents?.ScoreAttestation_Icon,
        
                    cyberScoreValue: res?.CommonComponents?.CyberScoreNumber,
                    cyberThreatValue: res?.CommonComponents?.CyberThreatNumber,
                    AccessObjList: res?.mr?.AccessObjList,
                  })
                );

                const secretKey = 'Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j'; // Your secret key
                // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
                const userIdentifier = res?.UserDetail?.UserName; // The email for your user
              
                // Creating HMAC using SHA256 algorithm
                const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);
              
 
                // Intercomm
                window.Intercom('boot', {
                  app_id: 'jaa5e0c1', 
                  email:  res?.UserDetail?.UserName,
                  name: res?.UserDetail?.FirstName  ,
                
                  user_hash:hash
               });

                setUserType(responseData?.EntityId);
                setLoading(false);
                localStorage.setItem("object_Data", JSON.stringify(data));
                /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
                console.log("settiing access key");
                updateAccessKeyInLocalStorage(responseData?.Message)
               
                setMessageEncrypt(responseData?.Message); // Setting Access key
                setdbObject(res);
                setAuth(true);
                setFormState(100);
              })
              .catch((err) => {
                setLoading(false);
              });

           
          } else {
            setTotpError("Incorrect verification code.");
            setLoading(false);

            setLoggedIn(false);
            setAccessToken(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
      });
  }
  const authHandler = (err, data) => {
    console.log("err", err);
    console.log("data", data);
  };
  const token = localStorage.getItem("tokens")
    ? JSON.parse(localStorage.getItem("tokens"))
    : null;
  const userAccessKey = localStorage.getItem("user_MessageObj")
    ? JSON.parse(localStorage.getItem("user_MessageObj"))
    : null;

async function loginByMicrosoft(_email,_password){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  const response = null
  
  // await fetch("https://geolocation-db.com/json/", {
  //   method: "GET",
  // })
  //   .then((response) => response)
  //   .catch((err) => {});

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username",   _email);
    urlencoded.append("password", _password);
    if (response) {
      const ip = await response.json();
      urlencoded.append("ipaddress", ip.IPv4);
    } else {
      urlencoded.append("ipaddress", "127.0.0.1");
    }  

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      requestOptions
    ).then((response) => response.json())
    .catch((err) => {}); 
if(data.error){
  // STOP LOADING ITS ERROR
setPageLoading(false)
return
}else{
  setAccessToken(data.access_token); //Set Token to Local Storage
  setAuthTokens(data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token)
}

// Code To ByPass 2 MFA
var MFA_ByPass_myHeaders = new Headers();
MFA_ByPass_myHeaders.append("Authorization", `Bearer ${data.access_token}`);

var MFA_ByPass_requestOptions = {
  method: "GET",
  headers: MFA_ByPass_myHeaders,
  redirect: "follow",
};


const MFA_ByPass_data = await fetch(
  `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=${"999666"}`,
  MFA_ByPass_requestOptions
).then((response) => response.json())
.catch((err) => {});

if(MFA_ByPass_data.ExThrown){
  // STOP LOADING ITS ERROR
  setPageLoading(false)
return
}else{
  let responseData = { ...MFA_ByPass_data };

  getAPIData(0, data.access_token, { accessKey: MFA_ByPass_data?.Message })
    .then((res) => {
      TrackUser("User Signed In", {
        email: res?.UserDetail?.UserName,
        UserFullName: res?.UserDetail?.FullName,
      });
      localStorage.setItem("InActivityTimer",0)
      dispatch(
        setuser({
          ...res?.UserDetail,
          CommonComponents: res?.CommonComponents,
          ScoreAttestation_Icon:
            res?.CommonComponents?.ScoreAttestation_Icon,

          cyberScoreValue: res?.CommonComponents?.CyberScoreNumber,
          cyberThreatValue: res?.CommonComponents?.CyberThreatNumber,
          AccessObjList: res?.mr?.AccessObjList,
        })
      );

      const secretKey = 'Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j'; // Your secret key
      // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
      const userIdentifier = res?.UserDetail?.UserName; // The email for your user
    
      // Creating HMAC using SHA256 algorithm
      const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);
    

      // Intercomm
      window.Intercom('boot', {
        app_id: 'jaa5e0c1', 
        email:  res?.UserDetail?.UserName,
        name: res?.UserDetail?.FirstName  ,
      
        user_hash:hash
     });

      setUserType(responseData?.EntityId);
      setLoading(false);
      localStorage.setItem("object_Data", JSON.stringify(data));
      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
      console.log("settiing access key");
      updateAccessKeyInLocalStorage(responseData?.Message)
     
      setMessageEncrypt(responseData?.Message); // Setting Access key
      setdbObject(res);
      setAuth(true);
      setFormState(100);
    })
    .catch((err) => {
      setLoading(false);
    });
}
}

  if (auth && token && userAccessKey) {
    console.log("ENTERING AUTH");
    const RESTRICTED_URLS = [
      "logout",
      "no-access-to-highground",
      "no-access-to-msp",
      "session_expiry",
    ];

    if (
      loginInformation?.redirectUser &&
      !RESTRICTED_URLS.includes(loginInformation?.redirectUrl?.split("/")?.[1])
    ) {
      console.log("user-redirected");
      history.push(loginInformation?.redirectUrl);

      return <></>;
    }
    // let element = document.getElementById("root").firstChild;
    // element.classList.add("fadeIn");
    // setTimeout(() => {
    //   element.classList.remove("fadeIn");
    // }, 500);

    if (userType === "333333")
      return (
        <Redirect
          to={{
            pathname: `${urlParams("cfo")}`,
            state: { isLogin: true, ...loginInformation },
          }}
        />
      );
    else if (userType == "222222")
      return (
        <Redirect
          to={{
            pathname: `${urlParams("cto")}`,
            state: { isLogin: true, ...loginInformation },
          }} //"/cto/dashboard"
        />
      );
    else if (userType == "111111" || userType == "999999") {
      return (
        <Redirect
          to={{
            pathname: `${urlParams("ceo")}`,
            state: { isLogin: true, ...loginInformation },
          }}
        />
      );
    } else if (userType == "555555")
      return (
        <Redirect
          to={{ pathname: "/mspv2/clients", state: { isLogin: true } }}
        />
      );
  }

  const sections = [UserEmailSection, UserPasswordSection, ScanQRSection];
  const titles = LoginStatesTitles[formState];
  const CurSection = sections[formState] ?? <div />;

  return (
    <>
      {pageLoading ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 "
          style={{
            height: "100vh",
          }}
        >
          <FullScreenLoader
          />
        </div>
      ) : (
        <AuthLayout>
          <AuthBody>
            <AuthBottomInfoSections
              title={titles.title}
              description={titles.description}
              hideInfo
            >
              <CurSection 
                message={message}
                emailError={emailError}
                passwordError={passwordError}
                showSSOMethod={showSSOMethod}
                setEmailError={setEmailError}
                setPasswordError={setPasswordError}
                error={error}
                setError={setError}
                onLoginEmailSubmit={onLoginEmailSubmit}
                loading={loading}
                defaultEmail={email}
                setFormState={setFormState}
                loginUser={loginUser}
                qrCode={qrCode}
                showQR={showQR}
                setTotp={setTotp}
                setTotpError={setTotpError}
                totpError={totpError}
                submit2FACode={submit2FACode}
                disabledBtn={disabledBtn}
                setDisabledBtn={setDisabledBtn}
                setDisableCredFieldsHL={setDisableCredFieldsHL}
                disableCredFieldsHL={disableCredFieldsHL}
              />
            </AuthBottomInfoSections>
          </AuthBody>
        </AuthLayout>
      )}
    </>
  );
};

export default Loginv2;

export async function getUserDataFromAuthCode(_authCode) {
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get("role");
  const url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/SSO?authCode=${_authCode}&callingUrl=${window.location.origin+window.location.pathname}${role ?  `?role=${role}`  :"" }`;
  
  try {
      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.toLowerCase())
      if (data.toLowerCase().includes("error")) {
        console.log(data.toLowerCase())
        return null
       
      }
      console.log(parseData(data));
      return parseData(data);
  } catch (error) {
      console.error('Error making API call:', error);
  }
}

function parseData(_data) {
  try {
    return JSON.parse(_data)
  } catch (error) {
    return null
  }
  
}