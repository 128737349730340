import { BASE_API_ROUTES } from "../constants/apis.constants";
import { useAuth } from "../Components/context/Auth";
import { getAPIData, IndependentGetAPIData } from "../Utils/commonApi";
import { BASE_API_PAGE_ENUMS } from "../constants/apis.constants";
import { CallToast } from "../Components/PriceCalculator/modals/CustomToast";
import userUtils from "../Utils/user.utils";
import httpUtils from "../Utils/http.utils";
import { updateAccessKeyInLocalStorage } from "../Utils/CommonFunctions";

const useApiActions = () => {
  const { authTokens } = useAuth();

  const onSuccess = () => {
    CallToast("Updated successfully", false, false, "v2style");
  }

  const onError = () => {
    CallToast("Something went wrong", false, false, "v2style");
  }

  const getBody = (method, body) => {
    if (method === 'GET') return null;
    if (body instanceof FormData) return body;
    return JSON.stringify(body);
  }
  
  const sendRequest = async (url, method = 'GET', body = null, headers = {}) => {
    try {
      const resHeaders = body instanceof FormData ? headers : {
        ...headers,
        'Content-Type': 'application/json'
      };
      const response = await fetch(url, { method, body: getBody(method, body), headers: {
        ...resHeaders,
        ...(authTokens && {
          Authorization: `Bearer ${authTokens}`,
        })
      } });
      const data = await response.json();
      if (!response.ok) {
        onError();
        throw new Error(data?.message || 'Request error');
      }
      return data;
    } catch (error) {
      return error;
    }
  }

  const getAllIntegrationsByTechType = async ({ 
    technologyTypeEnum, 
    showAll = false,
    searchText = '',
  }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('TechnologyTypeEnum', technologyTypeEnum);
      formData.append('ShowAll', showAll);
      formData.append('Search_text', searchText);
      formData.append('accessKey', message);
      return fetch(
        BASE_API_ROUTES.getAllIntegrationsByTechType,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching integrations', error);
    }
  }

  const saveIntegrationsInfo = async (integrationInfoList) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('IntegrationInfo_List', JSON.stringify(integrationInfoList));
      formData.append('AccessKey', message);
      return fetch(
        BASE_API_ROUTES.saveIntegrationsInfo,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => {
        if (response.status < 400) {
          onSuccess();
        } else {
          onError();
        }
        return response.json();
      });
    } catch (error) {
      console.error('Error saving integrations info', error);
    }
  }

  const renameIntegration = async (_integrationId, newName) => {
    return IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      });
  }
  
  const submitNotificationSettings = async ({ 
    notificationValue, 
    notificationName,
    clientId
  }) => {
    try {
      const formData = httpUtils.toFormData({
        OwnerType: 0,
        NotificationName: notificationName,
        NotificationValue: notificationValue,
        optionEnum98: clientId,
        optionEnum99: 0,
        SettingOwner: 0,
        AccessKey: userUtils.getUserMessage(),
      })
      const response = await sendRequest(BASE_API_ROUTES.submitNotificationSettings, 'POST', formData);
      if (response instanceof Error) return;
      if (response?.Message) {
        updateAccessKeyInLocalStorage(response.Message);
      }
      onSuccess();
      return response;
    } catch (error) {
      console.error('Error submiting notification settings', error);
    }
  }

  const getClientsDashboardV2 = async (customerId) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        ...(customerId ? {
          ObjId_Customer: customerId,
          optionEnum98: customerId,
        } : {
          ObjId_Customer: "0",
        }),
        accessKey: message,
      });
      return fetch(
        `${BASE_API_ROUTES.getClientDashboardV2}${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching clients dashboard', error);
    }
  }

  const getTechnologySpend = async ({ customerId, fromDate, toDate }) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        accessKey: message,
      });
      const formData = new FormData();
      if (customerId) {
        formData.append('ObjId_Customer', customerId);
        formData.append('optionEnum98', customerId);
        formData.append('optionEnum99', 24);
      } else {
        formData.append('ObjId_Customer', 0);
        formData.append('optionEnum99', 32);
      }
      formData.append('FromDate', fromDate);
      formData.append('ToDate', toDate);
      return fetch(
        `${BASE_API_ROUTES.getTechnologySpend}${query}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching technology spend', error);
    }
  }

  const submitQuestionNote = ({ noteValue, technologyEnum, clientId }) => {
     return getAPIData(BASE_API_PAGE_ENUMS.QuestionNote, authTokens, {
      OptionEnum1: technologyEnum,
      optionEnum1: technologyEnum,
      ...(clientId && {
        optionEnum2: clientId,
      }),
      ...(typeof noteValue === 'string' && {
        optionStrEnum1: noteValue,
      })
    }).then((response) => {
      if (response?.mr?.Success < 400) {
        // onSuccess();
      } else {
        onError();
      }
    });
  }

  const updateToolToYes = async ({ technologyEnum }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.ToolToYes, authTokens, {
      optionEnum1: technologyEnum,
    }).then((response) => {
      if (response?.mr?.Success < 400) {
        onSuccess();
      } else {
        onError();
      }
    });
  }

  const getAllAssetSources = async ({ clientId, sortList }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('AccessKey', message);
      formData.append('ObjId_Customer', clientId);
      formData.append('MSPClientSortVisibility_List', sortList ? JSON.stringify(sortList) : '');
      return fetch(
        BASE_API_ROUTES.getAllAssetSourcesV2,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching asset sources', error);
    }
  }

  const getUserDeviceIssues = async ({ clientId, filterList }) => {
    try {
      const message = userUtils.getUserMessage();

      const query = httpUtils.generateQueryString({
        accessKey: message,
        ObjId_Customer: clientId,
        FilterList: filterList ? JSON.stringify(filterList) : '',
      });
      return fetch(
        `${BASE_API_ROUTES.getUserDeviceIssues}${query}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json())
      .then((response) => {
        if (!response?.mr?.Success) {
          onError();
        }
        return response;
      });
    } catch (error) {
      console.error('Error fetching user device issues', error);
    }
  }

  const getAddSubscriptionSpendCalcs = async ({
    clietId,
    term,
    invoiceId,
    reccuringPeriod,
    startDate
  }) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        accessKey: message,
        ObjId_Customer: clietId,
        Term: term,
        Objid_Invoice: invoiceId,
        Recurring_Period: JSON.stringify(reccuringPeriod),
        StartDate: startDate
      });
      return fetch(
        `${BASE_API_ROUTES.getAddSubscriptionSpendCalcs}${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching subscription spend calcs', error);
    }
  }

  const uploadFile = async (file, fileType, tokens) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('File', file);
      formData.append('optionEnum1', fileType);
      formData.append('accessKey', message);
      const query = httpUtils.generateQueryString({
        accessKey: message
      });
      return fetch(
        `${BASE_API_ROUTES.uploadFile}${query}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${tokens ?? authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error uploading file', error);
    }
  }

  const getExportPackageReportMenuSort = async ({
    clientId,
    menuList
  }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('AccessKey', message);
      formData.append('ObjId_Customer', clientId);
      if (menuList) {
        formData.append('ExportPackageReportMenuSort_List', JSON.stringify(menuList));
      }
      return fetch(
        BASE_API_ROUTES.getExportPackageReportMenuSort,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching export package report menu sort', error);
    }
  }

  const createTaxRate = async ({ ObjectId, TaxCode, TaxName, TaxRate, IsDefault, IsDisabled }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('ObjectId', ObjectId);
      formData.append('TaxName', TaxName);
      formData.append('TaxRate', TaxRate);
      formData.append('TaxCode', TaxCode);
      formData.append('IsDefault', IsDefault);
      formData.append('isDisabled', IsDisabled);
      formData.append('AccessKey', message);
      return fetch(
        BASE_API_ROUTES.createTaxRate,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json())
      .then((response) => {
        if (response?.Success) {
          onSuccess();
        } else {
          onError();
        }
        return response;
      } );
    } catch (error) {
      console.error('Error creating tax rate', error);
    }
  }

  const getTaxesList = () => {
    return getAPIData(BASE_API_PAGE_ENUMS.GetTaxesList, authTokens, {})
      .then((response) => {
        if (!response?.mr?.Success) {
          onError();
        }
        return response;
      });
  }

  const getPagedPackageProduct = ({ pageNumber = 0, searchValue }) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        accessKey: message,
      });
      const formData = new FormData();
      formData.append('PageNumber', pageNumber);
      formData.append('SearchText', searchValue);
      formData.append('optionEnum99', 18);
      return fetch(
        `${BASE_API_ROUTES.getPagedPackageProduct}${query}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json())
      .then((response) => {
        if (!response?.mr?.Success) {
          onError();
        }
        return response;
      });
    } catch (error) {
      console.error('Error fetching paged package product', error);
    }
  }

  const refreshAccessToken = async () => {
    const query = httpUtils.generateQueryString({
      key: userUtils.getRefreshToken(),
    });
    const path = `${BASE_API_ROUTES.refreshAccessToken}${query}`;
    const response = await sendRequest(path);
  }

  const getUserEmailById = async ({ id }) => {
    const query = httpUtils.generateQueryString({
      userId: id,
    });
    const path = `${BASE_API_ROUTES.getUserEmailById}${query}`;
    return sendRequest(path);
  }

  const hideConfirmVerifiedBanner = async ({ userId }) => {
    const query = httpUtils.generateQueryString({
      userId,
    });
    const path = `${BASE_API_ROUTES.hideConfirmVerifiedBanner}${query}`;
    const response = await sendRequest(path);
    if (response?.Message) {
      updateAccessKeyInLocalStorage(response.Message);
    }
    return response;
  }

  const getAllTechTypeByIntegration = async ({ integrationId }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.GetAllTechTypeByIntegration, authTokens, {
      optionEnum1: integrationId,
    });
  }

  const createMultipleIntegrationTechTool = async ({ 
    ObjectId, ToolName, Selected, IntegrationTechList, 
  }) => {
    const formData = new FormData();
    formData.append('ObjectId', ObjectId);
    formData.append('ToolName', ToolName);
    formData.append('Selected', Selected);
    formData.append('accessKey', userUtils.getUserMessage());
    formData.append('IntegrationTechnology_List', JSON.stringify(IntegrationTechList));
    formData.append('ObjId_Customer', 0);
    formData.append('IsMultipleIntegrationAllowed', true);
    formData.append('optionEnum99', 26);
    return sendRequest(BASE_API_ROUTES.CreateMultipleIntegrationTechTool, 'POST', formData);
  }

  const checkIntegrationSyncState = async ({ integrationId }) => {
    const query = httpUtils.generateQueryString({
      objId_integration: integrationId,
      accessKey: userUtils.getUserMessage(),
    });
    const path = `${BASE_API_ROUTES.CheckIntegrationSyncState}${query}`;
    return sendRequest(path);
  }

  const checkToolInUse = async ({ integrationId }) => {
    return getAPIData(BASE_API_PAGE_ENUMS.CheckToolInUse, authTokens, {
      optionEnum1: integrationId,
    });
  }

  const deleteIntegration = async ({ integrationId }) => {
    return IndependentGetAPIData(BASE_API_PAGE_ENUMS.DeleteIntegration, authTokens, {
      optionEnum1: integrationId,
      optionEnum2: 0
    }).then((response) => {
      if (response?.Success) {
        onSuccess();
      } else {
        onError();
      }
    });
  }

  const removeIntegration = async ({ integrationId }) => {
    return IndependentGetAPIData(BASE_API_PAGE_ENUMS.RemoveIntegration, authTokens, {
      optionEnum1: integrationId,
    }).then((response) => {
      if (response?.Success) {
        onSuccess();
      } else {
        onError();
      }
    });
  }

  return {
    getAllIntegrationsByTechType,
    saveIntegrationsInfo,
    renameIntegration,
    submitNotificationSettings,
    getClientsDashboardV2,
    getTechnologySpend,
    submitQuestionNote,
    getAllAssetSources,
    getUserDeviceIssues,
    getAddSubscriptionSpendCalcs,
    uploadFile,
    getExportPackageReportMenuSort,
    updateToolToYes,
    createTaxRate,
    getTaxesList,
    getPagedPackageProduct,
    refreshAccessToken,
    checkToolInUse,
    getUserEmailById,
    hideConfirmVerifiedBanner,
    getAllTechTypeByIntegration,
    createMultipleIntegrationTechTool,
    checkIntegrationSyncState,
    deleteIntegration,
    removeIntegration
  }
}

export default useApiActions;
