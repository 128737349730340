import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../../../../context/Auth";
import { AddEditTaxRateModal, CurrencyDropdown, TaxRateDropdown, deleteTaxRate } from "../../../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import { IndependentGetAPIData, getAPIData, postData } from "../../../../../Utils/commonApi";
import { CallToast } from "../../../../PriceCalculator/modals/CustomToast";
import SelectProductModal from "./SelectProductModal";
import SelectToolDropdown from "./SelectToolDropdown";
import useUpdateTaxRate from "../../../MspAttackSurface.js/addItemInSpendModal/update-tax-rate.data";

export const ImportTemplatesModal = ({
    show,
    hideModal,
    data,
    pageType,
    pageData,
    collectionId,
    selectedItemId,
    collectionType,
    setImportLoading,
    apiEnum,
    updateData = () => { }
}) => {

    const [showImportDetails, setShowImportDetails] = useState();


    useEffect(() => {
        if (showImportDetails) {
            getImportDetails();
        }
    }, [showImportDetails])

    const testData = [{
        objId_Service: 7289822,
        ScoreContributionSelected_List: [{
            ObjId_Source: 13538511,
            ObjId_ScoreContribution: 13538511,
            IntTech: 0,
            ContributionName: "Patch Management",
            ContribType: 2,
            Score: 0,
            CyberScore: 10.680000305175781,
            CustomCreated: false,
            GovRes: 0.0,
            Identify: 0.0,
            Protect: 4.0,
            Detect: 0.0,
            IsCustomProduct: false,
            List_ScoreProducts: [
                {
                    objId_Product: 97283,
                    ProductName: "Test For AMAN",
                    List_ProductTool: [
                        {
                            ObjId_Tool: 7242957,
                            ToolName: "Microsoft Lighthouse",
                            ToolIcon: null
                        },
                        {
                            ObjId_Tool: 3861365,
                            ToolName: "Connectwise Automate", "ToolIcon": null
                        }
                    ]
                }
            ]
        }]
    }]

    const [modalLoading, setModalLoading] = useState(false);
    const [importDetails, setImportDetails] = useState([]);
    const [startData, setStartData] = useState(false);

    async function getImportDetails() {
        setModalLoading(true);
        await getAPIData(992, authTokens, {
            optionEnum1: collectionId/*9865314*/,
        })
            .then((response) => {
                if (response?.mr?.Success) {
                    setImportDetails(response?.ServiceScoreContribution_List ?? [])
                    setStartData(response?.ServiceScoreContribution_List ?? [])
                }
                setModalLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setModalLoading(false);

            });
        // setLoading(false);
        // showHeight(false);
    }

    const [itemName, setItemName] = useState('')
    const [title, setTitle] = useState('')

    const { authTokens } = useAuth();
    const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [userSelectedCurrency, setUserSelectedCurrency] = useState({});
    const [taxList, setTaxList] = useState([]);
    const [selectedTax, setSelectedTax] = useState();
    const [taxRateData, setTaxRateData] = useState({});
    const [ForeignCurrencyRate, setForeignCurrencyRate] = useState(1);
    const [exchangeRateData, setExchangeRateData] = useState({});
    const [defaultCurrency, setDefaultCurrency] = useState({});
    const [isRenameClicked, setIsRenameClicked] = useState(false);
    const [renameId, setRenameId] = useState(0);
    const { isLoading: isTaxRateUpdating, updateTaxRate } = useUpdateTaxRate();

    useEffect(() => {
        if (pageType?.PageId == 1) {
            getTaxRateList(true);
            setSelectedTax(data?.TaxRates_Selected);
            setForeignCurrencyRate(data?.ForeignCurrencyRate);
            updateExchangeRateData(
                data?.CurrencyCode_Selected?.CurrencyName ?? currencyList?.find(f => f.IsDefault)?.CurrencyName ?? "GBP"
            );
            setDefaultCurrency(currencyList?.find(f => f.IsDefault))
            setUserSelectedCurrency(
                data?.CurrencyCode_Selected ??
                (currencyList && currencyList?.find(f => f.IsDefault))
            );
        } else {
            setCurrencyList(data?.CurrencyCode_List);
            setTaxList(data?.TaxRates_List);
            setSelectedTax(data?.TaxRates_Selected);
            setForeignCurrencyRate(data?.ForeignCurrencyRate);
            updateExchangeRateData(
                data?.CurrencyCode_Selected?.CurrencyName ?? data?.CurrencyCode_List?.find(f => f.IsDefault)?.CurrencyName ?? "GBP"
            );
            setDefaultCurrency(data?.CurrencyCode_List?.find(f => f.IsDefault))
            setUserSelectedCurrency(
                data?.CurrencyCode_Selected ??
                (data?.CurrencyCode_List && data?.CurrencyCode_List?.find(f => f.IsDefault))
            );
        }
        if (pageType?.PageId == 1) {
            setTitle(pageData?.CollectionName);
        }
        if (pageType?.PageId == 2) {
            setItemName(data?.ScenarioHeader?.Name + ' - Clone');
            setTitle(data?.ScenarioHeader?.Name);
        }
        if (pageType?.PageId == 3) {
            setItemName(data?.Headline + ' - Clone');
            setTitle(data?.Headline);
        }
        if (pageType?.PageId == 4) {
            setItemName(data?.ProductName + ' - Clone');
            setTitle(data?.ProductName);
        }
    }, [data, pageData])


    const includes = [
        {
            id: 0,
            img: '/images/other-apps-icon.svg',
            includeText: `${pageData?.ServiceCount} ${pageData?.ServiceCount == 1 ? 'Service' : 'Services'}` ?? '0 Services'

        },
        {
            id: 1,
            img: '/images/box-icon.svg',
            includeText: `${pageData?.ProductCount} ${pageData?.ProductCount == 1 ? 'Product' : 'Products'}` ?? '0 Products'

        }
    ]

    const clientId = window.localStorage.getItem("object_Data")
        ? JSON.parse(window.localStorage.getItem("object_Data"))?.objId_Client : null;

    useEffect(() => {
        if (userSelectedCurrency?.CurrencyName || defaultCurrency?.CurrencyName) {
            updateExchangeRateData(userSelectedCurrency?.CurrencyName ?? defaultCurrency?.CurrencyName ?? "GBP");
        }
    }, [userSelectedCurrency]);

    function updateExchangeRateData(_currency = "GBP") {
        return new Promise((resolve, reject) => {
            postData(authTokens, "GetCurrencyConverionRate", {
                FromCurrency: _currency,
                ToCurrency: "GBP",
                accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            })
                .then((response) => {
                    resolve(response);
                    setExchangeRateData(response);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }


    function getTaxRateList(setCurrency = false) {
        return new Promise((resolve, reject) => {
            IndependentGetAPIData("632", authTokens, {
                ObjId_Customer: clientId,
                accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            })
                .then((response) => {
                    resolve(response);
                    setTaxList(response?.TaxRates_List ?? []);
                    if (setCurrency) {
                        setCurrencyList(response?.CurrencyCode_List ?? []);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    function onClickUpdateTaxRate(item) {
        return new Promise((resolve, reject) => {
          updateTaxRate(item)
            .then(() => {
              getTaxRateList();
              resolve();
            })
        });
      }

    function updateTaxList(stoploading = false) {
        if (!stoploading) {
        }
        getTaxRateList()
            .then((response) => {
                setTaxList(response?.TaxRates_List);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function importPackageTemplate() {
        postData(authTokens, "ImportPackageTemplate", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            ObjId_Package: data?.ScenarioHeader?.objId_Scenario,
            PackageName: itemName,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
            TaxRates_Selected: JSON.stringify(selectedTax)
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }


    function importServiceTemplate() {
        postData(authTokens, "ImportServiceTemplate", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            objId_Service: data?.objId_SecurityPack,
            ServiceName: itemName,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
            TaxRates_Selected: JSON.stringify(selectedTax)
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }


    function importProductTemplate() {
        postData(authTokens, "ImportProductTemplate", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            objId_Product: data?.ObjId_PSAProduct,
            ProductName: itemName,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
            TaxRates_Selected: JSON.stringify(selectedTax)
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    function importPackageTemplateCollection() {
        postData(authTokens, "ImportPackageTemplateCollection", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            ObjId_Collection: collectionId,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency ?? { "ObjectId": 4097775, "CurrencyName": "GBP", "CurrencySymbol": "£", "FullCurrencyName": "British Pound", "IsDefault": false }),
            TaxRates_Selected: JSON.stringify(selectedTax ?? { "ObjectId": 0, "TaxCode": "Not Taxable", "TaxName": "Not Taxable", "TaxRate": 0, "IsDefault": true, "IntegrationId": 0 })
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    function importServiceTemplateCollection() {
        postData(authTokens, "ImportServiceTemplateCollection", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            objId_Service: collectionId,
            ServiceName: title,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency ?? { "ObjectId": 4097775, "CurrencyName": "GBP", "CurrencySymbol": "£", "FullCurrencyName": "British Pound", "IsDefault": false }),
            TaxRates_Selected: JSON.stringify(selectedTax ?? { "ObjectId": 0, "TaxCode": "Not Taxable", "TaxName": "Not Taxable", "TaxRate": 0, "IsDefault": true, "IntegrationId": 0 })
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    function importProductTemplateCollection() {
        postData(authTokens, "ImportProductTemplateCollection", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            objId_Product: collectionId,
            ProductName: title,
            ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
            CurrencyCode_Selected: JSON.stringify(userSelectedCurrency ?? { "ObjectId": 4097775, "CurrencyName": "GBP", "CurrencySymbol": "£", "FullCurrencyName": "British Pound", "IsDefault": false }),
            TaxRates_Selected: JSON.stringify(selectedTax ?? { "ObjectId": 0, "TaxCode": "Not Taxable", "TaxName": "Not Taxable", "TaxRate": 0, "IsDefault": true, "IntegrationId": 0 })
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");
                    updateData();
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    const [newProductName, setNewProductName] = useState("");
    const [selectedHeaderTab, setSelectedHeaderTab] = useState({
        id: 0,
        label: 'Technologies'
    });
    const headerTabs = [{
        id: 0,
        label: 'Technologies'
    },
    {
        id: 1,
        label: 'Practices'
    }]

    const modalData = [{
        technologyId: 1,
        technologyName: 'Email Protection',
        products: [{
            productName: 'Mimecast Core License',
            isDefault: true,
            productId: 1
        }],
        tools: [{
            toolName: 'Mimecast',
            toolIcon: 607325
        }]
    },
    {
        technologyId: 2,
        technologyName: 'Email Protection',
        products: [{
            productName: 'Microsoft Defender for Office 365',
            isDefault: false,
            productId: 2
        }],
        tools: [{
            toolName: 'Mimecast',
            toolIcon: 607325
        },
        {
            toolName: 'Microsoft Defender',
            toolIcon: 607325
        }]
    },
    {
        technologyId: 3,
        technologyName: 'Patch Management',
        products: [],
        tools: []
    }]

    const modalData2 = [{
        practiceName: 'Incident Response Reviews',
        productsOrLabours: [{
            labour: 'Mimecast',
            icon: 607325
        },
        {
            labour: 'Microsoft Defender',
            icon: null
        },
        {
            labour: 'Incident Product',
            icon: null
        }],
        noProductSwitch: false
    },
    {
        practiceName: 'IT BCDR Testing',
        productsOrLabours: [],
        noProductSwitch: true
    }]

    function renameProduct(productId, newName) {
        postData(authTokens, "RenameProductName", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            ObjId_Product: productId,
            ProductName: newName
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Renamed successfully", false, false, "v2style");

                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }


    function resetToDefaultProductProduct(passedItem) {
        postData(authTokens, "CollectionResetToDefaultProduct", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            ServiceScoreContribution: passedItem ?? {},
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Changed successfully", false, false, "v2style");

                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    function linkedPracticesToTool() {
        postData(authTokens, "CollectionResetToDefaultProduct", {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            ServiceScoreContribution_List: JSON.stringify(importDetails ?? []),
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Imported successfully", false, false, "v2style");

                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setImportLoading(false);
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setImportLoading(false);
            });
    }

    const [isReplaceWithClicked, setIsReplaceWithClicked] = useState(false);
    const [replaceWithId, setReplaceWithId] = useState(0);

    function checkIfReplaced(id, findFrom, contributionName) {
        return findFrom?.find(({ ScoreContributionSelected_List }) =>
            ScoreContributionSelected_List?.find(({ ContributionName, List_ScoreProducts }) =>
                ContributionName == contributionName &&
                List_ScoreProducts?.find(({ replacedTo }) => replacedTo == id)
            )
        )?.ScoreContributionSelected_List?.find(({ ContributionName, List_ScoreProducts }) =>
            ContributionName == contributionName
        )?.List_ScoreProducts?.find(({ replacedTo }) => replacedTo == id);
    }

    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName={`${showImportDetails ? 'import-template-modal-details' : 'import-template-modal'}`}
            className=" "
            aria-labelledby={`${showImportDetails ? 'import-template-modal-details' : 'import-template-modal'}`}
            centered
            onHide={() => {
                hideModal();
                setShowImportDetails(false);
                setSelectedHeaderTab({
                    id: 0,
                    label: 'Technologies'
                });
            }}
        >
            {
                showImportDetails && <Modal.Header>
                    <div className="d-flex align-items-center">
                        {
                            headerTabs?.map((headerTabItem, index) => {
                                return (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={`f-500 f-14 ${selectedHeaderTab?.id == headerTabItem?.id ? 'f-black' : 'f-grey pointer'}`}
                                            onClick={() => {
                                                setSelectedHeaderTab(headerTabItem);
                                            }}
                                        >
                                            {headerTabItem?.label}
                                        </div>
                                        {
                                            index < (headerTabs?.length - 1) && <img
                                                alt=""
                                                src="/images/msp/integrations/chevron-right.svg"
                                                className="mx-2"
                                                width={10}
                                                height={11}
                                            />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                {
                    showImportDetails ? <div>
                        <div>
                            <div className="f-500 f-16 f-black line-height-28 letter-space-2">
                                {selectedHeaderTab?.id == 0 ?
                                    'Link technologies to products and tools in your security stack' :
                                    'Link practices to tools in your security stack'}
                            </div>
                            <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 my-1">
                                {selectedHeaderTab?.id == 0 ?
                                    'When importing products, services and packages from the HighGround Template Library, you must link the technologies to the products and tools in your existing security stack, or create and link them here.' :
                                    'When importing products, services and packages, you must link the practices to the products and tools in your existing security stack.'}
                            </div>

                            {
                                selectedHeaderTab?.id == 0 ? <div className="w-100 bg-grey radius-4 p-2 mt-3"
                                    style={{
                                        height: '368px',
                                        overflowY: 'scroll',
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none'
                                    }}
                                >
                                    <div className="d-flex align-items-center w-100">
                                        <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-33">
                                            Technologies
                                        </div>
                                        <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-33">
                                            Products
                                        </div>
                                        <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-33">
                                            Tools
                                        </div>
                                    </div>

                                    {
                                        modalLoading ? <div
                                            className="d-flex align-items-center justify-content-center w-100"
                                            style={{
                                                marginTop: '80px',
                                                marginBottom: '80px'
                                            }}
                                        >
                                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                                            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                                        </div> : <div>
                                            {
                                                importDetails?.length > 0 ?
                                                    <div>
                                                        {

                                                            importDetails?.map((data) => {
                                                                return (
                                                                    <div>
                                                                        {
                                                                            data?.ScoreContributionSelected_List?.filter(contribution => contribution.ContribType == 2)?.length > 0 ?
                                                                                <div>
                                                                                    {
                                                                                        data?.ScoreContributionSelected_List?.filter(contribution => contribution.ContribType == 2)?.map((dataItem) => {
                                                                                            return (
                                                                                                <div className="d-flex mt-2">
                                                                                                    <div className="w-33 d-flex justify-content-between">
                                                                                                        <div className="f-400 f-14 f-black line-height-22 letter-space-2">

                                                                                                            {dataItem?.ContributionName}
                                                                                                        </div>
                                                                                                        <img
                                                                                                            alt=""
                                                                                                            src="/images/attack-surface/right-arrow.svg"
                                                                                                            className="mr-3"
                                                                                                            style={{
                                                                                                                marginTop: '6px'
                                                                                                            }}
                                                                                                            width={10}
                                                                                                            height={11}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className="w-66">
                                                                                                        {
                                                                                                            dataItem?.List_ScoreProducts?.length > 0 ? <div>
                                                                                                                {
                                                                                                                    dataItem?.List_ScoreProducts?.map((productItem) => {
                                                                                                                        return (
                                                                                                                            <div className="w-100 d-flex mt-1">
                                                                                                                                <div className="w-50">
                                                                                                                                    {
                                                                                                                                        isRenameClicked && renameId == productItem?.objId_Product ? <div className="d-flex align-items-center pr-3">
                                                                                                                                            <div className="always-highlight-input w-100 d-flex align-items-center p-1">
                                                                                                                                                <img
                                                                                                                                                    alt=""
                                                                                                                                                    src={`${(checkIfReplaced(productItem?.objId_Product, startData, dataItem?.ContributionName) || productItem?.isAdded) ? '/images/box-icon-blue.svg' : '/images/box-icon.svg'}`}
                                                                                                                                                    className="mr-2"
                                                                                                                                                    width={13}
                                                                                                                                                    height={13}
                                                                                                                                                />
                                                                                                                                                <input
                                                                                                                                                    autoFocus={true}
                                                                                                                                                    className="pl-2 flex-grow-1"
                                                                                                                                                    placeholder="New name"
                                                                                                                                                    type="text"
                                                                                                                                                    style={{
                                                                                                                                                        maxWidth: '160px',
                                                                                                                                                    }}
                                                                                                                                                    value={newProductName}
                                                                                                                                                    onChange={(e) => {
                                                                                                                                                        setNewProductName(e?.target?.value);
                                                                                                                                                    }}
                                                                                                                                                />

                                                                                                                                                <div className="mb-1"
                                                                                                                                                    style={{
                                                                                                                                                        marginLeft: '6px'
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div className="custom-checkbox-button"
                                                                                                                                                        onClick={() => {
                                                                                                                                                            const updatedData = importDetails.map(service => {
                                                                                                                                                                return {
                                                                                                                                                                    ...service,
                                                                                                                                                                    ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                                        if (contribution.ContributionName == dataItem?.ContributionName) {
                                                                                                                                                                            return {
                                                                                                                                                                                ...contribution,
                                                                                                                                                                                List_ScoreProducts: contribution.List_ScoreProducts.map(product => {
                                                                                                                                                                                    if (product.objId_Product === productItem?.objId_Product) {
                                                                                                                                                                                        return {
                                                                                                                                                                                            ...product,
                                                                                                                                                                                            ProductName: newProductName
                                                                                                                                                                                        };
                                                                                                                                                                                    }
                                                                                                                                                                                    return product;
                                                                                                                                                                                })
                                                                                                                                                                            };
                                                                                                                                                                        }
                                                                                                                                                                        return contribution;
                                                                                                                                                                    })
                                                                                                                                                                };
                                                                                                                                                            });

                                                                                                                                                            setImportDetails(updatedData);
                                                                                                                                                            renameProduct(productItem?.objId_Product, newProductName);
                                                                                                                                                            setIsRenameClicked(false);
                                                                                                                                                            setRenameId(0);
                                                                                                                                                            setNewProductName("");
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <img
                                                                                                                                                            alt=""
                                                                                                                                                            src="/images/check-white.svg"
                                                                                                                                                            className=""
                                                                                                                                                            width={13}
                                                                                                                                                            height={13}
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>

                                                                                                                                            <img
                                                                                                                                                alt=""
                                                                                                                                                src="/images/attack-surface/right-arrow.svg"
                                                                                                                                                className="ml-2"
                                                                                                                                                style={{
                                                                                                                                                    marginTop: '6px'
                                                                                                                                                }}
                                                                                                                                                width={10}
                                                                                                                                                height={11}
                                                                                                                                            />
                                                                                                                                        </div> : <div className="products_grid">

                                                                                                                                            <SelectProductModal
                                                                                                                                                children={<div className="d-flex align-items-center">
                                                                                                                                                    <img
                                                                                                                                                        alt=""
                                                                                                                                                        src={`${(checkIfReplaced(productItem?.objId_Product, startData, dataItem?.ContributionName) || productItem?.isAdded) ? '/images/box-icon-blue.svg' : '/images/box-icon.svg'}`}
                                                                                                                                                        className="mr-2"
                                                                                                                                                        width={13}
                                                                                                                                                        height={13}
                                                                                                                                                    />
                                                                                                                                                    <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                                                                                                                                                        {productItem?.ProductName.length > 18
                                                                                                                                                            ? `${productItem.ProductName.substring(0, 18)}...`
                                                                                                                                                            : productItem.ProductName}
                                                                                                                                                    </div>
                                                                                                                                                </div>}
                                                                                                                                                contributionName={dataItem?.ContributionName}
                                                                                                                                                contributionId={dataItem?.ObjId_ScoreContribution}
                                                                                                                                                importDetails={importDetails}
                                                                                                                                                setImportDetails={setImportDetails}
                                                                                                                                                startData={startData}
                                                                                                                                                setStartData={setStartData}
                                                                                                                                                checkIfReplaced={checkIfReplaced}
                                                                                                                                                productIdToReplace={productItem?.objId_Product}
                                                                                                                                                isReplaceWithClicked={replaceWithId == productItem?.objId_Product ? isReplaceWithClicked : false}
                                                                                                                                                setIsReplaceWithClicked={setIsReplaceWithClicked}
                                                                                                                                            />
                                                                                                                                            <Dropdown className="transparennt-wrapper-dropdown">
                                                                                                                                                <Dropdown.Toggle>
                                                                                                                                                    <img
                                                                                                                                                        alt=""
                                                                                                                                                        src="/images/horizontal-dots.svg"
                                                                                                                                                        className="mx-2 mt-1 pointer"
                                                                                                                                                        width={12}
                                                                                                                                                        height={12}
                                                                                                                                                    />
                                                                                                                                                </Dropdown.Toggle>
                                                                                                                                                <Dropdown.Menu>
                                                                                                                                                    <div
                                                                                                                                                        className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            setNewProductName(productItem?.ProductName);
                                                                                                                                                            setRenameId(productItem?.objId_Product);
                                                                                                                                                            setIsRenameClicked(true);
                                                                                                                                                            e.target
                                                                                                                                                                .closest(".transparennt-wrapper-dropdown")
                                                                                                                                                                .click();
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        Rename
                                                                                                                                                    </div>
                                                                                                                                                    <div
                                                                                                                                                        className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2"
                                                                                                                                                        onClick={(e) => {
                                                                                                                                                            setReplaceWithId(productItem?.objId_Product);
                                                                                                                                                            setIsReplaceWithClicked(true);
                                                                                                                                                            e.target
                                                                                                                                                                .closest(".transparennt-wrapper-dropdown")
                                                                                                                                                                .click();
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        Replace with...
                                                                                                                                                    </div>
                                                                                                                                                    {
                                                                                                                                                        checkIfReplaced(productItem?.objId_Product, startData, dataItem?.ContributionName) && <div
                                                                                                                                                            className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2"
                                                                                                                                                            onClick={(e) => {
                                                                                                                                                                const replacedFrom = checkIfReplaced(productItem?.objId_Product, startData, dataItem?.ContributionName);
                                                                                                                                                                const updatedData = importDetails.map(service => {
                                                                                                                                                                    return {
                                                                                                                                                                        ...service,
                                                                                                                                                                        ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                                            if (contribution.ContributionName == dataItem?.ContributionName) {
                                                                                                                                                                                return {
                                                                                                                                                                                    ...contribution,
                                                                                                                                                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                                                                                                                                                        if (item.objId_Product === productItem?.objId_Product) {
                                                                                                                                                                                            return {
                                                                                                                                                                                                ProductName: replacedFrom?.ProductName,
                                                                                                                                                                                                objId_Product: replacedFrom?.objId_Product,
                                                                                                                                                                                                List_ProductTool: [
                                                                                                                                                                                                    ...item?.List_ProductTool
                                                                                                                                                                                                ]
                                                                                                                                                                                            };
                                                                                                                                                                                        }
                                                                                                                                                                                        return item;
                                                                                                                                                                                    })
                                                                                                                                                                                };
                                                                                                                                                                            }
                                                                                                                                                                            return contribution;
                                                                                                                                                                        })
                                                                                                                                                                    };
                                                                                                                                                                });

                                                                                                                                                                setImportDetails(updatedData);

                                                                                                                                                                const updatedStartData = startData.map(service => {
                                                                                                                                                                    return {
                                                                                                                                                                        ...service,
                                                                                                                                                                        ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                                            if (contribution.ContributionName == dataItem?.ContributionName) {
                                                                                                                                                                                return {
                                                                                                                                                                                    ...contribution,
                                                                                                                                                                                    List_ScoreProducts: contribution.List_ScoreProducts.map(item => {
                                                                                                                                                                                        if (item.objId_Product === productItem?.objId_Product) {
                                                                                                                                                                                            return {
                                                                                                                                                                                                ProductName: replacedFrom?.ProductName,
                                                                                                                                                                                                objId_Product: replacedFrom?.objId_Product,
                                                                                                                                                                                                List_ProductTool: [
                                                                                                                                                                                                    ...item?.List_ProductTool
                                                                                                                                                                                                ]
                                                                                                                                                                                            };
                                                                                                                                                                                        }
                                                                                                                                                                                        return item;
                                                                                                                                                                                    })
                                                                                                                                                                                };
                                                                                                                                                                            }
                                                                                                                                                                            return contribution;
                                                                                                                                                                        })
                                                                                                                                                                    };
                                                                                                                                                                });

                                                                                                                                                                setStartData(updatedStartData);

                                                                                                                                                                e.target
                                                                                                                                                                    .closest(".transparennt-wrapper-dropdown")
                                                                                                                                                                    .click();
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            Reset to default
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                </Dropdown.Menu>
                                                                                                                                            </Dropdown>
                                                                                                                                            {
                                                                                                                                                !(checkIfReplaced(productItem?.objId_Product, startData, dataItem?.ContributionName) || productItem?.isAdded) ? <SelectProductModal
                                                                                                                                                    children={<img
                                                                                                                                                        alt=""
                                                                                                                                                        src='/images/chevron-down.svg'
                                                                                                                                                        className="mx-2 mt-1 pointer"
                                                                                                                                                        width={10}
                                                                                                                                                        height={10}
                                                                                                                                                    />}
                                                                                                                                                    contributionName={dataItem?.ContributionName}
                                                                                                                                                    contributionId={dataItem?.ObjId_ScoreContribution}
                                                                                                                                                    importDetails={importDetails}
                                                                                                                                                    setImportDetails={setImportDetails}
                                                                                                                                                    startData={startData}
                                                                                                                                                    setStartData={setStartData}
                                                                                                                                                    checkIfReplaced={checkIfReplaced}
                                                                                                                                                    productIdToReplace={productItem?.objId_Product}
                                                                                                                                                /> :
                                                                                                                                                    <img
                                                                                                                                                        alt=""
                                                                                                                                                        src='/images/grey-cross.svg'
                                                                                                                                                        className="mx-2 mt-1 pointer"
                                                                                                                                                        width={10}
                                                                                                                                                        height={10}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            const updatedData = importDetails.map(service => {
                                                                                                                                                                return {
                                                                                                                                                                    ...service,
                                                                                                                                                                    ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                                        if (contribution.ContributionName === dataItem?.ContributionName) {
                                                                                                                                                                            return {
                                                                                                                                                                                ...contribution,
                                                                                                                                                                                List_ScoreProducts: contribution.List_ScoreProducts.filter(product => {
                                                                                                                                                                                    return product.objId_Product !== productItem?.objId_Product;
                                                                                                                                                                                })
                                                                                                                                                                            };
                                                                                                                                                                        }
                                                                                                                                                                        return contribution;
                                                                                                                                                                    })
                                                                                                                                                                };
                                                                                                                                                            });

                                                                                                                                                            setImportDetails(updatedData);
                                                                                                                                                        }}
                                                                                                                                                    />
                                                                                                                                            }
                                                                                                                                            <img
                                                                                                                                                alt=""
                                                                                                                                                src="/images/attack-surface/right-arrow.svg"
                                                                                                                                                className="ml-2"
                                                                                                                                                style={{
                                                                                                                                                    marginTop: '6px'
                                                                                                                                                }}
                                                                                                                                                width={10}
                                                                                                                                                height={11}
                                                                                                                                            />
                                                                                                                                        </div>

                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                                <div className="w-50">
                                                                                                                                    {
                                                                                                                                        productItem?.List_ProductTool?.length > 0 ?
                                                                                                                                            productItem?.List_ProductTool?.map((toolItem, toolIndex) => {
                                                                                                                                                return (
                                                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                                                        <div className="d-flex align-items-center bg-greyish radius-2 mt-1 px-1 w-fit-content">
                                                                                                                                                            <img
                                                                                                                                                                alt=""
                                                                                                                                                                src={`${toolItem?.ObjId_Tool ? `/images/attack-surface/integrations/${toolItem?.ObjId_Tool}.webp` : '/images/attack-surface/generic2-dark.svg'}`}
                                                                                                                                                                className="mr-1 rounded-full"
                                                                                                                                                                width={16}
                                                                                                                                                                height={16}
                                                                                                                                                            />
                                                                                                                                                            <div className="f-400 f-14 f-black line-height-22 letter-space-2 pointer">
                                                                                                                                                                {toolItem?.ToolName}
                                                                                                                                                            </div>
                                                                                                                                                            <img
                                                                                                                                                                alt=""
                                                                                                                                                                src="/images/grey-cross.svg"
                                                                                                                                                                className="ml-1 pointer"
                                                                                                                                                                width={10}
                                                                                                                                                                height={10}
                                                                                                                                                                onClick={() => {
                                                                                                                                                                    const updatedData = importDetails.map(service => {
                                                                                                                                                                        return {
                                                                                                                                                                            ...service,
                                                                                                                                                                            ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                                                if (contribution.ContributionName === dataItem?.ContributionName) {
                                                                                                                                                                                    return {
                                                                                                                                                                                        ...contribution,
                                                                                                                                                                                        List_ScoreProducts: contribution.List_ScoreProducts.map(product => {
                                                                                                                                                                                            if (product.objId_Product === productItem?.objId_Product) {
                                                                                                                                                                                                return {
                                                                                                                                                                                                    ...product,
                                                                                                                                                                                                    List_ProductTool: product.List_ProductTool.filter(tool => tool.ObjId_Tool !== toolItem?.ObjId_Tool)
                                                                                                                                                                                                };
                                                                                                                                                                                            }
                                                                                                                                                                                            return product;
                                                                                                                                                                                        })
                                                                                                                                                                                    };
                                                                                                                                                                                }
                                                                                                                                                                                return contribution;
                                                                                                                                                                            })
                                                                                                                                                                        };
                                                                                                                                                                    });

                                                                                                                                                                    setImportDetails(updatedData);
                                                                                                                                                                }}
                                                                                                                                                            />
                                                                                                                                                        </div>
                                                                                                                                                        {
                                                                                                                                                            toolIndex == (productItem?.List_ProductTool?.length - 1) &&
                                                                                                                                                            <SelectToolDropdown
                                                                                                                                                                children={<div
                                                                                                                                                                    className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                                                                >
                                                                                                                                                                    Add
                                                                                                                                                                </div>}
                                                                                                                                                                contributionName={dataItem?.ContributionName}
                                                                                                                                                                productId={productItem?.objId_Product}
                                                                                                                                                                importDetails={importDetails}
                                                                                                                                                                setImportDetails={setImportDetails}
                                                                                                                                                            />
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            }) : <SelectToolDropdown
                                                                                                                                                children={<div className="d-flex align-items-center pointer">
                                                                                                                                                    <img
                                                                                                                                                        alt=""
                                                                                                                                                        src="/images/warningTriangle.svg"
                                                                                                                                                        // className="mr-1"
                                                                                                                                                        width={14}
                                                                                                                                                        height={12}
                                                                                                                                                    />
                                                                                                                                                    <div
                                                                                                                                                        className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                                                    >
                                                                                                                                                        Add
                                                                                                                                                    </div>
                                                                                                                                                </div>}
                                                                                                                                                contributionName={dataItem?.ContributionName}
                                                                                                                                                productId={productItem?.objId_Product}
                                                                                                                                                importDetails={importDetails}
                                                                                                                                                setImportDetails={setImportDetails}
                                                                                                                                            />


                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div> : <div className="w-100 d-flex ">
                                                                                                                <div className="d-flex align-items-center justify-content-between w-50">
                                                                                                                    <SelectProductModal
                                                                                                                        children={<div className="d-flex align-items-center pointer ">
                                                                                                                            <img
                                                                                                                                alt=""
                                                                                                                                src="/images/warningTriangle.svg"
                                                                                                                                // className="mr-1"
                                                                                                                                width={14}
                                                                                                                                height={12}
                                                                                                                            />
                                                                                                                            <div
                                                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                            >
                                                                                                                                Add
                                                                                                                            </div>
                                                                                                                        </div>}
                                                                                                                        contributionName={dataItem?.ContributionName}
                                                                                                                        contributionId={dataItem?.ObjId_ScoreContribution}
                                                                                                                        importDetails={importDetails}
                                                                                                                        setImportDetails={setImportDetails}
                                                                                                                        startData={startData}
                                                                                                                        setStartData={setStartData}
                                                                                                                        checkIfReplaced={checkIfReplaced}
                                                                                                                    />
                                                                                                                    {/* <div className="d-flex align-items-center pointer ">
                                                                                            <img
                                                                                                alt=""
                                                                                                src="/images/warningTriangle.svg"
                                                                                                // className="mr-1"
                                                                                                width={14}
                                                                                                height={12}
                                                                                            />
                                                                                            <div
                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                            >
                                                                                                Add
                                                                                            </div>
                                                                                        </div> */}
                                                                                                                    <img
                                                                                                                        alt=""
                                                                                                                        src="/images/attack-surface/right-arrow.svg"
                                                                                                                        className="ml-2 mr-3"
                                                                                                                        style={{
                                                                                                                            marginTop: '6px'
                                                                                                                        }}
                                                                                                                        width={10}
                                                                                                                        height={11}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <SelectToolDropdown
                                                                                                                    children={<div className="d-flex align-items-center justify-content-between w-50">
                                                                                                                        <div className="d-flex align-items-center pointer ">
                                                                                                                            <img
                                                                                                                                alt=""
                                                                                                                                src="/images/warningTriangle.svg"
                                                                                                                                // className="mr-1"
                                                                                                                                width={14}
                                                                                                                                height={12}
                                                                                                                            />
                                                                                                                            <div
                                                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                            >
                                                                                                                                Add
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>}
                                                                                                                    contributionName={dataItem?.ContributionName}
                                                                                                                />
                                                                                                                {/* <div className="d-flex align-items-center justify-content-between w-50">
                                                                                                        <div className="d-flex align-items-center pointer ">
                                                                                                            <img
                                                                                                                alt=""
                                                                                                                src="/images/warningTriangle.svg"
                                                                                                                // className="mr-1"
                                                                                                                width={14}
                                                                                                                height={12}
                                                                                                            />
                                                                                                            <div
                                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                            >
                                                                                                                Add
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/* <div className="w-33">
                                                                            {
                                                                                dataItem?.List_ScoreProducts?.length > 0 ?
                                                                                    dataItem?.List_ScoreProducts?.map((productItem) => {
                                                                                        return (
                                                                                            <div className="">
                                                                                                {
                                                                                                    isRenameClicked && renameId == productItem?.objId_Product ? <div className="d-flex align-items-center pr-3">
                                                                                                        <div className="always-highlight-input w-100 d-flex align-items-center p-1">
                                                                                                            <img
                                                                                                                alt=""
                                                                                                                src={`${productItem?.isDefault ? '/images/box-icon.svg' : '/images/box-icon-blue.svg'}`}
                                                                                                                className="mr-2"
                                                                                                                width={13}
                                                                                                                height={13}
                                                                                                            />
                                                                                                            <input
                                                                                                                autoFocus={true}
                                                                                                                className="pl-2 flex-grow-1"
                                                                                                                placeholder="New name"
                                                                                                                type="text"
                                                                                                                style={{
                                                                                                                    maxWidth: '160px',
                                                                                                                }}
                                                                                                                value={newProductName}
                                                                                                                onChange={(e) => {
                                                                                                                    setNewProductName(e?.target?.value);
                                                                                                                }}
                                                                                                            />

                                                                                                            <div className="mb-1"
                                                                                                                style={{
                                                                                                                    marginLeft: '6px'
                                                                                                                }}
                                                                                                            >
                                                                                                                <div className="custom-checkbox-button"
                                                                                                                    onClick={() => {
                                                                                                                        const updatedData = importDetails.map(service => {
                                                                                                                            return {
                                                                                                                                ...service,
                                                                                                                                ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                    if (contribution.ContributionName == dataItem?.ContributionName) {
                                                                                                                                        return {
                                                                                                                                            ...contribution,
                                                                                                                                            List_ScoreProducts: contribution.List_ScoreProducts.map(product => {
                                                                                                                                                if (product.objId_Product === productItem?.objId_Product) {
                                                                                                                                                    return {
                                                                                                                                                        ...product,
                                                                                                                                                        ProductName: newProductName
                                                                                                                                                    };
                                                                                                                                                }
                                                                                                                                                return product;
                                                                                                                                            })
                                                                                                                                        };
                                                                                                                                    }
                                                                                                                                    return contribution;
                                                                                                                                })
                                                                                                                            };
                                                                                                                        });

                                                                                                                        setImportDetails(updatedData);
                                                                                                                        setIsRenameClicked(false);
                                                                                                                        setRenameId(0);
                                                                                                                        setNewProductName("");
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <img
                                                                                                                        alt=""
                                                                                                                        src="/images/check-white.svg"
                                                                                                                        className=""
                                                                                                                        width={13}
                                                                                                                        height={13}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <img
                                                                                                            alt=""
                                                                                                            src="/images/attack-surface/right-arrow.svg"
                                                                                                            className="ml-2"
                                                                                                            style={{
                                                                                                                marginTop: '6px'
                                                                                                            }}
                                                                                                            width={10}
                                                                                                            height={11}
                                                                                                        />
                                                                                                    </div> : <div className="products_grid">

                                                                                                        <SelectProductModal
                                                                                                            children={<div className="d-flex align-items-center">
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={`${productItem?.isDefault ? '/images/box-icon.svg' : '/images/box-icon-blue.svg'}`}
                                                                                                                    className="mr-2"
                                                                                                                    width={13}
                                                                                                                    height={13}
                                                                                                                />
                                                                                                                <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                                                                                                                    {productItem?.ProductName.length > 18
                                                                                                                        ? `${productItem.ProductName.substring(0, 18)}...`
                                                                                                                        : productItem.ProductName}
                                                                                                                </div>
                                                                                                            </div>}
                                                                                                            contributionName={dataItem?.CollectionName}
                                                                                                        />
                                                                                                        <Dropdown className="transparennt-wrapper-dropdown">
                                                                                                            <Dropdown.Toggle>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src="/images/horizontal-dots.svg"
                                                                                                                    className="mx-2 mt-1 pointer"
                                                                                                                    width={12}
                                                                                                                    height={12}
                                                                                                                />
                                                                                                            </Dropdown.Toggle>
                                                                                                            <Dropdown.Menu>
                                                                                                                <div
                                                                                                                    className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2"
                                                                                                                    onClick={(e) => {
                                                                                                                        setNewProductName(productItem?.ProductName);
                                                                                                                        setRenameId(productItem?.objId_Product);
                                                                                                                        setIsRenameClicked(true);
                                                                                                                        e.target
                                                                                                                            .closest(".transparennt-wrapper-dropdown")
                                                                                                                            .click();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    Rename
                                                                                                                </div>
                                                                                                                <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2">
                                                                                                                    Replace with...
                                                                                                                </div>
                                                                                                                {
                                                                                                                    !productItem?.isDefault && <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 bg-hover-grey pointer py-1 px-2">
                                                                                                                        Reset to default
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </Dropdown.Menu>
                                                                                                        </Dropdown>
                                                                                                        {
                                                                                                            productItem?.isDefault ? <SelectProductModal
                                                                                                                children={<img
                                                                                                                    alt=""
                                                                                                                    src='/images/chevron-down.svg'
                                                                                                                    className="mx-2 mt-1 pointer"
                                                                                                                    width={10}
                                                                                                                    height={10}
                                                                                                                />}
                                                                                                                contributionName={dataItem?.ContributionName}
                                                                                                            /> :
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src='/images/grey-cross.svg'
                                                                                                                    className="mx-2 mt-1 pointer"
                                                                                                                    width={10}
                                                                                                                    height={10}
                                                                                                                    onClick={() => {
                                                                                                                        const updatedData = importDetails.map(service => {
                                                                                                                            return {
                                                                                                                                ...service,
                                                                                                                                ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                    if (contribution.ContributionName === dataItem?.ContributionName) {
                                                                                                                                        return {
                                                                                                                                            ...contribution,
                                                                                                                                            List_ScoreProducts: contribution.List_ScoreProducts.filter(product => {
                                                                                                                                                // Удаляем продукт, если objId_Product совпадает
                                                                                                                                                return product.objId_Product !== productItem?.objId_Product;
                                                                                                                                            })
                                                                                                                                        };
                                                                                                                                    }
                                                                                                                                    return contribution;
                                                                                                                                })
                                                                                                                            };
                                                                                                                        });

                                                                                                                        setImportDetails(updatedData);
                                                                                                                    }}
                                                                                                                />
                                                                                                        }
                                                                                                        <img
                                                                                                            alt=""
                                                                                                            src="/images/attack-surface/right-arrow.svg"
                                                                                                            className="ml-2"
                                                                                                            style={{
                                                                                                                marginTop: '6px'
                                                                                                            }}
                                                                                                            width={10}
                                                                                                            height={11}
                                                                                                        />
                                                                                                    </div>

                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }) : <div className="d-flex align-items-center justify-content-between">
                                                                                        <div className="d-flex align-items-center pointer">
                                                                                            <img
                                                                                                alt=""
                                                                                                src="/images/warningTriangle.svg"
                                                                                                // className="mr-1"
                                                                                                width={14}
                                                                                                height={12}
                                                                                            />
                                                                                            <div
                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                            >
                                                                                                Add
                                                                                            </div>
                                                                                        </div>
                                                                                        <img
                                                                                            alt=""
                                                                                            src="/images/attack-surface/right-arrow.svg"
                                                                                            style={{
                                                                                                marginTop: '6px',
                                                                                                marginRight: '17px'
                                                                                            }}
                                                                                            width={10}
                                                                                            height={11}
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        <div className="w-33">
                                                                            {
                                                                                dataItem?.List_ScoreProducts?.length > 0 ?
                                                                                    dataItem?.List_ScoreProducts?.map((productItem) => {
                                                                                        return (
                                                                                            <div>
                                                                                                {
                                                                                                    productItem?.List_ProductTool?.length > 0 ?
                                                                                                        productItem?.List_ProductTool?.map((toolItem, toolIndex) => {
                                                                                                            return (
                                                                                                                <div className="d-flex align-items-center">
                                                                                                                    <div className="d-flex align-items-center bg-greyish radius-2 mt-1 px-1 w-fit-content">
                                                                                                                        <img
                                                                                                                            alt=""
                                                                                                                            src={`${toolItem?.ToolIcon ? `/images/attack-surface/integrations/${toolItem?.ToolIcon}.webp` : '/images/attack-surface/generic2-dark.svg'}`}
                                                                                                                            className="mr-1 rounded-full"
                                                                                                                            width={16}
                                                                                                                            height={16}
                                                                                                                        />
                                                                                                                        <div className="f-400 f-14 f-black line-height-22 letter-space-2 pointer">
                                                                                                                            {toolItem?.ToolName}
                                                                                                                        </div>
                                                                                                                        <img
                                                                                                                            alt=""
                                                                                                                            src="/images/grey-cross.svg"
                                                                                                                            className="ml-1"
                                                                                                                            width={10}
                                                                                                                            height={10}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        toolIndex == (productItem?.List_ProductTool?.length - 1) && <div
                                                                                                                            className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                        >
                                                                                                                            Add
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )
                                                                                                        }) : <div className="d-flex align-items-center pointer">
                                                                                                            <img
                                                                                                                alt=""
                                                                                                                src="/images/warningTriangle.svg"
                                                                                                                // className="mr-1"
                                                                                                                width={14}
                                                                                                                height={12}
                                                                                                            />
                                                                                                            <div
                                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                            >
                                                                                                                Add
                                                                                                            </div>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }) : <div className="d-flex align-items-center pointer">
                                                                                        <img
                                                                                            alt=""
                                                                                            src="/images/warningTriangle.svg"
                                                                                            // className="mr-1"
                                                                                            width={14}
                                                                                            height={12}
                                                                                        />
                                                                                        <div
                                                                                            className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                        >
                                                                                            Add
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div> */}
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                : <div
                                                                                    className="d-flex align-items-center justify-content-center w-100 f-400 f-darkgrey"
                                                                                    style={{
                                                                                        marginTop: '80px',
                                                                                        marginBottom: '80px'
                                                                                    }}
                                                                                >
                                                                                    No data yet
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : <div
                                                        className="d-flex align-items-center justify-content-center w-100 f-400 f-darkgrey"
                                                        style={{
                                                            marginTop: '80px',
                                                            marginBottom: '80px'
                                                        }}
                                                    >
                                                        No data yet
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div> : <div className="w-100 bg-grey radius-4 p-2 mt-3"
                                    style={{
                                        height: '368px',
                                        overflowY: 'scroll',
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none'
                                    }}
                                >
                                    <div className="d-flex align-items-center w-100">
                                        <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-33">
                                            Practices
                                        </div>
                                        <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-33">
                                            Products or Labour
                                        </div>
                                    </div>


                                    {
                                        modalLoading ? <div
                                            className="d-flex align-items-center justify-content-center w-100"
                                            style={{
                                                marginTop: '80px',
                                                marginBottom: '80px'
                                            }}
                                        >
                                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                                            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                                        </div> : <div>
                                            {
                                                importDetails?.length > 0 ?
                                                    <div>
                                                        {
                                                            importDetails?.map((data) => {
                                                                return (
                                                                    <div>
                                                                        {
                                                                            data?.ScoreContributionSelected_List?.filter(contribution => contribution.ContribType == 1)?.length ? <div>
                                                                                {
                                                                                    data?.ScoreContributionSelected_List?.filter(contribution => contribution.ContribType == 1)?.map((dataItem) => {
                                                                                        return (
                                                                                            <div className="d-flex mt-2">
                                                                                                <div className="w-40 d-flex justify-content-between">
                                                                                                    <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                                                                                                        {dataItem?.ContributionName}
                                                                                                    </div>
                                                                                                    <img
                                                                                                        alt=""
                                                                                                        src="/images/attack-surface/right-arrow.svg"
                                                                                                        className="mr-3"
                                                                                                        style={{
                                                                                                            marginTop: '6px'
                                                                                                        }}
                                                                                                        width={10}
                                                                                                        height={11}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="w-40">
                                                                                                    {
                                                                                                        dataItem?.List_ScoreProducts?.length > 0 ?
                                                                                                            dataItem?.List_ScoreProducts?.map((productOrLabourItem, productOrLabourIndex) => {
                                                                                                                return (
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <div className="d-flex align-items-center bg-greyish radius-2 mt-1 px-1 w-fit-content">
                                                                                                                            <img
                                                                                                                                alt=""
                                                                                                                                // src={`/images/attack-surface/integrations/${productOrLabourItem?.icon}.webp`}
                                                                                                                                src='/images/box-icon.svg'
                                                                                                                                className="mr-1"
                                                                                                                                width={13}
                                                                                                                                height={13}
                                                                                                                            />
                                                                                                                            <div className="f-400 f-14 f-black line-height-22 letter-space-2 pointer">
                                                                                                                                {productOrLabourItem?.ProductName.length > 25
                                                                                                                                    ? `${productOrLabourItem.ProductName.substring(0, 25)}...`
                                                                                                                                    : productOrLabourItem.ProductName}
                                                                                                                            </div>
                                                                                                                            <img
                                                                                                                                alt=""
                                                                                                                                src="/images/grey-cross.svg"
                                                                                                                                className="m-1 pointer"
                                                                                                                                width={10}
                                                                                                                                height={10}
                                                                                                                                onClick={() => {
                                                                                                                                    const updatedData = importDetails.map(service => {
                                                                                                                                        return {
                                                                                                                                            ...service,
                                                                                                                                            ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                                if (contribution.ContributionName === dataItem?.ContributionName) {
                                                                                                                                                    return {
                                                                                                                                                        ...contribution,
                                                                                                                                                        List_ScoreProducts: contribution.List_ScoreProducts.filter(product => {
                                                                                                                                                            return product.objId_Product !== productOrLabourItem?.objId_Product;
                                                                                                                                                        })
                                                                                                                                                    };
                                                                                                                                                }
                                                                                                                                                return contribution;
                                                                                                                                            })
                                                                                                                                        };
                                                                                                                                    });

                                                                                                                                    setImportDetails(updatedData);
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            productOrLabourIndex == (dataItem?.List_ScoreProducts?.length - 1) &&
                                                                                                                            <SelectProductModal
                                                                                                                                children={<div
                                                                                                                                    className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                                >
                                                                                                                                    Add
                                                                                                                                </div>}
                                                                                                                                contributionName={dataItem?.ContributionName}
                                                                                                                                contributionId={dataItem?.ObjId_ScoreContribution}
                                                                                                                                importDetails={importDetails}
                                                                                                                                setImportDetails={setImportDetails}
                                                                                                                                startData={startData}
                                                                                                                                setStartData={setStartData}
                                                                                                                                checkIfReplaced={checkIfReplaced}
                                                                                                                                fromPractices={true}
                                                                                                                                serviceId={data?.objId_Service}
                                                                                                                            />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }) :
                                                                                                            <div>
                                                                                                                {
                                                                                                                    dataItem?.NoProducts ? <div
                                                                                                                        className={`f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2`}
                                                                                                                    >
                                                                                                                        No Products
                                                                                                                    </div> :
                                                                                                                        <SelectProductModal
                                                                                                                            children={<div
                                                                                                                                className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                                                            >
                                                                                                                                Add
                                                                                                                            </div>}
                                                                                                                            contributionName={dataItem?.ContributionName}
                                                                                                                            contributionId={dataItem?.ObjId_ScoreContribution}
                                                                                                                            importDetails={importDetails}
                                                                                                                            setImportDetails={setImportDetails}
                                                                                                                            startData={startData}
                                                                                                                            setStartData={setStartData}
                                                                                                                            checkIfReplaced={checkIfReplaced}
                                                                                                                            fromPractices={true}
                                                                                                                            serviceId={data?.objId_Service}
                                                                                                                        />
                                                                                                                }
                                                                                                            </div>


                                                                                                        // <div
                                                                                                        //     className={`f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 ${dataItem?.noProductSwitch ? '' : 'pointer'}`}
                                                                                                        // >
                                                                                                        //     {dataItem?.noProductSwitch ? 'No Products' : 'Add'}
                                                                                                        // </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="w-fit-content">
                                                                                                    <OverlayTrigger
                                                                                                        placement="bottom"
                                                                                                        trigger={["hover", "focus"]}
                                                                                                        delay={{ show: 200, hide: 250 }}
                                                                                                        overlay={
                                                                                                            <Tooltip
                                                                                                                id={`tooltip-1365`}
                                                                                                                style={{
                                                                                                                    maxWidth: '120px',
                                                                                                                    maxHeight: '198px'
                                                                                                                }}
                                                                                                            >
                                                                                                                Select if you don’t have a product for this service but deliver this through labour.
                                                                                                            </Tooltip>
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="d-flex align-items-center pointer">
                                                                                                            <Form.Group className="blue-checkbox pointer-checkbox mx-2">
                                                                                                                <Form.Check
                                                                                                                    type="checkbox"
                                                                                                                    name="checkbox"
                                                                                                                    checked={dataItem?.NoProducts}
                                                                                                                    onChange={() => {
                                                                                                                        const updatedData = importDetails.map(service => {
                                                                                                                            return {
                                                                                                                                ...service,
                                                                                                                                ScoreContributionSelected_List: service.ScoreContributionSelected_List.map(contribution => {
                                                                                                                                    if (contribution.ContributionName == dataItem?.ContributionName) {
                                                                                                                                        return {
                                                                                                                                            ...contribution,
                                                                                                                                            List_ScoreProducts: [],
                                                                                                                                            NoProducts: contribution?.NoProducts ? false : true
                                                                                                                                        };
                                                                                                                                    }
                                                                                                                                    return contribution;
                                                                                                                                })
                                                                                                                            };
                                                                                                                        });

                                                                                                                        setImportDetails(updatedData);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </Form.Group>
                                                                                                            <div className="f-500 f-14 f-black line-height-22 letter-space-2">
                                                                                                                No product
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </OverlayTrigger>

                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })

                                                                                }
                                                                            </div> : <div
                                                                                className="d-flex align-items-center justify-content-center w-100 f-400 f-darkgrey"
                                                                                style={{
                                                                                    marginTop: '80px',
                                                                                    marginBottom: '80px'
                                                                                }}
                                                                            >
                                                                                No data yet
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : <div
                                                        className="d-flex align-items-center justify-content-center w-100 f-400 f-darkgrey"
                                                        style={{
                                                            marginTop: '80px',
                                                            marginBottom: '80px'
                                                        }}
                                                    >
                                                        No data yet
                                                    </div>
                                            }
                                        </div>
                                    }

                                    {/* <div>
                                        {
                                            modalData2?.map((dataItem) => {
                                                return (
                                                    <div className="d-flex mt-2">
                                                        <div className="w-33 d-flex justify-content-between">
                                                            <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                                                                {dataItem?.practiceName}
                                                            </div>
                                                            <img
                                                                alt=""
                                                                src="/images/attack-surface/right-arrow.svg"
                                                                className="mr-3"
                                                                style={{
                                                                    marginTop: '6px'
                                                                }}
                                                                width={10}
                                                                height={11}
                                                            />
                                                        </div>
                                                        <div className="w-33">
                                                            {
                                                                dataItem?.productsOrLabours?.length > 0 ?
                                                                    dataItem?.productsOrLabours?.map((productOrLabourItem, productOrLabourIndex) => {
                                                                        return (
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="d-flex align-items-center bg-greyish radius-2 mt-1 px-1 w-fit-content">
                                                                                    <img
                                                                                        alt=""
                                                                                        src={`/images/attack-surface/integrations/${productOrLabourItem?.icon}.webp`}
                                                                                        className="mr-1 rounded-full"
                                                                                        width={16}
                                                                                        height={16}
                                                                                    />
                                                                                    <div className="f-400 f-14 f-black line-height-22 letter-space-2 pointer">
                                                                                        {productOrLabourItem?.labour}
                                                                                    </div>
                                                                                    <img
                                                                                        alt=""
                                                                                        src="/images/grey-cross.svg"
                                                                                        className="ml-1"
                                                                                        width={10}
                                                                                        height={10}
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    productOrLabourIndex == (dataItem?.productsOrLabours?.length - 1) && <div
                                                                                        className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 pointer"
                                                                                    >
                                                                                        Add
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }) : <div
                                                                        className={`f-400 f-14 f-darkgrey line-height-22 letter-space-2 ml-2 ${dataItem?.noProductSwitch ? '' : 'pointer'}`}
                                                                    >
                                                                        {dataItem?.noProductSwitch ? 'No Products' : 'Add'}
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className="w-fit-content">
                                                            <OverlayTrigger
                                                                placement="bottom"
                                                                trigger={["hover", "focus"]}
                                                                delay={{ show: 200, hide: 250 }}
                                                                overlay={
                                                                    <Tooltip
                                                                        id={`tooltip-1365`}
                                                                        style={{
                                                                            maxWidth: '120px',
                                                                            maxHeight: '198px'
                                                                        }}
                                                                    >
                                                                        Select if you don’t have a product for this service but deliver this through labour.
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div className="d-flex align-items-center pointer">
                                                                    <Form.Group className="blue-checkbox pointer-checkbox mx-2">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            name="checkbox"
                                                                            checked={dataItem?.noProductSwitch}
                                                                            onChange={() => {
                                                                                // const updatedData = currentSpendTableData.map(item => {
                                                                                //     if (item.ObjectId === tableItem.ObjectId) {
                                                                                //         return { ...item, IsSelected: !item.IsSelected };
                                                                                //     }
                                                                                //     return item;
                                                                                // });
                                                                                // setCurrentSpendTableData(updatedData);
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                    <div className="f-500 f-14 f-black line-height-22 letter-space-2">
                                                                        No product
                                                                    </div>
                                                                </div>
                                                            </OverlayTrigger>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> */}
                                </div>
                            }

                        </div>
                    </div> : <div
                        className="w-100">
                        <div className="w-100 d-flex justify-content-center align-items-center mt-1">
                            <img
                                alt=""
                                src="/images/file-copy-icon.svg"
                                className="mr-2"
                                width={24}
                                height={24}
                            />
                        </div>
                        <div className="f-500 f-16 f-black d-flex justify-content-center align-items-center mt-3">Import {title}</div>
                        <div className="f-400 f-14 f-darkgrey d-flex justify-content-center align-items-center mt-2">How you would like to Import this template</div>

                        <div className="w-100">
                            {
                                pageType?.PageId == 1 ? <></> :
                                    <div
                                        className={`highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 mt-2`}
                                    >
                                        <div className="f-darkgrey">Name:</div>
                                        <input
                                            tabIndex={0}
                                            type="text"
                                            className="flex-grow-1 pl-1 pr-2 f-black f-400 f-14"
                                            value={itemName}
                                            onChange={(e) => {
                                                setItemName(e?.target?.value);
                                            }}
                                        />
                                    </div>
                            }

                            <div className="mx-2">
                                <div className="w-100 py-2 d-flex align-items-center justify-content-between f-black">
                                    <div className="f-400 f-14 f-black">Currency</div>
                                    <div className="d-flex align-items-center currency-dropdown-container justify-content-end">
                                        <div className="">
                                            <CurrencyDropdown
                                                currencyList={currencyList}
                                                menuClassName={
                                                    "right-align upload-only-template-drop"
                                                }
                                                onChange={(data) => {
                                                    setUserSelectedCurrency(data);
                                                }}
                                                selected={userSelectedCurrency}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="w-100 py-2 d-flex align-items-center justify-content-between f-black">
                                    <div className="f-400 f-14 f-black">Tax Rate</div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="tax-rate-dropdown-container">
                                            <TaxRateDropdown
                                                deleteTaxRate={deleteTaxRate}
                                                updateTaxList={updateTaxList}
                                                onClickMakeDefault={onClickUpdateTaxRate}
                                                isUpdating={isTaxRateUpdating}
                                                onChange={(data) => {
                                                    setSelectedTax(data);
                                                }}
                                                taxList={taxList}
                                                selected={selectedTax}
                                                onClickEditTaxRate={(data) => {
                                                    setTaxRateData(data);
                                                    setShowAddEditTaxRateModal(true);
                                                }}
                                                onClickAddTaxRate={() => {
                                                    setTaxRateData({
                                                        ObjectId: 0,
                                                        TaxCode: "",
                                                        TaxName: "",
                                                        TaxRate: "",
                                                    });
                                                    setShowAddEditTaxRateModal(true);
                                                }}
                                                imgClassName={'mr-0'}
                                            />
                                        </div>
                                        <div
                                            className={`add-edit-tax-rate-modal-wrapper ${showAddEditTaxRateModal ? "show" : ""
                                                }`}
                                        >
                                            <AddEditTaxRateModal
                                                show={showAddEditTaxRateModal}
                                                hideModal={() => {
                                                    setShowAddEditTaxRateModal(false);
                                                }}
                                                taxRateData={taxRateData}
                                                updateTaxList={updateTaxList}
                                                deleteTaxRate={deleteTaxRate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grey-container">
                                <div className="d-flex align-items-center">
                                    <img
                                        alt=""
                                        src="/images/download-icon-grey.svg"
                                        className="mr-2"
                                        width={12}
                                        height={12}
                                    />
                                    <div className="f-400 f-14 greyText2">Additional assets will be imported</div>
                                </div>
                                <div className="ml-3">
                                    {
                                        includes?.map((item) => {
                                            return (
                                                <div className="d-flex">
                                                    <img
                                                        alt=""
                                                        src={item?.img}
                                                        className="mr-2"
                                                        width={10}
                                                        height={10}
                                                    />
                                                    <div className="f-400 f-14 f-black">{item?.includeText}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    showImportDetails ? <div className="d-flex align-items-center justify-content-end w-100">
                        <Button
                            className="hg-cancel-btn mr-2"
                            onClick={() => {
                                hideModal();
                                setShowImportDetails(false);
                                setSelectedHeaderTab({
                                    id: 0,
                                    label: 'Technologies'
                                });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="hg-blue-btn mr-2"
                            onClick={() => {
                                if (selectedHeaderTab?.id == 0) {
                                    setSelectedHeaderTab({
                                        id: 1,
                                        label: 'Practices'
                                    });
                                } else {
                                    setImportLoading(true);
                                    linkedPracticesToTool();
                                    if (pageType?.PageId == 1) {
                                        if (apiEnum == 699) {
                                            importPackageTemplateCollection();
                                        }
                                        if (apiEnum == 700) {
                                            importServiceTemplateCollection();
                                        }
                                        if (apiEnum == 951) {
                                            importProductTemplateCollection();
                                        }
                                    }
                                    if (pageType?.PageId == 2) {
                                        importPackageTemplate();
                                    }
                                    if (pageType?.PageId == 3) {
                                        importServiceTemplate();
                                    }
                                    if (pageType?.PageId == 4) {
                                        importProductTemplate();
                                    }
                                    hideModal();
                                    setShowImportDetails(false);
                                    setSelectedHeaderTab({
                                        id: 0,
                                        label: 'Technologies'
                                    });
                                }
                            }}
                        >
                            {selectedHeaderTab?.id == 0 ? 'Continue' : 'Import'}
                        </Button>
                    </div> : <div className="d-flex align-items-center">
                        <Button
                            className="hg-blue-btn w-100"
                            onClick={() => {
                                if (pageType?.PageId == 1) {
                                    setShowImportDetails(true);
                                } else {
                                    setImportLoading(true);
                                    // if (pageType?.PageId == 1) {
                                    //     if (apiEnum == 699) {
                                    //         importPackageTemplateCollection();
                                    //     }
                                    //     if (apiEnum == 700) {
                                    //         importServiceTemplateCollection();
                                    //     }
                                    //     if (apiEnum == 951) {
                                    //         importProductTemplateCollection();
                                    //     }
                                    // }
                                    if (pageType?.PageId == 2) {
                                        importPackageTemplate();
                                    }
                                    if (pageType?.PageId == 3) {
                                        importServiceTemplate();
                                    }
                                    if (pageType?.PageId == 4) {
                                        importProductTemplate();
                                    }
                                    hideModal();
                                }
                            }}
                        >
                            Import Template
                        </Button>
                    </div>
                }
            </Modal.Footer>
        </Modal >
    );
};

export default ImportTemplatesModal;
