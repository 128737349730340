import React from "react";
import HorizontalDotsIcon from "../../icons/horizontal-dots.icon";
import { Dropdown } from "react-bootstrap"
import SwitchButtonMulti from "../../Theme/switchButtonMulti";
import "./kpi-settings.styles.scss";
import useApiActions from "../../../hooks/api-actions.hook";

const KPISettingsDropdown = ({ switchId, showAttestationIcons, setShowAttestationIcons, clientId }) => {
  const { submitNotificationSettings } = useApiActions();

  const onShowAttestationIconsChange = (e) => {
    setShowAttestationIcons(!showAttestationIcons);
    submitNotificationSettings({
      notificationValue: !showAttestationIcons ? 1 : 0,
      notificationName: "SOShowAttestationIcons",
      clientId
    });
  }

  return (
    <Dropdown className="kpi-settings-dropdown transparennt-wrapper-dropdown">
      <Dropdown.Toggle>
        <div 
          className="pointer px-2"
        >
          <img
            alt=""
            className="pointer"
            src="/images/msp/horizontal-dots.svg"
          />
          <HorizontalDotsIcon fill="#616778" width="15"  />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="kpi-settings-dropdown-menu">
        <div className="title-container">
          <h3 className="title">Settings</h3>
        </div>
        <div className="list">
          <div className="item">
            <h4 className="w-100">Show attestation icons</h4>
            <div>
              <SwitchButtonMulti
                value={showAttestationIcons}
                id={switchId}
                onChange={onShowAttestationIconsChange}
              />
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default KPISettingsDropdown;
