import { useState } from "react";
import useApiActions from "../../../../hooks/api-actions.hook";

const useUpdateTaxRate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { createTaxRate } = useApiActions();

  const updateTaxRate = async ({ ObjectId, TaxCode, TaxName, TaxRate, IsDefault, IsDisabled }) => {
    setIsLoading(true);
    try {
      const response = await createTaxRate({ ObjectId, TaxCode, TaxName, TaxRate, IsDefault, IsDisabled });
      return response;
    } catch (error) {
      console.error("Error updating tax rate", error);
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, updateTaxRate };
}

export default useUpdateTaxRate;
