import { Dropdown, Spinner } from "react-bootstrap";
import IntegrationItem from "./integration-item.component";
import AddToolBtn from "./add-tool.button";

const ResultIntegrations = ({ integrationList, onOptionAdd, searchValue, onNewOptionAdd, addingNewTool, refreshData }) => {
  return (
    <div>
      <h3 className="px-3 f-12 all-tools-title mb-1">Results</h3>
      <div className="scrollable-div pr-1">
        {integrationList?.map((option, optionIndex) => (
          <Dropdown.Item
            className="px-0 pl-3 py-2 integration-item"
            key={`stio-${option?.ToolName}-${optionIndex}`} 
          >
            <IntegrationItem 
              integrationName={option?.ToolName}
              integrationId={option?.obj_IntegrationId}
              toolIcon={option?.ToolIcon}
              isLoading={option?.loading} 
              onAdd={() => {
                onOptionAdd(option);
              }}
              searchValue={searchValue}
              refreshData={refreshData}
              isCustom={option?.IsUserDefined} 
            />
          </Dropdown.Item>
        ))}
      </div>
      <div className="px-3 mt-2">
        <AddToolBtn 
          isAdding={addingNewTool} 
          onClick={() => onNewOptionAdd(searchValue)} 
          value={searchValue}
        />
        {addingNewTool && (
          <Spinner
            animation="border"
            variant="primary"
            size="sm"
            className="mx-2 result-spinner "
            style={{
              width: "12px",
              height: "12px",
              marginBottom: '2px'
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ResultIntegrations;
