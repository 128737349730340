import { useEffect, useMemo, useState } from "react";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
import { DateRangePicker, createStaticRanges } from "react-date-range"
import { Dropdown } from "react-bootstrap";

const DatePickerDropdown = ({ setDateSelection, id = '' }) => {
  const defineds = useMemo(() => (
    {
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: endOfWeek(new Date()),
      startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
      endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
      startOfToday: startOfDay(new Date()),
      startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
      startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
      startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
      endOfToday: endOfDay(new Date()),
      startOfYesterday: startOfDay(addDays(new Date(), -1)),
      endOfYesterday: endOfDay(addDays(new Date(), -1)),
      startOfMonth: startOfMonth(new Date()),
      endOfMonth: endOfMonth(new Date()),
      startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
      endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
      startOfYear: startOfYear(new Date()),
      endOfYear: endOfYear(new Date()),
      startOflastYear: startOfYear(addYears(new Date(), -1)),
      endOflastYear: endOfYear(addYears(new Date(), -1)),
      startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
      endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
      startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
      startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
      startOfCurrentYear: startOfYear(new Date()),
      endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
    }
  ), []);
  const [dateSelectionValue, setDateSelectionValue] = useState({
    startDate: defineds.startOfLastTwelveMonth,
    endDate: defineds.endOfToday,
    key: "selection",
  })
  function getDateDifferenceCategory(date1, date2) {
    // Convert the input strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Get the current date
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 0);

    // Define the time frames
    const currentYearStart = new Date(currentDate.getFullYear(), 0, 1);

    const last12MonthsStart = startOfMonth(subMonths(new Date(), 12));
    const previousYearStart = new Date(currentDate.getFullYear() - 1, 0, 1);
    const previousYearEnd = new Date(currentDate.getFullYear() - 1, 11, 31);
    previousYearEnd.setHours(23, 59, 59);
    const last6MonthsStart = startOfMonth(subMonths(new Date(), 6));
    const last90DaysStart = startOfDay(addDays(new Date(), -90));
    const last30DaysStart = startOfDay(addDays(new Date(), -30));
    function isDifferenceInRange(number1, number2, difference) {
      const absoluteDifference = Math.abs(number1 - number2);
      return absoluteDifference <= difference;
    }
    // Compare the input dates with the defined time frames
    if (
      startDate.getTime() === currentYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Current Year";
    } else if (
      startDate.getTime() === previousYearStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), previousYearEnd.getTime(), 5000)
    ) {
      return "Previous Year";
    } else if (
      startDate.getTime() === last12MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 12 Months";
    } else if (
      startDate.getTime() === last6MonthsStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 6 Months";
    } else if (
      startDate.getTime() === last90DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 90 Days";
    } else if (
      startDate.getTime() === last30DaysStart.getTime() &&
      isDifferenceInRange(endDate.getTime(), currentDate.getTime(), 5000)
    ) {
      return "Last 30 Days";
    } else {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const _startDate = startDate.toLocaleDateString("en-US", options);
      const _endDate = endDate.toLocaleDateString("en-US", options);
      return _startDate + " - " + _endDate;
    }
  }
  const selectedDateFilter = useMemo((state) => {
    let dashboard_date = {
      ...dateSelectionValue,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  },[dateSelectionValue]);
  const dateFilterValue = getDateDifferenceCategory(
    selectedDateFilter?.startDate,
    selectedDateFilter?.endDate
  );
  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];

    return customDateObjects;
  };
  const sideBar = sideBarOptions();
  const staticRanges = [...createStaticRanges(sideBar)];

  useEffect(() => {
    setDateSelection(dateSelectionValue);
  }, [dateSelectionValue, id]);

  return (
    <Dropdown
      className="date-filter-dropdown dashboardDateFilter"
      alignRight
      onToggle={(e) => {
        // setToggleDateFilterDropdown(e);
      }}
      // id={`${activeFilter?.filterType}_filterX`}
    >
      <Dropdown.Toggle className="p-0 w-100">
        <div className="d-flex align-items-center justify-content-between p-2 bg-grey f-grey f-500 radius-4 w-100">
          <div className="d-flex align-items-center">
            <img
              src="/images/attack-surface/date-icon.svg"
              alt=""
              className={`mx-2 ${""}`}
            />
            {dateFilterValue}
          </div>
          <div>
            <img
              src="/images/big-chevron-down.svg"
              alt=""
              className={`ml-2 ${""}`}
            />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0"
        style={{
          zIndex: 4,
        }}
      >
        <DateRangePicker
          direction="vertical"
          ranges={[selectedDateFilter ?? {}]}
          staticRanges={staticRanges}
          onChange={(ranges) => {
            setDateSelectionValue(ranges.selection);
            // if (
            //   ranges.selection.startDate ===
            //   ranges.selection.endDate
            // )
            //   return;
            // setLoading(true);
            // getTechnologySpendData(ranges.selection).then(
            //   () => {
            //     setLoading(false);
            //   }
            // );
            // document
            //   .querySelector(".dashboardDateFilter")
            //   .click();
          }}
        />
        <div
          className="pl-4 f-12 pointer border-bottom pt-0"
          onClick={() => {
            setDateSelectionValue({
              startDate: defineds.startOfLastTwelveMonth,
              endDate: defineds.endOfToday,
              key: "selection",
            });
            // getTechnologySpendData({
            //   startDate: defineds.startOfLastTwelveMonth,
            //   endDate: defineds.endOfToday,
            // });
          }}
          style={{
            padding: "0.7rem",
          }}
        >
          Clear
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DatePickerDropdown;
