import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { HGHighlightText } from "../../../App/App";
import { addZerows } from ".";
import { useAuth } from "../../../context/Auth";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import MultiplyIcons from "./mspCalculatorDropdownAndModals/MultiplyIcons";

export const ChooseProductModal = ({
    show,
    toolId,
    toolName,
    serviceComponents,
    setServiceComponents = () => { },
    setChanged = () => { },
    setServicePageErrors = () => { },
    productsList,
    setProductsList = () => { },
    contributionName,
    hideModal = () => { },
}) => {

    useEffect(() => {
        if (show) {
            setLoading(true);
            getLinkedProducts();
        }
    }, [show])


    const scrollContainerRef = useRef(null);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [open, setOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [productData, setProductData] = useState([]);
    const { authTokens } = useAuth();
    const [isLinkedProducts, setIsLinkedProducts] = useState(false);

    useEffect(() => {
        if (!open) setPageNumber(0);
    }, [open]);
    useEffect(() => {
    }, [pageNumber]);

    async function getMoreClients(e) {
        try {
            if (
                e.target.clientHeight + e.target.scrollTop + 201 >=
                e.target.scrollHeight
            ) {
                if (isLastPage) return;

                let element = document.getElementById("scrollList");
                element.removeEventListener("scroll", getMoreClients);

                try {
                    await getProducts();

                    console.log("You've reached the end of the screen!");
                } finally {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log("errror aa gya", error);
        }
    }

    useEffect(() => {
        var element = document.getElementById("scrollList");
        if (element) {
            element.removeEventListener("scroll", getMoreClients);
            element.addEventListener("scroll", getMoreClients);

            return () => {
                element.removeEventListener("scroll", getMoreClients);
            };
        }
    }, [productData, loading, open]);

    function removeDuplicates(arr) {
        const seen = {};
        return arr.filter(item => {
            return seen.hasOwnProperty(item.ObjId_PSAProduct) ? false : (seen[item.ObjId_PSAProduct] = true);
        });
    }

    async function getProducts(num, searchStr) {
        try {
            const pageN = num ?? pageNumber;
            const response = await postData(authTokens, "GetPagedPackageProduct", {
                PageNumber: pageN,
                SearchText: searchStr ?? searchString,
                ToolId: isLinkedProducts ? toolId : 0,
                IsToolNeeded: true
            });

            if (num == 0) {
                setProductData(response?.PSAProductVM_List);
                setPageNumber(response?.PageNumber);
            } else if (response?.PageNumber === 0 || pageNumber === 0) {
                setProductData(response?.PSAProductVM_List);
                setPageNumber(response?.PageNumber);
            } else {
                const new_output = response?.PSAProductVM_List;
                const old_client_data = [...productData];
                const updated_data = [...new Set([...old_client_data, ...new_output])];

                const new_updated_data = removeDuplicates(updated_data);
                setProductData(new_updated_data);
                setPageNumber(response?.PageNumber);
            }
            setLoading(false);
            setIsLastPage(response?.IsLastPage);

            console.log("response num", response?.PageNumber);

            return response?.PageNumber;
        } catch (error) {
            console.error("error in getProducts", error);
            setLoading(false);
            throw error;
        }
    }

    async function getLinkedProducts(num, searchStr) {
        try {
            const pageN = num ?? pageNumber;
            const response = await postData(authTokens, "GetPagedPackageProduct", {
                PageNumber: pageN,
                SearchText: searchStr ?? searchString,
                ToolId: toolId ?? 0,
                IsToolNeeded: true
            });

            if (response?.PSAProductVM_List?.length > 0) {
                setIsLinkedProducts(true);
                if (num == 0) {
                    setProductData(response?.PSAProductVM_List);
                    setPageNumber(response?.PageNumber);
                } else if (response?.PageNumber === 0 || pageNumber === 0) {
                    setProductData(response?.PSAProductVM_List);
                    setPageNumber(response?.PageNumber);
                } else {
                    const new_output = response?.PSAProductVM_List;
                    const old_client_data = [...productData];
                    const updated_data = [...new Set([...old_client_data, ...new_output])];

                    const new_updated_data = removeDuplicates(updated_data);
                    setProductData(new_updated_data);
                    setPageNumber(response?.PageNumber);
                }
                setLoading(false);
                setIsLastPage(response?.IsLastPage);
            } else {
                setIsLinkedProducts(false);
                getProducts();
            }


            console.log("response num", response?.PageNumber);

            return response?.PageNumber;
        } catch (error) {
            console.error("error in getProducts", error);
            setLoading(false);
            throw error;
        }
    }


    async function linkProductToTool(productId) {
        await getAPIData(1054, authTokens, {
            optionEnum1: productId,
            optionEnum2: toolId
        })
            .then((response) => {
                if (response?.mr?.Success) {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }



    const [timeoutId, setTimeoutId] = useState();

    const handleInputChange = (value) => {
        setSearchString(value);

        // Clear the previous timeout
        clearTimeout(timeoutId);
        console.log("check input", value);
        // Set a new timeout to call the API after 1 second
        let _timeoutId = setTimeout(() => {
            setLoading(true);
            getProducts(0, value).then((tempN) => {
                setLoading(false);
                setPageNumber(tempN);
            });
        }, 800);
        setTimeoutId(_timeoutId);
    };


    const [selectedProducts, setSelectedProducts] = useState([]);


    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName="choose-product-modal"
            className="choose-product-modal"
            aria-labelledby="choose-product-modal"
            centered
            scrollable
            onHide={() => {
                hideModal();
            }}
        >
            <Modal.Header className="choose-product-modal-header">
                <div className="d-flex align-items-center justify-content-center w-100 f-500 f-14 f-black line-height-22 letter-space-2">
                    {isLinkedProducts ? `Select products associated with ${toolName}` : `Link ${toolName} to a product`}
                </div>
                <img
                    alt=""
                    height={11}
                    width={11}
                    className="pointer"
                    src="/images/attack-surface/cross-icon.svg"
                    onClick={() => {
                        hideModal();
                    }}
                />
            </Modal.Header>
            <Modal.Body className="choose-product-modal-body">
                <div>
                    <div
                        className={`transparent-input  d-flex align-items-center w-100 p-2 radius-4 mt-2 ${false ? "input-error" : ""
                            } `}
                    >
                        <div className="f-darkgrey flex-shrink-0">
                            <img
                                alt=""
                                src="/images/attack-surface/search-icon.svg"
                                className="pointer mr-2"
                                width={14}
                                height={14}

                            />
                        </div>
                        <input
                            className=" pl-1 pr-2 w-100"
                            style={{
                                border: 'none'
                            }}
                            placeholder="Search"
                            value={searchString}
                            onChange={(e) => {
                                setPageNumber(0);
                                handleInputChange(e.target.value);
                            }}
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <Form.Group className="square-checkbox blue-checkbox-minus pointer-checkbox mx-2 mb-1">
                            <Form.Check
                                type="checkbox"
                                name="checkbox"
                                checked={selectedProducts?.length > 0}
                                onChange={() => {
                                    if (selectedProducts?.length > 0) {
                                        setSelectedProducts([]);
                                    } else {
                                        setSelectedProducts(productData);
                                    }
                                }}
                            />
                        </Form.Group>
                        <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2">Products</div>
                    </div>

                    {
                        loading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
                            <div className="msp-service-update-loader ml-2 mr-1"></div>
                            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
                        </div> :
                            <div
                                className="transparent-scroller"
                                style={{
                                    maxHeight: "527px",
                                    overflowY: "auto",
                                }}
                                ref={scrollContainerRef}
                                id="scrollList"
                            >
                                {
                                    productData?.length > 0 ? <div>
                                        {
                                            productData?.map((product) => {
                                                return (
                                                    <div className={`d-flex align-items-center mt-1 ${selectedProducts?.findIndex(item => item?.ObjId_PSAProduct === product?.ObjId_PSAProduct) !== -1 ? 'bg-lightblue radius-8' : 'bg-hover-grey radius-8'}`}>
                                                        <Form.Group className="blue-checkbox pointer-checkbox mx-2">
                                                            <Form.Check
                                                                type="checkbox"
                                                                name="checkbox"
                                                                checked={selectedProducts?.findIndex(item => item?.ObjId_PSAProduct === product?.ObjId_PSAProduct) !== -1}
                                                                onChange={() => {
                                                                    setSelectedProducts()
                                                                    let updData = selectedProducts;
                                                                    const index = updData?.findIndex(item => item?.ObjId_PSAProduct === product?.ObjId_PSAProduct);

                                                                    if (index !== -1) {
                                                                        updData?.splice(index, 1);
                                                                    } else {
                                                                        updData?.push(product);
                                                                    }
                                                                    setSelectedProducts(updData);
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <div className={`product-item d-flex align-items-center justify-content-between w-100 py-1 mx-1 mb-1`}>
                                                            <div>
                                                                <div className="d-flex align-items-center">
                                                                    {
                                                                        product?.ToolSelected_List?.length > 0 &&
                                                                        <MultiplyIcons
                                                                            icons={product?.ToolSelected_List}
                                                                            nameKey={'IntegrationName'}
                                                                            idKey={'IntegrationId'}
                                                                            iconKey={'ToolIcon'}
                                                                        />
                                                                    }
                                                                    <div className={`f-500 subclass f-grey ${product?.ToolSelected_List?.length > 0 && 'ml-2'}`}>
                                                                        {/* {product?.IntegrationId > 0 && (
                                                                        <img
                                                                            src={`/images/attack-surface/integrations/${product?.IntegrationId}.webp`}
                                                                            alt=""
                                                                            className="rounded-full mr-1"
                                                                            width={13}
                                                                            height={13}
                                                                            style={{}}
                                                                        />
                                                                    )} */}
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: HGHighlightText(
                                                                                    product?.ProductName,
                                                                                    searchString
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex f-darkgrey f-400 f-12 align-items-center">
                                                                    {product?.CurrencyCode_Selected?.CurrencySymbol && (
                                                                        <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                                                                    )}
                                                                    {product?.Price_Text && (
                                                                        <div className="mr-1">{`${/*product?.Price_Text*/addZerows(product?.Cost?.toString())} `}</div>
                                                                    )}
                                                                    {product?.SnapToUnit_Text && (
                                                                        <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                                                                    )}
                                                                    {product?.BillingPeriod_Text && (
                                                                        <div>{`/${product?.BillingPeriod_Text} `}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {product?.IsMissingDetails && (
                                                                <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                                                                    {" "}
                                                                    <img
                                                                        width={16}
                                                                        alt=""
                                                                        className="mr-1"
                                                                        src="/images/msp/grey-alert.svg"
                                                                    />
                                                                    <div>Missing Details</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                                        {searchString != "" ? `No results for "${searchString}"` : 'No products yet'}
                                    </div>
                                }
                            </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className="choose-product-modal-footer">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <Button
                        className="hg-cancel-btn mx-1"
                        style={{
                            width: '70px'
                        }}
                        onClick={() => {
                            hideModal();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="hg-blue-btn mx-1"
                        style={{
                            width: isLinkedProducts ? '70px' : '100px'
                        }}
                        onClick={() => {
                            if (!isLinkedProducts) {
                                selectedProducts?.map((prodItem) => {
                                    linkProductToTool(prodItem?.ObjId_PSAProduct);
                                })
                            }
                            const updated_data = serviceComponents?.map(item => {
                                if (item.ContributionName === contributionName) {
                                    const filteredProducts = item.List_ScoreProducts ?? [];                                    

                                    const newProductsWithDefaults = selectedProducts.map(product => ({
                                        ...product,
                                        ...(product.List_ProductTool ? {} : {
                                            List_ProductTool: product?.ToolSelected_List
                                                ? product.ToolSelected_List.map(tool => ({
                                                    ObjId_Tool: tool?.IntegrationId,
                                                    ToolIcon: tool?.ToolIcon,
                                                    ToolName: tool?.IntegrationName
                                                }))
                                                : []
                                        }),
                                        objId_Product: product.ObjId_PSAProduct
                                    }));

                                    const updatedProducts = [...filteredProducts, ...newProductsWithDefaults]?.filter(
                                        (product, index, self) =>
                                          index === self?.findIndex((p) => p?.objId_Product == product?.objId_Product)
                                      );

                                    return {
                                        ...item,
                                        List_ScoreProducts: updatedProducts,
                                    };
                                }
                                return item;
                            });


                            setServiceComponents(updated_data);

                            setChanged(true);
                            setServicePageErrors((prev) => ({
                                ...prev,
                                selectedListError: false,
                            }));

                            let updated_product_list = [...productsList];

                            selectedProducts.forEach((newProduct) => {
                                let existingIndex = updated_product_list.findIndex(
                                    (item) => item?.ObjId_PSAProduct === newProduct?.ObjId_PSAProduct
                                );

                                if (existingIndex === -1) {
                                    updated_product_list.push({ ...newProduct });
                                }
                            });

                            setProductsList(updated_product_list);
                            hideModal();
                        }}
                    >
                        {isLinkedProducts ? 'Add' : 'Link and Add'}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal >
    );
};

export default ChooseProductModal;
