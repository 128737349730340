import integrationUtils from "../../../Utils/integration.utils";
import { getAccessStateValue, HGHighlightText } from "../../App/App";
import ImageLoader from "../../Common/ImageLoader";

const ConnectedToolItem = ({ tool, searchValue = "", passedStyle }) => {
  const image = integrationUtils.getImagePath(tool?.obj_IntegrationId, tool?.ToolIcon);
  return (
    <div className={`d-flex justify-content-end ${passedStyle && passedStyle}`}>
      <div
        className="d-flex align-items-center connected-tool w-fit-content"
      >
        <ImageLoader
          alt=""
          width={16}
          height={16}
          className="rounded-full"
          style={{ marginLeft: '3px' }}
          src={image}
          noImageComponent={(
            <img
              alt=""
              width={16}
              height={16}
              className="rounded-full"
              style={{ marginLeft: '3px' }}
              src={"/images/attack-surface/generic2-dark.svg"}
            />
          )}
        />
        <div className="d-flex align-items-center mx-2 f-12 f-500">
          <span
            dangerouslySetInnerHTML={{
              __html: HGHighlightText(
                tool?.ToolName,
                searchValue
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ConnectedToolItem;
