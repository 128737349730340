import integrationUtils from "../../Utils/integration.utils";
import { HGHighlightText } from "../App/App";

const ToolsSection = ({
  title, 
  list, 
  search, 
  checkIfIdExists, 
  toolAttestationData,
  onRemoveTool,
  onAddTool,
  addButtonDisplaying = true,
  noToolsTitle = "No tools available"
}) => {
  return (
    <div style={{ padding: '0px 4px' }}>
      <span className="f-12 f-darkgrey" style={{ marginLeft: '12px' }}>
        {title}
      </span>
      {
        list.length === 0 ? (
          <div style={{ marginLeft: '12px' }}>
            <h3 
              className="f-grey m-0 no-available-title"
              style={{ fontSize: '11px', fontWeight: '500' }}
            >
              {noToolsTitle}
            </h3>
          </div>
        ) : (
          list.map(tool => {
            return (
              <div
                className="d-flex align-items-center f-grey f-14 f-500 w-100 bg-hover-grey-light pt-2 pb-2 pr-1 w-100 parent-hovering item"
                onClick={() => {
                  if (addButtonDisplaying) return;
                  onAddTool(tool);
                }}
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex align-items-center">
                    {!tool.IsPractice && (
                      <img
                        alt=""
                        className="mr-2 rounded-full"
                        style={{ marginLeft: "12px" }}
                        width={16}
                        src={integrationUtils.getImagePath(tool?.IntegrationId, tool?.ToolIcon)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/images/attack-surface/generic2.svg";
                        }}
                      />
                    )}
                    <span 
                      dangerouslySetInnerHTML={{
                        __html: HGHighlightText(
                          tool?.IntegrationName,
                          search
                        ),
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    {tool?.loading ? (
                      <div className="msp-service-update-loader mx-1"></div>
                    ) : addButtonDisplaying ? (
                      (checkIfIdExists && checkIfIdExists(
                        tool?.IntegrationId,
                        toolAttestationData?.SelectedTool_List
                      )) ? (
                        <>
                          <img
                            alt=""
                            width={12}
                            className="mx-1 invisible-child"
                            src="/images/msp/integrations/grey-tick-msp.svg"
                          />
                          <img
                            alt=""
                            width={12}
                            className="mx-1 visible-child pointer"
                            src="/images/attack-surface/cross-icon.svg"
                            onClick={() => {
                              onRemoveTool(tool);
                            }}
                          />
                        </>
                      ) : (
                        <button 
                          className="integration-add select-tool-btn pointer" 
                          onClick={() => {
                            onAddTool(tool);
                          }}
                        >
                          <img
                            alt=""
                            src="/images/attack-surface/add-tool-icon.svg"
                            className="mr-2"
                            width={11}
                            height={11}
                            style={{ top: '-1px', position: 'relative' }}
                          />
                          <span className="f-grey">Add</span>
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })
        )
      }
    </div>
  )
}

export default ToolsSection;
