import { useSelector } from "react-redux";
import ImageWithInitials from "../../Utils/ImageWithInitials";

const useClientUserSettings = () => {
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const userAvatar = userDetails?.Avatar;
  const userAvatarStyles = { width: '16px', height: '16px', borderRadius: '9999px' };
  const userNoImageComponent = (
    <div className="mb-1 mr-1">
      <ImageWithInitials 
        initials={userDetails?.Initials ?? userDetails?.FirstName[0]}
        width={16}
        height={16}
        background={"purple"}
        padding="8px"
      />
    </div>
  );
  const SuggestedPages = [
    {
      pageName: "My profile",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "Company settings",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Billing",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
  ];

  const SearchablePages = [
    {
      pageName: "Company settings > Company Name",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Industry",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Employee",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Address",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Language",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "Company settings > Timezone",
      pageURL: "/settings/v2/company-settings",
      Image: "/images/settings-nav/company_settings.svg",
    },
    {
      pageName: "My profile > Name",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "My profile",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "My profile > Email",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "My profile > Role",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "My profile > Reset MFA",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "My profile > Reset Password",
      pageURL: "/settings/v2/my_profile",
      Image: userAvatar,
      imageStyle: userAvatarStyles,
      noImageComponent: userNoImageComponent
    },
    {
      pageName: "Billing > Active Subscriptions",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Payment Methods",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Add Payment Method",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Billing > Billing History",
      pageURL: "/settings/v2/billing",
      Image: "/images/settings-nav/billing.svg",
    },
    {
      pageName: "Members and access > Add Members",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/memberaccess",
    },
    {
      pageName: "Members and access > Users",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/memberaccess",
    },
    {
      pageName: "Members and access > User Details",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/memberaccess",
    },
    {
      pageName: "Subscriptions > Subscription Plans",
      Image: "/images/settings-nav/subscriptions.svg",
      pageURL: "/settings/v2/subscriptions",
    },
    {
      pageName: "Subscriptions > Integration Packs",
      Image: "/images/settings-nav/subscriptions.svg",
      pageURL: "/settings/v2/subscriptions",
    },
    {
      pageName: "Action Centre > Integrations",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Action Centre > Task Creation",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Action Centre",
      Image: "/images/settings-nav/action_centre.svg",
      pageURL: "/settings/v2/action_centre",
    },
    {
      pageName: "Team",
      Image: "/images/settings-nav/members.svg",
      pageURL: "/settings/v2/team",
    },
  ];

  return { SuggestedPages, SearchablePages };
}

export default useClientUserSettings;
