import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { postData } from "../../../../../Utils/commonApi";
import { HGHighlightText } from "../../../../App/App";
import { useAuth } from "../../../../context/Auth";
import Loader from "../../../../Common/loader";
import "./mspCalculatorDropdownAndModals.scss";
import integrationUtils from "../../../../../Utils/integration.utils";
import { addZerows } from "..";
import MultiplyIcons from "./MultiplyIcons";

const AddProductSellDropdown = ({
  setPage,
  // data,
  onSelect,
  selectedList,
  errorInput,
  setNestedProductModalState,
  returnData,
  onAdd,
  setBillingProductError,
  selectedBillingPeriod,
}) => {
  const scrollContainerRef = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [data, setData] = useState([]);
  const { authTokens } = useAuth();

  useEffect(() => {
    if (!open) setPageNumber(0);
  }, [open]);
  useEffect(() => {
    console.log("after pgNum", pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (returnData?.ObjId_PSAProduct) {
      console.log("fgfgfgfgfg");
      const updatedData = data.map((item) => {
        if (item.ObjId_PSAProduct === returnData.ObjId_PSAProduct) {
          // Заменяем объект новым
          return { ...returnData /* новые свойства */ };
        }
        return item;
      });
      if (onAdd) {
        onAdd(returnData);
      }

      console.log("updatedData", updatedData);
      setData(updatedData);
      // setOpen(true);
      getProducts().then(() => {
        setLoading(false);
      });
    }
  }, [returnData]);

  async function getMoreClients(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 201 >=
        e.target.scrollHeight
      ) {
        if (isLastPage) return;

        let element = document.getElementById("scrollList");
        element.removeEventListener("scroll", getMoreClients);

        // setLoading(true);
        console.log("pgNum", pageNumber);
        try {
          await getProducts();

          console.log("You've reached the end of the screen!");
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("scrollList");
    if (element) {
      element.removeEventListener("scroll", getMoreClients);
      element.addEventListener("scroll", getMoreClients);

      return () => {
        element.removeEventListener("scroll", getMoreClients);
      };
    }
  }, [data, loading, open]);

  function removeDuplicates(arr) {
    const seen = {};
    return arr.filter(item => {
      return seen.hasOwnProperty(item.ObjId_PSAProduct) ? false : (seen[item.ObjId_PSAProduct] = true);
    });
  }

  async function getProducts(num, searchStr) {
    try {
      const pageN = num ?? pageNumber;
      const response = await postData(authTokens, "GetPagedPackageProduct", {
        PageNumber: pageN,
        SearchText: searchStr ?? searchString,
        IsToolNeeded: true
      });

      if (response.PSAProductVM_List?.length == 0) {
        setData(response?.PSAProductVM_List);
      } else if (response?.PageNumber === 0 || pageNumber === 0) {
        setData(response?.PSAProductVM_List);
        setPageNumber(response?.PageNumber);
      } else {
        const new_output = response?.PSAProductVM_List;
        const old_client_data = [...data];
        const updated_data = [...new Set([...old_client_data, ...new_output])];

        const new_updated_data = removeDuplicates(updated_data);
        setData(new_updated_data);
        setPageNumber(response?.PageNumber);
      }

      setIsLastPage(response?.IsLastPage);

      console.log("response num", response?.PageNumber);

      return response?.PageNumber;
    } catch (error) {
      console.error("error in getProducts", error);
      throw error;
    }
  }

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (value) => {
    setSearchString(value);

    // Clear the previous timeout
    clearTimeout(timeoutId);
    console.log("check input", value);
    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setLoading(true);
      getProducts(0, value).then((tempN) => {
        setLoading(false);
        setPageNumber(tempN);
      });
    }, 800);
    setTimeoutId(_timeoutId);
  };

  return (
    <Dropdown
      className="msp-product-cost-dropdown"
      onToggle={() => {
        if (!selectedBillingPeriod || selectedBillingPeriod?.DDLText == "") {
          setBillingProductError(true);
        } else {
          getProducts().then(() => {
            setLoading(false);
          });
          setSearchString("");
          // setPageNumber(0);
          setOpen(!open);

        }
      }}
      show={open}
    >
      <Dropdown.Toggle className="w-100 d-flex product-cost-parent-toggle">
        <div
          className={`${errorInput ? "input-error" : ""
            } bg-grey radius-4 f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-2`}
          onClick={() => { }}
        >
          <img
            alt=""
            src="/images/actioncenter/plusgrey.svg"
            className="mr-2"
          />
          Add Product
        </div>
        <div
          style={{ height: "34px", width: "100%" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="  p-0 link-to-series-parent-dropdown-menu"
        style={{
          width: "432px",
          zIndex: 100,
        }}
      >
        {" "}
        <>
          <div className="d-flex align-items-center m-1 pl-1">
            <img
              className="mr-1 ml-1"
              src="/images/attack-surface/search-icon.svg"
              alt=""
            />
            <input
              autoFocus={true}
              type="text"
              placeholder="Find products..."
              className="border-none bg-transparent p-2"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
                setPageNumber(0);
                handleInputChange(e.target.value);
              }}
            />
          </div>

          <div
            className="f-blue f-500 ml-3 my-2 d-flex align-items-center pointer"
            onClick={() => {
              setPage("addProduct", searchString);
            }}
          >
            <img alt="" src="/images/msp/blue-add-icon.svg" className="mr-2" />
            {searchString ? (
              <div className="pt-1">Add {` "${searchString}" `}</div>
            ) : (
              <div className="pt-1">Add product</div>
            )}
          </div>
          <div
            className="f-12 f-darkgrey mt-3"
            style={{ padding: " 4px 12px" }}
          >
            Products
          </div>
          <div
            ref={scrollContainerRef}
            id="scrollList"
            className={` ${data?.length > 4 ? "transparent-scroller" : ""}`}
            style={{
              maxHeight: ` ${data?.length > 4 ? "470px" : ""}`,
              overflowY: ` ${data?.length > 4 ? "auto" : ""}`,
            }}
          >
            {loading ? (
              <div
                className="d-flex align-items-center justify-content-center w-100 "
                style={{
                  height: "20vh",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                {data
                  // ?.filter((item) =>
                  //   item?.Name?.toLowerCase().includes(
                  //     searchString?.toLowerCase()
                  //   )
                  // )
                  .map((product, productIndex) => {
                    let isALreadySelected = selectedList?.some(
                      (obj) =>
                        obj.ObjId_PSAProduct === product?.ObjId_PSAProduct
                    );
                    return (
                      <div
                        className={`product-item align-items-center justify-content-between bg-hover-grey p-2 mx-1 mb-1 radius-4 pointer
                  
                  ${isALreadySelected ? "bg-grey" : ""}
                  `}
                        onClick={(e) => {
                          if (product?.IsMissingDetails) {
                            e.stopPropagation();
                            setNestedProductModalState((prev) => ({
                              ...prev,
                              show: true,
                              selectedProductId: product.ObjId_PSAProduct,
                            }));
                          } else {
                            onSelect(product);
                            e.target
                              .closest(".msp-product-cost-dropdown")
                              .click();
                          }
                        }}
                      >
                        <div>
                          <div className="d-flex align-items-center">
                            {
                              product?.ToolSelected_List?.length > 0 &&
                              <MultiplyIcons
                                icons={product?.ToolSelected_List}
                                nameKey={'IntegrationName'}
                                idKey={'IntegrationId'}
                                iconKey={'ToolIcon'}
                              />
                            }
                            <div className="f-grey f-500 subclass">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: HGHighlightText(
                                    product?.ProductName,
                                    searchString
                                  ),
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex f-darkgrey f-400 f-12 align-items-center">
                            {/* {product?.IntegrationId > 0 && (
                              <img
                                src={integrationUtils.getImagePath(product?.IntegrationId, product?.ToolIcon)}
                                alt=""
                                className="rounded-full mr-1"
                                width={13}
                                height={13}
                                style={{}}
                              />
                            )}
                            {product?.IntegrationName && (
                              <div className="mr-1">
                                {product?.IntegrationName}
                              </div>
                            )} */}

                            {product?.CurrencyCode_Selected?.CurrencySymbol && (
                              <div>{`${product?.CurrencyCode_Selected?.CurrencySymbol} `}</div>
                            )}
                            {product?.Cost && (
                              <div className="mr-1">{`${addZerows(product?.Cost?.toString())} `}</div>
                            )}
                            {product?.SnapToUnit_Text && (
                              <div className="mr-1">{`/${product?.SnapToUnit_Text} `}</div>
                            )}
                            {product?.BillingPeriod_Text && (
                              <div>{`/${product?.BillingPeriod_Text} `}</div>
                            )}
                          </div>
                        </div>
                        {product?.IsMissingDetails && (
                          <div className="d-flex align-items-center f-darkgrey f-500 f-12">
                            {" "}
                            <img
                              width={16}
                              alt=""
                              className="mr-1"
                              src="/images/msp/grey-alert.svg"
                            />
                            <div>Missing Details</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                {data?.length == 0 && (
                  <div className="d-flex align-items-start f-darkgrey px-2 f-12">
                    No products found
                  </div>
                )}
              </>
            )}
          </div>
          <div className="border-bottom  m-2"></div>
          <div className="d-flex align-items-start f-darkgrey px-2 f-12">
            <img
              src="/images/msp/integrations/grey-info.svg"
              className="mr-2 mt-1"
              alt=""
            />
            Products must have their billing period and snap to unit defined
            before they can be added to a service.
          </div>
          <div className="p-1" />
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddProductSellDropdown;
