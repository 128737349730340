import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TrackUser } from "../../../../Utils/SegmentFunctions";
import { TrimString } from "../../../../Utils/StringTrimmer";
import { Button, Dropdown } from "react-bootstrap";
import Confetti from "react-dom-confetti";
import integrationUtils from "../../../../Utils/integration.utils";

export const IntegrationCard = ({
    integration,
    isVotingCard,
    isRecommended,
    isAllTool,
    refreshData,
    voteIntegration,
    showConnectToolModal,
  }) => {
    const config = {
      angle: 90,
      spread: "360",
      startVelocity: 50,
      elementCount: 70,
      dragFriction: 0.3,
      duration: 4000,
      stagger: 1,
      width: "10px",
      height: "10px",
      perspective: "500px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
    };
    const [isExploding, setIsExploding] = useState(false);
  
    const history = useHistory();
    return (
      <div
        className="bg-grey bg-hover-dark-grey radius-8 d-flex align-items-center flex-column mr-3 mb-3 p-3 pointer"
        style={{
          width: "360px",
        }}
        onClick={() => {
          if (!isVotingCard) {
            history.push(`/integrations/${integration?.IntegrationId}`);
            TrackUser("Tool description clicked",{
              type:integration?.IntegrationName
            });
          }
        }}
      >
        {/* first row */}
        <div
          className="d-flex align-items-center justify-content-between w-100 mb-2"
          style={{
            height: "auto",
            width: "100%",
          }}
        >
          <div
            className="f-500 f-black d-flex align-items-center"
            style={
              {
                // whiteSpace: "nowrap",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
              }
            }
          >
            <img
              src={integrationUtils.getImagePath(integration?.IntegrationId, integration?.ToolIcon)}
              alt=""
              className="mr-2 radius-4"
              width={34}
              height={34}
            />
            {TrimString(integration?.IntegrationName, 50)}
          </div>
          <div className="radius-2 d-flex align-items-center justify-content-end       ">
            {/* Is Recomended Card */}
            {isRecommended && (
              <>
                {integration?.ButtonFunction !== 2 && (
                  <Button
                    className="hg-blue-btn d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      showConnectToolModal(integration?.IntegrationId);
                      TrackUser("Clicked Integrate Tool");
                    }}
                  >
                    <img
                      src="/images/attack-surface/white-plus-icon.svg"
                      alt=""
                      className=" mr-2"
                    />
                    Connect
                  </Button>
                )}
                {integration?.ButtonFunction === 2 && (
                  <div className="f-green d-flex">
                    <img
                      alt=""
                      src="/images/attack-surface/voted-tick.svg"
                      className="mr-1"
                    />
                    Connected
                  </div>
                )}
              </>
            )}
            <Confetti active={isExploding} config={config} />
  
            {/* Is All tools  Card */}
            {isAllTool && (
              <>
                {integration?.ButtonFunction !== 2 && (
                  <Button
                    className="hg-tranparent-grey-btn d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      showConnectToolModal(integration?.IntegrationId);
                      TrackUser("Clicked Integrate Tool");
                    }}
                  >
                    <img
                      src="/images/actioncenter/plusgrey.svg"
                      alt=""
                      className=" mr-2"
                    />
                    Connect
                  </Button>
                )}
  
                {integration?.ButtonFunction === 2 && (
                  <div className="f-green d-flex">
                    <img
                      alt=""
                      src="/images/attack-surface/voted-tick.svg"
                      className="mr-1"
                    />
                    Connected
                  </div>
                )}
              </>
            )}
  
            {/* If isVoting Card */}
            {isVotingCard && (
              <>
                {integration?.IsUpVoted ? (
                  <div className="f-green">
                    <img
                      alt=""
                      src="/images/attack-surface/voted-tick.svg"
                      className="mr-1"
                    />
                    Voted
                  </div>
                ) : (
                  <Button
                    className="voting-btn d-flex align-items-center"
                    onClick={() => {
                      voteIntegration(integration?.IntegrationId);
                      setIsExploding(true);
                      TrackUser("Integration Upvote clicked", {
                        type:integration?.IntegrationName,
                      });
                      setTimeout(() => {
                        setIsExploding(false);
                      }, 2500);
                    }}
                  >
                    <img
                      src="/images/attack-surface/white-thumbs-up.svg"
                      alt=""
                      className=" mr-2"
                    />
                    Vote
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        {/* second row */}
        {isRecommended && (
          <>
            <div className="w-100 d-flex flex-column justify-content-between h-100">
              <div className="f-grey " title={integration?.IntegrationSummary}>
                {TrimString(integration?.IntegrationSummary, 125)}
              </div>
              <div className="d-flex align-items-end justify-content-between">
                <div className="d-flex align-items-center w-100 mt-2">
                <MspMultiTagsUI tagList={integration?.Tags_List} />
                  
                </div>
  
                <div className="d-flex align-items-center justify-content-end w-100">
                  <img
                    src="/images/attack-surface/thunder-icon.svg"
                    alt=""
                    className=""
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isAllTool && (
          <>
            <div className="w-100 d-flex flex-column justify-content-between h-100">
              <div className="f-grey " title={integration?.IntegrationSummary}>
                {TrimString(integration?.IntegrationSummary, 125)}
              </div>
              <div className="d-flex align-items-center w-100 mt-2">
              <MspMultiTagsUI tagList={integration?.Tags_List} />
                
              </div>
            </div>
          </>
        )}
  
        {isVotingCard && (
          <>
            <div className="w-100 d-flex align-items-center justify-content-start">
              <div className="d-flex align-items-center w-100 mt-2">
                <MspMultiTagsUI tagList={integration?.Tags_List} />
                 
              </div>
            </div>
  
            <div className="d-flex align-items-center w-100 mb-2">
              {integration?.type?.map((item) => {
                return (
                  <div
                    className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                    style={{
                      background: `${item.color}30`,
                      color: `${item.color}`,
                    }}
                  >
                    {item.name === "Default" && (
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/integrations/default-integration-icon.svg"
                      />
                    )}
                    {item.name}
                  </div>
                );
              })}
            </div>
            {/* Third row */}
            <div className="w-100 d-flex flex-column mt-1">
              <div className="d-flex align-items-center justify-content-between mb-1">
                <div className="f-grey">Added</div>
                <div className="f-grey">{integration?.UpVoteDate ?? "N/A"}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="f-grey">Votes</div>
                <div className="f-grey"> {integration?.UpVoteCount} </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };





  
const MspMultiTagsUI = ({
    tagList,
  }) => {
    return (
      <div className="d-flex align-items-center">
        {tagList?.slice(0, 2).map((tagName) => (
          <MspSingleTagUI itemName={tagName} />
        ))}
        {/* if tags length more than 2 */}
  
        {tagList?.length > 2 && (
          <div>
            <Dropdown className="interationv2_tagDropdown"
            
            onClick={e=>e.stopPropagation()}
            >
              <Dropdown.Toggle className="">
                <div
                  className="radius-2 p-1 px-2 f-12 f-500 mr-2"
                  style={{
                    background: `${"#616778"}30`,
                    color: `${"#616778"}`,
                    // whiteSpace: "nowrap",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  +{tagList?.length - 2}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="d-flex flex-column px-2">
                {tagList?.slice(2, 1000)?.map((tagName) => (
                  <div className="mb-2"><MspSingleTagUI itemName={tagName} /></div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    );
  };
  
 export const MspSingleTagUI = ({ itemName }) => {
    return (
      <div
        className="radius-2 p-1 px-2 f-12 f-500 mr-2 w-max-content"
        style={{
          background: `${"#616778"}30`,
          color: `${"#616778"}`,
          // whiteSpace: "nowrap",
          // overflow: "hidden",
          // textOverflow: "ellipsis",
        }}
      >
        
        {itemName}
      </div>
    );
  };