import { useState } from "react";
import useApiActions from "../../../hooks/api-actions.hook"

const useSourcesModalSortList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const { getAllAssetSources } = useApiActions();

  const getSourcesModalSortList = async ({ clientId, sortList }) => {
    try {
      setIsLoading(true);
      const response = await getAllAssetSources({ clientId, sortList });
      setData(response);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching sources modal sort list', error);
    }
  }

  return {
    isLoading,
    data,
    getSourcesModalSortList
  }
}

export default useSourcesModalSortList;
