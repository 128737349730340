import React from 'react'
import "./sign-up.scss"
import { useEffect } from 'react';
import { CallToast } from '../PriceCalculator/modals/CustomToast';
import { useState } from 'react';
import { useAuth } from '../context/Auth';
import { useDb } from '../context/db';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import { CustomGetApi, getAPIData, postData } from '../../Utils/commonApi';
import { setuser } from '../../redux/userState/actions';
import { useDispatch } from 'react-redux';
import { updateAccessKeyInLocalStorage } from '../../Utils/CommonFunctions';
import AuthLayout from './auth-layout/auth.layout';
import AuthBody from './auth-layout/auth.body';
import TeamInviteSignupSection from './sections/team-invite-signup.section';
import AuthBottomInfoSections from './auth-layout/auth-bottom-info-sections.component';
import SaasAnimationSection from './sections/saas-animation.section';
import AuthSignupSections from './auth-layout/auth-signup-sections.component copy';
import { FullScreenLoader } from '../Common/loader';
import SignUpPasswordSection from './sections/sign-up-password.section';

const RegistrationFormState = {
  ACCEPT: 0,
  INFO: 1,
  REGISTER: 2,
}

const RegistrationStatesTitles = [
  { title: "Sign up to Dashboard", description: "Monitor your cyber security side by side with your Managed Security Provider" },
  { title: "Sign up to Dashboard", description: "Monitor your cyber security side by side with your Managed Security Provider" },
]

const Registrationv2 = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {
      setUserType,
      setAuthTokens,
      setAuth,
      setMessageEncrypt 
    } = useAuth();
    const { setdbObject } = useDb();
    const {authTokens}= useAuth()
    const [passwordShow, setPasswordShow] = useState(false);
    const [formState, setFormState] = useState(RegistrationFormState.ACCEPT);
    const [finishSignUpData, setFinishSignUpData] = useState({
      signUpCompleted: false,
      data: null,
      callBackFunc: null,
    });
    const [redirectingMessage, setRedirectingMessage] = useState(
      "Creating your account, please wait.."
    );
    const [email, setEmail] = useState("");
    const [otherRole, setOtherRole] = useState("");
    
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [happyToRecieve, setHappyToReceive] = useState(false);
    const [fullName, setFullName] = useState("");
    const [fullNameError, setFullNameError] = useState(false);
    const [jobTitle, setJobTitle] = useState("");
    const [role, setRole] = useState("CEO");
    const [formSubmit, setFormSubmit] = useState(0);
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [showAnimation, setShowAnimation] = useState(false);
    const [resendEmail, setResendEmail] = useState(false);
    const [resendEmailState, setResendEmailState] = useState(0);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [cropperModal, setCropperModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [linkValidation, setLinkValidation] = useState({valid:false, message:""})
    const fileUpload = useRef();
    const showCropperModal = () => {
        setCropperModal(true);
      };
 
    
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
 
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [userAccessKey, setUserAccessKey] = useState(null);
    // console.log(formSubmit);
  
    useEffect(() => {
      // setAuth(false);
      window.localStorage.clear()
      setdbObject();
      localStorage.setItem("dasboardLoader", true);
    }, []);
    let runOnce = 0;

    async function makeAPICall(link, userAccessKey) {
      await getAPIData(0, authTokens, {
      }).then((data) => {
          setdbObject(data);
          dispatch(
            setuser({
              ...data?.UserDetail,
              CommonComponents: data?.CommonComponents,
              ScoreAttestation_Icon:
                data?.CommonComponents?.ScoreAttestation_Icon,
  
              cyberScoreValue: data?.CommonComponents?.CyberScoreNumber,
              cyberThreatValue: data?.CommonComponents?.CyberThreatNumber,
              AccessObjList: data?.mr?.AccessObjList,
            })
          );
          if (data?.allowMSP) {
            history.push({
              pathname: `/mspv2/clients`,
              state: { isLogin: true },
            });
            return
          } else if (data?.allowSaaS) {
            history.push( 
              `/${link}/dashboard`, 
             {
                isLogin: true, 
                userAccessKey: userAccessKey, 
              }
            );
          }
      });
    }

  
    // Redirect User directly to Login Screen
     function RedirectToDashboard(email, password) {
      if (runOnce > 0) return;
      runOnce = 2;
      setTimeout(() => {
        runOnce = 0;
      }, 3000);
      setShowAnimation(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "password");
      urlencoded.append("username", email)
      // clientData.email  );

      urlencoded.append("password", password)
      
      // password);
      urlencoded.append("ipaddress", "111.111.111.111");
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
  
      const data =  fetch(
        `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
        requestOptions
      )
        .then((response) => response.json())
        .then( (data) => {
          if (data.error) {
            if (
              data.error_description ==
              "The email address provided has not been confirmed."
            ) {
            
            }
          } else {
            //Set Token to Local Storage
            setAuthTokens(data.access_token);
            const token = data.access_token;
             localStorage.setItem("tokens", JSON.stringify(data.access_token));
           
            setRedirectingMessage(
              `Redirecting you to your dashboard, please wait . . .`
            );
            var myHeaders2 = new Headers();
            myHeaders2.append("Authorization", `Bearer ${data.access_token}`);
  
            let requestOptions = {
              method: "GET",
              headers: myHeaders2,
              redirect: "follow",
            };
  
            fetch(
              `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`,
              requestOptions
            )
              .then((response) => response.json())
              .then( (data) => {
                
                if (data?.ByPass2FA === true || true) {
                  window.localStorage.setItem(
                    "token_time",
                    JSON.stringify(Math.floor(Date.now() / 60000))
                  );
                   
                  let myHeaders = new Headers();
                  myHeaders.append("Authorization", `Bearer ${token}`);
  
                  let requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                  };
  
                  let googlePinData =  fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=999666`,
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((data) => {
                     
  
                      setUserType(data.EntityId);
                      window.localStorage.setItem(
                        "userType",
                        JSON.stringify(data?.EntityId)
                      );
                      localStorage.setItem("object_Data", JSON.stringify(data));
                      updateAccessKeyInLocalStorage(data.Message)
                      setMessageEncrypt(data?.Message);

                      setFinishSignUpData({
                        signUpCompleted: true,
                        data: data,
                        callBackFunc: finishSignUpStep,
                      });
                    
                      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
                      console.log("setting access key in sign up");
                      // setMessageEncrypt(data.Message);
  
                      let userType = {
                        111111: "ceo",
                        999999: "ceo",
                        222222: "cto",
                        333333: "cfo",
                      };
                      setAuth(true);
                      
                      setShowAnimation(false);
                    })
                    .catch((err) => {
                      setFormSubmit(1);
                      setRedirectingMessage(
                        "Creating your account, please wait.."
                      );
                      setShowAnimation(false);
                      setLoading(false);
                      CallToast(
                        "500 - Internal Server Error",
                        false,
                        false,
                        "v2style"
                      );
                      console.log("error- in api 1", error);
                    });
                }
              })
              .catch((error) => {
                setFormSubmit(3);
                setRedirectingMessage("Creating your account, please wait..");
                setShowAnimation(false);
                setLoading(false);
                CallToast("500 - Internal Server Error", false, false, "v2style");
                console.log("error- in api 2", error);
              });
          }
        })
        .catch((error) => {
          setFormSubmit(3);
          setRedirectingMessage("Creating your account, please wait..");
          setShowAnimation(false);
          setLoading(false);
          CallToast("500 - Internal Server Error", false, false, "v2style");
          console.log("login error", error);
        });
    }

    function finishSignUpStep(data) {
      window.localStorage.setItem("userType", JSON.stringify(data?.EntityId));
      updateAccessKeyInLocalStorage(data.Message);
  
      setAuth(true);
  
      setUserType(data.EntityId);
  
      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
      console.log("setting access key in sign up");
      // setMessageEncrypt(data.Message);
      let element = document.getElementById("root").firstChild;
      element.classList.add("fadeIn");
      setTimeout(() => {
        element.classList.remove("fadeIn");
      }, 500);
  
      let userType = {
        111111: "ceo",
        999999: "ceo",
        222222: "cto",
        333333: "cfo",
      };
      setShowAnimation(false);
      if (data.EntityId === 555555) {
        history.push({
          pathname: `/mspv2/clients`,
          state: { isLogin: true },
        });
        return;
      }
  
      history.push({
        pathname: `/${userType[data.EntityId]}/dashboard`,
        state: { isLogin: true },
      });
    }
  
    useEffect(() => {
      const params = new URLSearchParams(window.location.search); 
      if (params?.get("email")) {
        setEmail(params.get("email"));
      }
    }, []);
  
    function togglePasswordShow() {
      if (passwordShow) return setPasswordShow(false);
      return setPasswordShow(true);
    }

   const [linkId, setLinkId] = useState("")
   const [pageLoading, setPageLoading] = useState(true)
   const [clientData, setClientData] = useState({
    email:"",
    name:"",
    password:"",
    jobRole:"",
   })
   function verifyLink(_linkId) {
     return new Promise((resolve, reject) => {
       CustomGetApi("CC_API/VerifyRegistration", authTokens, {
         linkId: _linkId,
       }).then((res) => {
         resolve(res);
        setPageLoading(false);
       });
     });
   }

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const _linkId = urlParams.get("linkId");
      setLinkId(_linkId);
      setPageLoading(true);
      verifyLink(_linkId).then((res) => {
        if (res?.isLinkExpired || res?.isLinkInvalid) {
          setLinkValidation({ valid: false, message: res?.mr?.Message });
          // setTimeout(() => {
          //   history.push("/login")
          // }, 4000);
        } else {
          setLinkValidation({ valid: true, message: "" });
          setClientData(prevData => ({
            ...prevData,
            ...res
          }));
          
          setFormSubmit(0);
        }
      });
    }, []);
  async function completeRegistration(password, fullName, jobTitle, selectedImage) {
    setFormSubmit(RegistrationFormState.REGISTER);
    setLoading(true);
    setShowAnimation(true);
    postData(authTokens,"CompleteRegistration",  {
      linkId: linkId,
      emailAddress: clientData.email,
      password:  password,
      repeatPassword:  password,
      happyToRecieve:happyToRecieve,
      fullName:fullName,
      jobTitle:jobTitle,
      file:selectedImage ,

    }).then((res) => {
      if (!res.mr.Success) {
        setLoading(false);
        CallToast(res.mr.ExMessage ?? "Something went wrong", false, false, "v2style");
        setFormSubmit(RegistrationFormState.INFO);
        return;
      }
      if (res?.isLinkExpired || res?.isLinkInvalid) {
        setLinkValidation({ valid: false, message: res?.mr?.Message });
      } else {
        setShowAnimation(true);
        setUserAccessKey(res?.accessKey)
        RedirectToDashboard(clientData.email, password);
      }
    }).catch(()=>{
      setLoading(false);
      CallToast("Something went wrong", false, false, "v2style");
      setFormSubmit(RegistrationFormState.INFO);
    })
  }

  async function onProfileInfoSubmit(fullName, password, img, passwordStrength, jobTitle) {
    let hasError = false;
    if (fullName == "") {
      setFullNameError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (password == "") {
      setPasswordError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (passwordStrength < 5) {
      setPasswordError(
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
      );
      setLoading(false);
      hasError = true;
    }
    if (jobTitle == "") {
      setJobTitleError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (hasError) return;
    setFullName(fullName);
    setPassword(password);
    setSelectedImage(img);
    setJobTitle(jobTitle);
    completeRegistration(password, fullName, jobTitle, img)
  }

  const sections = [TeamInviteSignupSection, SignUpPasswordSection];
  const sectionLayouts = [AuthBottomInfoSections, AuthSignupSections];
  const titles = RegistrationStatesTitles[formState] ?? {};
  const isAccCreation = formSubmit >= RegistrationFormState.REGISTER && (loading || showAnimation) 
  const CurSection = isAccCreation
    ? SaasAnimationSection 
    : sections[formState] ?? <div />;
  const CurLayout = isAccCreation ? AuthSignupSections : sectionLayouts[formState] ?? <div />;
   
  return (
    <div className="d-flex w-100 h-100 ">
      {pageLoading ? (
        <div className='d-flex justify-content-center w-100'>
          <FullScreenLoader />
        </div>
      ) : (
        !linkValidation.valid ? (
          <div className="p-3 w-100 p-4">
            <div className="d-flex">
              <img alt="" src="/images/logo-large.svg" className="icon" />
            </div>
    
            <div className="d-flex align-items-center justify-content-center mt-5">
              <img src="/images/msp/snooze-icon.svg" alt="" />
            </div>
            <div className="f-24 f-700 d-flex align-items-center justify-content-center mt-4">
              {linkValidation.message}
            </div>
            <div className="mt-4 f-14 f-darkgrey d-flex align-items-center justify-content-center mx-auto w-25 text-center">
              Your login link has been expired or revoked. Contact sender again
              to review a new login link
            </div>
          </div>
        ) : (
          <AuthLayout>
            <AuthBody>
              <CurLayout
                title={titles.title}
                description={titles.description}
                removePaddings
              >
                <CurSection
                  inviteByName={clientData.UserName} 
                  inviteSentToName={clientData.CompanyName}
                  defaultEmail={clientData.email}
                  logo={clientData.CompanyLogo}
                  setFormState={setFormState}
                  setPasswordError={setPasswordError}
                  setError={setError}
                  defaultPassword={password}
                  defaultFullName={fullName}
                  error={error}
                  fullNameError={fullNameError}
                  setFullNameError={setFullNameError}
                  loading={loading}
                  userType='MSP'
                  passwordError={passwordError}
                  defaultProfileImg={selectedImage}
                  onProfileInfoSubmit={onProfileInfoSubmit}
                  showJobTitle
                  setDisableCredFieldsHL={() => {}}
                  jobTitleError={jobTitleError}
                  setJobTitleError={setJobTitleError}
                  defaultJobTitle={jobTitle}
                  signUpCompleted={finishSignUpData.signUpCompleted}
                  callBack={finishSignUpData.callBackFunc}
                  callBackData={finishSignUpData.data}
                />
              </CurLayout>
            </AuthBody>
          </AuthLayout>
        )
      )}
    </div>
  );
}

export default Registrationv2


 