import TermsInfo from "../sections/terms-info.component";

const AuthBottomInfoSections = ({ children, title, description, removePaddings, hideInfo }) => (
  <div className="auth-bottom-info-sections">
    <div className="left-section">
      <img 
        src="/images/settings-v2/logo.svg" 
        alt="login" 
        width="40px"
        height="40px"
      />
      <div className="info">
        <h1 className="title m-0">{title}</h1>
        <p className="description">{description}</p>
      </div>
    </div>
    <div className={`right-section ${removePaddings ? 'remove-paddings' : ''}`}>
      {children}
      <div 
        className="terms-info-wrapper"
        style={{ display: hideInfo ? 'none' : 'block' }}
      >
        <TermsInfo />
      </div>
    </div>
  </div>
);

export default AuthBottomInfoSections;
