function getSubscriptionAddSpendCalcs(startDate, Term, Recurring_Period) {
    let SubscriptionModal = {
        Last_Payment: "",
        Next_Payment: "",
        Renewal_Date: "",
        Total_Payments: "0"
    };

    try {
        let currentDate = new Date();

        let monthsDifference = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + currentDate.getMonth() - startDate.getMonth();
        if (currentDate.getDate() < startDate.getDate()) {
            monthsDifference--;
        }

        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + Term);

        let nextPaymentDate = new Date(0);
        let lastPaymentDate = new Date(0);
        let nextRenewalDate = endDate;

        switch (Recurring_Period.Id) {
            case 6423: // monthly
                lastPaymentDate = new Date(startDate);
                lastPaymentDate.setMonth(lastPaymentDate.getMonth() + monthsDifference);
                nextPaymentDate = new Date(lastPaymentDate);
                nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
                break;

            case 6424: // quarterly
                let quartersElapsed = Math.floor(monthsDifference / 3);
                lastPaymentDate = new Date(startDate);
                lastPaymentDate.setMonth(lastPaymentDate.getMonth() + (quartersElapsed * 3));
                nextPaymentDate = new Date(lastPaymentDate);
                nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);
                break;

            case 6425: // yearly
                let yearsElapsed = Math.floor(monthsDifference / 12);
                lastPaymentDate = new Date(startDate);
                lastPaymentDate.setFullYear(lastPaymentDate.getFullYear() + yearsElapsed);
                nextPaymentDate = new Date(lastPaymentDate);
                nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
                break;
        }

        let formattedLastPaymentDate = lastPaymentDate ? lastPaymentDate.toLocaleDateString('en-GB') : "";
        let formattedNextPaymentDate = nextPaymentDate ? nextPaymentDate.toLocaleDateString('en-GB') : "";
        let formattedNextRenewalDate = nextRenewalDate ? nextRenewalDate.toLocaleDateString('en-GB') : "";

        SubscriptionModal.Last_Payment = formattedLastPaymentDate;
        SubscriptionModal.Next_Payment = formattedNextPaymentDate;
        SubscriptionModal.Renewal_Date = formattedNextRenewalDate;
        SubscriptionModal.Total_Payments = "0";

    } catch (error) {
        console.error(error.message);
    }

    return SubscriptionModal;
}

const subscriptionUtils = {
  getSubscriptionAddSpendCalcs
};

export default subscriptionUtils;