const AuthDefaultSections = ({ children, title, description }) => (
  <div className="auth-default-sections">
    <div className="left-section">
      <img 
        src="/images/settings-v2/logo.svg" 
        alt="login" 
        width="40px"
        height="40px"
      />
      <div className="info">
        <h1 className="title m-0">{title}</h1>
        <p className="description" style={{ whiteSpace: 'pre-line' }}>{description}</p>
      </div>
    </div>
    <div className="right-section">
      {children}
    </div>
  </div>
);

export default AuthDefaultSections;
