import React, { useMemo, useState } from 'react';
import './risky-user.styles.scss';
import ImageLoader from '../../../Common/ImageLoader';
import ImageWithInitials from '../../../../Utils/ImageWithInitials';

const riskyUserTitles = [
  { Title: 'Detection type', key: 'Detection_type' },
  { Title: 'Detection time', key: 'Detection_time' },
  { Title: 'Last updated', key: 'Last_updated' },
  { Title: 'Risk state', key: 'Risk_state' },
  { Title: 'IP Address', key: 'IP_Address' },
  { Title: 'Location', key: 'Location' },
];

const RiskyUserTooltip = ({ children, item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const data = useMemo(() => riskyUserTitles.map((title) => ({
    Title: title.Title,
    Value: item.RiskObject[title.key],
  })), [item.RiskObject]);
  return (
    <div 
      className='risky-user-tooltip' 
      onMouseLeave={() => setIsHovered(false)}
    >
      <div 
        className='d-flex align-items-center anchor'
        onMouseEnter={() => setIsHovered(true)}
        
      >
        {children}
      </div>
      {isHovered && (
        <div className='menu'>
          <div className='header'>
            <ImageLoader 
                src={item?.UserIcon}
                alt=""
                width={32}
                height={32}
                noImageComponent={(
                  <ImageWithInitials
                    initials={item.User ?? item?.Name?.charAt(0)}
                    width={32}
                    height={23}
                    background={"purple"}
                  />
                )}
              />
            <div className='name-email-container'>
              <h3 className='name m-0 f-black f-500'>
                {item.Name}
              </h3>
              <h4 className='email m-0 f-darkgrey f-500'>
                {item.Email}
              </h4>
            </div>
          </div>
          <div className='body'>
            <h3 className='f-12 f-darkgrey f-500 m-0'>Details</h3>
            <div className='list'>
              {data.map((section, index) => (
                <div key={index} className='item'>
                  <h4 className='title m-0 f-grey'>{section.Title}</h4>
                  <h3 className='value m-0 f-black'>{section.Value}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RiskyUserTooltip;
