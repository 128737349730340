import React from "react";

const ImageWithInitials = ({ initials, width, backgroundColor, textColor, border, className, borderRadius, padding = "10px" }) => {
  return (
    <div
      className={` ${className}`}
      style={{
        border: border === undefined ? 'none' : border,
        height: `${width}px`,
        width: `${width}px`,
        background: backgroundColor === undefined ? "#3d42de" : backgroundColor, // background,
        color: textColor === undefined ? "#fff" : textColor,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: `${borderRadius ?? '50'}%`,
        fontWeight: "400",

        fontFamily: "Archivo, sans-serif",
        padding,
        fontSize: `${width > 23 ? 12 : width * 0.5 - 1}px`,
      }}
    >
      <span
        className="d-inline-flex"
        style={{
          opacity: "0.8",
          background: "transparent",
          lineHeight: "13.06px",
        }}
      >
        {initials}
      </span>
    </div>
  );
};

export default ImageWithInitials;
