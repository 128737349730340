const ExportClientPDFFooter = (props) => (
  <div {...props} className={`page-footer ${props.className ? props.className : ''}`}>
    <div className="hr" />
    <div>
    <div className="page-number"></div>
    <div className="powered-container">
      <span>Powered by</span>
      <img src="/images/logo/highground/regular.svg" alt="" />
    </div>
    </div>
  </div>
);

export default ExportClientPDFFooter;
