import { Dropdown } from "react-bootstrap";
import SelectArrowIcon from "../../icons/select-arrow.icon";

const MSPAddDropdown = ({ 
  setShowCategorySpendModal,
  setSubscriptionViewInvoiceData,
  setShowTechnologySpendModal,
  setDefaultSpendSelectedTab
}) => {
  const items = [
    { label: "Add Category", onClick: () => { setShowCategorySpendModal(true); } },
    { 
      label: "Add Subscription", 
      onClick: () => { 
        setSubscriptionViewInvoiceData({ newInvoice: true });
        setShowTechnologySpendModal(true);
        setDefaultSpendSelectedTab("addBySubscription");
      } 
    },
    { label: "Add Amount", 
      onClick: () => { 
        setSubscriptionViewInvoiceData({ newInvoice: true });
        setShowTechnologySpendModal(true);
        setDefaultSpendSelectedTab("addByAmount");
      } 
    },
    { label: "Add Invoice", 
      onClick: () => { 
        setSubscriptionViewInvoiceData({ newInvoice: true });
        setShowTechnologySpendModal(true);
        setDefaultSpendSelectedTab("addByInvoice");
      } 
    },
  ]
  return (
    <Dropdown className="transparennt-wrapper-dropdown my-dropdown">
      <Dropdown.Toggle className="p-0 toggle">
        <div className="radius-4 hg-blue-btn p-2  f-500 d-flex align-items-center">
          Add
          <SelectArrowIcon className="ml-1 arrow" width="12px" height="12px" fill="#fff" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight
        className="p-0 py-1 menu disable-transform align-right"
        style={{
          zIndex: 4,
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="p-2 f-500 f-grey cursor-pointer item"
            onClick={item.onClick}
          >
            {item.label}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MSPAddDropdown;

