import moment from "moment";
import { TrimString } from "../../../../../Utils/StringTrimmer";
import SubPaymentInvoiceIcon from "../../../../icons/sub-payment-invoice.icon";

const SubPaymentCardItem = ({ 
  _invoice,
  categoryPageData,
  subCategoryPageData,
  goTo,
  getInvoiceDetails,
  setInvoiceEditMode,
  setAddSpendSelectedTab,
  setLoading,
  Pages,
 }) => (
  <div
    className="py-1 px-2 bg-hover-grey radius-4 pointer"
    onClick={() => {
      setLoading(true);
      setInvoiceEditMode(true);

      if (_invoice?.IsDirectAmount) {
        setAddSpendSelectedTab(
          "addByAmount"
        );
      } else {
        setAddSpendSelectedTab(
          "addByInvoice"
        );
      }

      goTo(
        <>
          <SubPaymentInvoiceIcon 
            width="20px"
            height="20px"
            className="mr-2 d-inline-block"
          />
          {TrimString(
            _invoice?.InvoiceRef,
            30
          )}
        </>,
        Pages.ADD_SPEND,
        {
          invoiceId:
            _invoice?.objId_Invoice,
          categoryId:
            categoryPageData?.ID ?? 0,
          subCategoryId:
            subCategoryPageData?.ID ?? 0,
          invoiceType: "addBySubscription",
        }
      );
      getInvoiceDetails(
        _invoice?.objId_Invoice,
        0,
        {
          invoiceItemId:
            _invoice?.objId_Invoice,
        }
      );
    }}
  >
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <SubPaymentInvoiceIcon 
          width="20px"
          height="20px"
          className="mr-2 d-inline-block"
        />
        <div>
          <h3 className="m-0 f-14 f-black" style={{ fontWeight: 400 }}>
            {_invoice?.InvoiceRef}
          </h3>
          <span className="f-12 f-grey">
            {moment(_invoice?.InvoiceDate).format(
              "ll"
            )}
          </span>
        </div>
      </div>
      <div className="f-black f-500">
        {_invoice?.TotalItemValue_Text}
      </div> 
    </div>
  </div>
);

export default SubPaymentCardItem;
