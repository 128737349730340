import React from "react";
import "./sign-up.scss";
import { TrackUser } from "../../Utils/SegmentFunctions";
import {
  Tooltip,
} from "react-bootstrap";
import { useEffect } from "react";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useState } from "react";
import { useAuth } from "../context/Auth";
import { useDb } from "../context/db";
import { useHistory } from "react-router-dom";
import { getUserDataFromAuthCode } from "./Loginv2";
import { CustomGetApi, getAPIData } from "../../Utils/commonApi";
import CryptoJS from "crypto-js";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";
import { FullScreenLoader } from "../Common/loader";
import UserEmailSection from "./sections/user-email.section";
import AuthLayout from "./auth-layout/auth.layout";
import AuthBody from "./auth-layout/auth.body";
import AuthSignupSections from "./auth-layout/auth-signup-sections.component copy";
import SignUpPasswordSection from "./sections/sign-up-password.section";
import SignUpCompanySection from "./sections/sign-up-company.section";
import SaasAnimationSection from "./sections/saas-animation.section";
import Lottie from "react-lottie-player";
import * as animationData from "../Spinner/Icon-check.json";
import useApiActions from "../../hooks/api-actions.hook";
import { FILE_TYPES_API } from "../../constants/apis.constants";
import { useDispatch } from "react-redux";
import { setuser } from "../../redux/userState/actions";
import AuthBottomInfoSections from "./auth-layout/auth-bottom-info-sections.component";
import { emailRegExp } from "../../constants/regex.constants";

const SignUpFormState = {
  EMAIL: 0,
  ACCOUNT: 1,
  COMPANY: 2,
}

const SignUpStatesTitles = [
  { title: "Create account", description: "Login to HighGround or create an account to get started." },
  { title: "Create account", description: "Login to HighGround or create an account to get started." },
  { title: "Create account", description: "Login to HighGround or create an account to get started." },
]

const SignUpv2 = (props) => {
  const history = useHistory();
  const [formState, setFormState] = useState(SignUpFormState.EMAIL);
  const [showUserPassfields, setShowUserPassfields] = useState(false)
  const [showSSOMethod, setShowSSOMethod] = useState(false);
  const { uploadFile } = useApiActions();
  const dispatch = useDispatch();

  const { setUserType, setAuthTokens, setAuth,setMessageEncrypt } =
    useAuth();
  const { setdbObject } = useDb();
  const [passwordShow, setPasswordShow] = useState(false);
  const [redirectingMessage, setRedirectingMessage] = useState(
    "Creating your account, please wait.."
  );
  const [finishSignUpData, setFinishSignUpData] = useState({
    signUpCompleted: false,
    data: null,
    callBackFunc: null,
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [IsMicrosoftLogon, setIsMicrosoftLogon] = useState(false);

  const [email, setEmail] = useState("");
  const [otherRole, setOtherRole] = useState("");
  const [otherRoleError, setOtherRoleError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [happyToRecieve, setHappyToReceive] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [companyImg, setCompanyImg] = useState("");
  const [disableCredFieldsHL, setDisableCredFieldsHL] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("MSP");
  const [formSubmit, setFormSubmit] = useState("submitRole");
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showAnimation, setShowAnimation] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [resendEmailState, setResendEmailState] = useState(0);
  const [companyError, setCompanyError] = useState(false);
  const [roleData, setRoleData] = useState({ id: 4, category: "MSP" });
  //
  const [IsMsp, setIsMsp] = useState(true);
  const [loading, setLoading] = useState(false);
  const [referral, setReferral] = useState(
    props.match.params.referraltoken || ""
  );
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    objId: 4097775,
    name: "United Kingdom",
    code: "GB",
  });

  async function getCountryList() {
    await CustomGetApi(`CC_API/GetCountries?IsSimpleSort=0`)
      .then((response) => {
        if (response?.mr?.Success) {
          setCountryList(response?.detail);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    let user_token = localStorage.getItem("tokens");
    let user_data = localStorage.getItem("object_Data");
    let existingAuth = localStorage.getItem("userAuthenticated")
      ? Boolean(JSON.parse(localStorage.getItem("userAuthenticated")))
      : null;
    let userAccesKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : null;
    if (user_token && user_data && existingAuth && userAccesKey) {
      let _data = JSON.parse(user_data);

      if (_data?.UserDetail?.Role?.toLowerCase() === "msp") {
        // If Role is ""MSP"""
        history.push("/mspv2/clients");
        return;
      }
      history.push(`/${_data?.UserDetail?.Role?.toLowerCase()}/dashboard`);
    } else {
      setdbObject();
      localStorage.setItem("dasboardLoader", true);
      getCountryList();
    }
  }, []);

  let runOnce = 0;

  function uploadImages(tokens) {
    setTimeout(() => {
      if (profileImg) {
        uploadFile(profileImg, FILE_TYPES_API.UserProfileImg, tokens)
          .then((response) => {
            updateAccessKeyInLocalStorage(response?.mr?.Message)
            dispatch(setuser({ Avatar: response.FileURL }));
          });
      }
      if (companyImg) {
        uploadFile(companyImg, FILE_TYPES_API.UserCompanyLogo, tokens)
          .then((response) => {
            updateAccessKeyInLocalStorage(response?.mr?.Message)
            dispatch(setuser({ OrgLogo: response.FileURL }));
          });
      }
    }, 7000);
  }

  // Redirect User directly to Login Screen
  function RedirectToDashboard(email, password) {
    if (runOnce > 0) return;
    runOnce = 2;
    setTimeout(() => {
      runOnce = 0;
    }, 3000);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    urlencoded.append("ipaddress", "111.111.111.111");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (
            data.error_description ==
            "The email address provided has not been confirmed."
          ) {
            // setFormState(4);
          }
        } else {
          //Set Token to Local Storage
          setAuthTokens(data.access_token);
          const token = data.access_token;
          localStorage.setItem("tokens", JSON.stringify(data.access_token));

          setRedirectingMessage(
            `Redirecting you to your dashboard, please wait . . .`
          );
          var myHeaders2 = new Headers();
          myHeaders2.append("Authorization", `Bearer ${data.access_token}`);

          let requestOptions = {
            method: "GET",
            headers: myHeaders2,
            redirect: "follow",
          };

          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              // test =false
              if (data?.ByPass2FA === true) {
                window.localStorage.setItem(
                  "token_time",
                  JSON.stringify(Math.floor(Date.now() / 60000))
                );
                console.log("enteres by pass mfa");
                let myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);

                let requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };

                fetch(
                  `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=999666`,
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((data) => {
                    let responseData = { ...data };
                    getAPIData(0, JSON.parse(localStorage.getItem("tokens")), {
                      accessKey: data?.Message,
                    }).then((res) => {
                      TrackUser("User Signed In", {
                        email: res?.UserDetail?.UserName,
                        UserFullName: res?.UserDetail?.FullName,
                      });
                      if (res?.isTempAccessSession === true) {
                        window.localStorage.setItem("showTempAccessBanner", "true");
                      } else {
                        window.localStorage.removeItem("showTempAccessBanner");
                      }
                      localStorage.setItem("InActivityTimer", 0);
                      setFinishSignUpData({
                        signUpCompleted: true,
                        data: data,
                        callBackFunc: finishSignUpStep,
                      });

                      const secretKey =
                        "Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j"; // Your secret key
                      // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
                      const userIdentifier = email; // The email for your user

                      // Creating HMAC using SHA256 algorithm
                      const hash = CryptoJS.HmacSHA256(
                        userIdentifier,
                        secretKey
                      ).toString(CryptoJS.enc.Hex);

                      // Intercomm
                      window.Intercom("boot", {
                        app_id: "jaa5e0c1",
                        email: email,
                        name: fullName,

                        user_hash: hash,
                      });

                      setUserType(responseData?.EntityId);
                      setLoading(false);
                      localStorage.setItem("object_Data", JSON.stringify(data));
                      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
                      console.log("settiing access key");
                      updateAccessKeyInLocalStorage(responseData?.Message);

                      setMessageEncrypt(responseData?.Message); // Setting Access key
                      setdbObject(res);
                      setAuth(true);

                      // setTimeout(() => {
                      //   history.push({
                      //     pathname: `/mspv2/clients`,
                      //     state: { isLogin: true },
                      //   });
                      // }, 1000);
                    });
                  })
                  .catch((err) => {
                    setFormSubmit(3);
                    setRedirectingMessage(
                      "Creating your account, please wait.."
                    );
                    setShowAnimation(false);
                    setLoading(false);
                    CallToast(
                      "500 - Internal Server Error",
                      false,
                      false,
                      "v2style"
                    );
                    console.log("error- in api 1", error);
                  });
              }
            })
            .catch((error) => {
              setFormSubmit(3);
              setRedirectingMessage("Creating your account, please wait..");
              setShowAnimation(false);
              setLoading(false);
              CallToast("500 - Internal Server Error", false, false, "v2style");
              console.log("error- in api 2", error);
            });
        }
      })
      .catch((error) => {
        setFormSubmit(3);
        setRedirectingMessage("Creating your account, please wait..");
        setShowAnimation(false);
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("login error", error);
      });
  }

  function finishSignUpStep(data) {
    window.localStorage.setItem("userType", JSON.stringify(data?.EntityId));
    updateAccessKeyInLocalStorage(data.Message);

    setAuth(true);

    setUserType(data.EntityId);

    /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
    console.log("setting access key in sign up");
    // setMessageEncrypt(data.Message);
    let element = document.getElementById("root").firstChild;
    element.classList.add("fadeIn");
    setTimeout(() => {
      element.classList.remove("fadeIn");
    }, 500);

    let userType = {
      111111: "ceo",
      999999: "ceo",
      222222: "cto",
      333333: "cfo",
    };
    setShowAnimation(false);
    uploadImages(JSON.parse(localStorage.getItem("tokens")));
    if (data.EntityId === 555555) {
      history.push({
        pathname: `/mspv2/clients`,
        state: { isLogin: true },
      });
      return;
    }

    history.push({
      pathname: `/${userType[data.EntityId]}/dashboard`,
      state: { isLogin: true },
    });
  }

  const strength = [
    {
      name: "Use A-Z uppercase, a-z lowercase, 0-9 number, symbols, 8-digits",
      maxValue: 0,
      color: "greyText",
      barColor: "bar-not-active",
    },
    {
      name: "Use A-Z uppercase, symbols, 0-9 number, 8-digits",
      maxValue: 1,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use symbols, 0-9 number, 8-digits",
      maxValue: 2,
      color: "redText",
      barColor: "bar-filled-red",
    },
    {
      name: "Use 0-9 number, 8-digits",
      maxValue: 3,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Use 8-digits",
      maxValue: 4,
      color: "orangeText",
      barColor: "bar-filled-orange",
    },
    {
      name: "Your password is strong",
      maxValue: 5,
      color: "greenText",
      barColor: "bar-filled",
    },
  ];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oldVersion = urlParams.get("method");
    if(oldVersion == 'sso'){
      // setShowUserPassfields(true)
      setShowSSOMethod(true);
    }


    if (urlParams?.get("email")) {
      setEmail(urlParams.get("email"));
    }

    const microsoftCode = urlParams.get("code");
    const session_state = urlParams.get("session_state");
    const microsoftLogonEmail = urlParams.get("microsoftLogonEmail");
    const userGivenName = urlParams.get("userGivenName");
    const role = urlParams.get("role");
    if (microsoftCode) {
      setIsMicrosoftLogon(true);
      setPassword("jgchthdcm@mhcggg3598265365$$$#");
      //1. Came from Login Screen
      if (microsoftLogonEmail && userGivenName) {
        setEmail(microsoftLogonEmail);
        setFullName(userGivenName);

        setPageLoading(false);
        return;
      }
      setPageLoading(true);
      getUserDataFromAuthCode(microsoftCode)
        .then((response) => {
          if (!response) {
            setPageLoading(false);
      setIsMicrosoftLogon(false);

            return;
          }

          if (response?.existingHgUser === true) {
            history.push(
              `/login?code=${microsoftCode}&microsoftLogonEmail=${response?.userLogon}&userGivenName=${response?.userGivenName}`
            );
          } else {
            if (role === "ceo") {
              setIsMsp(false);
              setRole("ceo");
              setRoleData({ id: 4, category: "SAAS" });
              setFormSubmit(2);
            } else {
              setIsMsp(true);
              setRole("MSP");
              setRoleData({ id: 4, category: "MSP" });

              setFormSubmit(2);
            }
          }
        })
        .catch(() => {
          setPageLoading(false);
        });
      return;
    }
  }, []);

  useEffect(() => {}, []);

  function togglePasswordShow() {
    if (passwordShow) return setPasswordShow(false);
    return setPasswordShow(true);
  }

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const handlePassword = (pwd) => {
    setPassword(pwd);
    var variations = {
      length: /^.{8,}$/.test(pwd),
      digits: /\d/.test(pwd),
      lower: /[a-z]/.test(pwd),
      upper: /[A-Z]/.test(pwd),
      nonWords: /\W/.test(pwd),
    };

    var strengthCount = 0;
    for (var check in variations) {
      strengthCount += variations[check] == true ? 1 : 0;
    }
    setPasswordStrength(strengthCount);
  };
  console.log(formSubmit, "formSubmit");

  async function handleCheckUser(value, email) {
    setLoading(true);
    setFormSubmit(value);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var inputs = new URLSearchParams();
    inputs.append("EmailAddress", email);

    if (new URLSearchParams(window.location.search).get("psid")) {
      inputs.append(
        "PotentialSupplierId",
        new URLSearchParams(window.location.search).get("psid")
      );
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckUserExists`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.Success === false) {
          setLoading(false);
          setEmailError("There is a problem with your username or password. Please check and try again");
          setPasswordError("There is a problem with your username or password. Please check and try again");
          setDisableCredFieldsHL(true);
        } else {
          setLoading(false);
          setFormSubmit(value + 1);
          setFormState(SignUpFormState.COMPANY);
          setEmailError(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function handleRegistration(value, password, fullName, companyName, role, country) {
    setLoading(true);
    setFormSubmit(value);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var inputs = new URLSearchParams();
    inputs.append("EmailAddress", email);

    if (new URLSearchParams(window.location.search).get("psid")) {
      inputs.append(
        "PotentialSupplierId",
        new URLSearchParams(window.location.search).get("psid")
      );
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    inputs.append("Password", password);
    inputs.append("HappyToReceive", happyToRecieve);
    inputs.append("FullName", fullName);
    inputs.append("CompanyName", companyName);
    inputs.append("CompanyRole", role);
    inputs.append("Country", country.objId);

    setFormSubmit(value + 1);
    setIsMsp(role === "MSP");

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UserRegistration`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data?.Success) {
          // setLoading(false);

          setShowAnimation(true);

          setFormSubmit(value + 2);

          if (referral) {
            TrackUser("Invite Activated", {
              email: email,
              UserFullname: fullName,
            });
          } else {
            TrackUser("User signed up", {
              email: email,
              UserFullname: fullName,
            });
          }
        }
        if (data.Success === false) {
          setLoading(false);
          setFormSubmit(2);
          CallToast("There is a problem with your username or password. Please check and try again", false, false, "v2style");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleResendEmail() {
    setResendEmail(true);
    setResendEmailState(resendEmailState + 1);
    if (resendEmailState > 2) {
      var duration = 300;
    } else var duration = 10;
    var handle = setInterval(function () {
      var minutes, seconds;
      minutes = parseInt(duration / 60, 10);
      seconds = parseInt(duration % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      document.querySelector("#time").textContent = minutes + ":" + seconds;
      if (duration > 0) {
        duration--;
      } else {
        clearInterval(handle);
        setResendEmail(false);
      }
    }, 1000);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var inputs = new URLSearchParams();
    inputs.append("EmailAddress", email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResendVerification`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.Success == false) {
          setError("Unable to send");
          setTimeout(() => {
            setError(false);
          }, 3000);
        } else {
          setError(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Handle validations
   */
  const handleValidations = (submit) => {
    let error_structure = {
      eEmail: false,
      ePassword: false,
      eCompanyName: false,
      eFullName: false,
    };
    if (submit == 0) return error_structure;
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || !re.test(email)) {
      error_structure.eEmail = true;
      // setEmailError("Kindly enter valid email");
    }
    if (email && !re.test(email)) {
      error_structure.eEmail = "Invalid email address format.";
    }
    if (!password || passwordStrength < 5) {
      // error_structure.ePassword = true;
    }
    if (!companyName && formSubmit == 3) {
      error_structure.eCompanyName = true;
    }
    if (!fullName && formSubmit == 3) {
      error_structure.eFullName = true;
    }
    const { eEmail, ePassword, eCompanyName, eFullName } = error_structure;

    if (eEmail || ePassword || eCompanyName || eFullName) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  async function onLoginEmailSubmit(email) {
    if (email == "") {
      setEmailError("Email can't be empty");
      setLoading(false);
      return false;
    }
    if (!emailRegExp.test(email)) {
      setEmailError("Invalid email address format. Please check and try again.");
      setLoading(false);
      return false;
    }
    setEmail(email);
    setEmailError("");
    setFormState(SignUpFormState.ACCOUNT);
  }

  async function onProfileInfoSubmit(fullName, password, img, passwordStrength) {
    let hasError = false;
    if (fullName == "") {
      setFullNameError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (password == "") {
      setPasswordError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (passwordStrength < 5) {
      setPasswordError(
        "Password must contain 8 characters or more and must include lowercase, uppercase, numeric and special characters"
      );
      setLoading(false);
      hasError = true;
    }
    if (hasError) return;
    setFullName(fullName);
    setPassword(password);
    setProfileImg(img);
    handleCheckUser(1, email);
  }

  async function onCompanyInfoSubmit(companyName, role, country, img) {
    let hasError = false;
    if (companyName == "") {
      setCompanyError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (role == "") {
      setCompanyError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (country == "") {
      setCompanyError("Required field");
      setLoading(false);
      hasError = true;
    }
    if (hasError) return;
    setCompanyName(companyName);
    setRole(role);
    setSelectedCountry(country);
    setCompanyImg(img);
    handleRegistration(2, password, fullName, companyName, role, country);
  }

  const { eEmail, ePassword, eCompanyName, eFullName } =
    handleValidations(formSubmit);
  const message = eEmail ? eEmail : emailError;

  const sections = [UserEmailSection, SignUpPasswordSection, SignUpCompanySection];
  const sectionLayouts = [AuthBottomInfoSections, AuthSignupSections, AuthSignupSections];
  const titles = SignUpStatesTitles[formState] ?? {};
  const isAccCreation = formSubmit >= 3 && (loading || showAnimation) 
  const CurSection = isAccCreation
    ? SaasAnimationSection 
    : sections[formState] ?? <div />;
  const CurLayout = isAccCreation ? AuthSignupSections : sectionLayouts[formState] ?? <div />;

  return (
    <>
      {pageLoading ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 "
          style={{
            height: "100vh",
          }}
        >
          <FullScreenLoader />
        </div>
      ) : (
        <AuthLayout>
          <AuthBody>
            <CurLayout
              title={titles.title}
              description={titles.description}
            >
              <CurSection
                flowType="signup" 
                message={message}
                emailError={emailError}
                passwordError={passwordError}
                showSSOMethod={showSSOMethod}
                setEmailError={setEmailError}
                setPasswordError={setPasswordError}
                error={error}
                setError={setError}
                onLoginEmailSubmit={onLoginEmailSubmit}
                loading={loading}
                defaultPassword={password}
                defaultEmail={email}
                defaultFullName={fullName}
                defaultProfileImg={profileImg}
                setFormState={setFormState}
                fullNameError={fullNameError}
                setFullNameError={setFullNameError}
                onProfileInfoSubmit={onProfileInfoSubmit}
                countryList={countryList}
                companyError={companyError}
                setCompanyNameError={setCompanyError}
                onCompanyInfoSubmit={onCompanyInfoSubmit}
                defaultCompanyImage={companyImg}
                defaultCompanyName={companyName}
                defaultSelectedCountry={selectedCountry}
                defaultRole={role}
                signUpCompleted={finishSignUpData.signUpCompleted}
                callBack={finishSignUpData.callBackFunc}
                callBackData={finishSignUpData.data}
                userType={IsMsp ? "MSP" : "SAAS"}
                setDisableCredFieldsHL={setDisableCredFieldsHL}
                disableCredFieldsHL={disableCredFieldsHL}
                curStep={formState}
                amountsStep={SignUpFormState.COMPANY}
              />
              {showAnimation && (
                <div
                  className="wrapperContainer paddingTopWrap text-center w-100"
                  style={{ minHeight: "75vh", opacity: 0, position: "absolute" }}
                >
                  <Lottie
                    loop={false}
                    play={true}
                    animationData={animationData.default}
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                      filterSize: {
                        width: "200%",
                        height: "200%",
                        x: "-50%",
                        y: "-50%",
                      },
                    }}
                    onComplete={() => {
                      RedirectToDashboard(email, password);
                    }}
                    width={200}
                    height={200}
                  ></Lottie>
                  <div className="heading4">
                    {/* Sending Verification Email... */}
                    {redirectingMessage}
                  </div>
                </div>
              )}
            </CurLayout>
          </AuthBody>
        </AuthLayout>
      )}
    </>
  );
};

export default SignUpv2;
