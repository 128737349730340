import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import "./integration-list.styles.scss";
import IntegrationItemDropdown from "./integration-item.dropdown";
import CustomInputField from "../../Theme/CustomInputField";
import integrationUtils from "../../../Utils/integration.utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ManagedBySOCState } from "../../../constants/integrations.constants";
import SearchTitle from "../../Common/search-title.component";

const IntegrationListItem = ({ 
  integrationName, 
  integrationId, 
  isLoading, 
  onRemoveOption,
  onRenameOption, 
  style,
  renameStyle,
  toolIcon,
  drop = "down",
  showAddSub = true,
  showRename = true,
  showRemove = true,
  showSOC = false,
  isUserDefined = false,
  isManagedBySOC,
  managedBySOCState,
  searchComponent,
  onAddSubscription = () => { },
  onDeleteOption = () => { },
  onSOCStateChange = () => { }
}) => {
  const searchTitle = searchComponent ? SearchTitle({ title: integrationName, searchValue: searchComponent }) : integrationName;
  const isSOCChecked = 
  managedBySOCState === ManagedBySOCState.Always ? true 
    :  managedBySOCState === ManagedBySOCState.Optional ? isManagedBySOC
    : false;

  const [socCheked, setSocChecked] = useState(isSOCChecked);
  const [inputRef, setInputRef] = useState(null);
  const [newIntegrationName, setNewIntegrationName] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);

  const imagePath = integrationUtils.getImagePath(integrationId, toolIcon);

  const onRenameOptionClick = () => {
    setIsRenaming(true);
    setNewIntegrationName(integrationName);
  }

  const onCancelRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    setNewIntegrationName('');
  }

  const onAcceptRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    onRenameOption(integrationId, newIntegrationName);
  }

  const isSOCDisabled = managedBySOCState === ManagedBySOCState.Always 
    || managedBySOCState === ManagedBySOCState.Never;
  const isSocAlways = managedBySOCState === ManagedBySOCState.Always;
  const isSocNo = managedBySOCState === ManagedBySOCState.Never;
  const displaySOCTooltip = isSocAlways || isSocNo;
  
  const getSOCTooltipText = () => {
    if (isSocAlways) {
      return 'This tool is always monitored by a SOC.';
    }
    return 'This tool cannot be monitored by a SOC.';
  }

  useEffect(() => {
    if (!isRenaming) return;
    inputRef?.current?.focus();
  }, [isRenaming, inputRef?.current]);

  useEffect(() => {
    setSocChecked(isSOCChecked);
  }, [isSOCChecked]);

  const tooltipId = `tooltip-integrations-soc-${integrationId}`;

  return (
    <div className="d-flex align-items-center justify-content-between integration-list-item" style={style}>
      <div className="d-flex align-items-center">
        <img
          alt=""
          style={{
            width: "16px",
            height: "16px",
            borderRadius: '2px'
          }}
          src={imagePath}
          className="mr-2"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "/images/msp/no-tool-image.png";
          }}
        />
        {isRenaming ? (
          <div className="d-flex align-items-center" style={renameStyle}>
            <CustomInputField
              placeholder={"Integration name"}
              type={"text"}
              value={newIntegrationName}
              onChange={(e) => {
                setNewIntegrationName(e.target.value);
              }}
              className='rename-input'
              setRef={setInputRef}
            />
          </div>
        ) : (
          <div className="f-500" style={{ padding: '2px 0' }}> {searchTitle || integrationName}</div>
        )}
        <div>
          {isLoading? (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
              style={{
                width: "12px",
                height: "12px",
                marginBottom: '2px'
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {!isRenaming ? (
        <div className="d-flex align-items-center" style={{ gap: '8px' }}>
          {showSOC && (
            <OverlayTrigger
                placement="bottom"
                trigger={displaySOCTooltip ? ["hover", "focus"] : []}
                delay={{ show: 200, hide: 250 }}
                overlay={(props) => (
                  <Tooltip
                      id={tooltipId}
                      className="tooltip-integrations-soc" 
                      style={{ position: 'relative', zIndex: 10000,  }}
                      positionLeft={0}
                      {...props}
                  >
                    <div>
                      <span>
                        {getSOCTooltipText()}
                      </span>
                    </div>
                  </Tooltip>
                )}
            >
              <div className="soc-container">
                <Form.Group 
                  className={`round-checkbox blue-checkbox checkboxstyle ${isSOCDisabled ? 'disabled cursor-default' : 'cursor-pointer'}`}
                  style={{ marginBottom: '15px' }}
                  onClick={() => {
                    if (isSOCDisabled) return;
                    onSOCStateChange(integrationId, !socCheked);
                    setSocChecked(!socCheked);
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    name={`checkbox` + integrationId}
                    checked={socCheked}
                    onChange={(e) => {}}
                    disabled={isSOCDisabled}
                  />
                </Form.Group>
                <span className={`soc-txt ${isSOCDisabled ? 'f-darkgrey' : 'f-black'}`}>
                  Monitored by SOC
                </span>
              </div>
            </OverlayTrigger>
          )}
          {
            showAddSub || showRename || showRemove || isUserDefined ? (
              <IntegrationItemDropdown 
                integrationId={integrationId} 
                onRemoveOption={onRemoveOption} 
                onRenameOption={onRenameOptionClick}
                onDeleteOption={onDeleteOption}
                showAddSub={showAddSub}
                showRename={showRename}
                showRemove={showRemove}
                showDelete={isUserDefined}
                drop={drop} 
                onAddSubscription={onAddSubscription}
              />
            ) : null
          }
          
        </div>
      ) : (
        <div className="d-flex align-items-center ml-2" style={{ gap: '8px' }}>
          <button className="apply" onClick={onAcceptRename}>Apply</button>
          <button className="cancel" onClick={onCancelRename}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default IntegrationListItem;
