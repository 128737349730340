import React, { memo, useMemo } from "react";
import MyRadarChart from "../../chartjs/my-radar.chart";
const MspClientPriceCalculatorRadarChart = ({
  activeChart = 1,
  _datasets = staticDatasets,
  merged_Array = [],
  merged_filter,
  passedW,
  passedH
}) => {
  console.log("merged_Array", merged_Array)
  const mergedDataList = useMemo(() => {
    const firstDataArray = _datasets[0].data;

    return _datasets.map((dataset, index) => {
      if (index === 0) {
        return dataset;
      }

      const newData = dataset.data.map((value, i) =>
        Math.max(value, firstDataArray[i])
      );

      return {
        ...dataset,
        data: newData,
      };
    });
  }, [_datasets]);

  console.log("mergedDataList", mergedDataList.map((item) => item.data))

  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      data: merged_Array[activeChart] ? mergedDataList[dataIndex].data : data.data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      pointRadius:
        dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],

      borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
    }));
    const test = merged_filter;
    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart, merged_Array, mergedDataList, merged_filter]);



  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 300,// 4000, // Set the duration of the transition in milliseconds
      },
      devicePixelRatio: 4,
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: /*40*/50,
          min: 0,
          ticks: {
            display: false,
            stepSize: 10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  return (
    <div
      className="position-relative"
      style={{
        width: `${passedW ? `${passedW}px` : "375px"}`,
        height: `${passedH ? `${passedH}px` : "375px"}`,
        marginTop: "60px",
        marginBottom: "40px",
      }}
    >
      <MyRadarChart
        id="actial-radar"
        redraw={true}
        height={passedW ? passedW : 375}
        width={passedH ? passedH : 375}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-24px",
          left: `${passedW ? '121px' : "187px"}`,
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[0] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-50px",
          top: `${passedW ? "calc(101px - 3rem)" : "calc(130px - 3rem)"}`,
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[1] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: `${passedW ? "calc(40px - 3rem)" : "calc(53px - 3rem)"}`,
          bottom: "calc(43px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[2] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: `${passedW ? "calc(241px - 3rem)" : "calc(356px - 3rem)"}`,
          bottom: "calc(43px - 3rem)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[3] : 0}
        </div>
      </div>

      <div
        className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "calc(-13px - 3rem)",
          top: `${passedW ? "calc(101px - 3rem)" : "calc(130px - 3rem)"}`,
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[4] : 0}
        </div>
      </div>
    </div>
  );
};

export default memo(MspClientPriceCalculatorRadarChart);



// const MspClientPriceCalculatorRadarChartPolina = ({
//   activeChart = 1,
//   _datasets = staticDatasets,
//   merged_Array = [],
//   merged_filter,
//   isAnimating = false,
//   setIsAnimating = () => { }
// }) => {
//   const [gradient, setGradient] = useState(null);
//   const [gradientBorder, setGradientBorder] = useState(null);

//   useEffect(() => {
//     if (isAnimating && merged_Array[activeChart]) {
//       const animateGradient = (ctx) => {
//         let step = 0;
//         const steps = 100;
//         const updateGradient = () => {
//           step += 1;
//           if (step > steps) {
//             step = 0;
//             return
//           }

//           const colorStops = [
//             { stop: 0, color: 'rgba(64, 216, 142, 0.2)' },
//             { stop: 0.17, color: 'rgba(255, 209, 29, 0.2)' },
//             { stop: 0.32, color: 'rgba(255, 172, 44, 0.2)' },
//             { stop: 0.49, color: 'rgba(243, 96, 96, 0.2)' },
//             { stop: 0.66, color: 'rgba(255, 172, 44, 0.2)' },
//             { stop: 0.85, color: 'rgba(255, 209, 29, 0.2)' },
//             { stop: 1, color: 'rgba(64, 216, 142, 0.2)' }
//           ];

//           const colorStopsBorder = [
//             { stop: 0, color: 'rgba(64, 216, 142, 1)' },
//             { stop: 0.17, color: 'rgba(255, 209, 29, 1)' },
//             { stop: 0.32, color: 'rgba(255, 172, 44, 1)' },
//             { stop: 0.49, color: 'rgba(243, 96, 96, 1)' },
//             { stop: 0.66, color: 'rgba(255, 172, 44, 1)' },
//             { stop: 0.85, color: 'rgba(255, 209, 29, 1)' },
//             { stop: 1, color: 'rgba(64, 216, 142, 1)' }
//           ];

//           const newGradient = ctx.createLinearGradient(0, 150, 300, 150);
//           colorStops.forEach(({ stop, color }) => {
//             const newStop = (stop + step / steps) % 1;
//             newGradient.addColorStop(newStop, color);
//           });

//           const newGradientBorder = ctx.createLinearGradient(0, 150, 300, 150);
//           colorStopsBorder.forEach(({ stop, color }) => {
//             const newStop = (stop + step / steps) % 1;
//             newGradientBorder.addColorStop(newStop, color);
//           });

//           setGradient(isAnimating ? newGradient : null);
//           setGradientBorder(isAnimating ? newGradientBorder : null)
//         };

//         const intervalId = setInterval(updateGradient, 200);
//         return () => clearInterval(intervalId);
//       };

//       const canvas = document.getElementById('actial-radar');
//       if (canvas && isAnimating) {
//         const ctx = canvas.getContext('2d');
//         animateGradient(ctx);
//       }

//     } else {
//       setGradient(null);
//     }
//   }, [isAnimating]);

//   const mergedDataList = useMemo(() => {
//     const firstDataArray = _datasets[0].data;

//     return _datasets.map((dataset, index) => {
//       if (index === 0) {
//         return dataset;
//       }

//       const newData = dataset.data.map((value, i) =>
//         Math.max(value, firstDataArray[i])
//       );

//       return {
//         ...dataset,
//         data: newData,
//       };
//     });
//   }, [_datasets]);


//   const updatedData = useMemo(() => {
//     let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
//       ...data,
//       data: merged_Array[activeChart] ? mergedDataList[dataIndex].data : data.data,
//       backgroundColor:  isAnimating ? gradient : merged_Array[activeChart] ? 'rgba(54, 162, 235, 0.3)' :
//         dataIndex === activeChart
//           ? `${data.backgroundColor}30`
//           : `${data.backgroundColor}00`,
//       borderColor: isAnimating ? gradientBorder : merged_Array[activeChart] ? 'rgba(54, 162, 235, 1)' :
//         dataIndex === activeChart
//           ? `${data.borderColor}`
//           : `${data.borderColor}`,
//       pointBackgroundColor: isAnimating ? gradientBorder : merged_Array[activeChart] ? 'rgba(54, 162, 235, 1)' :
//         dataIndex === activeChart
//           ? `${data.borderColor}`
//           : `${data.borderColor}`,
//       pointRadius: dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0],
//       borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
//     }));
//     if (isAnimating) {
//       setTimeout(() => {
//         setIsAnimating(false);
//         setGradient(null);
//       }, 300);
//     }

//     return {
//       labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
//       datasets: updatedDatasets,
//     };
//   }, [_datasets, activeChart, merged_Array, mergedDataList, gradient]);

//   const updatedOptions = useMemo(() => {
//     return {
//       animation: {
//         duration: 200, 
//       },
//       responsive: false,
//       maintainAspectRatio: false,
//       legend: {
//         display: false,
//       },
//       tooltips: {
//         enabled: false,
//       },
//       scale: {
//         pointLabels: {
//           display: false,
//           fontSize: 16,
//           fontFamily: "Archivo, sans-serif",
//           fontColor: "#101010",
//         },
//         ticks: {
//           beginAtZero: false,
//           stepSize: 5,
//           max: 35,
//           min: 0,
//           display: false,
//           backdropColor: "rgba(0, 0, 0, 0)",
//         },
//       },
//       elements: {
//         point: {
//           radius: 2,
//           hoverRadius: 4,
//         },
//       },
//     };
//   }, []);

//   return (
//     <div
//       className="zoom-independent position-relative"
//       style={{
//         width: "375px",
//         height: "375px",
//         marginTop: "60px",
//         marginBottom: "40px",
//       }}
//     >
//       <Radar
//         id="actial-radar"
//         // redraw={true}
//         height={375}
//         width={375}
//         style={{ margin: "auto" }}
//         data={updatedData}
//         options={updatedOptions}
//       />
//       <div
//         className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
//         style={{
//           top: "-24px",
//           left: "187px",
//           transform: "translate(-50%, -50%)",
//         }}
//       >
//         <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
//           Identify
//         </div>

//         <div
//           className="w-fit-content h-fit-content "
//           style={{
//             color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
//           }}
//         >
//           {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[0] : 0}
//         </div>
//       </div>

//       <div
//         className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
//         style={{
//           right: "-50px",
//           top: "calc(150px - 3rem)",
//         }}
//       >
//         <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
//           Protect
//         </div>

//         <div
//           className="w-fit-content h-fit-content "
//           style={{
//             color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
//           }}
//         >
//           {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[1] : 0}
//         </div>
//       </div>

//       <div
//         className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
//         style={{
//           right: "calc(58px - 3rem)",
//           bottom: "calc(53px - 3rem)",
//         }}
//       >
//         <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
//           Detect
//         </div>

//         <div
//           className="w-fit-content h-fit-content "
//           style={{
//             color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
//           }}
//         >
//           {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[2] : 0}
//         </div>
//       </div>

//       <div
//         className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
//         style={{
//           right: "calc(356px - 3rem)",
//           bottom: "calc(53px - 3rem)",
//         }}
//       >
//         <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
//           Respond
//         </div>

//         <div
//           className="w-fit-content h-fit-content "
//           style={{
//             color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
//           }}
//         >
//           {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[3] : 0}
//         </div>
//       </div>

//       <div
//         className="position-absolute w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
//         style={{
//           left: "calc(-13px - 3rem)",
//           top: "calc(150px - 3rem)",
//         }}
//       >
//         <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
//           Recover
//         </div>

//         <div
//           className="w-fit-content h-fit-content "
//           style={{
//             color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
//           }}
//         >
//           {updatedData?.datasets[activeChart]?.data ? updatedData?.datasets[activeChart]?.data[4] : 0}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default memo(MspClientPriceCalculatorRadarChartPolina);


const staticDatasets = [
  {
    // data: [25, 20, 17, 30, 31],
    data: [0, 0, 0, 0, 0],
    pointRadius: [0, 0, 0, 0, 0],
    datalabels: {
      display: false,
    },
    backgroundColor: `${"#5244B3"}`,
    borderColor: `${"#5244B3"}`,
    pointBackgroundColor: "#5244B3",
    borderWidth: 1,
    pointLabelFontSize: 0,
    scaleShowLabels: false,
    label: "Current Posture",
  },
  // {
  //   // data: [30, 5, 34, 20, 15],
  //   data: [0, 0, 0, 0, 0],
  //   pointRadius: [0, 0, 0, 0, 0],
  //   datalabels: {
  //     display: false,
  //   },
  //   backgroundColor: `${"#008fd4"}`,
  //   borderColor: `${"#008fd4"}`,
  //   pointBackgroundColor: "#008fd4",
  //   borderWidth: 1,
  //   pointLabelFontSize: 0,
  //   scaleShowLabels: false,
  //   label: "Current Posture",
  // },
  // {
  //   // data: [0, 10, 30, 25, 5],
  //   data: [0, 0, 0, 0, 0],
  //   pointRadius: [0, 0, 0, 0, 0],
  //   datalabels: {
  //     display: false,
  //   },
  //   backgroundColor: `${"#008fd4"}`,
  //   borderColor: `${"#008fd4"}`,
  //   pointBackgroundColor: "#008fd4",
  //   borderWidth: 1,
  //   pointLabelFontSize: 0,
  //   scaleShowLabels: false,
  //   label: "Current Posture",
  // },
];





export const MspTechSpendScreenRadarChart = ({
  activeChart = 0,
  _datasets = staticDatasets,
  width = 150,
  height = 150,
  borderDash = true
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      pointRadius:
        dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],
      ...(borderDash && {
        borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
      }),
    }));

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],
      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);



  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1,// 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
      devicePixelRatio: 4,
      scales: {
        r: {
          beginAtZero: false,
          max: /*35*/50,
          min: 0,
          ticks: {
            display: false,
            stepSize: /*8.75*/10,
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      backgroundColor: 'red'
    };
  }, []);
  return (
    <div
      className="position-relative"
      style={{
        transform: "translate(0px, 0px)",
        maxWidth: `${width}px`,
        maxHeight: `${height}px`,
        minHeight: `${height}px`,
        // marginTop: "60px",
        // marginBottom: "40px",
      }}
    >
      <MyRadarChart
        id="actial-radar"
        // redraw={true}
        height={height ?? 150}
        width={width ?? 150}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-fixed identify w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-13%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[0]}
        </div>
      </div>

      <div
        className="position-fixed w-fit-content protect h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-42%",
          top: "29%",

          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[1]}
        </div>
      </div>

      <div
        className="position-fixed detect w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: '-26%',
          bottom: '-26%',
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[2]}
        </div>
      </div>

      <div
        className="position-fixed respond w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "64%",
          bottom: "-26%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[3]}
        </div>
      </div>

      <div
        className="position-fixed recover w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-14%",
          top: "28%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[4]}
        </div>
      </div>
    </div>
  );
};
export const ClientReportIndividualRadarChart = ({
  activeChart = 0,
  _datasets = staticDatasets,
  showPillar
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      // pointRadius:
      //   dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],

      // borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
    }));

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);



  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1,// 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: 35,
          min: 0,
          ticks: {
            display: false,
            stepSize: 5,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  return (
    <div
      className="zoom-independent position-relative"
      style={{
        transform: "translate(0px, 0px)"
        // width: "450px",
        // height: "450px",
        // marginTop: "60px",
        // marginBottom: "40px",
      }}
    >
      <MyRadarChart
        id="actial-radar"
        redraw={true}
        height={150}
        width={150}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      {showPillar === "identify" && <div
        className="position-fixed identify w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-13%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[0]}
        </div>
      </div>}

      {showPillar === "protect" && <div
        className="position-fixed w-fit-content protect h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-42%",
          top: "29%",

          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[1]}
        </div>
      </div>}

      {showPillar === "detect" && <div
        className="position-fixed detect w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: '-26%',
          bottom: '-26%',
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[2]}
        </div>
      </div>}

      {showPillar === "respond" && <div
        className="position-fixed respond w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "64%",
          bottom: "-26%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[3]}
        </div>
      </div>}

      {showPillar === "recover" && <div
        className="position-fixed recover w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-14%",
          top: "28%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "
          style={{
            color: `${updatedData?.datasets?.[activeChart]?.borderColor}`,
          }}
        >
          {updatedData.datasets[activeChart].data[4]}
        </div>
      </div>}
    </div>
  );
};
export const DualPackageRadarChart = ({
  activeChart = 0,
  _datasets = staticDatasets,
}) => {
  const updatedData = useMemo(() => {
    let updatedDatasets = [..._datasets]?.map((data, dataIndex) => ({
      ...data,
      backgroundColor:
        dataIndex === activeChart
          ? `${data.backgroundColor}30`
          : `${data.backgroundColor}00`,
      pointRadius:
        dataIndex === activeChart ? [2, 2, 2, 2, 2] : [0, 0, 0, 0, 0],

      borderDash: dataIndex === 0 ? [5, 5] : [0, 0],
    }));

    return {
      labels: ["Identity", "Protect", "Detect", "Respond", "Recover"],

      datasets: updatedDatasets,
    };
  }, [_datasets, activeChart]);

  console.log("updatedData", updatedData)

  const updatedOptions = useMemo(() => {
    return {
      animation: {
        duration: 1,// 4000, // Set the duration of the transition in milliseconds
      },
      responsive: false,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: false,
      },
      scales: {
        r: {
          beginAtZero: false,
          max: /*35*/50,
          min: 0,
          ticks: {
            display: false,
            stepSize: /*5*/10,
            backdropColor: "rgba(0, 0, 0, 0)",
          },
          pointLabels: {
            display: false,
            fontSize: 16,
            fontFamily: "Archivo, sans-serif",
            fontColor: "#101010",
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
  }, []);
  return (
    <div
      className="zoom-independent position-relative"
      style={{
        transform: "translate(0px, 0px)"
        // width: "450px",
        // height: "450px",
        // marginTop: "60px",
        // marginBottom: "40px",
      }}
    >
      <MyRadarChart
        // id="actial-radar"
        redraw={true}
        height={200}
        width={200}
        style={{ margin: "auto" }}
        data={updatedData}
        options={updatedOptions}
      />
      <div
        className="position-fixed identify w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          top: "-10%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Identify
        </div>

        <div
          className="w-fit-content h-fit-content "

        >
          <span className="mr-1" style={{
            color: `${updatedData?.datasets?.[0]?.borderColor}`,
          }}>{updatedData.datasets[0].data[0]}</span>
          <span style={{
            color: `${updatedData?.datasets?.[1]?.borderColor}`,
          }}>{updatedData.datasets[1].data[0]}</span>

        </div>
      </div>

      <div
        className="position-fixed w-fit-content protect h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "-33%",
          top: "29%",

          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Protect
        </div>

        <div
          className="w-fit-content h-fit-content "

        >
          <span className="mr-1" style={{
            color: `${updatedData?.datasets?.[0]?.borderColor}`,
          }}>{updatedData.datasets[0].data[1]}</span>
          <span style={{
            color: `${updatedData?.datasets?.[1]?.borderColor}`,
          }}>{updatedData.datasets[1].data[1]}</span>

        </div>
      </div>

      <div
        className="position-fixed detect w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: '-0%',
          bottom: '-20%',
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Detect
        </div>

        <div
          className="w-fit-content h-fit-content "

        >
          <span className="mr-1" style={{
            color: `${updatedData?.datasets?.[0]?.borderColor}`,
          }}>{updatedData.datasets[0].data[2]}</span>
          <span style={{
            color: `${updatedData?.datasets?.[1]?.borderColor}`,
          }}>{updatedData.datasets[1].data[2]}</span>

        </div>
      </div>

      <div
        className="position-fixed respond w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          right: "62%",
          bottom: "-20%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Respond
        </div>

        <div
          className="w-fit-content h-fit-content "

        >
          <span className="mr-1" style={{
            color: `${updatedData?.datasets?.[0]?.borderColor}`,
          }}>{updatedData.datasets[0].data[3]}</span>
          <span style={{
            color: `${updatedData?.datasets?.[1]?.borderColor}`,
          }}>{updatedData.datasets[1].data[3]}</span>

        </div>
      </div>

      <div
        className="position-fixed recover w-fit-content h-fit-content d-flex flex-column align-items-center justify-content-center"
        style={{
          left: "-10%",
          top: "30%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={`w-fit-content h-fit-content ${"f-black f-14 f-500"}`}>
          Recover
        </div>

        <div
          className="w-fit-content h-fit-content "

        >
          <span className="mr-1" style={{
            color: `${updatedData?.datasets?.[0]?.borderColor}`,
          }}>{updatedData.datasets[0].data[4]}</span>
          <span style={{
            color: `${updatedData?.datasets?.[1]?.borderColor}`,
          }}>{updatedData.datasets[1].data[4]}</span>

        </div>
      </div>
    </div>
  );
};