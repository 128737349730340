import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";

import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import MyLineChart from "../../chartjs/my-line.chart";

const AreaCharts = (props) => {
  const [displayChart, setDisplayChart] = useState(false);
  const hexRgb = require("hex-rgb");
  const [data] = useState(props.data || "");
  const [colour] = useState(props.color || "");
  const [chartGradient, setChartGradient] = useState("");
  const [background] = useState(props.background || "");
  const [hexConversion, setHexConversion] = useState("");
  const [height] = useState(props.height || "90%");
  const maxCount = props.maxCount || null;

  const mulitipleData = typeof data.values[0] == "object";

  if (colour && hexConversion == "")
    setHexConversion(hexRgb(colour, { format: "object" }));

  function generateDatasetArray(gradient) {
    var dataset = [];

    if (typeof data.values[0] == "object") {
      var dataMainArray = [];
      for (var i = 0; i < Object.keys(data.values).length; i++) {
        dataset.push(
          datasetObject(null, data.colours[i], data.values[i], `Test${i}`)
        );
      }
      console.log("dataMainArray", dataMainArray);
    } else {
      dataset.push(datasetObject(gradient, colour, data.values, `Test0`));
    }

    return dataset;
  }

  const datasetObject = (gradient, colour, values, name) => {
    return {
      label: name,
      fill: "end",
      backgroundColor: background
        ? background
        : gradient !== null
        ? gradient
        : "inherit",
      lineTension: 0.1,
      borderColor: colour,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: colour,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: values,
      datalabels: {
        display: false,
      },
    };
  };

  const options = {
    tension: 0.4,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x:
        {
          grid: {
            display: false,
          },
          ticks: {
            color: "#B6B6BF",
            autoSkip: true,
            maxTicksLimit: 10,
          },
          border: {
            display: false,
          },
        },
      y: 
        {
          min: 0,
          grid: {
            display: false,
          },
          position: "right",
          ticks: {
            color: "#B6B6BF",
            autoSkip: true,
            maxTicksLimit: 6,
            max: maxCount || 50,
          },
          border: {
            display: false,
          },
        },
    },
    animation: {
      easing: "linear",
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const chartId = "canvas-area-chart-id";

  const chartRef = Chart.getChart(chartId);

  useEffect(() => {
    if (!chartRef) {
      setDisplayChart(false);
      return;
    };
    const ctx = chartRef.getContext('2d').chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 700);
    gradient.addColorStop(
      0,
      hexConversion != ""
        ? `rgba(${hexConversion.red}, ${hexConversion.green}, ${hexConversion.blue}, 0)`
        : ""
    );
    gradient.addColorStop(
      1,
      hexConversion != ""
        ? `rgba(${hexConversion.red}, ${hexConversion.green}, ${hexConversion.blue}, 1)`
        : ""
    );
    setChartGradient(gradient);
    setTimeout(() => {
      setDisplayChart(true);
    }, 100);
  }, [chartRef, hexConversion, data]);

  return (
    <div style={{ 
        width: "100%", 
        height: height, 
        position: "relative",
        ...(!chartRef || !displayChart ? {opacity: 0} : {})
      }}>
      <ResponsiveContainer className="pl-3 pr-3" width="100%" height="100%">
        <MyLineChart
          id={chartId}
          data={{
            labels: data.labels,
            datasets: generateDatasetArray(chartGradient),
          }}
          options={options}
          redraw={false}
        />
      </ResponsiveContainer>
      {mulitipleData ? (
        <React.Fragment>
          {/* <div className="canvas-lable" style={{ background: data.colours[0], bottom: calculateValueLabelPosition(data.values[0], data.values[0][data.values[0].length - 1]) }}>{data.values[0][data.values[0].length - 1]}%</div>
                    <div className="canvas-lable" style={{ background: data.colours[1], bottom: calculateValueLabelPosition(data.values[1], data.values[1][data.values[1].length - 1]) }}>{data.values[1][data.values[1].length - 1]}%</div> */}
        </React.Fragment>
      ) : (
        // <div className="canvas-lable" style={{ background: colour, bottom: calculateValueLabelPosition(data.values, data.values[data.values.length - 1]) }}>{data.values[data.values.length - 1]}%</div>
        ""
      )}
    </div>
  );
};

export default AreaCharts;
