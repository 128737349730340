import React from "react";
import { Button, Modal } from "react-bootstrap";
import './mspClientPriceCalculator.scss';

export const InfoModal = ({
    show,
    label,
    children,
    buttonLabel = 'Ok',
    buttonOnClick = () => { },
    hideModal = () => { },
}) => {

    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName="info-modal"
            className="info-modal"
            aria-labelledby="info-modal"
            centered
            scrollable
            onHide={() => {
                hideModal();
            }}
        >
            <Modal.Header className="">
                <div className="f-500 f-16 f-black line-height-28 letter-space-2">
                    {label}
                </div>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button
                        className="hg-blue-btn w-100 mb-2"
                        onClick={() => {
                            buttonOnClick();
                            hideModal();
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default InfoModal;
