const AddMemberIcon = (props) => (
  <svg width="12" height="12" {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_62998_8267)">
    <path d="M7 7.126V11H2C1.99984 10.3894 2.13946 9.78695 2.40815 9.2387C2.67684 8.69044 3.06747 8.21097 3.55011 7.83702C4.03275 7.46307 4.59459 7.20457 5.19258 7.08132C5.79057 6.95808 6.40883 6.97336 7 7.126ZM6 6.5C4.3425 6.5 3 5.1575 3 3.5C3 1.8425 4.3425 0.5 6 0.5C7.6575 0.5 9 1.8425 9 3.5C9 5.1575 7.6575 6.5 6 6.5ZM9 8.5V7H10V8.5H11.5V9.5H10V11H9V9.5H7.5V8.5H9Z" fill="#616778"/>
    </g>
    <defs>
    <clipPath id="clip0_62998_8267">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default AddMemberIcon;
  