import React, { useState, useEffect, Fragment } from "react";
import "./CyberCompliance.css"; 
import $ from "jquery";
import "../../main.scss";
import { useDb } from "../context/db";

const CyberCompliance = (props) => {
   
  const [widthHeightPx] = useState(props.whpx || "120px");
  const [widthHeight] = useState(props.wh || "120");
  const [dataStroke] = useState(props.dataStroke || 15);
  const { dbObj } = useDb();
  const uniqueNum = props.id ? props.id : new Date().getUTCMilliseconds();
  const [fontSize, ] = useState(props.fontSize || false);

  function indicatorInit(id) {
    // indicator canvas
    var indicator = document.getElementById(id);
    var ctx = indicator.getContext("2d");
    var width = indicator.width;
    var height = indicator.height;
  
    indicator.setAttribute("width", width);
    indicator.setAttribute("height", height);
    indicator.style.width = width + "px";
    indicator.style.height = height + "px";
    // ctx.scale(2, 2);
  
    var percent = indicator.getAttribute("data-percent") / 100;
  
    var trackStartAngle = 40; // in degrees
    var trackEndAngle = 320; // in degrees
    var strokeWidth = indicator.getAttribute("data-stroke");
    var x = width / 2;
    var y = height / 2;
    var diameter = width > height ? height : width;
    var radius = diameter / 2 - strokeWidth / 2;
    var progressEndAngle =
      (trackEndAngle - trackStartAngle) * percent + trackStartAngle;
    var currentIteration = 0;
  
    function easeOutCubic(
      currentIteration,
      startValue,
      changeInValue,
      totalIterations
    ) {
      return (
        changeInValue *
          (Math.pow(currentIteration / totalIterations - 1, 3) + 1) +
        startValue
      );
    }
  
    function degreesToRadians(angle) {
      var orientCircle = 90;
      return (Math.PI / 180) * (angle + orientCircle);
    }
  
    function draw(timestamp) {
      ctx.clearRect(0, 0, width, height);
  
      var startRadians = degreesToRadians(trackStartAngle);
      var endRadians = degreesToRadians(trackEndAngle);
      var lingrad = ctx.createLinearGradient(
        x - diameter / 2.2,
        0,
        x + diameter / 3.3,
        0
      );
  
      ctx.lineWidth = strokeWidth;
  
      if (indicator.getAttribute("data-type") === "B") {
        lingrad.addColorStop(0, "rgba(255, 92, 91, .8)");
        lingrad.addColorStop(0.35, "rgba(255, 133, 58, .8)");
        lingrad.addColorStop(0.8, "rgba(255, 209, 29, .8)");
        lingrad.addColorStop(1, "rgba(59, 217, 143, .8)");
      } else {
        lingrad.addColorStop(1, "#008FD4");
      }
  
      // track
      ctx.strokeStyle = "#EBECF1";
      ctx.beginPath();
      ctx.arc(x, y, radius, startRadians, endRadians);
      ctx.stroke();
  
      // progress
      if (percent > 0) {
        var currentProgressEndAngle = easeOutCubic(
          currentIteration++,
          trackStartAngle,
          progressEndAngle,
          100
        );
        endRadians = degreesToRadians(currentProgressEndAngle);
        ctx.strokeStyle = lingrad;
        ctx.beginPath();
        ctx.arc(x, y, radius, startRadians, endRadians);
        ctx.stroke();
  
        // stop the animation when progress animates to final angle
        if (currentProgressEndAngle <= progressEndAngle) {
          window.requestAnimationFrame(draw);
        }
      }
    }
  
    if (indicator.getContext) {
      // start progress animation
      window.requestAnimationFrame(draw);
    }
  }
  useEffect(() => {
    // setdbObj(dbObj => dbObj);
    // console.log("test "+dbObj);

    var compliancePercent = $(`indicatorA${uniqueNum}`).attr("data-percent");

    $({ countPieChartA: 0 }).animate(
      { countPieChartA: compliancePercent },
      {
        easing: "swing",
        duration: 800,
        step: function () {
          $(".percentage").text(Math.ceil(this.countPieChartA) + "%");
        },
        complete: function () {},
      }
    );

    indicatorInit(`indicatorA${uniqueNum}`);
  }, [props.ccNumber]);

  return (
    <Fragment>
      {dbObj != 0 ? (
        <div className="wrapper mx-auto" style={{ width: widthHeightPx }}>
          <canvas
            id={`indicatorA${uniqueNum}`}
            data-type="A"
            data-percent={props.ccNumber}
            data-stroke={dataStroke}
            width={widthHeight}
            height={widthHeight}
          ></canvas>
          <span
            className={`percentage ${
              dbObj.SubscriptionDetail && dbObj.SubscriptionDetail.PreDataView
                ? "greyShadow"
                : ""
            }`}
            style={
              fontSize ? { fontSize: `${fontSize}px` } : { color: "inherit" }
            }
          >
            {props.ccNumber !== null && props.ccNumber > 0
              ? props.ccNumber
              : "0"}
            {/* {props.ccNumber == -2
              ? ""
              : props.ccNumber === null //dbObj.SubscriptionDetail && dbObj.SubscriptionDetail.PreDataView
              ? "0"
              : props.ccNumber} */}
            {props.ccNumber == -2 ? "" : "%"}
          </span>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};
export default CyberCompliance;
