import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setuser } from "../../../redux/userState/actions";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import TestNotificationModal from "../activeSessions/TestNotificationModal";
import InvitationModal from "../members/InvitationModal";
import "../members/members.scss";
import UserPageLoader from "../members/UserPageLoader";
import "../membersAccess/membersAccess.scss"
import MemberAccessRoleModal from "./MemberAccessRoleModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import UserDetailModal from "./UserDetailModal";
import LogoutAllDevicesModal from "./LogoutAllDevicesModal";
import ImageCropperModal from "./ImageCroperModal";
import PreviewAccessModal from "./PreviewAccessModal";
import AddEditCertificateModal from "../../MSPv2/secOps/trainingCertificates/AddEditCertificateModal";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { useSelector } from "react-redux";

const SaasMemberDetails = () => {
  const { state } = useLocation();
  const selectedUserId = state?.selectedUserId;
  const isSelectedUserLoggedIn = state?.isSelectedUserLoggedIn;
  const history = useHistory();
  const fileUpload = useRef();
  const [IsDropdownOpen, setIsDropdownOpen] = useState({ id: 0, state: false })
  const dispatch = useDispatch();
  let { member: user } = useParams();
  const { authTokens } = useAuth();
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [invitationModal, setInvitationModal] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [deviceList, setDeviceList] = useState(5);
  const [type, setType] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [roles, setRoles] = useState([])
  const [removingPhoto, setRemovingPhoto] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [cropperModal, setCropperModal] = useState(false);
  const [showLogoutAllDevicesModal, setShowLogoutAllDevicesModal] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [showPreviewAccessModal, setShowPreviewAccessModal] = useState(false);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [certificatesInfo, setCertificatesInfo] = useState([]);
  const [selectedCertificateData, setSelectedCertificateData] = useState({});
  const [categoryIdType, setCategoryIdType] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteMessage: "You are about to delete.",
    deleteSentQuestionnaire: () => { },
  });
  const [userImage, setUserImage] = useState(
    "https://st4.depositphotos.com/1012074/20946/v/450/depositphotos_209469984-stock-illustration-flat-isolated-vector-illustration-icon.jpg"
  );

  const mspDetail = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail : null;

  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  const setImage = (image) => {
    setUserImage(image);
  };

  const showCropperModal = () => {
    setCropperModal(true);
  };

  const hideCropperModal = () => {
    setCropperModal(false);
  };

  const hideDetailModal = () => {
    setDetailModal(false)
  };
  const showAsNameModal = () => {
    setType("name");
    setDetailModal(true);
  };
  const showAsPositionModal = () => {
    setType("position");
    setDetailModal(true);
  };

  const hideNotificationModal = () => {
    setNotificationModal(false);
  };
  const hideDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const hideInvitationModal = () => {
    setInvitationModal(false);
  };

  useEffect(() => {
    setMainPageLoading(true);
    getUserData();
    getUserInfo();
    getCertificatesInfo();
  }, []);

  async function getUserInfo(openTeamModal = null) {
    await getAPIData("851", authTokens, {
      optionEnum99: 42,
      optionStrEnum1: selectedUserId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setUserInfo(response);
        setRoles(response?.roles);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function getUserData() {
    await getAPIData("101", authTokens, {
      optionEnum99: 42
    }).then((response) => {
      setMainPageLoading(false);
      if (response.mr?.Success) {
        let data = response?.UserAndDevices.filter(
          (item) => item?.UserDetails?.objId_UserSettings == user
        )[0];
        setUserData(data);
      }
    });
  }

  async function getCertificatesInfo() {
    await getAPIData("806", authTokens, {
      optionEnum99: 42,
      //   optionEnum98: mspClientId,
      optionStrEnum1: selectedUserId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setCertificatesInfo(response);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  // async function getMFAstatus() {
  //   const response = await getAPIData(102, authTokens, {
  //     optionEnum1: userInfo?.objId_UserSettings,
  //     optionEnum99: 42,
  //     // accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
  //   });
  //   if (response?.mr?.Success) {
  //     setUserData(response);
  //     console.log("dispatched");
  //     dispatch(setuser({ FullName: response?.FullName }));
  //   }
  // }

  async function changeMemberRole(roleId, userId, roleAction) {
    await postRequest(authTokens, "UpdateUserAccessRole", {
      objId_AccessRole: roleId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: userId,
      action: roleAction,
      optionEnum99: 42
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Access role changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }


  async function changeMemberDashboard(dashboardId, roleAction) {
    await postRequest(authTokens, "AssignDashboard", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: selectedUserId,
      objId_UserSettings: userInfo?.objId_UserSettings,
      objId_Dashboard: dashboardId,
      action: roleAction,
      optionEnum99: 42
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Dashboard changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function changeMemberDefaultDashboard(dashboardId) {
    await postRequest(authTokens, "AssignDefaultDashboard", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      userId: selectedUserId,
      objId_UserSettings: userInfo?.objId_UserSettings,
      objId_Dashboard: dashboardId,
      optionEnum99: 42
    }
    )
      .then((response) => {
        if (response?.Success) {
          CallToast("Default dashboard changed successfully", false, false, "v2style");
          getUserInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function RemoveDevice(enum1) {
    await getAPIData(105, authTokens, {
      optionEnum1: enum1,
      optionEnum99: 42,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
    })
      .then((result) => {
        if (result.Success) {
          TrackUser("Device deleted");

          CallToast("Device removed successfully", false, false, "v2style");
        } else {
          CallToast("Unable to update", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }


  async function resetData(id) {
    const response = await getAPIData(id, authTokens, {
      optionEnum1: userInfo?.objId_UserSettings,
      optionEnum99: 42
    });

    if (response.Success) {
      if (Number(id) === 108) {
        CallToast("MFA reset email sent", false, false, "v2style");
      }

    } else {
      CallToast("Something went wrong", false);
    }
  }

  async function resetPassword() {
    const response = await getAPIData("107", authTokens, {
      optionEnum1: userInfo?.objId_UserSettings,
      optionEnum99: 42
    });

    if (response.Success) {
      CallToast("Password reset email sent", false, false, "v2style");

    } else {
      CallToast("Something went wrong", false);
    }
  }

  async function removePhoto() {
    setRemovingPhoto(true);
    await getAPIData("852", authTokens, {
      optionStrEnum1: selectedUserId,
      optionEnum99: 42
    }).then((response) => {
      setRemovingPhoto(false);
      if (response.Success) {
        dispatch(setuser({ Avatar: "No URL" }));
        CallToast("Photo removed", false, false, "v2style");
        getUserInfo();
        getUserData();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function deleteCertification(certificationId) {
    await postData(authTokens, "Certification/ArchiveOrDelete", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      objId_Certification: certificationId,
      userId: selectedUserId,
      optionEnum99: 42
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Certification deleted successfully",
            true,
            false,
            "v2style"
          );
          getCertificatesInfo();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const DeleteUserModal = ({ show, hideModal }) => {
    async function handleDeleteUser() {
      await getAPIData("109", authTokens, {
        optionEnum1: userInfo?.objId_UserSettings,
        optionEnum99: 42
      }).then((response) => {
        hideModal();
        if (response.Success) {
          CallToast(
            " <img src='/images/settings-v2/bin.svg' class='mr-2'/>&nbsp;&nbspAccount successfully deleted",
            false,
            false
          );
          history.push("/settings/v2/memberaccess");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
    return (
      <Modal
        animation={false}
        show={show}
        dialogClassName="delete-user-modal"
        className=" "
        aria-labelledby="delete-user-modal"
        centered
        onHide={hideModal}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div className="permission mb-3 mt-3">
                Are you sure you want to delete this account?
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-flex align-items-center">
                {userData?.UserDetails?.showInitials ? (
                  <ImageWithInitials
                    initials={userData.UserDetails.Initials}
                    width={40}
                    background={"purple"}
                  />
                ) : (
                  <img
                    alt=""
                    src={userInfo?.ImageURL}
                    className="user-avatar"
                    style={{
                      width: "40px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                )}

                <div className="ml-3">
                  <p className="user-name m-0">
                    {userData?.UserDetails?.FullName}
                  </p>
                  <p className="user-email m-0">
                    {userData?.UserDetails?.UserName}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button className="cancel-btn" onClick={hideModal}>
              No, Cancel
            </Button>
            <Button
              className="delete-btn"
              onClick={() => {
                handleDeleteUser(userData?.UserDetails?.objId_UserSettings);
              }}
            >
              Yes, delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  // async function handleUserStatus(deviceID) {
  //   await getAPIData("106", authTokens, {
  //     optionEnum1: user,
  //   }).then((response) => {
  //     if (response.Success) {
  //       CallToast("Updated successfully", false, false, "v2style");
  //     } else {
  //       CallToast("Something went wrong", false, false, "v2style");
  //     }
  //   });
  // }

  const userDetails = useSelector((state) => state.user?.userDetail);


  return (
    <>
      {/* {
        accessState?.Override === true && accessState?.AccessState_UserManagement === 0 ?
          <>
            <Fragment >
              <div className="user-wrapper">
                <div className="d-flex justify-content-start align-items-center pointer minusTopMargin"
                  onClick={() => {
                    history.push("/settings/v2/memberaccess")
                  }}
                >
                  <img
                    alt=""
                    src="/images/leftarrow.png"
                    className="mr-4 pointer"
                    onClick={() => { }}
                  />
                  <div className="user-title">{userInfo?.FullName} </div>
                </div>
              </div>
              <div className="w-100 h-75 d-flex flex-column align-items-center justify-content-center">
                <img
                  alt=""
                  className="bigLockerImage"
                  src="/images/settings-v2/locker.svg"
                />
                <div className="text-center mt-2">
                  You don't have access to this data
                </div>
              </div>
            </Fragment>
          </> : <> */}
            <Fragment>
              {mainPageLoading && <UserPageLoader />}
              <div className={`user-wrapper  ${mainPageLoading ? "d-none" : ""}  `}>
                <div className="d-flex justify-content-start align-items-center pointer"
                  onClick={() => {
                    history.push("/settings/v2/memberaccess")
                  }}

                >
                  <img
                    alt=""
                    src="/images/leftarrow.png"
                    className="mr-4 pointer"
                    onClick={() => { }}
                  />
                  <div className="user-title">{userInfo?.FullName} </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center my-4">
                    {userData?.UserDetails?.showInitials && userInfo?.ImageURL === '' ? (
                      <ImageWithInitials
                        initials={userData.UserDetails.Initials}
                        width={40}
                        background={"purple"}
                      />
                    ) : (
                      <img
                        alt=""
                        src={userInfo?.ImageURL}
                        className="user-avatar "
                        style={{
                          width: "40px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileUpload}
                      className="d-none"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          const reader = new FileReader();
                          reader.readAsDataURL(e.target.files[0]);

                          reader.onload = function () {
                            setSelectedImage(reader.result);
                            showCropperModal();
                          };
                        }
                      }}
                    />

                    <div className="ml-3">
                      <p className="userName m-0">{userInfo?.FullName}</p>
                      <p className="userEmail m-0">{userInfo?.Email}</p>
                    </div>


                  </div>
                  <div>
                    <Button
                      className="changeMemberPhotoButton"
                      onClick={() => {
                        fileUpload.current.click();
                        getUserInfo();
                        getUserData();
                      }}
                    >
                      Upload photo
                    </Button>
                    {
                      userInfo?.ImageURL && userInfo?.ImageURL !== "" ? (
                        <Button
                          className="removeMemberPhotoButton"
                          onClick={() => {
                            removePhoto();
                          }}
                        >
                          {removingPhoto ? (
                            <>
                              <Spinner
                                size="sm"
                                className=" mr-2"
                                animation="border"
                                variant="secondary"
                              />
                              Removing...
                            </>
                          ) : (
                            "Remove photo"
                          )}
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  </div>
                </div>
                <hr className="m-0 mb-3 p-0" />

                {/* Devices details */}

                <div className="personal-info">
                  <h4>Company</h4>
                  <div className="bg-grey radius-8 border-parent">
                    <div
                      className="d-flex align-items-center justify-content-between border-item p-3"
                    >
                      <div className="f-14 f-500 f-black">Company</div>
                      <div className="mr-4 f-400 greyText2">{userDetails?.OrgName}</div>

                    </div>
                  </div>
                </div>

                <h4>Personal info</h4>

                <div className="bg-grey radius-8 border-parent">
                  <div
                    className="d-flex align-items-center justify-content-between border-item p-3"
                    onClick={showAsNameModal}
                  >
                    <div className="f-14 f-500">Name</div>
                    <div className="f-grey d-flex align-items-center pointer my-2">
                      <div className="mr-4">{userInfo?.FullName}</div>
                      <div>
                        <img src="/images/chevron-right.svg" className="mr-2" alt="" />
                      </div>
                    </div>

                  </div>
                  <div className="d-flex align-items-center justify-content-between border-item p-3">
                    <div className="f-14 f-500">Email</div>
                    <div className="f-grey d-flex align-items-center pointer my-2">
                      <div className="mr-2">{userInfo?.Email}</div>
                    </div>

                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between border-item p-3"
                    onClick={() => {
                      showAsPositionModal();
                      getUserInfo();
                    }}
                  >
                    <div className="f-14 f-500">Position</div>
                    <div className="f-grey d-flex align-items-center pointer my-2">
                      <div className="mr-4">{userInfo?.Position}</div>
                      <div>
                        <img src="/images/chevron-right.svg" className="mr-2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <h4>Access roles</h4>

                <div className="bg-grey radius-8 border-parent">
                  <div className="border-item d-flex align-items-center justify-content-between p-3">
                    <div className="f-500">Access roles</div>
                    <div className="d-flex align-items-center">
                      <div className="f-darkgrey mr-3">
                        {
                          userInfo?.AccessRoles.length < 30 ?
                            userInfo?.AccessRoles : `${userInfo?.AccessRoles.slice(0, 30)}...`
                        }
                      </div>

                      <Dropdown
                        className="hg-white-dropdown user-detail-dropdown transparennt-wrapper-dropdown"
                        onToggle={(e) => {
                          setIsDropdownOpen(e)
                        }}
                      >
                        <Dropdown.Toggle
                          disabled={(mspDetail !== null && true) || (userInfo?.IsLoggedInUser === true ? true : false)}
                          className={`  f-blue ${IsDropdownOpen ? "opened" : ""}`}
                        >
                          {
                            userInfo?.IsLoggedInUser !== true &&
                            <div className="f-blue">
                              {
                                userInfo?.AccessRoles?.length < 1 ? 'Select' : 'Change'
                              }
                            </div>
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div
                            className="p-2 pl-3 my-1 pointer f-darkgrey"
                            style={{ 'display': 'grid', 'grid-template-columns': '10% 90%' }}
                            onClick={(e) => {
                              setShowAccessLevelModal(true);
                              e.target.closest(".hg-white-dropdown").click();
                              getUserInfo();
                            }}
                          >
                            <img
                              alt=""
                              src="/images/settings-v2/lightgrey-plus-icon.svg"
                              className="mr-2"
                            />
                            <div>Create Access Role</div>
                          </div>
                          <div
                            style={{
                              maxHeight: '200px',
                              overflowY: 'auto',
                            }}
                          >
                            {roles.map(el => {
                              return (<div
                                key={el.RoleName}
                                className="p-2 pl-3 my-1 pointer f-grey"
                                style={{ 'display': 'grid', 'grid-template-columns': '10% 90%' }}
                                onClick={(e) => {
                                  const memberRolesArray = userInfo?.objId_AccessRoles;
                                  let action;

                                  if (memberRolesArray) {
                                    const isRoleHere = memberRolesArray?.find(
                                      (item) => item === el.objId_AccessRole
                                    );
                                    action = isRoleHere === el.objId_AccessRole ? 0 : 1;
                                  } else {
                                    action = 1;
                                  }

                                  if (isSelectedUserLoggedIn === true) {
                                    if (memberRolesArray.length === 1 && action === 0) {
                                      CallToast("Member role can not be empty", false, false, "v2style");
                                    }
                                  } else {
                                    changeMemberRole(el.objId_AccessRole, selectedUserId, action);
                                  }
                                  getUserInfo();
                                  e.target.closest(".hg-white-dropdown").click();
                                }}
                              >
                                <div>
                                  {userInfo?.objId_AccessRoles?.map((membRoleId) => (
                                    membRoleId === el.objId_AccessRole ?
                                      <img
                                        alt=""
                                        src="/images/settings-v2/check-icon.svg"
                                        className="mr-2"
                                      />
                                      : <></>
                                  ))
                                  }
                                </div>

                                <div>
                                  {el.RoleName}
                                  <div className="smallGreyText text-ellipsis f-darkgrey">{el.RoleDescription}</div>
                                </div>

                              </div>)
                            })}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>
                  </div>
                  <div className="border-item d-flex align-items-center justify-content-between px-3 py-2">
                    <div className="f-500 f-grey f-14">Preview access</div>
                    <div
                      onClick={() => {
                        setShowPreviewAccessModal(true);
                      }}
                    >
                      <img src="/images/chevron-right.svg" className="mr-2 pointer" alt="" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-between mb-2 mt-3">
                    <h4>Training Certificates</h4>
                    <div>
                      <Button
                        disabled={(accessState?.Override === true && accessState?.AccessState_UserManagement >= 99) ? false : true}
                        onClick={() => {
                          setShowCertificateModal(true);
                        }}
                      >
                        <div>
                          <img
                            alt=""
                            src="/images/settings-v2/plus-icon.svg"
                            className="mr-2"
                          />
                          Add Certificate
                        </div>
                      </Button>
                    </div>
                  </div>

                  <div className="border-parent radius-8 ">
                    {
                      certificatesInfo?.items && certificatesInfo?.items[0] ? (
                        <>
                          {certificatesInfo?.items?.map((item, index) => {
                            return (
                              <div
                                className={`
                                  border-item bg-hover-row d-flex align-items-center justify-content-between p-3
                                  ${accessState?.Override === true && 
                                    accessState?.AccessState_UserManagement >= 99 ? 
                                      ' pointer ' : ' cursor-default ' 
                                  }  
                                `}
                              >
                                <div
                                  className={`
                                    d-flex align-items-center overlap_parent w-100
                                    ${accessState?.Override === true && 
                                      accessState?.AccessState_UserManagement >= 99 ? 
                                        ' pointer ' : ' cursor-default ' 
                                    }
                                  `}
                                  onClick={() => {
                                    if (accessState?.Override === true && accessState?.AccessState_UserManagement >= 99) {
                                      setSelectedCertificateData(item);
                                      setShowCertificateModal(true);
                                    }
                                  }}
                                >
                                  {item?.certificateIcon?.showInitials ? (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                      <ImageWithInitials
                                        initials={item?.certificateIcon?.initials}
                                        width={33}
                                        background={"purple"}
                                      />
                                      {item?.certificateIcon?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            item?.certificateIcon?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                                      <img
                                        width={33}
                                        src={`/images/msp/framework-certificates/${item?.certificateIcon?.icon}`}
                                        className="rounded-full"
                                        alt=""
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            "/images/msp/default-cert-icon.svg";
                                        }}
                                      />
                                      {item?.certificateIcon?.statusIcon && (
                                        <img
                                          width={13}
                                          src={
                                            "/images/msp/" +
                                            item?.certificateIcon?.statusIcon
                                          }
                                          className="rounded-full overlapping_icon"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                  <div className="ml-3">
                                    <div className="f-500">
                                      {item?.name}
                                      &nbsp; &nbsp;
                                      {item?.status && (
                                        <span
                                          style={{
                                            color: item?.status?.foreColor,
                                          }}
                                        >
                                          {item?.status?.text}
                                        </span>
                                      )}
                                    </div>
                                    <div className="f-darkgrey">
                                      {item?.points?.map((point, idx) => (
                                        <span>
                                          {idx > 0 ? " • " : ""} {point}
                                        </span>
                                      ))}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  {item?.linkUrl && (
                                    <div
                                      className={`
                                        d-flex align-items-center f-12 f-darkgrey mr-2
                                        ${accessState?.Override === true && 
                                          (accessState?.AccessState_UserManagement >= 99 || accessState?.AccessState_UserManagement === 1) ? 
                                            ' pointer ' : ' cursor-default ' 
                                        }
                                      `}
                                      onClick={() => {
                                        if (accessState?.Override === true && (accessState?.AccessState_UserManagement >= 99 || accessState?.AccessState_UserManagement === 1)) {
                                          const win = window.open(`${item?.linkUrl}`, "_blank");
                                          win.focus();
                                        }
                                      }}
                                    >
                                      <img
                                        src={"/images/msp/msp-cert-link-icon.svg"}
                                        alt=""
                                        className="mr-2  "
                                      />
                                      <div className="h-17px">
                                        Link
                                      </div>
                                    </div>
                                  )}
                                  {item?.fileUrl && (
                                    <div
                                      className={`
                                        d-flex align-items-center f-12 f-darkgrey mr-2
                                        ${accessState?.Override === true && 
                                          (accessState?.AccessState_UserManagement >= 99 || accessState?.AccessState_UserManagement === 1) ? 
                                            ' pointer ' : ' cursor-default ' 
                                        }
                                      `}
                                      onClick={() => {
                                        if (accessState?.Override === true && (accessState?.AccessState_UserManagement >= 99 || accessState?.AccessState_UserManagement === 1)) {
                                          downloadFile(
                                            item?.fileUrl,
                                            item?.fileName
                                          );
                                        }
                                      }}
                                    >
                                      <img
                                        src={"/images/msp/msp-cert-file-icon.svg"}
                                        alt=""
                                        className="mr-2  "
                                      />
                                      <div className="h-17px">
                                        File
                                      </div>
                                    </div>
                                  )}
                                  {item?.showOnlyAddButton && (
                                    <div
                                      className={`
                                        d-flex align-items-center f-12 f-darkgrey border1 px-2 radius-2 mr-2
                                        ${accessState?.Override === true && 
                                          accessState?.AccessState_UserManagement >= 99 ? 
                                            ' pointer ' : ' cursor-default ' 
                                        }
                                      `}
                                      onClick={() => {
                                        if (accessState?.Override === true && accessState?.AccessState_UserManagement >= 99) {
                                          setSelectedCertificateData(item);
                                          setShowCertificateModal(true);
                                        }
                                      }}
                                    >
                                      <img
                                        src={"/images/msp/msp-cert-add-icon.svg"}
                                        alt=""
                                        className="mr-2  "
                                      />
                                      Add
                                    </div>
                                  )}
                                  <div
                                    className={`
                                      d-flex align-items-center f-12 f-darkgrey ml-1
                                      ${accessState?.Override === true && 
                                        accessState?.AccessState_UserManagement >= 99 ? 
                                          ' pointer ' : ' cursor-default ' 
                                      }
                                    `}
                                    onClick={() => {
                                      if (accessState?.Override === true && accessState?.AccessState_UserManagement >= 99) {
                                        setShowDeleteModal({
                                          showModal: true,
                                          deleteMessage:
                                            "You are about to delete a training certificate.",
                                          deleteSentQuestionnaire:
                                            () =>
                                              deleteCertification(item?.objId_Certification)
                                        });
                                      }
                                    }}
                                  >
                                    <img
                                      src={"/images/complianceManager/deleteIcon.svg"}
                                      alt=""
                                      className=""
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="p-3 f-grey">No certificates for this user yet</div>
                      )
                    }
                  </div>
                </div>
{/* 
                <div className="mb-2 mt-3">Dashboard</div>

                <div className="bg-grey radius-8 border-parent">
                  <div className="border-item d-flex align-items-center justify-content-between p-3">
                    <div className="f-500">Available dashboards</div>
                    <div className="d-flex align-items-center">


                      <Dropdown
                        className="hg-white-dropdown user-detail-dropdown transpare nnt-wrapper-dropdown"
                        onToggle={(e) => {
                          setIsDropdownOpen(e)
                        }}
                      >
                        <Dropdown.Toggle
                          disabled={mspDetail !== null && true}
                          className={`  f-blue custom-dropdown-toggle f-500 ${IsDropdownOpen ? "opened" : ""
                            }`}
                        >
                          {userInfo?.dashBoardTypes?.map((dashboardItem, dashboardItemIndex) => (
                            dashboardItem?.isSelected ?
                              `${dashboardItem?.name}, ` : ''
                          ))}

                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-2"
                          style={{
                            width: "320px"
                          }}

                        >
                          <div className="f-darkgrey">
                            Available dashboards
                          </div>
                          <div>
                            {userInfo?.dashBoardTypes?.map((dashboardItem, dashboardItemIndex) => {
                              return (
                                <div className="d-flex align-items-ceter bg-hover-grey pointer justify-content-between p-2 parent-visible">
                                  <div
                                    className="d-flex align-items-center f-grey"
                                    onClick={() => {
                                      if (dashboardItem?.isDefault) {
                                        CallToast("Cannot unassign the only default dashboard. Please set another dashboard as default before unassigning.", false, false, "v2style");
                                      } else {
                                        let action = dashboardItem.isSelected ? 0 : 1;
                                        changeMemberDashboard(dashboardItem?.objId_Dashboard, action);
                                      }
                                    }}
                                  >
                                    <img
                                      src="/images/msp/dropdown-grey-tick.svg"
                                      className="mr-2"
                                      alt=""
                                      style={{
                                        visibility: dashboardItem.isSelected ? "visible" : "hidden"
                                      }}
                                    />

                                    {dashboardItem.name}
                                  </div>
                                  {
                                    dashboardItem.isDefault ? (
                                      <div className="f-blue">
                                        Default
                                      </div>
                                    ) : dashboardItem.isSelected ? (
                                      <div
                                        className="f-darkgrey child-visible"
                                        onClick={() => {
                                          changeMemberDefaultDashboard(dashboardItem?.objId_Dashboard)
                                        }}
                                      >
                                        Make default
                                      </div>
                                    ) : (
                                      <></>
                                    )
                                  }

                                </div>

                              )
                            })

                            }
                          </div>


                        </Dropdown.Menu>
                      </Dropdown>

                    </div>

                  </div>

                </div>


                <p className="topic-heading mb-2 mt-3">Devices</p>
                <div className="devices">
                  <Row>
                    <div className="devices-wrapper w-100">
                      {
                        userInfo?.UserDevices?.map((device) => (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between device-wrap">
                              <div className="d-flex align-items-center">
                                <img
                                  alt=""
                                  src="/images/mobile.svg"
                                  className="mr-3"
                                />
                                <div className="device-name">
                                  {device.Detail}
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="device-date">
                                  {device.DateAdded}
                                </div>
                                {
                                  device.DeviceId !== 0 && (
                                    <Dropdown className="device-dropdown black-dropdown">
                                      <Dropdown.Toggle>
                                        <img
                                          alt=""
                                          src="/images/horizontal-dots.svg"
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <div>
                                          <Row>
                                            <Col
                                              xs={12}
                                              onClick={(e) => {
                                                setNotificationModal(true);
                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                              }}
                                            >
                                              <div className="notification-btn bg-hover-grey-light">
                                                Send Notifications
                                              </div>
                                            </Col>
                                            <hr
                                              style={{
                                                width: "70%",
                                                margin: "auto",
                                              }}
                                            />
                                            <Col
                                              xs={12}
                                              onClick={(e) => {
                                                RemoveDevice(device?.DeviceId);
                                                e.target
                                                  .closest(".device-dropdown")
                                                  .click();
                                                getUserInfo();
                                              }}
                                            >
                                              <div className="remove-btn delete-btn bg-hover-grey-light">
                                                Remove
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )
                                }
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </div>
                  </Row>
                </div>

                {
                  <div className="d-flex justify-content-between">
                    {userData?.Device_Count > 0 ? (
                      <div
                        className={`show-more-btn  mt-3  ${deviceList > 5 ? "arrow-down" : "arrow-up"
                          } `}
                        onClick={() => {
                          if (deviceList > 5) {
                            setDeviceList(5);
                          } else {
                            setDeviceList(1000);
                          }
                        }}
                      >
                        {deviceList > 5 ? "Show less" : "Show more"}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {
                      userData?.Device_Count > 0 &&
                      <Button
                        className="reset-btn mt8px"
                        onClick={() => {
                          setShowLogoutAllDevicesModal(true);
                          getUserInfo();
                        }}
                      >
                        Logout all
                      </Button>
                    }
                  </div>
                } */}

                {/* User Settings */}
                <h4>Security</h4>
                <div className="user-settings">
                  <Row>
                    <div className="settings-wrapper w-100">
                      <>
                        <Col xs={12}>
                          <div className="d-flex justify-content-between settings-wrap">
                            <div className="">
                              <p className="title m-0">Reset MFA</p>
                              <p className="subtitle m-0">
                                Reset MFA for this user
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="reset-btn"
                                style={{
                                  width: '70px'
                                }}
                                onClick={() => {
                                  resetData("108");
                                  getUserInfo();
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <hr className=" m-0" />
                        <Col xs={12}>
                          <div className="d-flex justify-content-between settings-wrap">
                            <div className="">
                              <p className="title m-0"> Reset Password</p>
                              <p className="subtitle m-0">
                                We will send email to your email with reset
                                instruction
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="reset-btn"
                                style={{
                                  width: '70px'
                                }}
                                onClick={() => {
                                  resetPassword();
                                }}
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <hr className=" m-0" />
                        <Col xs={12}>
                          <div className="d-flex justify-content-between settings-wrap">
                            <div className="">
                              <p className="title m-0"> Delete user</p>
                              <p className="subtitle m-0">
                                This action can't be undone
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="reset-btn"
                                style={{
                                  width: '70px'
                                }}
                                onClick={() => {
                                  setDeleteUserModal(true);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </>
                    </div>
                  </Row>
                </div>
                <p className="date-status m-0 mt-3">Added: {userInfo?.AddedOn}</p>
                <UserDetailModal
                  type={type}
                  userDetails={userInfo}
                  memberId={selectedUserId}
                  show={detailModal}
                  hideModal={hideDetailModal}
                  refreshData={() => {
                    getUserInfo();
                    getUserData();
                  }}
                />
              </div>
              <DeleteUserModal show={deleteUserModal} hideModal={hideDeleteUserModal} />
              <InvitationModal show={invitationModal} hideModal={hideInvitationModal} />
              <ImageCropperModal
                show={cropperModal}
                hideModal={hideCropperModal}
                profileImage={selectedImage}
                setProfileImage={setImage}
                isMemberImage={true}
                memberId={selectedUserId}
                refreshData={getUserInfo}
              />
              <TestNotificationModal
                show={notificationModal}
                hideModal={hideNotificationModal}
                isForMember={true}
              />
              <MemberAccessRoleModal
                accessLevelId={0}
                show={showAccessLevelModal}
                hideModal={() => {
                  setShowAccessLevelModal(false);
                }}
                parentID="mainBody"
                refreshData={() => {
                  setMainPageLoading(true);
                  getUserInfo();
                }}
              />
              <LogoutAllDevicesModal
                show={showLogoutAllDevicesModal}
                hideModal={() => {
                  setShowLogoutAllDevicesModal(false);
                }}
                userId={selectedUserId}
                userSettingsId={userInfo?.objId_UserSettings}
              />
              <PreviewAccessModal
                show={showPreviewAccessModal}
                hideModal={() => {
                  setShowPreviewAccessModal(false);
                }}
                userId={selectedUserId}
              />
              <AddEditCertificateModal
                show={showCertificateModal}
                categoryId={categoryIdType}
                hideModal={() => {
                  setCategoryIdType(null);
                  setShowCertificateModal(false);
                  setSelectedCertificateData({});
                }}
                selectedUserId={userInfo?.userId}
                selectedCertificateId={
                  selectedCertificateData?.objId_Certification ?? 0
                }
                passedUserId={userInfo?.userId}
                passedOptionEnum99={42}
                refreshData={() => {
                  getCertificatesInfo();
                  getUserInfo();
                }}
              />
              <DeleteModal
                show={showDeleteModal.showModal}
                hideModal={() =>
                  setShowDeleteModal({
                    showModal: false,
                  })
                }
                deleteMessage={showDeleteModal.deleteMessage}
                deleteType={"deleteSentQuestionnaire"}
                deleteSentQuestionnaire={() => {
                  showDeleteModal.deleteSentQuestionnaire();
                }}
              />
            </Fragment>
          {/* </> */}
      {/* } */}
    </>
  );
};

export default SaasMemberDetails;

function downloadFile(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}