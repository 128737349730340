import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../../Utils/StringTrimmer";
import "./dropdown.scss";
import useDropdown from "../../../../hooks/dropdown.hook";

const CompanySettingsDropdown = (props) => {
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false })
  return (
    <Dropdown
      className={`${props?.isGrayToggle ? 'custom_drop-down-gray' : 'custom_drop-down'} ${props.transparentBackgroundToggle ? "bg-transparent" :""}    ${props.error ?  "input-error" :""}`}
      onToggle={(e) => {
        setIsDropdownOpen(e);
        setIsOpen(prev => !prev)
      }}
      show={isOpen}
    >
      <Dropdown.Toggle
        ref={refBtn}
        id={props.toggleId}
        className={`company-dropdown ${props.selected ? "selected" : ""}   ${
          IsDropdownOpen ? "opened" : ""
        } ${props.disabled && props.disabledIcon ? "locked" : ""}
        
        
        
        `}
      >
        {props.children ? (
          props.children
        ) : (
          <div
            className={`selected-option d-flex ${
              props.disabled ? "f-darkgrey" : ""
            }`}
          >
            {props?.selected ? (
              props?.showid ? (
                props?.selected[props.fieldId]
              ) : (
                TrimString(
                  props?.selected[props.fieldValue]?.replaceAll("_", " "),
                  props.trimStringSelected ?? 30
                )
              )
            ) : (
              <span className="drop-placeholder">{props?.placeholder}</span>
            )}
            {props.disabled && props.disabledIcon ? (
              <>
                {" "}
                <img
                  width={14}
                  height={14}
                  alt=""
                  src="/images/attack-surface/small-lock.svg"
                  className="ml-1"
                  style={{ marginRight: "-2rem" }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </Dropdown.Toggle>
      {!props.disabled && (
        <Dropdown.Menu
          ref={refMenu}
          className={`${
            props.rightAlign ? "right-align" : "left-align"
          } hide-scrollbar  ${props.DropDownMenuClassName ?? ""}`}
          style={{
            zIndex: 5,
            maxHeight: props?.maxHeight ?? "200px",
            overflowY: "auto",
          }}
        >
          {props?.data?.map((val) => (
            <div
              className={`dropdown_ite m mx-1 pointer d-flex contextmenu-grey-item
              
              ${!props.selectionArrowAtLeft ? "justify-content-between" : ""}
              
              ${
                props?.selected &&
                props?.selected[props.fieldId] == val[props.fieldId]
                  ? "selected"
                  : ""
              }`}
              onClick={(e) => {
                props.onClick(
                  val[props.fieldId],
                  val[props.fieldValue],
                  e,
                  val
                );
                {
                  !props.controlClosing &&
                    e.target.closest(".custom_drop-down").click();
                  setIsOpen(false);
                }
              }}
              key={val[props.fieldId]}
              data-id={val[props.fieldId]}
              data-value={val[props.fieldValue]}
            >
              {props.selectionArrowAtLeft && (
                <img
                  src={"/images/settings-v2/black-tick.svg"}
                  className="float-left tick"
                  alt=""
                  style={{
                    visibility: `${
                      props.selected &&
                      props.selected[props.fieldId] == val[props.fieldId]
                        ? "visible"
                        : "hidden"
                    }`,

                    filter: `${
                      props.greyTextAndArrowTheme
                        ? "invert(46%) sepia(2%) saturate(3030%) hue-rotate(187deg) brightness(84%) contrast(85%)"
                        : ""
                    }`,
                  }}
                />
              )}
              <span
                className={`${props.selectionArrowAtLeft ? "pl-1" : ""}
              
              ${props.greyTextAndArrowTheme ? "f-grey" : ""}
              
              `}
              >
                {" "}
                {TrimString(
                  val[props.fieldValue]?.replaceAll("_", " "),
                  props.trimStringItem ?? 54
                )}
              </span>
              {!props.selectionArrowAtLeft &&
              props.selected &&
              props.selected[props.fieldId] == val[props.fieldId] ? (
                <img
                  // src={"/images/settings-v2/black-tick.svg"}
                  src={"/images/msp/dropdown-grey-tick.svg"}
                  className="float-right tick"
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default CompanySettingsDropdown;

export const CustomCompanySettingsDropdown = (props) => {
  return (
    <Dropdown className="custom_drop-down-adjustable">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}`}>
        <div className="selected-option">
          {props?.selected ? (
            props?.showid ? (
              props?.selected[props.fieldId]
            ) : (
              TrimString(
                props?.selected[props.fieldValue]?.replaceAll("_", " "),
                30
              )
            )
          ) : (
            <span className="drop-placeholder">{props?.placeholder}</span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props?.data?.map((val) => (
          <div
            className={`dropdown_item ${
              props?.selected &&
              props?.selected[props.fieldId] == val[props.fieldId]
                ? "selected"
                : ""
            }`}
            onClick={(e) => {
              props.onClick(val[props.fieldId], val[props.fieldValue], e, val);
              {
                !props.controlClosing &&
                  e.target.closest(".custom_drop-down-adjustable").click();
              }
            }}
            key={val[props.fieldId]}
            data-id={val[props.fieldId]}
            data-value={val[props.fieldValue]}
          >
            {TrimString(val[props.fieldValue]?.replaceAll("_", " "), 54)}
            {props.selected &&
            props.selected[props.fieldId] == val[props.fieldId] ? (
              <img
                src={"/images/settings-v2/black-tick.svg"}
                className="float-right tick"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
